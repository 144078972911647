import React from "react";
import { Link } from "react-router-dom";
import PharmacyDataTable from "../../components/Pharmacy/PharmacyDataTable";
import CustomSelect from "../../components/common/CustomSelect";
import Searchbar from "../../components/common/Searchbar";

// img svg
import RightArrow from "../../assets/images/doctor/RightArrow.svg";
import { useState } from "react";
import PharmacyOrderList from "../../components/Pharmacy/PharmacyOrderList";
import MedicalEquipmentOrderList from "../../components/MedicalEquipment/MedicalEquipmentOrderList";

import BreadCrum from "../../atoms/breadcrum/BreadCrum";
import usePost from "../../customHook/usePost";
import { useEffect } from "react";
import ListHeader from "../../molecules/ListHeader/ListHeader";
import RoomsTableListHeader from "../../molecules/HospitalRoomManagement/RoomsTableListHeader";
import FiltersHelper from "../../molecules/HospitalRoomManagement/helper";
import select from "../../utilities/data/list.select";

const PharmacyShopDetail = () => {

  const [orderListState, setOrderListState] = useState();
  const orderlistPost = usePost();

  useEffect(() => {
    orderlistPost?.postData(
      `${process.env.REACT_APP_GET_GET_ORDERS}?pharmacy=${0}`,
      {},
      (res) => {
        console.log("ressss", res?.data?.data);
        setOrderListState(res);
      }
    );
  }, []);

  const dataaa =
    orderListState?.data?.map((m) => [
      m?.id,
      m?.user?.name,
      process.env.REACT_APP_IMAGE_URL + m?.user?.profile_pic,
      m?.user?.email,
      m?.user?.address ? m?.user?.address : "-",
      m?.user?.contact,
      m?.order_items[0]?.product?.title,
      m?.total_amount,
      m?.status === 1 ? "Accepted" : m?.status === 2 ? "Declined" : "Pending",
    ]) || [];

  const csvData = [
    [
      "ID",
      "Name",
      "Pic",
      "Email",
      "Address",
      "Mobile No.",
      "Product",
      "Total",
      "Status",
    ],
    ...dataaa,
  ];

  const breadCrumbItems = [
    {
      title: 'Medical Equipment'
    },
    {
      title: 'Medical Equipment All Orders',
      href: ''
    }
  ];

  const { 
    filterStates: { filtersData },
    filterFuncs
  } = FiltersHelper();

  const filtersOrder = [
    {
      type: 'search',
      name: 'title',
      value: filtersData?.title,
      divClass: 'col-5 p-0 pr-1',
      placeholder: 'Search by name',
      onChange: filterFuncs?.handleOnFilterChange
    },
    {
      type: 'select',
      name: 'status',
      value: filtersData?.status,
      divClass: 'col-5 p-0 pr-1',
      placeholder: 'Select Status',
      onChange: filterFuncs?.handleOnFilterChange,
      options: select?.fourAcceptedStatus
    },
    {
      type: 'csv',
      divClass: 'col-2',
      data: csvData,
      fileName: 'Medical_Equipment_order_list',
      disabled: orderlistPost?.isLoading
    }
  ];

  return (
    <>
      <div className="row px-4 pt-4 roomslistBody">

        <RoomsTableListHeader
          pageTitle="Medical Equipment All Orders"
          breadCrumbItems={breadCrumbItems}
          filtersOrder={filtersOrder}
        />

        <div className="col-12 px-0 mt-2">
          <MedicalEquipmentOrderList
            filtersData={filtersData}
            handleSortByAge={filterFuncs?.handleSortByAge}
            handleSortIcons={filterFuncs?.handleSortIcons}
          />
        </div>

      </div>
    </>
  );
};

export default PharmacyShopDetail;
