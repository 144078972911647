import { Modal } from "antd";
import React, { useState } from "react";
import UploadFileModal from "../UploadFileModal";
import AddDocUploadImgeIcon from "../../assets/images/doctor/AddDocUploadImgeIcon.svg";
import DocumentRemoveIconRed from "../../assets/images/doctor/DocumentRemoveIconRed.svg";
import BrowseDocumentIcon from "../../assets/images/doctor/BrowseDocumentIcon.svg";

const CertificateInput = ({
  selectedFiles,
  setSelectedFiles,
  formDataState,
  setFormDataState,
  objectKey,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const RemoveDocument = (key) => {
    if (formDataState?.documents[key]?.id) {
      setFormDataState((formDataState) => ({
        ...formDataState,
        remove_document_ids: [
          ...formDataState?.remove_document_ids,
          formDataState?.documents[key]?.id,
        ],
      }));
    }

    const array = [...formDataState?.documents];
    array.splice(key, 1);
    setFormDataState((formDataState) => ({
      ...formDataState,
      documents: [...array],
    }));
  };

  return (
    <div
      className="row mx-auto"
      style={{
        paddingLeft: "",
        paddingRight: "",
        minHeight: formDataState?.documents?.length <= 4 ? "36.6px" : "140px",
        maxHeight: "140px",
        overflowY: "scroll",
        border: "1px solid #E4E3E4",
        borderRadius: "8px",
      }}
    >
      <div
        className="col-lg-10 row mx-auto"
        style={{
          width: "",
          display: "",
          columnGap: "13px",
          justifyContent: "",
        }}
      >
        {formDataState?.documents?.map((obj, index) => {
          var fileName = obj?.name ? obj?.name : obj?.original_name;
          // var fileName = obj?.document?.name;

          if (fileName?.length <= 17) {
            fileName = fileName;
          } else {
            fileName =
              fileName?.slice(0, 8) +
              "..." +
              fileName?.slice(fileName?.length - 6, fileName?.length);
          }

          return (
            <div className="" style={{ width: "21%" }}>
              <div
                className="position-relative d-flex align-items-end justify-content-center"
                style={{ height: "40px" }}
              >
                <img src={BrowseDocumentIcon} className=""></img>
                <img
                  src={DocumentRemoveIconRed}
                  className="position-absolute cursor-pointer"
                  style={{ top: "9%", right: "18%", width: "20%" }}
                  onClick={() => {
                    RemoveDocument(index);
                  }}
                ></img>
              </div>
              <p
                className="m-0 py-1 text-center"
                style={{
                  fontSize: "10px",
                  fontWeight: "400",
                  lineHeight: "1",
                  wordBreak: "break-all",
                }}
              >
                {fileName}
              </p>
            </div>
          );
        })}
        {/* </div>
        })} */}
      </div>

      <div className="text-right col-lg-2" style={{ width: "%" }}>
        <img
          src={AddDocUploadImgeIcon}
          onClick={() => {
            setIsModalOpen(true);
          }}
          className="cursor-pointer"
        ></img>
      </div>

      <Modal
        open={isModalOpen}
        closeIcon={false}
        footer={null}
        centered
        width={"50%"}
        className="upload-certificate-modal"
        onCancel={() => {
          setIsModalOpen(false);
        }}
      >
        <UploadFileModal
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          formDataState={formDataState}
          setFormDataState={setFormDataState}
        />
      </Modal>
    </div>
  );
};

export default CertificateInput;
