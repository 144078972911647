import React from 'react'

const InputLabel = ({label, arLabel, labelClass, labelTag, labelDivClass, required}) => {

  const Tag = labelTag || 'p';

  return (
    <div className={`${labelDivClass} d-flex justify-content-between`}>
        <Tag className={labelClass}>{label}{required && <span className="text-danger">*</span>}</Tag>
        <Tag className={labelClass}>{arLabel && arLabel}</Tag>
    </div>
  )
}

export default InputLabel;