import React, { useState, useEffect } from "react";
import DataTable from "../../components/appointments/DataTable";
import "../../assets/css/appointments/appointments.scss";
import AcceptModal from "../../components/common/AcceptModal";
import CancelModal from "../../components/common/CancelModal";
import AddAppointmentModal from "../../components/appointments/AddAppointmentModal";
import useFetch from "../../customHook/useFetch";

// img
import ListHeader from "../../molecules/ListHeader/ListHeader";

const Appointments = () => {
  
  const [rows, setRows] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [filterOption, setFilterOption] = useState("All"); // default to "today"
  const [searchQuery, setSearchQuery] = useState("");
  const [showCheckPatientModal, setShowCheckPatientModal] = useState(false);
  const [showTickModal, setShowTickModal] = useState(false);
  const [showCrossModal, setShowCrossModal] = useState(false);
  const [showAddAppointmentModal, setshowAddAppointmentModal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = "/appointment";
  const [paramTitle, setParamTitle] = useState("");
  const [isSortBy, setIsSortBy] = useState("");
  const [dateRange, setDateRange] = useState({
    start_date: "",
    end_date: "",
  });

  const DropDownItems = [
    {
      label: "Date",
      sortBy: "date",
      key: 0,
    },
  ];

  //  AcceptAppointModal handler
  const handleTickClick = () => {
    setShowTickModal(true);
  };

  //  CancelAppointentModal handlar
  const handleCrossClick = () => {
    setShowCrossModal(true);
  };

  useEffect(() => {
    if (navigate === "/appointment") {
      setShowCheckPatientModal(true);
    }
  }, [navigate.location]);

  //  Accept appointment handler
  const handleAcceptAppointment = () => {
    // Find the index of the appointment row that needs to be updated
    const rowIndex = rows.findIndex(
      (row) => row.appointmentStatus === "Pending"
    );
    if (rowIndex !== -1) {
      // Update the appointment status to "Approved"
      const updatedRows = [...rows];
      updatedRows[rowIndex] = {
        ...updatedRows[rowIndex],
        appointmentStatus: "Approved",
      };
      setRows(updatedRows); // Update the state of rows
    }
  };

  const exportData = useFetch(`${process.env.REACT_APP_GET_APPOINTMENTS}`);

  const exportData1 = useFetch(`${process.env.REACT_APP_GET_APPOINTMENTS}`);

  const dataaa =
    exportData1?.data?.data?.map((m) => [
      m?.id,
      m?.row?.kwd_id || "-",
      m?.patient_id,
      m?.user?.name,
      m?.date,
      m?.created_at?.slice(11, 16),
      m?.hospital?.name || "-",
      m?.doctor?.user?.name,
      m?.specialization?.name,
      m?.fees,
      m?.status === 1 ? "Approved" : "Pending",
    ]) || [];

  const csvData = [
    [
      "ID",
      "KWD ID",
      "Patient Id",
      "Patient Name",
      "Date",
      "Time",
      "Hospital Name",
      "Doctor Name",
      "Specialization",
      "Fees",
      "Appointment Status",
    ],
    ...dataaa,
  ];

  const filterOptionData = [
    {
      id: 0,
      text: "All",
    },
    {
      id: 1,
      text: "Today",
    },
    {
      id: 2,
      text: "Tomorrow",
    },
    {
      id: 3,
      text: "Yesterday",
    },
  ];

  const breadCrumbItems = [
    {
      title: 'Appointments'
    },
    {
      title: 'Appointments List',
      href: '/appointment'
    }
  ];

  return (
    <>
      <AcceptModal
        heading="Accept Appointment"
        description="Are you sure you want to Accept an appointment?"
        open={showTickModal}
        onClose={() => setShowTickModal(false)}
        onAccept={handleAcceptAppointment}
      />
      <CancelModal
        heading="Cancel Appointment"
        description="Are you sure you want to cancel an appointment?"
        open={showCrossModal}
        onClose={() => setShowCrossModal(false)}
      />
      <AddAppointmentModal
        open={showAddAppointmentModal}
        onClose={() => setshowAddAppointmentModal(false)}
      />

      <div className="row pl-3 pr-2 pt-4 appointment-tab">

        <div className="col-12">
          <ListHeader
            mainHeading="Appointments"
            isAppointmentHeader={true}
            placeholder="Search Title"
            items={DropDownItems}
            isSortBy={isSortBy}
            setIsSortBy={setIsSortBy}
            setParamTitle={setParamTitle}
            dateRange={dateRange}
            setDateRange={setDateRange}
            btnShow={true}
            btnText="Add Appointment"
            linkbtn="/appointment/add"
            linkBreadCrum="/appointment"
            blinkBreadCrumText="APPOINTMENT LIST"
            csvData={csvData}
            disabled={exportData?.isLoading}
            exportFileName="Appointment_list"
            filterOptionData={filterOptionData}
            filterOption={filterOption}
            setFilterOption={setFilterOption}
            breadCrumbItems={breadCrumbItems}
          />
        </div>

        <div className="col-12 my-4">
          <div className="row">
            <div class="col-md-6 text-md-right">
            </div>
          </div>
        </div>

        <div className="col-12  mb-5 pb-5">
          <DataTable
            rows={rows}
            onTickClick={handleTickClick}
            onCrossClick={handleCrossClick}
            filterOption={filterOption}
            searchQuery={searchQuery}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            paramTitle={paramTitle}
            isSortBy={isSortBy}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        </div>
      </div>
    </>
  );
};

export default Appointments;
