import React, { useState } from "react";
import Chevron from "../../assets/images/common/chevron-right.svg";
import Searchbar from "./../../components/common/Searchbar";
import EditLaboratoryModal from "../../components/laboratory/laboratorylist/EditLaboratoryModal";
import DeleteModal from "../../components/common/DeleteModal";
import NeedyPatientDataTable from "../../components/treatmentsponsor/needpatientslist/NeedyPatientDataTable";
import NeedPatientEditModal from "../../components/treatmentsponsor/needpatientslist/NeedPatientEditModal";
import "../../assets/css/laboratory/laboratorylist/laboratorylist.scss";
import CancelModal from "../../components/common/CancelModal";
import AcceptModal from "../../components/common/AcceptModal";
import { Link } from "react-router-dom";
import useFetch from "../../customHook/useFetch";
import ListHeader from "../../molecules/ListHeader/ListHeader";

const NeedyPatientList = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [showAddLaboratoryModal, setshowAddLaboratoryModal] = useState(false);
  const [showRoleModal, setshowRoleModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [tickCompleted, settickCompleted] = useState(false);
  const [paramTitle, setParamTitle] = useState("");
  const [isSortBy, setIsSortBy] = useState("");
  const role = JSON.parse(localStorage.getItem("userRoles"));
  const allowedhost = Object.keys(role).includes("hospitaladmin");

  const DropDownItems = [
    {
      label: "Age",
      sortBy: "age_asc",
      key: 0,
    },
  ];

  //   handleEditClickModal handler
  const handleEditClick = () => {
    setShowEditModal(true);
  };

  //  handleDeleteClickModal handlar
  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleTick = () => {
    settickCompleted(true);
  };

  const exportData = useFetch(`${process.env.REACT_APP_LIST_NEEDY_PATIENT}`);

  const rowss = exportData?.data;
  console.log("bin@gmail.com", rowss);
  const dataaa =
    rowss?.data?.map((m) => [
      m?.id,
      m?.first_name,
      m?.patient_id,
      m?.hospital?.name,
      m?.contact,
      m?.civil_id,
      m?.gender == 1 ? "Male" : m.gender == 3 ? "Female" : "Other",
      m.approx_treatment_cost ? m.approx_treatment_cost : "-",
      m?.disease,
      m.status === 1 ? "Approved" : "Pending",
    ]) || [];

  const csvData = [
    [
      "ID",
      "Name",
      "Patient ID no",
      "Hospital",
      "Mobile No.",
      "Civil ID no",
      "Gender",
      "Treatment Cost",
      "Disease",
      "Status",
    ],
    ...dataaa,
  ];

  const breadCrumbItems = [
    {
      title: 'Treatment Sponsor'
    },
    {
      title: 'Needy Patients List',
      href: ''
    }
  ];

  return (
    <>
      <NeedPatientEditModal
        open={showEditModal}
        onClose={() => setShowEditModal(false)}
        onSave={handleEditClick}
      />
      <CancelModal
        heading="Delete Record"
        description="Are you sure you want to delete the  Patient’s  record?"
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onDelete={handleDeleteClick}
      />
      <AcceptModal
        open={tickCompleted}
        heading="Accept Application"
        description="Do you want to Accepted the Application?"
        onClose={() => settickCompleted(false)}
        onAccept={handleTick}
      />
      <div className="row pl-3 pr-2 pt-4 laboratorylist-tab">
        <div className="row ">

          <div class="col-md-6 text-md-right ">
            
          </div>
        </div>

        {/* Table */}

        <div className="col-12 ">
          <ListHeader
            mainHeading="Needy Patients List"
            placeholder="Search by title or Civil Id"
            isSortBy={isSortBy}
            setIsSortBy={setIsSortBy}
            setParamTitle={setParamTitle}
            btnText="Add Needy Patient"
            btnShow={allowedhost ? !rowss || rowss?.data?.length < 1 : true}
            linkbtn="/needy-patient/add"
            linkBreadCrum="/needy-patients"
            blinkBreadCrumText="NEEDY-PATIENT LIST"
            csvData={csvData}
            disabled={exportData?.isLoading}
            exportFileName="Needy_Patient_list"
            breadCrumbItems={breadCrumbItems}
          />
        </div>

        <div className="col-12 mb-5 pb-5">
          <NeedyPatientDataTable
            onEditClick={handleEditClick}
            onDeleteClick={handleDeleteClick}
            onTickComplete={handleTick}
            searchQuery={searchQuery}
            paramTitle={paramTitle}
            isSortBy={isSortBy}
            setIsSortBy={setIsSortBy}
          />
        </div>
      </div>
    </>
  );
};

export default NeedyPatientList;
