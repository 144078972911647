
const changePasswordCrumbs = [
    {
      title: 'Profile'
    },
    {
      title: 'Change Password Form',
      href: ''
    }
];

const hospRoomCrumbs = [
    {
      title: 'Hospital'
    },
    {
      title: 'Hospital Rooms',
      href: '/hospitals/hospitalrooms'
    }
];

const promotionCrumbs = [
    {
      title: 'Sales Promotion'
    },
    {
      title: 'All Sales Promotions',
      href: ''
    }
];

const needyPatientCrumbs = (id) => [
  {
    title: 'Treatment Sponsor'
  },
  {
    title: id ? 'Edit Needy Patient': 'Add Needy Patient',
    href: ''
  }
];

export default {
    changePasswordCrumbs,
    hospRoomCrumbs,
    needyPatientCrumbs,
    promotionCrumbs
}