import { useState, useRef, useEffect } from "react";
import camera from "../../../assets/images/common/camera.svg";
import CustomSelect from "../../common/CustomSelect";
import "../../../assets/css/patients/addpatients/addnewpatientform.scss";
import { Modal, TimePicker } from "antd";
import { DatePicker } from "antd";
import Location from "../../../atoms/Location/Location";
import { optionHostpital } from "../../../Data/DoctorData";
import CustomDropDown from "../../../atoms/CustomDropDown/Index";
import GoogleMap from "../../common/GoogleMap";
import { Controller, useController, useForm } from "react-hook-form";
import useFetch from "../../../customHook/useFetch";
import Phone from "../../../atoms/phone";
import usePost from "../../../customHook/usePost";
import ImageUploader from "../../../atoms/uploadProfile/UploadProfile";
import ButtonLoader from "../../../atoms/buttonLoader";
import { CustomToast } from "../../../atoms/toastMessage";
import { useNavigate } from "react-router-dom";
import useDeleteData from "../../../customHook/useDelete";
import moment from "moment";
import SearchMap from "../../common/SearchMap";
import ArabicInput from "../../../molecules/ArabicInput";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import SpinnerLoaderModal from "../../common/spinnerloadermodal";
import { handlePhoneCode } from "../../common/functionalityhelper";
import schema from "../../../formvalidation/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import ErrorMessage from "../../../formvalidation/errormessage";
import dayjs from "dayjs";
import formschemas from "../../../formvalidation/formschemas";
import commonUtils from "../../../utilities/common.utils";

const AddNewPatient = ({ Id }) => {
  const [previewImage, setPreviewImage] = useState(null);
  const fileInputRef = useRef(null);
  const [dropdownValueChange, setDropdownValueChange] =
    useState("Adan Hospital");
  const [showMap, setShowMap] = useState(false);
  const [Kwd_idValidation, setkwd_idValidation] = useState(false);
  const [locationProp, setLocationProp] = useState("");
  const [errorMessage, setErrorMessage] = useState({
    profile_pic: 0,
    visit_date: 0,
    visit_time: 0,
  });
  const [hospitals, setHospitals] = useState([]);
  const [patientData, setPatientData] = useState({
    country: "Kuwait",
    lat: 31.48544799999999,
    long: 74.3046761,
    visit_date: "",
  });
  const navigate = useNavigate();
  const [modal2Open, setModal2Open] = useState(false);
  const { data, isLoading, error, postData } = usePost();
  const { t } = useTranslation();

  const {
    reset,
    handleSubmit,
    setValue,
    control,
    trigger,
    formState: { errors }
  } = useForm({
    mode: 'all',
    resolver: yupResolver(formschemas?.patientSchema),
  });

  const { data: hospitalData } = useFetch(
    process.env.REACT_APP_GET_HOSPITAL_DATA
  );

  useEffect(() => {
    if (hospitalData?.success) {
      setHospitals(
        hospitalData?.data?.map(({ id, name }) => ({
          value: id,
          label: name,
        }))
      );
    }
  }, [hospitalData]);

  const deleteProductData = useDeleteData();
  const deleteData = deleteProductData.deleteData;

  useEffect(() => {
    if (Id) {
      deleteData(`get_patient_by_id/${Id}`, (response) => {
        setPreviewImage(response?.data);
        setPatientData((pre) => ({
          ...pre,
          ...(commonUtils?.nonNullOrUndefinedObject(response?.data)),
          ...(commonUtils?.nonNullOrUndefinedObject(response?.data?.user))
        }));
        setValue("customer_email", response?.data?.user?.email);
        Object.entries(response?.data?.user).forEach(
          ([fieldName, fieldValue]) => {
            setValue(fieldName, fieldValue ?? '');
          }
        );
        Object.entries(response?.data).forEach(([fieldName, fieldValue]) => {
          setValue(fieldName, fieldValue ?? '');
        });
      });

    }
  }, [Id]);

  const onSubmit = () => {

    const tempError = { ...errorMessage };

    if (!patientData?.visit_date) {
      tempError.visit_date = 1;
    }

    if (!patientData?.visit_time) {
      tempError.visit_time = 101;
    }

    if (!patientData?.profile_pic) {
      tempError.profile_pic = 111;
    }

    setErrorMessage({ ...tempError });

    if (
      patientData?.visit_date &&
      patientData?.visit_time &&
      patientData?.profile_pic
    ) {
      const formData = new FormData();
      for (const key in patientData) {
        if (key === "specialties" && Array.isArray(patientData[key])) {
          patientData[key].forEach((value) => {
            formData.append(`${key}[]`, value);
          });
        } else {
          formData.append(key, patientData[key]);
        }
      }

      // alert('asdf')
      postData(
        Id
          ? `${process.env.REACT_APP_UPDATE_PATIENT}/${Id}`
          : `${process.env.REACT_APP_ADD_PATIENT}`,
        formData,
        (res) => {
          // setAddRole({...addRole, 'join_id':res?.data?.id})

          if (res?.success === true) {
            CustomToast({
              type: "success",
              message: Id ? 'Patient updated successfully.' : `Patient added successfully.`,
            });
            setValue("");
            setPatientData({});
            reset();
            navigate("/patients");
          } else {
            toast?.error(res?.response?.slice(0, 60));
          }
        }
      );
    }
  };

  const handleLocationIconClick = () => {
    !showMap ? setShowMap(true) : setShowMap(false);
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    setValue('profile_pic', file, {shouldValidate: true});
    setPatientData((pre) => ({ ...pre, profile_pic: file }));
    setErrorMessage({ ...errorMessage, profile_pic: 0 });
    reader.onload = () => {
      setPreviewImage(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
    trigger('profile_pic');
  };

  const handleChangeSelect = (value) => {
    setDropdownValueChange(value);
  };
  const gender = [
    {
      label: "Male",
      value: 1,
    },
    {
      label: "Female",
      value: 2,
    },
  ];

  useEffect(() => {
    setValue("address", patientData?.address);
  }, [patientData?.address]);

  const KWDID_Api = useDeleteData();

  const handleButtonClick = () => {
    // Fetch data with the updated URL
    KWDID_Api.deleteData(
      `${process.env.REACT_APP_GET_PATIENT_DETAIL_BY_KWD_ID}/${patientData?.kwd_id}`,
      (response) => {
        setkwd_idValidation(true);
        setPatientData((pre) => ({
          ...pre,
          last_name: response?.data?.user?.last_name,
          first_name: response?.data?.user?.first_name,
          contact: response?.data?.user?.contact,
          gender: response?.data?.user?.gender,
          age: response?.data?.user?.age,
          visit_date: response?.data?.visit_date,
          visit_time: response?.data?.visit_time,
          address: response?.data?.user?.address,
          lat: response?.data?.user?.lat,
          long: response?.data?.user?.long,
          hospital_id: response?.data?.hospital_id,
          customer_email: response?.data?.user?.email,
        }));
        setValue("first_name", response?.data?.user?.first_name);
        setValue("last_name", response?.data?.user?.last_name);
        setValue("contact", response?.data?.user?.contact);
        setValue("gender", response?.data?.user?.gender);
        setValue("age", response?.data?.user?.age);
        setValue("visit_date", response?.data?.visit_date);
        setValue("visit_time", response?.data?.visit_time);
        setValue("hospital_id", response?.data?.hospital_id);
        setValue("address", response?.data?.user?.address);
        setValue("lat", response?.data?.user?.lat);
        setValue("long", response?.data?.user?.long);
        setValue("customer_email", response?.data?.user?.email);

        CustomToast({
          type: "success",
          message: `Patient record found `,
        });
      }
    );
  };

  const [currentDate, setCurrentDate] = useState({
    dateObj: dayjs(),
    dateText: dayjs()?.format('YYYY/MM/DD')
  });

  const [startDate, setStartDate] = useState({
    dateObj: dayjs(),
    dateText: dayjs()?.format('YYYY/MM/DD'),
  });

  const disabledStartDate = (current) => {
    return current && current < dayjs(currentDate?.dateText, 'YYYY/MM/DD');
  }

  const handleDisableTime = (time) => {

    const currentHour = currentDate?.dateObj?.hour();
    const currentMinute = currentDate?.dateObj?.minute();

    if(startDate?.dateText === currentDate?.dateText){
      return{
        disabledHours: () => Array.from({ length: currentHour }, (_, i) => i),
        disabledMinutes: (selectedHour) => {
          if (selectedHour === currentHour) {
            return Array.from({ length: currentMinute }, (_, i) => i);
          }
          return [];
        },
      }
    }
    else return {};

  }

  const {field: visitDateField} = useController({
    name: 'visit_date',
    control
  });

  const {field: visitTimeField} = useController({
    name: 'visit_time',
    control
  });

  return (
    <div className="add-new-patient-form-wrapper">
      <div className="add-profile">

      {Id && <SpinnerLoaderModal isDataLoading={deleteProductData?.isLoading} />}

        <div className="image-uploader">
          <div className="left">
            <div className="image-preview">
              {patientData?.profile_pic ? (
                <img
                  src={
                    patientData?.profile_pic?.name
                      ? URL?.createObjectURL(patientData?.profile_pic)
                      : `${process.env.REACT_APP_IMAGE_URL}/${patientData?.profile_pic}`
                  }
                  alt="Preview"
                  width="80"
                  height="80"
                  style={{ objectFit: "cover", borderRadius: "50%" }}
                  onClick={handleUploadClick}
                />
              ) : (
                <img src={camera} onClick={handleUploadClick} />
              )}
            </div>
            <div>Profile Picture / {`${t('Common.ProfilePicture')}`}</div>
          </div>

          <div className="right">
            <div className="upload-text" onClick={handleUploadClick}>
              Upload Image
            </div>
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
          </div>
        </div>
        <ErrorMessage error={errors?.profile_pic?.message} />
      </div>

      <form className="add-new-patient-form">
        <div class="form-row">
          <div class="form-group col-lg-12">

            <div className="d-flex justify-content-between">
              <label for="last-name-input">
                KWD ID<span className="text-danger">*</span>
              </label>
              <p className="mb-2">{`${t("Common.KWDID")}`}</p>
            </div>

            <Controller
              name="kwd_id"
              control={control}
              rules={{
                required: true,
                pattern: /^\d{12}$/,
              }}
              render={({ field }) => (
                <>
                  <input
                    type="text"
                    name="kwd_id"
                    {...field}
                    value={field.value || ""}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      // setNameData(e.target.value);
                      setPatientData((pre) => ({
                        ...pre,
                        kwd_id: e.target.value,
                      }));
                      // handleChangeHospital(e);
                    }}
                  />

                  <ErrorMessage error={errors?.kwd_id?.message} />
                </>
              )}
            />
          </div>

        </div>

        <div class="form-row">
          <div class="form-group col-lg-3">
            <label for="first-name-input">
              First Name<span className="text-danger">*</span>
            </label>
            <Controller
              name="first_name"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <>
                  <input
                    type="text"
                    name="first_name"
                    {...field}
                    value={field.value || ""}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      // setNameData(e.target.value);
                      setPatientData((pre) => ({
                        ...pre,
                        first_name: e.target.value,
                      }));
                    }}
                  />

                  <ErrorMessage error={errors?.first_name?.message} />
                </>
              )}
            />
          </div>

          <ArabicInput
            divClass="form-group col-3"
            label={t("Patient.FirstName")}
            formStateData={patientData}
            setFormStateData={setPatientData}
            name="first_name_ar"
          />

          <div class="form-group col-lg-3">
            <label for="last-name-input">
              Last Name<span className="text-danger">*</span>
            </label>
            {/* <input type="text" id="last-name-input" name="last-name" /> */}
            <Controller
              name="last_name"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <>
                  <input
                    type="text"
                    name="last_name"
                    {...field}
                    value={field.value || ""}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      // setNameData(e.target.value);
                      setPatientData((pre) => ({
                        ...pre,
                        last_name: e.target.value,
                      }));
                    }}
                  />
                  <ErrorMessage error={errors?.last_name?.message} />
                </>
              )}
            />
          </div>

          <ArabicInput
            divClass="form-group col-3"
            label={t("Patient.LastName")}
            formStateData={patientData}
            setFormStateData={setPatientData}
            name="last_name_ar"
          />
        </div>

        <div class="form-row">
          <div class="form-group col-lg-6">
            
            <div className="d-flex justify-content-between">
              <label for="age-input">
                Age<span className="text-danger">*</span>
              </label>
              <p className="mb-2">{`${t("Common.Age")}`}</p>
            </div>

            <Controller
              name="age"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <>
                  <input
                    type="text"
                    name="age"
                    {...field}
                    className="pl-2"
                    value={field.value || ""}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      // setNameData(e.target.value);
                      setPatientData((pre) => ({
                        ...pre,
                        age: e.target.value,
                      }));
                    }}
                  />
                  <ErrorMessage error={errors?.age?.message} />
                </>
              )}
            />
          </div>
          <div class="form-group col-lg-6">
            
            <div className="d-flex justify-content-between">
              <label>
                Gender<span className="text-danger">*</span>
              </label>
              <p className="mb-2">{`${t("Common.Gender")}`}</p>
            </div>

            <Controller
              name="gender"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <>
                  <CustomDropDown
                    handleChangeSelect={(value, name) => {
                      field.onChange(value);
                      setPatientData((pre) => ({ ...pre, gender: value }));
                    }}
                    option={gender}
                    field={field}
                    value={field.value || ""}
                    onBlur={field.onBlur}
                  />

                  <ErrorMessage error={errors?.gender?.message} />
                </>
              )}
            />
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-lg-6 " style={{ paddingTop: "2px" }}>
            {/* <label for="phone-number-input">Phone Number</label> */}
            {/* <input
              type="text"
              id="phone-number-input"
              name="phone-number-input"
            /> */}
            <Controller
              name="contact"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <>
                  <Phone
                    name="contact"
                    field={field}
                    value={field.value}
                    handleChange={(e) => {
                      field.onChange(e);
                      // handleChange(e.target.name, e.target.value);
                      setPatientData((pre) => ({
                        ...pre,
                        contact: handlePhoneCode(e?.target?.name, e?.target?.value, setValue),
                      }));
                    }}
                    arLabel={`${t("Common.PhoneNo")}`}
                  />
                  <ErrorMessage error={errors?.contact?.message} />
                </>
              )}
            />
          </div>
          <div class="form-group col-lg-6">
            
            <div className="d-flex justify-content-between">
              <label for="email-input">
                Email<span className="text-danger">*</span>
              </label>
              <p className="mb-2">{`${t("Common.Email")}`}</p>
            </div>

            <Controller
              name="customer_email"
              control={control}
              rules={{
                required: true,
                pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
              }}
              render={({ field }) => (
                <>
                  <input
                    className=""
                    type="text"
                    name="customer_email"
                    {...field}
                    value={field.value || ""}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      setPatientData((pre) => ({
                        ...pre,
                        email: e.target.value,
                      }));
                    }}
                  />
                  <ErrorMessage error={errors?.customer_email?.message} />
                </>
              )}
            />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-lg-6">
            
            <div className="d-flex justify-content-between">
              <label for="visit-date-input">
                Visit Date<span className="text-danger">*</span>
              </label>
              <p className="mb-2">{`${t("Common.VisitDate")}`}</p>
            </div>

            <div className="border" style={{ borderRadius: "5px" }}>
              <DatePicker
                {...visitDateField}
                size="large"
                style={{ width: "100%", border: "none" }}
                allowClear={false}
                placeholder={"Select date"}
                value={patientData?.visit_date ? dayjs(patientData?.visit_date) : null }
                onChange={(e) => {
                  setPatientData((prev) => ({
                    ...prev,
                    visit_date: e.format("YYYY-MM-DD"),
                    visit_time: null
                  }));
                  setValue('visit_date', e.format("YYYY-MM-DD"), { shouldValidate: true });
                  setValue('visit_time', null, {shouldValidate: true});
                  setErrorMessage({ ...errorMessage, visit_date: 0 });
                  setStartDate({ 
                    dateObj: dayjs(e)?.format('YYYY/MM/DD') === currentDate?.dateText ? dayjs(`${dayjs(e)?.format('YYYY/MM/DD')} ${dayjs().format('HH:mm:ss')}`, 'YYYY/MM/DD HH:mm:ss') : e,
                    dateText: dayjs(e)?.format('YYYY/MM/DD')
                  });
                }}
                disabledDate={disabledStartDate}
              />
            </div>

            <ErrorMessage error={errors?.visit_date?.message} />
          </div>

          <div class="form-group col-lg-6">

            <div className="d-flex justify-content-between">
              <label>Visit Time</label>
              <p className="mb-2">{`${t("Common.VisitTime")}`}</p>
            </div>

            <div className="border" style={{ borderRadius: "5px" }}>
              <TimePicker
                {...visitTimeField}
                format="HH:mm"
                size="large"
                placeholder={"Select time"}
                value={patientData?.visit_time ? dayjs(patientData?.visit_time, 'HH:mm') : null}
                style={{ width: "100%", border: "none" }}
                onChange={(e) => {
                  setPatientData((prev) => ({
                    ...prev,
                    visit_time: e.format("HH:mm"),
                  }));
                  setErrorMessage(0);
                  setValue('visit_time', e.format("HH:mm"), { shouldValidate: true })
                }}
                allowClear={false}
                disabledTime={handleDisableTime}
              />
            </div>

            <ErrorMessage error={errors?.visit_time?.message} />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-lg-6">

            <div className="d-flex justify-content-between">
              <label for="Location">
                Hospital<span className="text-danger">*</span>{" "}
              </label>
              <p className="mb-2">{`${t("Common.Hospital")}`}</p>
            </div>

            <Controller
              name="hospital_id"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <>
                  <CustomDropDown
                    handleChangeSelect={(value, name) => {
                      field.onChange(value);
                      setPatientData((pre) => ({ ...pre, hospital_id: value }));
                    }}
                    option={hospitals}
                    field={field}
                    value={field.value}
                    onBlur={field.onBlur}
                  />

                  <ErrorMessage error={errors?.hospital_id?.message} />
                </>
              )}
            />
          </div>
          <div className="col-lg-6 mt-lg-0 mt-4 pr-lg-1 doc-setting-input ">

            <div className="d-flex justify-content-between">
              <p className="mb-2">
                Address
                <span className="error-message">*</span>
              </p>
              <p className="mb-2">{`${t("Common.Address")}`}</p>
            </div>

            <div className="d-flex  ">
              <Controller
                name="address"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <>
                    <input
                      type="text"
                      name="address"
                      {...field}
                      value={field.value || ""}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setPatientData((pre) => ({
                          ...pre,
                          address: e.target.value,
                        }));
                      }}
                    />
                  </>
                )}
              />
            </div>

            <ErrorMessage error={errors?.address?.message} />
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-lg-12 mt-lg-0 mt-0  doc-setting-input">

            <div className="d-flex justify-content-between">
              <p className="mb-2"> Location </p>
              <p className="mb-2">{`${t("Common.Location")}`}</p>
            </div>

            <div onClick={() => setModal2Open(true)}>
              <Location
                handleLocation={handleLocationIconClick}
                locationProp={patientData?.lat && patientData?.long &&  (patientData?.lat + "," + patientData?.long)}
              />
            </div>
            {showMap && (
              <>
                {/* <GoogleMap
                        locationProp={locationProp}
                        setLocationProp={setLocationProp}
                      /> */}
                <Modal
                  className="location-modal"
                  centered
                  open={modal2Open}
                  onOk={() => setModal2Open(false)}
                  onCancel={() => setModal2Open(false)}
                  width={735}
                  footer={<div className=""></div>}
                >
                  <div className="row   position-relative">
                    <div className="col-lg-12  ">
                      <SearchMap
                        addHospitalData={patientData}
                        setAddHospitalData={setPatientData}
                      />
                    </div>
                    <div
                      className="col-12 position-absolute  d-flex justify-content-end"
                      style={{
                        top: "415px",
                        left: "0",
                      }}
                    >
                      <button
                        style={{ height: "36.6px", width: "100px" }}
                        className="apply-filter add-doc-changes"
                        onClick={() => {
                          setModal2Open(false);
                        }}
                      >
                        Done
                      </button>
                    </div>
                  </div>
                </Modal>
              </>
            )}
            {errorMessage === 9 ? (
              <p className="mb-0 error-message">Please select location</p>
            ) : null}
          </div>
        </div>

        <button
          type="button"
          disabled={isLoading || Kwd_idValidation === true}
          style={{
            cursor:
              isLoading || Kwd_idValidation === true ? "not-allowed" : null,
          }}
          className="add-patient"
          onClick={handleSubmit(onSubmit)}
        >
          {!isLoading ? (
            <span>{Id ? "Update Patient" : "Add Patient"}</span>
          ) : (
            <div className="pb-2">
              <ButtonLoader />
            </div>
          )}
        </button>
      </form>
      {Kwd_idValidation === true ? (
        <span className="error-message">KWD ID already exist</span>
      ) : null}
    </div>
  );
};

export default AddNewPatient;
