import schema from "./schema";
import * as Yup from 'yup';
import validationmessages from "./validationmessages";

const FILE_SUPPORTED_FORMATS = ['image/png', 'image/jpeg', 'image/jpg'];

const roleSchema = Yup?.object()?.shape({
    profile_picture: schema?.profilePicture(true)?.notRequired(),
    role_type_id: schema?.alphanumericString(),
    join_id: schema?.alphanumericString('alphabets', 1, 30),
    name: schema?.alphanumericString('alphabets', 1, 30),
    email: schema?.alphanumericString('email'),
    password: schema?.alphanumericString('password', 6, 10),
    contact: schema?.contact,
    country: schema?.alphanumericString()?.notRequired(),
    state: schema?.alphanumericString()?.notRequired()
});

const appointmentSchema = Yup?.object()?.shape({
    kwdId: schema?.alphanumericString('kwdID', '', '', 12),
    patient_name: schema?.alphanumericString(),
    phone: schema?.alphanumericString(),
    age: schema?.alphanumericString(),
    email: schema?.alphanumericString(),
    hospital_id: schema?.alphanumericString(),
    specialization_id: schema?.alphanumericString(),
    doctor_id: schema?.alphanumericString(),
    doctor_availability: schema?.alphanumericString(),
    fees: schema?.alphanumericString(),
    time: schema?.alphanumericString(),
    date: schema?.alphanumericString()
});

const doctorSchema = (id) => Yup?.object()?.shape({
    profile_pic: schema?.profilePicture(),
    first_name: schema?.alphanumericString('alphabets', 1, 15),
    last_name: schema?.alphanumericString('alphabets', 1, 15),
    email: schema?.alphanumericString('email'),
    contact: schema?.contact,
    support_email: schema?.alphanumericString('email')?.notRequired(),
    support_phone_no: schema?.contact?.notRequired(),
    gender: schema?.alphanumericString(),
    hospitals: schema?.arrayRange(1, 10),
    specialization_id: schema?.alphanumericString(),
    qualification: schema?.alphanumericString(),
    experience_years: schema?.numericRange(0, 100),
    council_registration_no: schema?.alphanumericString('kwdID', '', '', 10)?.notRequired(),
    facebook: schema?.alphanumericString('username', 0, 10)?.notRequired(),
    instagram: schema?.alphanumericString('username', 0, 10)?.notRequired(),
    twitter: schema?.alphanumericString('username', 0, 10)?.notRequired(),
    linkedin: schema?.alphanumericString('username', 0, 10)?.notRequired(),
    website: schema?.alphanumericString('website'),
    languages: schema?.arrayRange(1, 50),
    about: schema?.alphanumericString('', 1, 500),
    availability: schema?.arrayRange(1, 2),
    fees: schema?.doctorFeesArray('', '', 0, 999999)?.notRequired(),
    ...(!id && { role: roleSchema })
});

const patientSchema = Yup?.object()?.shape({
    profile_pic: schema?.profilePicture(),
    kwd_id: schema?.alphanumericString('kwdID', '', '', 12),
    first_name: schema?.alphanumericString('alphabets', 1, 15),
    last_name: schema?.alphanumericString('alphabets', 1, 15),
    age: schema?.numericRange(0,200),
    gender: schema?.alphanumericString(),
    contact: schema?.contact,
    customer_email: schema?.alphanumericString('email'),
    visit_date: schema?.alphanumericString(),
    visit_time: schema?.alphanumericString(),
    hospital_id: schema?.alphanumericString(),
    address: schema?.alphanumericString()
  });

const hospitalSchema = (id) => Yup?.object()?.shape({
    profile_picture: schema?.profilePicture(),
    name: schema?.alphanumericString('alphabets', 1, 30),
    email: schema?.alphanumericString('email'),
    phone_no: schema?.contact,
    support_email: schema?.alphanumericString('email'),
    support_phone_no: schema?.contact,
    country: schema?.alphanumericString(),
    state: schema?.alphanumericString(),
    specialties: schema?.arrayRange(1, 50),
    hospital_type: schema?.alphanumericString(),
    facebook: schema?.alphanumericString('username', 0, 10)?.notRequired(),
    instagram: schema?.alphanumericString('username', 0, 10)?.notRequired(),
    twitter: schema?.alphanumericString('username', 0, 10)?.notRequired(),
    linkedin: schema?.alphanumericString('username', 0, 10)?.notRequired(),
    website: schema?.alphanumericString('website'),
    address: schema?.alphanumericString(),
    about: schema?.alphanumericString('', 1, 500),
    ...(!id && { role: roleSchema })
});

const hospRoomSchema = Yup?.object()?.shape({
    images: schema?.multipleFiles(1, 5, FILE_SUPPORTED_FORMATS),
    hospital_id: schema?.alphanumericString(),
    room_specifications: schema?.roomSpecificationsArray(1, 3),
    room_type_id: schema?.alphanumericString(),
    per_day_fee: schema?.numericRange(0,999999),
    booking_cancelation_duration: schema?.alphanumericString(),
    room_facility_id: schema?.arrayRange(1, 10),
    description: schema?.alphanumericString('', 1, 500),
});

const pharmacySchema = (id) => Yup?.object()?.shape({
    profile_picture: schema?.profilePicture(),
    name: schema?.alphanumericString('alphabets', 1, 30),
    email: schema?.alphanumericString('email'),
    phone: schema?.contact,
    support_email: schema?.alphanumericString('email'),
    support_phone_no: schema?.contact,
    country: schema?.alphanumericString(),
    state: schema?.alphanumericString(),
    city: schema?.alphanumericString(),
    address: schema?.alphanumericString(),
    facebook: schema?.alphanumericString('username', 0, 10)?.notRequired(),
    instagram: schema?.alphanumericString('username', 0, 10)?.notRequired(),
    twitter: schema?.alphanumericString('username', 0, 10)?.notRequired(),
    linkedin: schema?.alphanumericString('username', 0, 10)?.notRequired(),
    website: schema?.alphanumericString('website'),
    description: schema?.alphanumericString('', 1, 500),
    ...(!id && { role: roleSchema })
});

const pharmacyShopSchema = (module) => Yup?.object()?.shape({
    images: schema?.multipleFiles(1, 4, FILE_SUPPORTED_FORMATS),
    category_id: schema?.alphanumericString(),
    title: schema?.alphanumericString('alphabets', 1, 30),
    price: schema?.numericRange(0,999999),
    label: schema?.arrayRange(1, 3),
    sales_price: schema?.numericRange(0,999999),
    quantity: schema?.numericRange(0,1000),
    about: schema?.alphanumericString('', 1, 500),
    ...(module === 'pharmacy' ? { pharmacy_id: schema?.alphanumericString() } : { equipment_store_id: schema?.alphanumericString() })
});

const labTestSchema = (module) => Yup?.object()?.shape({
    title: schema?.alphanumericString('alphabets', 1, 30),
    blood_test_category_id: schema?.alphanumericString(),
    price: schema?.numericRange(0,999999),
    description: schema?.alphanumericString('', 1, 500),
    ...(module === 'lab' ? { laboratory_id: schema?.alphanumericString() } : { xray_lab_id: schema?.alphanumericString() })
});

const serviceProviderSchema = (id) => Yup?.object()?.shape({
    profile_picture: schema?.profilePicture(),
    name: schema?.alphanumericString('alphabets', 1, 30),
    email: schema?.alphanumericString('email'),
    phone_no: schema?.contact,
    support_email: schema?.alphanumericString('email'),
    support_phone_no: schema?.contact,
    country: schema?.alphanumericString(),
    state: schema?.alphanumericString(),
    provider_category: schema?.arrayRange(1, 50),
    facebook: schema?.alphanumericString('username', 0, 10)?.notRequired(),
    instagram: schema?.alphanumericString('username', 0, 10)?.notRequired(),
    twitter: schema?.alphanumericString('username', 0, 10)?.notRequired(),
    linkedin: schema?.alphanumericString('username', 0, 10)?.notRequired(),
    website: schema?.alphanumericString('website')?.notRequired(),
    address: schema?.alphanumericString(),
    about: schema?.alphanumericString('', 1, 500),
    ...(!id && { role: roleSchema })
});

const serviceSchema = Yup?.object()?.shape({
    profile_picture: schema?.profilePicture(),
    provider_name: schema?.alphanumericString('alphabets', 1, 30),
    amount_per_hour: schema?.numericRange(0, 999999),
    experience: schema?.alphanumericString('alphanumeric', '', 15)?.notRequired(),
    provider_id: schema?.alphanumericString(),
    provider_category_id: schema?.alphanumericString(),
    slot_gap: schema?.alphanumericString(),
    service_detail: schema?.alphanumericString('', 1, 500)
});

const salesPromotionSchema = Yup?.object()?.shape({
    title: schema?.alphanumericString('alphabets', 1, 30),
    start_date: schema?.alphanumericString(),
    start_time: schema?.alphanumericString(),
    end_date: schema?.alphanumericString(),
    end_time: schema?.alphanumericString(),
    sales_promo_category_id: schema?.alphanumericString(),
    company_name: schema?.alphanumericString('alphabets', 1, 20),
    company_email: schema?.email,
    status: schema?.alphanumericString(),
    actual_price: schema?.numericRange(0,999999),
    discounted_price: schema?.numericRange(0,999999),
    image: schema?.profilePicture(),
    description: schema?.alphanumericString('', 1, 500)
});

const needyPatientSchema = Yup?.object()?.shape({
    profile_pic: schema?.profilePicture(),
    kwd_id: schema?.alphanumericString('kwdID', 1, 200),
    first_name: schema?.alphanumericString('alphabets', 1, 15),
    last_name: schema?.alphanumericString('alphabets', 1, 10),
    approx_treatment_cost: schema?.numericRange(0,999999),
    age: schema?.numericRange(0,200),
    gender: schema?.alphanumericString(),
    contact: schema?.contact,
    hospital_id: schema?.alphanumericString(),
    disease: schema?.alphanumericString('alphabets', 1, 30),
    monthly_income: schema?.numericRange(0, 999999),
    description: schema?.alphanumericString('', 1, 500)
  });

const bannerSchema = Yup?.object()?.shape({
    title: schema?.alphanumericString('alphabets', 1, 30),
    start_date: schema?.alphanumericString(),
    start_time: schema?.alphanumericString(),
    end_date: schema?.alphanumericString(),
    end_time: schema?.alphanumericString(),
    placement_id: schema?.alphanumericString(),
    placement_list_id: schema?.alphanumericString(),
    is_mobile_banner: schema?.alphanumericString(),
    image: schema?.profilePicture(),
    link: schema?.website?.required(validationmessages?.required),
    status: schema?.alphanumericString(),
    description: schema?.alphanumericString('', 1, 500)
});

const promoSchema = Yup?.object()?.shape({
    title: schema?.alphanumericString('alphabets', 1, 30),
    status: schema?.alphanumericString(),
    promo_category_id: schema?.alphanumericString(),
    image: schema?.profilePicture(),
    promo_type_id: schema?.alphanumericString(),
    discount_percentage: schema?.numericRange(0, 100),
    start_date: schema?.alphanumericString(),
    start_time: schema?.alphanumericString(),
    end_date: schema?.alphanumericString(),
    end_time: schema?.alphanumericString(),
    total_coupon: schema?.numericRange(0, 4000),
    description: schema?.alphanumericString('', 1, 500)
});

const newsSchema = Yup?.object()?.shape({
    news: schema?.alphanumericString('alphanumeric', 10, 200),
    expiry_date: schema?.alphanumericString()
});

const notificationsSchema = Yup?.object()?.shape({
    title: schema?.alphanumericString('alphanumeric', 1, 30),
    body: schema?.alphanumericString('alphanumeric', 10, 200)
});

const schemas =  {
    roleSchema,
    appointmentSchema,
    hospitalSchema,
    hospRoomSchema,
    doctorSchema,
    patientSchema,
    pharmacySchema,
    pharmacyShopSchema,
    labTestSchema,
    serviceProviderSchema,
    serviceSchema,
    salesPromotionSchema,
    needyPatientSchema,
    bannerSchema,
    promoSchema,
    newsSchema,
    notificationsSchema
};

export default schemas;
