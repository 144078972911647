import React, { useState, useEffect } from "react";
import SelectCountry from "../../atoms/Country";
import SelectState from "../../atoms/State";
import CameraIcon from "../../assets/images/doctor/CameraIcon.svg";
import closeIcon from "../../assets/images/common/close.svg";
import Phone from "../../atoms/phone";
import { Controller, useForm } from "react-hook-form";
import ButtonLoader from "../../atoms/buttonLoader";
import usePost from "../../customHook/usePost";
import { CustomToast } from "../../atoms/toastMessage";
import Location from "../../atoms/Location/Location.js";
import GoogleMap from "../common/GoogleMap.js";
import FacebookInput from "../../assets/images/doctor/FacebookInput.png";
import InstaInput from "../../assets/images/doctor/InstaInput.png";
import LinkedInInput from "../../assets/images/doctor/LinkedInInput.png";
import TwitterInput from "../../assets/images/doctor/Twitter.png";
import WebInput from "../../assets/images/doctor/website.png";
import ClockIcon from "../../assets/images/doctor/ClockIcon.svg";
import TimeTablePencil from "../../assets/images/doctor/TimeTablePencil.svg";
import useDeleteData from "../../customHook/useDelete";
// import UploadFile from "../../molecules/UploadFile/UploadFile";
import { useNavigate } from "react-router-dom";
import AddRoleIcon from "../../assets/images/doctor/AddRoleIcon.svg";

import { Modal } from "antd";

import { Link } from "react-router-dom";
import MuiltiplesImages from "../../atoms/MuiltiplesImages/MuiltiplesImages";
import AddRole from "../../pages/Role/AddRole";
import PharmacyTimings from "../Pharmacy/PharmacyTimings";
import SearchMap from "../common/SearchMap";
import CertificateInput from "../../molecules/CertificateInput/CertificateInput.jsx";
import ArabicInput from "../../molecules/ArabicInput/index.jsx";
import { useTranslation } from "react-i18next";
import ArabicTextArea from "../../molecules/ArabicTextArea/ArabicTextArea.jsx";
import SpinnerLoaderModal from "../common/spinnerloadermodal.jsx";
import { handlePhoneCode } from "../common/functionalityhelper.js";
import SocialInput from "../../molecules/SocialInput/SocialInput.jsx";
import { yupResolver } from "@hookform/resolvers/yup";
import formschemas from "../../formvalidation/formschemas.js";
import ErrorMessage from "../../formvalidation/errormessage.jsx";
import TextInput from "../../molecules/TextInput/TextInput.jsx";

const TimePicker = ({ label, name, value, onChange }) => {
  return (
    <div className="d-inline-flex time-picker time-picker-Pharmacy py-1 px-2">
      <input
        className="pl-1"
        type="time"
        name={name}
        value={value || ""}
        onChange={(e) => onChange(e.target.value)}
        min="00:00"
        max="23:59"
        step="60"
        timeFormat="HH:mm"
      />
    </div>
  );
};

const NewLaboratoryForm = ({
  id,
  lastTextBoxTitle = "About Pharmacy",
  submitButtonText = "Add Pharmacy",
  submitUpdateText = "Update Pharmacy",
  apiEndpoint = process.env.REACT_APP_ADD_PHARMACY_DATA,
  updateApiEndPoint = process.env.REACT_APP_UPDATE_PHARMACY_DATA,
  timeSetApi = process.env.REACT_APP_SET_PHARMACY_SLOT,
  timeGetApi = process.env.REACT_APP_GET_LABORATORY_SLOT,
  getByIdAPI = process.env.REACT_APP_GET_PHARMACY_DATA_ID,
  entityType = "pharmacy",
  customToastMessage = "Pharmacy Added Successfully",
  updateToastMessage = "Pharmacy Details Updated Successfully!",
  click,
  setClick,
}) => {
  const [addPharmacyData, setAddPharmacyData] = useState("");
  const [image, setImage] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImagesCall, setSelectedImagesCall] = useState(true);
  const [modal2Open, setModal2Open] = useState(true);
  const [formDataState, setFormDataState] = useState({
    documents: [],
    remove_document_ids: [],
    country: "Kuwait",
    latitude: 31.48544799999999,
    longitude: 74.3046761,
  });
  const [showMap, setShowMap] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [addedTimings, setAddedTimings] = useState([]);
  const [addRole, setAddRole] = useState({ country: "Kuwait" });
  const [roleParentValidation, setRoleParentValidation] = useState(false);
  const [roleCategoryId, setRoleCategoryId] = useState("");
  const [addTimePostReq, setaddTimePostReq] = useState({
    doctor_id: 131,
    schedules: [{ day: 0, time_slots: [{ start_time: "", end_time: "" }] }],
  });
  const navigate = useNavigate();
  const AddRoleHook = usePost();
  const [LabCertificateSelected, setLabCertificateSelected] = useState([]);
  const { t } = useTranslation();
  const [isDataLoading, setIsDataLoading] = useState(true);

  const handleDoctorImageClick = () => {
    
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";

    input.onchange = (event) => {
      const file = event.target.files[0];
      // Set the image state to the source of the uploaded picture
      setImage(URL.createObjectURL(file));
      setAddPharmacyData({
        ...addPharmacyData,
        profile_picture: file,
      });
      setValue('profile_picture', file, { shouldValidate: true });
    };

    input.click();
    setValue('profile_picture', watch('profile_picture'), { shouldValidate: true });
  };

  const handleModalToggle = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleChangeSelect = (value, name) => {
    setAddPharmacyData({ ...addPharmacyData, [name]: value });
    setFormDataState((pre) => ({ ...pre, [name]: value }));
  };

  const formatTimeTo24Hour = (time) => {
    if (!time) return "";
    const [hours, minutes] = time.split(":");
    const parsedTime = new Date(0, 0, 0, parseInt(hours), parseInt(minutes));
    const formattedTime = parsedTime.toTimeString().slice(0, 5);

    return formattedTime;
  };

  const handleChangePharmacy = (e) => {
    const { name, value } = e.target;
    if (name === "start_time" || name === "end_time") {
      setAddPharmacyData((prevData) => ({
        ...prevData,
        [name]: formatTimeTo24Hour(value),
      }));
    } else {
      setAddPharmacyData({ ...addPharmacyData, [name]: value });
    }
  };
  const handleLocationIconClick = () => {
    !showMap ? setShowMap(true) : setShowMap(false);
  };

  useEffect(() => {
    setValue("address", formDataState?.address);
  }, [formDataState?.address]);

  const role = JSON.parse(localStorage.getItem("userRoles"));
  const isSuperAdmin = Object.keys(role).length === 0;

  const savePharmacySchedule = (res) => {
    const requestBody = {
      [`${entityType}_id`]: res.id,
      schedules: addTimePostReq.schedules,
    };

    postData(timeSetApi, requestBody, () => {
      setAddedTimings(addTimePostReq.schedules);
    });
  };
  const { isLoading, postData } = usePost();
  const { deleteData } = useDeleteData();

  useEffect(() => {
    if (id) {
      const temp = {};
      deleteData(`${getByIdAPI}/${id}`, (response) => {
        Object.entries(response?.data).forEach(([fieldName, fieldValue]) => {
          setValue(fieldName, fieldValue ?? '');
          temp[fieldName] = fieldValue ?? '';
        });
        setFormDataState((prev) => ({
          ...prev,
          state: response?.data.state,
          documents: response?.data?.documents,
          location: response?.data.location,
          latitude: response?.data.latitude,
          longitude: response?.data.longitude,
        }));

        setAddPharmacyData({...temp});
        if (id && response?.data?.data?.state == "undefined") {
          setValue();
        }
        setIsDataLoading(false);
        // setAddPharmacyData
      });

      deleteData(`${timeGetApi}/${id}`, (response) => {
        setaddTimePostReq(response?.data);
      });
    }
  }, [id]);

  const handleFormSubmit = async (formData) => {
    
      const FarmData = new FormData();
      const completeFormData = {
        ...formData,
        profile_picture: addPharmacyData.profile_picture,
        state: addPharmacyData.state,
        facebook: addPharmacyData.facebook,
        instagram: addPharmacyData.instagram,
        linkedin: addPharmacyData.linkedin,
        description: addPharmacyData.description,
        documents: formDataState?.documents,
        is_laboratory: 1,
        location: formDataState?.location,
        latitude: formDataState?.latitude,
        longitude: formDataState?.longitude,
        twitter: addPharmacyData?.twitter,
        website: addPharmacyData?.website,
        ...(id
          ? { remove_document_ids: formDataState?.remove_document_ids }
          : {}),
        name_ar: addPharmacyData?.name_ar,
        description_ar: addPharmacyData.description_ar,
      };

      Object.keys(completeFormData).forEach((key) => {
        if (
          (key === "documents" || key === "remove_document_ids") &&
          Array.isArray(completeFormData[key])
        ) {
          completeFormData[key].forEach((file) => {
            FarmData.append(`${key}[]`, file);
          });
        } else {
          const value = completeFormData[key];
          FarmData.append(
            key,
            value !== undefined && value !== null ? value : ""
          );
        }
      });

        postData(
          id ? `${updateApiEndPoint}/${id}` : `${apiEndpoint}`,
          FarmData,
          (response) => {
            if (response?.success === true) {
              savePharmacySchedule(response?.data);

              if (!id) {
                setRoleCategoryId(response?.data?.id);
                setAddRole({ ...addRole, join_id: response?.data?.id });

                const updateRoleData = {
                  ...addRole,
                  join_id: response?.data?.id,
                };

                const formData = new FormData();

                for (const key in updateRoleData) {
                  if (Array.isArray(updateRoleData[key])) {
                    updateRoleData[key].forEach((value) => {
                      formData.append(`${key}[]`, value);
                    });
                  } else {
                    formData.append(key, updateRoleData[key]);
                  }
                }

                AddRoleHook?.postData(
                  `${process.env.REACT_APP_ADD_ROLE}`,
                  formData,
                  (response) => {
                    if (response?.success === true) {
                      CustomToast({
                        type: "success",
                        message: `${customToastMessage}`,
                      });

                      CustomToast({
                        type: "success",
                        message: `Role added successfully`,
                      });

                      !id && setAddPharmacyData({});
                      !id && reset();
                      !id && setImage("");

                      navigate("/laboratory");
                    }
                  }
                );
              } else {
                CustomToast({
                  type: "success",
                  message: `${updateToastMessage}`,
                });
                navigate("/laboratory");
              }
            }
          }
        );
  };

  const handleChange = (name, value) => {
    setFormDataState((prev) => ({ ...prev, 
      [name]: name === 'phone' || name === 'support_phone_no' ? handlePhoneCode(name, value, setValue) : value 
    }));
  };

  function getDayName(dayNumber) {
    switch (dayNumber) {
      case 1:
        return "Sunday";
      case 2:
        return "Monday";
      case 3:
        return "Tuesday";
      case 4:
        return "Wednesday";
      case 5:
        return "Thursday";
      case 6:
        return "Friday";
      case 7:
        return "Saturday";
      default:
        return null;
    }
  }

  const {
    handleSubmit,
    setValue,
    reset,
    watch,
    control,
    formState: { errors }
  } = useForm({
    mode: 'all',
    resolver: yupResolver(formschemas?.pharmacySchema(id))
  });

  return (
    <>
      <form onSubmit={handleSubmit(handleFormSubmit)}>

        {id && <SpinnerLoaderModal isDataLoading={isDataLoading} />}

        <div className="col-md-6 pt-2 d-flex align-items-center doc-cam">
          <div
            className="mt-4 mb-md-4 mb-0 d-flex align-items-center justify-content-center add-doc-camera-upload cursor-pointer"
            onClick={handleDoctorImageClick}
          >
            {image ? (
              <img
                className="add-doc-camera-upload-1st"
                src={image}
                alt="Uploaded image"
              />
            ) : addPharmacyData.profile_picture ? (
              <div className="add-doc-camera-upload">
                <img
                  className="w-100 h-100 add-doc-camera-upload-1st"
                  src={
                    process.env.REACT_APP_IMAGE_URL +
                    "/" +
                    addPharmacyData.profile_picture
                  }
                  alt=""
                />
              </div>
            ) : (
              <img src={CameraIcon} alt="" />
            )}
          </div>
          <span className="pl-4 ml-2 pt-lg-0 pt-4 doc-cam-text">
            Profile Picture / {`${t('Common.ProfilePicture')}`}
          </span>
        </div>

        <div className="col-12" style={{ marginTop: "-20px" }}>
          <ErrorMessage error={errors?.profile_picture?.message} />
        </div>

        <div className="col-12 mt-3">
          <div className="row">
            <div className="col-6 doc-setting-input">
              <p className="mb-2">
                Name<span className="error-message">*</span>{" "}
              </p>

              <Controller
                name="name"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <>
                    <input
                      type="text"
                      name="name"
                      {...field}
                      value={field.value || ""}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        handleChange(e.target.name, e.target.value);
                      }}
                    />

                    <ErrorMessage error={errors?.name?.message} />
                  </>
                )}
              />
            </div>

            <ArabicInput
              divClass="doc-setting-input col-6"
              label={t("Laboratory.Name")}
              formStateData={addPharmacyData}
              setFormStateData={setAddPharmacyData}
              name="name_ar"
            />
          </div>

          <div className="row mt-4">
            <div className="col-lg-6 pr-lg-1 doc-setting-input">
              
              <div className="d-flex justify-content-between">
                <p className="mb-2">
                  Email<span className="error-message">*</span>{" "}
                </p>
                <p className="mb-2">{`${t("Common.Email")}`}</p>
              </div>

              <Controller
                name="email"
                control={control}
                rules={{
                  required: true,
                  pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
                }}
                render={({ field }) => (
                  <>
                    <input
                      className=""
                      type="text"
                      name="email"
                      {...field}
                      value={field.value || ""}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        handleChange(e.target.name, e.target.value);
                      }}
                    />
                    <ErrorMessage error={errors?.email?.message} />
                  </>
                )}
              />
            </div>

            <div className="col-lg-6 mt-lg-0 mt-4 pl-lg-1 doc-setting-input">
              <Controller
                name="phone"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <>
                    <Phone
                      name="phone"
                      field={field}
                      value={field.value}
                      handleChange={(e) => {
                        field.onChange(e);
                        handleChange(e.target.name, e.target.value);
                      }}
                      arLabel={`${t("Common.PhoneNo")}`}
                    />
                    <ErrorMessage error={errors?.phone?.message} />
                  </>
                )}
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-6 pr-lg-1 doc-setting-input">
              
              <div className="d-flex justify-content-between">
                <p className="mb-2">
                  Customer Support Email<span className="error-message"></span>{" "}
                </p>
                <p className="mb-2">{`${t("Common.CustomerSupportEmail")}`}</p>
              </div>

              <Controller
                name="support_email"
                control={control}
                rules={{
                  required: false,
                  pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
                }}
                render={({ field }) => (
                  <>
                    <input
                      className=""
                      type="text"
                      name="support_email"
                      {...field}
                      value={field.value || ""}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        handleChange(e.target.name, e.target.value);
                      }}
                    />
                    <ErrorMessage error={errors?.support_email?.message} />
                  </>
                )}
              />
            </div>

            <div className="col-lg-6 mt-lg-0 mt-4 pl-lg-1 doc-setting-input">
              <Controller
                name="support_phone_no"
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field }) => (
                  <>
                    <Phone
                      label="Customer Support No"
                      name="support_phone_no"
                      field={field}
                      value={field.value}
                      handleChange={(e) => {
                        field.onChange(e);
                        handleChange(e.target.name, e.target.value);
                      }}
                      arLabel={`${t("Common.CustomerSupportNo")}`}
                    />
                    <ErrorMessage error={errors?.support_phone_no?.message} />
                  </>
                )}
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-6 pr-lg-1 doc-setting-input">
              <Controller
                name="country"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue="Kuwait"
                render={({ field }) => (
                  <>
                    <SelectCountry
                      handleChange={(value, name) => {
                        field.onChange(value);
                        handleChange(name, value);
                        handleChangeSelect(value, name);
                      }}
                      name="country"
                      field={field}
                      value={field.value}
                      onBlur={field.onBlur}
                      isDisabled={true}
                      arLabel={`${t("Common.Country")}`}
                    />

                    <ErrorMessage error={errors?.country?.message} />
                  </>
                )}
              />
            </div>

            <div className="col-lg-6 mt-lg-0 mt-4 pl-lg-1 doc-setting-input">
              <SelectState
                country={formDataState?.country}
                disabled={!formDataState?.country}
                name="state"
                value={
                  formDataState?.state == "undefined"
                    ? ""
                    : formDataState?.state
                }
                handleChange={handleChangeSelect}
                arLabel={`${t("Common.State")}`}
                isController={true}
                control={control}
                error={errors?.state?.message}
              />
            </div>

          </div>
          <div className="row mt-4">
            <div className="col-lg-6 pr-lg-1 doc-setting-input">
              
              <div className="d-flex justify-content-between">
                <p className="mb-2">
                  City<span className="error-message">*</span>{" "}
                </p>
                <p className="mb-2">{`${t("Common.City")}`}</p>
              </div>

              <Controller
                name="city"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <>
                    <input
                      className=""
                      type="text"
                      name="city"
                      {...field}
                      value={field.value || ""}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        handleChange(e.target.name, e.target.value);
                      }}
                    />
                    <ErrorMessage error={errors?.city?.message} />
                  </>
                )}
              />
            </div>

            <div className="col-lg-6 mt-lg-0 mt-4 pl-lg-1 doc-setting-input">
              
              <div className="d-flex justify-content-between">
                <p className="mb-2">
                  Address<span className="error-message">*</span>{" "}
                </p>
                <p className="mb-2">{`${t("Common.Address")}`}</p>
              </div>
              
              <Controller
                name="address"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <>
                    <input
                      className=""
                      type="text"
                      name="address"
                      {...field}
                      value={field.value || ""}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        handleChange(e.target.name, e.target.value);
                      }}
                    />
                    <ErrorMessage error={errors?.address?.message} />
                  </>
                )}
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-6 mt-lg-0 mt-4 pr-lg-1 doc-setting-input">
              
              <div className="d-flex justify-content-between">
                <p className="mb-2">Location</p>
                <p className="mb-2">{`${t("Common.Location")}`}</p>
              </div>

              <div onClick={() => setModal2Open(true)}>
                <Location
                  handleLocation={handleLocationIconClick}
                  locationProp={formDataState?.location}
                />
              </div>
              {showMap && (
                <Modal
                  className="location-modal"
                  centered
                  open={modal2Open}
                  onOk={() => setModal2Open(false)}
                  onCancel={() => setModal2Open(false)}
                  width={735}
                  footer={<div className=""></div>}
                >
                  <div className="row   position-relative">
                    <div className="col-lg-12  ">
                      <SearchMap
                        addHospitalData={formDataState}
                        setAddHospitalData={setFormDataState}
                      />
                    </div>
                    <div
                      className="col-12 position-absolute  d-flex justify-content-end"
                      style={{
                        top: "415px",
                        left: "0",
                      }}
                    >
                      <button
                        style={{ height: "36.6px", width: "100px" }}
                        className="apply-filter add-doc-changes"
                        onClick={() => {
                          setModal2Open(false);
                        }}
                      >
                        Done
                      </button>
                    </div>
                  </div>
                </Modal>
              )}
            </div>
          </div>

          <div className="row mt-4">

            <SocialInput
              label={'Facebook'} 
              arLabel={t('Common.Facebook')} 
              socialIcon={FacebookInput} 
              name={'facebook'} 
              value={addPharmacyData.facebook} 
              inputDivClass='col-lg-6 pr-lg-1 doc-setting-input pl-3 mb-4' 
              handleOnChange={handleChangePharmacy} 
              control={control}
              error={errors?.facebook?.message}
            />

            <SocialInput
              label={'Instagram'} 
              arLabel={t('Common.Instagram')} 
              socialIcon={InstaInput} 
              name={'instagram'} 
              value={addPharmacyData.instagram} 
              inputDivClass='col-lg-6 mt-lg-0 pl-lg-1 doc-setting-input pr-3 mb-4' 
              handleOnChange={handleChangePharmacy} 
              control={control}
              error={errors?.instagram?.message}
            />

          </div>

          <div className="row">

            <SocialInput
              label={'Twitter'} 
              arLabel={t('Common.Twitter')} 
              socialIcon={TwitterInput} 
              name={'twitter'} 
              value={addPharmacyData.twitter} 
              inputDivClass='col-lg-6 mt-lg-0 pr-lg-1 doc-setting-input pl-3 mb-4' 
              handleOnChange={handleChangePharmacy} 
              control={control}
              error={errors?.twitter?.message}
            />

            <SocialInput 
              label={'Website URL'} 
              arLabel={t('Common.WebsiteURL')} 
              socialIcon={WebInput} 
              name={'website'} 
              value={addPharmacyData?.website} 
              inputDivClass='col-lg-6 mt-lg-0 mt-4 pl-lg-1 doc-setting-input pr-3' 
              handleOnChange={handleChangePharmacy} 
              control={control}
              error={errors?.website?.message}
            />

          </div>

          <div className="row">

            <SocialInput
              label={'Linkedin'} 
              arLabel={t('Common.Linkedin')} 
              socialIcon={LinkedInInput} 
              name={'linkedin'} 
              value={addPharmacyData.linkedin} 
              inputDivClass='col-lg-6 mt-lg-0 pr-lg-1 doc-setting-input pl-3 mb-4' 
              handleOnChange={handleChangePharmacy} 
              control={control}
              error={errors?.linkedin?.message}
            />

            <div className="col-lg-6 mt-lg-0 mt-4 pl-lg-1 doc-setting-input">
              
              <div className="d-flex justify-content-between">
                <p className="mb-2"> Certificates </p>
                <p className="mb-2">{`${t("Common.Certificates")}`}</p>
              </div>

              <CertificateInput
                selectedFiles={LabCertificateSelected}
                setSelectedFiles={setLabCertificateSelected}
                formDataState={formDataState}
                setFormDataState={setFormDataState}
                // objectKey={'certificate'}
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-6 pr-lg-1 doc-setting-input">
              <p className="mb-2">Laboratory Timings / {t('Laboratory.LaboratoryTimings')}</p>
              <div className="pharmacy-schedule-shadow">
                <div className=" pharmacy-schedule d-flex w-100 justify-content-between flex-column border-left border-right border-top border-bottom">
                  <div className="d-flex w-100 justify-content-between px-2">
                    <span
                      className="mb-2  input-field-timings"
                      style={{ fontSize: "14px", color: "#535353" }}
                    >
                      Set Schedule
                    </span>
                    <img
                      onClick={handleModalToggle}
                      src={TimeTablePencil}
                      alt="TimeTablePencil"
                      className="input-field-icon input-field-timings cursor-pointer"
                    />
                  </div>
                </div>

                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th className="text-left pharmacy-timings pl-3 border-left border-right">
                        Day
                      </th>
                      <th className="text-center pharmacy-timings border-left border-right">
                        Opening Time
                      </th>
                      <th className="text-center pharmacy-timings  border-left border-right">
                        Closing Time
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {addTimePostReq?.schedules.map((time) => {
                      const hasTimeSlots = time?.time_slots;
                      return (
                        <tr
                          key={time.day}
                          className="border pharmacy-time-cell"
                        >
                          <td
                            className=""
                            style={{ fontSize: "14px", color: "#535353" }}
                          >
                            {getDayName(time?.day)
                              ? getDayName(time?.day)
                              : "Days"}
                          </td>
                          <td
                            className="text-center "
                            style={{ fontSize: "14px", color: "#535353" }}
                          >
                            {time?.time_slots[0]?.start_time
                              ? time?.time_slots[0]?.start_time
                              : "Off"}
                          </td>
                          <td
                            className="text-center  pl-3"
                            style={{ fontSize: "14px", color: "#535353" }}
                          >
                            {time?.time_slots[0]?.end_time
                              ? time?.time_slots[0]?.end_time
                              : "Off"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4 px-3">

          <TextInput
            label={'About Laboratory'} 
            inputDivClass='col-6 mt-lg-0 mt-0 pr-1 doc-setting-input pl-3 mb-3' 
            type='text' 
            name='description' 
            textArea={true} 
            value={addPharmacyData?.description} 
            handleOnChange={handleChangePharmacy} 
            control={control} 
            error={errors?.description?.message} 
          />

          <ArabicTextArea
            divClass="doc-setting-input col-6 pl-1"
            label={t("Laboratory.AboutLaboratory")}
            formStateData={addPharmacyData}
            setFormStateData={setAddPharmacyData}
            name="description_ar"
          />
        </div>

        <div className="pl-3">
          <MuiltiplesImages
            selectedImages={selectedImages}
            setSelectedImages={setSelectedImages}
            addUrl="add_laboratory_images"
            deleteUrl="delete_hospital_images"
            selectedImagesCall={selectedImagesCall}
            setSelectedImagesCall={setSelectedImagesCall}
          />
        </div>

        <div className="row m-0 px-3 mt-5">
          {isSuperAdmin && !id ? (
            <div className="col-lg-8 m-0 px-0 add-appointment-card-wrapper" style={{border: '1px solid #d9d9d9', borderRadius: '5px'}}>

              <div className="title-header px-3">
                <div className="title">Add a Role</div>
              </div>
              
              <hr />

              <AddRole
                upperData={false}
                roleParent={[{ value: 4, label: "Laboratory Admin" }]}
                setAddRole={setAddRole}
                addRole={addRole}
                roleParentValidation={roleParentValidation}
                roleCategoryId={roleCategoryId}
                navigateLink="laboratory"
                formDataState={formDataState}
                control={control}
                errors={errors?.role}
                setValue={setValue}
              />

            </div>
          ) : null}
        </div>

        <div className="row my-5 pt-2 pb-3 ">
          <div className="col-lg-6">
            <button
              type="submit"
              className="apply-filter add-doc-changes ml-2"
              disabled={isLoading}
            >
              {!isLoading ? (
                addPharmacyData.id ? (
                  submitUpdateText
                ) : (
                  submitButtonText
                )
              ) : (
                <ButtonLoader />
              )}
            </button>
          </div>

          <div className="col-lg-6"></div>
        </div>

        <Modal
          title="Time Table"
          visible={isModalVisible}
          onCancel={handleModalToggle}
          footer={
            <div className="row px-3 mt-4 mb-2">
              <div className="col-6"></div>
              <div className="col-6 d-flex justify-content-end mt-3">
                <button className="apply-filter" onClick={handleModalToggle}>
                  Save Schedule
                </button>
              </div>
            </div>
          }
        >
          <hr />
          <div className="row px-3 mt-4">
            <p className=" mb-0 time-edit-text1">Set Standard Hours</p>
          </div>
          <br />
          <PharmacyTimings
            addTimePostReq={addTimePostReq}
            setaddTimePostReq={setaddTimePostReq}
          />
        </Modal>
      </form>
    </>
  );
};

export default NewLaboratoryForm;
