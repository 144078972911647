import React, { useEffect, useState } from 'react'
import useDeleteData from '../../../customHook/useDelete';
import usePost from '../../../customHook/usePost';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import FiltersHelper from '../../../molecules/HospitalRoomManagement/helper';
import PaginationHelper from '../../../molecules/tablepagination/paginationhelper';

const ServiceProviderListHelper = (listName, deleteApiMethod, DropDownItems) => {

  const getApiMethod = useDeleteData();
  const postApiMethod = usePost();
  const [providersList, setProvidersList] = useState([]);
  const [tempRequestsList, setTempRequestList] = useState([]);
  const [listData, setListData] = useState({
    statusOptions: {
      0: 'Pending',
      1: 'Approved',
      2: 'Declined',
      3: 'Completed'
    }
  });
  const user = useSelector(state => state.auth);
  const {
    filterStates,
    filterFuncs,
    apiHooks
  } = FiltersHelper(deleteApiMethod, DropDownItems);

  const { filtersData, isDeleted } = filterStates;
  const { paginationData, setPaginationData, handleChangePage } = PaginationHelper();

  // Filter states
  const [reRender, setReRender] = useState(false);

  const providerTableColumns = [
    { name: '#', align: 'left', className: 'number' },
    { name: 'Name', align: 'left', textTruncLength: 20 },
    { name: 'Email', align: 'left' },
    { name: 'Contact', align: 'left' },
    { name: 'Address', align: 'left', textTruncLength: 30 },
    { name: 'Edit', align: 'left' }
  ];

  const serviceTableColumns = [
    { name: '#', align: 'left', className: 'number' },
    { name: 'Name', align: 'left', textTruncLength: 20 },
    { name: 'Amount Per Hour', align: 'left', rowColDir: 'center' },
    { name: 'Time Slot', align: 'left' },
    { name: 'Experience', align: 'center', rowColDir: 'center' },
    { name: 'Service Details', align: 'left', textTruncLength: 30 },
    { name: 'Document', align: 'left' },
    { name: 'Edit', align: 'left' }
  ];

  const serviceRequestTableColumns = [
    { name: '#', align: 'left', className: 'number' },
    { name: 'First Name', align: 'left', textTruncLength: 15 },
    { name: 'Last Name', align: 'left', textTruncLength: 15 },
    { name: 'Service Provider', align: 'left' },
    { name: 'Service Request', align: 'left' },
    { name: 'Date', align: 'left' },
    { name: 'Charges', align: 'left', rowColDir: 'center', sortBy: true, ascKey: 'charges_asc', descKey: 'charges_desc' },
    { name: 'Contact Number', align: 'left' },
    { name: 'Location', align: 'left', textTruncLength: 30 },
    { name: 'Status', align: 'left' },
];

  // Handlers

  // UseEffects
  
  useEffect(() => {
    if(listName == 'Service Provider List'){
      getApiMethod?.deleteData(`list_of_service_provider?page=${paginationData?.page}&per_page=10&title=${filtersData?.title}${user?.role === 'serviceprovider' ? `&userId=${user?.item}` : ''}`, (response) => {
        if(response?.success) {

          setProvidersList(response?.data?.service_providers?.data?.map((provider) => ({
            id: provider?.id,
            name: provider?.name,
            email: provider?.email,
            contact: provider?.phone_no,
            address: provider?.address
          })))

          setPaginationData({
            ...paginationData, 
            totalRows: response?.data?.service_providers?.total, 
            to: response?.data?.service_providers?.to,
            totalPages: Math.ceil(response?.data?.service_providers?.total / paginationData?.rowsPerPage)
          });

        }
        else{
          setProvidersList([]);

          setPaginationData({
            ...paginationData, 
            totalRows: 0, 
            to: 0,
            totalPages: 0
          });

        }

      })
    }
  },[paginationData?.page, reRender])

  const daysByNumber = {
    1: 'Sunday',
    2: 'Monday',
    3: 'Tuesday',
    4: 'Wednesday',
    5: 'Thursday',
    6: 'Friday',
    7: 'Saturday',
  };

  useEffect(() => {
    if(listName == 'Service List'){

      postApiMethod?.postData(`get_services_of_provider?page=${paginationData?.page}&per_page=10&title=${filtersData?.title}`, {...(user?.role === 'serviceprovider' && {service_provider_id: user?.item})}, (response) => {

        setProvidersList(response?.data?.data?.map((service) => {
          let tempTimeSlot = '';

          service?.timetable?.map((timeSlot, index) => {
            
            if(timeSlot?.start_time && timeSlot?.end_time && tempTimeSlot === ''){
              tempTimeSlot = `${daysByNumber[timeSlot?.day]} ${timeSlot?.start_time} - ${timeSlot?.end_time}`;
            }
          })

          return {
            id: service?.id,
            name: service?.provider_name,
            amount_per_hour: service?.amount_per_hour,
            time_slot: tempTimeSlot,
            expirience: service?.experience,
            service_details: service?.service_detail,
            document: service?.documents[0]?.document
          }
        }))

        setPaginationData({
          ...paginationData, 
          totalRows: response?.data?.total, 
          to: response?.data?.to,
          totalPages: Math.ceil(response?.data?.total / paginationData?.rowsPerPage)
        });

      })

    }
    else if(listName === 'Service Request List'){
      
      getApiMethod?.deleteData(
        `list_of_requested_services?page=${paginationData?.page}&per_page=10&title=${filtersData?.title}&service_provider_id=${filtersData?.service_provider_id}&status=${filtersData?.status}&sort_by=${filtersData?.isSortBy}`, (response) => {

        setProvidersList(response?.data?.data?.map((request) => ({
          id: request?.id,
          first_name: request?.first_name,
          last_name: request?.last_name,
          service_provider: request?.service_provider?.name,
          service_request: request?.services?.provider_name,
          date: request?.date,
          charges: request?.charges,
          phone: request?.phone,
          address: request?.address,
          status: parseInt(request?.status)
        })))

        setTempRequestList(response?.data?.data?.map((request) => ({
          id: request?.id,
          first_name: request?.first_name,
          last_name: request?.last_name,
          service_request: request?.services?.provider_name,
          date: request?.date,
          charges: request?.charges,
          phone: request?.phone,
          address: request?.address,
          service_detail: request?.services?.service_detail
        })))

        setPaginationData({
          ...paginationData, 
          totalRows: response?.data?.total, 
          to: response?.data?.to,
          totalPages: Math.ceil(response?.data?.total / paginationData?.rowsPerPage)
        });
      })
    }
  },[paginationData?.page, reRender])

  useEffect(() => {
    if(paginationData?.page !== 1){
      setPaginationData({...paginationData, page: 1});
    }
    else{
      setReRender(!reRender);
    }
  },[filtersData, isDeleted])

  return {
    providerTableColumns,
    serviceTableColumns,
    serviceRequestTableColumns,
    providersList,
    tempRequestsList,
    ...(listName === 'Service Provider List' || listName === 'Service Request List' ? {isLoading: getApiMethod?.isLoading} : {isLoading: postApiMethod?.isLoading}),
    paginationData,
    handleChangePage,

    providerStates: {
      listData
    },

    providerFuncs: {
      setListData
    },

    // Filter States

    filterStates,
    filterFuncs,
    apiHooks
  };

}

export default ServiceProviderListHelper;