import React, { useEffect, useState } from 'react'
import useDeleteData from '../../../customHook/useDelete';
import usePost from '../../../customHook/usePost';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { handlePhoneCode } from '../../common/functionalityhelper';

const ServiceProviderFormHelper = (serviceProviderId) => {

    // States

    const [serviceProviderData, setServiceProviderData] = useState({
        country: "Kuwait",
        lat: 31.48544799999999,
        long: 74.3046761,
    });
    const [addRole, setAddRole] = useState({ country: "Kuwait" });
    const [categoryList, setCategoryList] = useState([]);

    // Hooks

    const getApiHook = useDeleteData();
    const { postData } = usePost();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    // Handlers

    const handleOnChange = (e) => {
        const { name, value } = e.target ? e.target : e;
        setServiceProviderData({...serviceProviderData, 
            [name]: name === 'phone_no' || name === 'support_phone_no' ? handlePhoneCode(name, value) : value
        });
    }

    const handleOnSubmit = () => {

        if(!serviceProviderData?.profile_picture){
            toast?.error('Profile Picture is required.');
            return;
        }

        if(!serviceProviderData?.name){
            toast?.error('Name is required.');
            return;
        }

        if(!serviceProviderData?.email){
            toast?.error('Email is required.');
            return;
        }

        if(!serviceProviderData?.phone_no){
            toast?.error('Phone no is required.');
            return;
        }

        if(!serviceProviderData?.country){
            toast?.error('Country is required.');
            return;
        }

        if(!serviceProviderData?.state){
            toast?.error('State is required.');
            return;
        }

        if(serviceProviderData?.provider_category?.length === 0){
            toast?.error('Provider category is required.');
            return;
        }

        if(!serviceProviderData?.address){
            toast?.error('Address is required.');
            return;
        }

        setIsLoading(true);

        const formData = new FormData();

        for(const key in serviceProviderData){
            if(key === 'provider_category'){
                formData.append(`${key}`, `[${serviceProviderData[key]}]`);
            }
            else{
                formData.append(key, serviceProviderData[key] ? serviceProviderData[key] : '');
            }
        }

        if(
          (addRole?.name &&
            addRole?.email &&
            addRole?.password &&
            addRole?.role_type &&
            addRole?.role_type_id) || serviceProviderId
          ){

            postData(serviceProviderId ? `updateServiceProvider/${serviceProviderId}` : 'addServiceProvider', formData, (newProviderResponse) => {

                if(newProviderResponse?.success){

                    if(!serviceProviderId){
                        setAddRole({ ...addRole, 'join_id': newProviderResponse?.data?.id })
    
                        const tempRoleData = {
                          ...addRole,
                          join_id: newProviderResponse?.data?.id,
                        };
    
                        const formData = new FormData();
    
                        for(const key in tempRoleData){
                            formData.append(key, tempRoleData[key]);
                        }
    
                        postData(process.env.REACT_APP_ADD_ROLE, formData, (newRoleResponse) => {
    
                            toast.success('Successfully added service provider.');
                            toast.success("Successfully added service provider's role.");
                            navigate("/service-provider/list");
                            setIsLoading(false);
    
                        })
                    }
                    else{
                        toast.success("Successfully updated service provider.");
                        navigate("/service-provider/list");
                        setIsLoading(false);
                    }

                }
                else {
                    toast?.error(newProviderResponse?.response?.slice(0,80));
                    setIsLoading(false);
                }

            })

        }
        else {
            toast.error('Role name, email, password, type is required for adding the doctor.');
            setIsLoading(false);
        }

    }

    // UseEffects

    useEffect(() => {
        if(serviceProviderId){
            getApiHook?.deleteData(`getServiceProvider/${serviceProviderId}`, (response) => {
                setServiceProviderData({
                    ...serviceProviderData, 
                    ...response?.data,
                    provider_category: response?.data?.categories?.map(category => (
                        category?.name
                    )),
                    facebook: response?.data?.facebook || '',
                    instagram: response?.data?.instagram || '',
                    twitter: response?.data?.twitter || '',
                    website: response?.data?.website || '',
                    linkedin: response?.data?.linkedin || ''
                });
            })
        }
    },[])

    useEffect(() => {
        getApiHook?.deleteData('get_category_list', (response) => {
            setCategoryList(response?.data?.map((category) => ({
                label: category?.name,
                value: category?.name
            })))
        })
    },[])

    return {
        providerFormStates: {
            serviceProviderData,
            setServiceProviderData,
            categoryList,
            addRole,
            setAddRole,
            isLoading,
            isDataLoading: getApiHook?.isLoading
        },

        providerFormFuncs: {
            handleOnChange,
            handleOnSubmit
        }
    }
}

export default ServiceProviderFormHelper;