import { useState } from 'react';
import dayjs from "dayjs";

const DateTimeHelper = () => {
    
    const [currentDate, setCurrentDate] = useState({
        dateObj: dayjs(),
        dateText: dayjs()?.format('YYYY/MM/DD')
    });
    
    const [startDate, setStartDate] = useState({
        dateObj: dayjs(),
        dateText: dayjs()?.format('YYYY/MM/DD'),
    });
    
    const [endDate, setEndDate] = useState({
        dateObj: dayjs(),
        dateText: dayjs()?.format('YYYY/MM/DD')
    });
    
    const disabledStartDate = (current) => {
        return current && current < dayjs(currentDate?.dateText, 'YYYY/MM/DD');
    }
    
    const disabledEndDate = (current) => {
        return current && current < dayjs(startDate?.dateText, 'YYYY/MM/DD');
    }

    const handleDisableTime = (time, type) => {

        if(type === 'start'){
    
          const currentHour = currentDate?.dateObj?.hour();
          const currentMinute = currentDate?.dateObj?.minute();
    
          if(startDate?.dateText === currentDate?.dateText){
            return{
              disabledHours: () => Array.from({ length: currentHour }, (_, i) => i),
              disabledMinutes: (selectedHour) => {
                if (selectedHour === currentHour) {
                  return Array.from({ length: currentMinute }, (_, i) => i);
                }
                return [];
              },
            }
          }
          else return {};
    
        }
        else if(type === 'end'){
          
          if(startDate?.dateText === endDate?.dateText){
    
            const startDayHour = startDate?.dateObj?.hour();
            const startDayMinute = startDate?.dateObj?.minute();
    
            return{
              disabledHours: () => Array.from({ length: startDayHour }, (_, i) => i),
              disabledMinutes: (selectedHour) => {
                if (selectedHour === startDayHour) {
                  return Array.from({ length: startDayMinute }, (_, i) => i);
                }
                return [];
              },
            }
          }
          else return {};
    
        }
    
    }

    return {
        currentDate,
        setCurrentDate,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        disabledStartDate,
        disabledEndDate,
        handleDisableTime
    }
}

export default DateTimeHelper;