import React from 'react'
import ButtonLoader from '../../atoms/buttonLoader';

const CustomButton = ({label, type, buttonDivClass, buttonWidth, buttonHeight, buttonClass, isLoading, disabled, handleOnClick}) => {
  return (
    <div className={`${buttonDivClass}`}>
        <button type={type || undefined} style={{width: buttonWidth, height: buttonHeight}} className={`customButton ${buttonClass}`} onClick={handleOnClick} disabled={disabled || isLoading} >
          {isLoading ? <ButtonLoader /> : label}
        </button>
    </div>
  )
}

export default CustomButton;