import React from 'react'

const useFiltersLogic = () => {

    const handleSortIcons = (isSortBy, keyOne, keyTwo) => {

        if(isSortBy === '' || (isSortBy !== keyOne && isSortBy !== keyTwo)){
            return 'fa-arrow-up'
        }
        else if(isSortBy === keyOne){
            return 'fa-arrow-down'
        }
        else{
            return 'fa-arrow-up'
        }
    }

    return { handleSortIcons };
}

export default useFiltersLogic;