import React from 'react'
import ServiceProviderForm from '../../components/HomeServiceProvider/ServiceProviderForm/ServiceProviderForm'
import { useParams } from 'react-router-dom';

const EditServiceProvider = () => {

  const { id } = useParams();

  return (
    <ServiceProviderForm serviceProviderId={id} />
  )
}

export default EditServiceProvider;