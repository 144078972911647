import React, { useEffect } from "react";
import { Select, Input } from "antd";
import ErrorMessage from "../../formvalidation/errormessage";
import InputLabel from "../../molecules/TextInput/InputLabel";
import { Controller } from "react-hook-form";

const { Option } = Select;

const CustomDropDown = ({
  mode,
  value,
  selectLabel = "Select",
  option = [],
  handleChangeSelect,
  disabled,
  name,
  field,
  hospitalDopDown,
  dayId,
  allowClear,
  // Reusbale elements added
  dropDownDivClass,
  label,
  labelTag,
  labelDivClass,
  arLabel,
  labelClass,
  required,
  isController,
  control,
  error
}) => {
  const selectAllOption = { value: "all", label: "Select All" };
  const updatedOptions =
    mode === "multiple" ? [selectAllOption, ...option] : option;

  const handleSelectAll = () => {
    const allOptions = option.map((item) => item.value);
    handleChangeSelect(allOptions, name);
  };

  const handleSelectChange = (val, field) => {
    if (Array.isArray(val) && val.includes("all")) {
      handleSelectAll();
    } else {
      handleChangeSelect(val, name);
      field && field?.onChange(val);
      if (typeof hospitalDopDown === "function") {
        hospitalDopDown(val, dayId);
      }
    }
  };

  const renderOptions = updatedOptions?.map((opt) => {
    if (opt && opt.label) {
      return (
        <Option key={opt.value} value={opt.value}>
          {opt.label}
        </Option>
      );
    }
    return null;
  });

  const renderDropDown = (field) => {
    return (
      <Select
        {...field}
        defaultValue={mode === "multiple" ? value : selectLabel}
        className="custom-dropDown"
        name={name}
        mode={mode}
        // value={value || undefined}
        value={value ? value : selectLabel}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children &&
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        disabled={disabled}
        allowClear={
          allowClear === undefined || allowClear
            ? value
              ? true
              : false
            : allowClear
        }
        placeholder={!value && selectLabel}
        style={{
          width: "100%",
        }}
        onChange={(e) => {
          handleSelectChange(e, field);
        }}
        rules={{
          required: {
            value: true,
            message: "Please select at least one",
          },
        }}
      >
        {renderOptions}
      </Select>
    )
  }

  return (
    <div
      className={`${
        dropDownDivClass && `${dropDownDivClass} doc-setting-input mb-0`
      }`}
    >
      {dropDownDivClass && label &&

      <InputLabel
        label={label}
        arLabel={arLabel}
        required={required}
        labelTag={labelTag}
        labelClass={labelClass || 'mb-2'}
        labelDivClass={labelDivClass}
      />
      
      }

      {
        !isController ? renderDropDown(field) : 

        <Controller
          name={name}
          control={control}
          render={({ field }) => renderDropDown(field)}
        />
      }

      <ErrorMessage error={error} />
    </div>
  );
};

export default CustomDropDown;
