import { Modal, Spin } from 'antd';
import React from 'react'

const SpinnerLoaderModal = ({isDataLoading}) => {
  return (
    <Modal
        centered
        open={isDataLoading}
        className="spinnerLoaderModal"
        closeIcon={null}
        footer={null}
        bodyStyle={{
          height: "",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Spin spinning={isDataLoading} />
    </Modal>
  )
}

export default SpinnerLoaderModal;