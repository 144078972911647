import React from 'react'
import AppointmentVideoScreen from '../../components/appointments/VideoCalling/AppointmentVideoScreen'
import { useLocation, useParams } from 'react-router-dom'

const AppointmentVideoCall = () => {

  const { state } = useLocation();
  const { id } = useParams();

  return (
    <AppointmentVideoScreen id={id} patient={state} />
  )
}

export default AppointmentVideoCall;