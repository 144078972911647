import React from 'react'
import AddPharmacy from './AddPharmacy'
import { useParams } from 'react-router-dom'

const EditPharmacy = () => {

  const { id } = useParams();

  return (
    <AddPharmacy Id={id} />
  )
}

export default EditPharmacy;