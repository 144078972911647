import React from 'react'
import NewHospitalRoom from '../../components/Hospital/Hospital Room Form/NewHospitalRoom'
import { useParams } from 'react-router-dom'

const HospitalRoomForm = () => {

  const { id } = useParams();
  
  return (
    <NewHospitalRoom hospitalRoomID={id} />
  )
}

export default HospitalRoomForm;