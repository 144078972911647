import React, { useState } from 'react'
import RoomsTableListHeader from '../../molecules/HospitalRoomManagement/RoomsTableListHeader';
import usePost from '../../customHook/usePost';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CustomButton from '../../molecules/CustomButton/CustomButton';
import InputLabel from '../../molecules/TextInput/InputLabel';
import { useTranslation } from 'react-i18next';
import listBreadcrumbs from '../../utilities/data/list.breadcrumbs';

const ChangePasswordForm = () => {

    const { postData, isLoading } = usePost();
  const [resetPassword, setResetPassword] = useState({});
  const [error, setError] = useState(0);
  const navigate = useNavigate();

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setResetPassword({...resetPassword, [name]: value});
    setError(0);
  }

  const handleResetPassword = async () => {

    if(!resetPassword?.old_password || !resetPassword?.new_password || !resetPassword?.confirm_password){
        toast.error('Please fill all the fields.');
        return;
    }

    if(resetPassword?.new_password !== resetPassword?.confirm_password){
        toast.error('New password must match with confirm password.');
        return;
    }

    postData('change_password', {
        old_password: resetPassword?.old_password,
        new_password: resetPassword?.new_password
    }, (response) => {
        if(response?.success){
            toast.success('Password changed successful.');
            navigate('/dashboard');
        }
        else{
            toast.error(response?.response);
        }
    }, 'https://api.maweidi.com.kw/api')

  };

  const { t } = useTranslation();

  return (
    <div className='row pl-4 pr-4 pt-3 roomslistBody'>

        <RoomsTableListHeader
            pageTitle='Change Password Form'
            breadCrumbItems={listBreadcrumbs?.changePasswordCrumbs}
        />

        <div className="col-lg-6">
            <div className="doc-setting-col-left row">
              <div className="col-12 pt-3 pb-2">
                <p className="mb-0 doc-setting-col-left-text">
                  {" "}
                  Change Password
                </p>
              </div>

              <hr className="my-1 w-100" />

              <div className="col-12 mt-3">
                <div className="row">
                  <div className="col-12 doc-setting-input">
                    <InputLabel
                      label={'Old Password'}
                      labelClass={'mb-2'}
                      required={true}
                      arLabel={t('Common.OldPassword')}
                    />
                    <input name='old_password' type="password" onChange={handlePasswordChange} />
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-12 doc-setting-input">
                    <InputLabel
                      label={'New Password'}
                      labelClass={'mb-2'}
                      required={true}
                      arLabel={t('Common.NewPassword')}
                    />
                    <input name='new_password' type="password" onChange={handlePasswordChange} />
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-12 doc-setting-input">
                    <InputLabel
                      label={'Confirm New Password'}
                      labelClass={'mb-2'}
                      required={true}
                      arLabel={t('Common.ConfirmNewPassword')}
                    />
                    <input name='confirm_password' type="password" onChange={handlePasswordChange} />
                  </div>
                </div>

                <div className="row my-4 ">
                  <CustomButton label='Save Password' buttonWidth='30%' buttonHeight='45px' buttonDivClass={'col-12'} handleOnClick={handleResetPassword} isLoading={isLoading} />
                  <div className="col-6"></div>
                </div>
              </div>
            </div>
          </div>
      
    </div>
  )
}

export default ChangePasswordForm;