import { Avatar, Divider, List, Skeleton, Rate, Select } from "antd";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

// css
import "../../assets/css/dashboard.scss";

// img
import DownIcon from "../../assets/images/dashboard/DownIcon.svg";
import LinearChart from "../../atoms/LinearChart";
import CustomDropDown from "../../atoms/CustomDropDown/Index";
import { gender, optionHostpital } from "../../Data/DoctorData";
import useFetch from "../../customHook/useFetch";
import { DatePicker } from "antd";
import moment from "moment";
import usePost from "../../customHook/usePost";

const App = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isActive, setIsActive] = useState(false);

  let currentYear = new moment().format("YYYY");
  const [yearRange, setYearRange] = useState({
    start_date: currentYear,
    end_date: currentYear,
  });
  const [appointsmentData, setAppointmentsData] = useState([]);

  const { RangePicker } = DatePicker;
  const { postData } = usePost();

  const exportData = useFetch(`${process.env.REACT_APP_GET_HOSPITAL_DATA}`);

  const hospitalsData = exportData?.data?.data?.map((item) => ({
    value: item.id,
    label: item.name,
  }));
  const [hospitalID, setHospitalID] = useState();
  const [check, setCheck] = useState(true);

  const handleChangeSelect = (e) => {
    setHospitalID(e);
  };

  useEffect(() => {
    if (hospitalsData && check) {
      setHospitalID(hospitalsData[15].value);
      setCheck(false);
    }
  }, [hospitalsData]);

  const { Option } = Select;

  const loadMoreData = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    fetch(
      "https://randomuser.me/api/?results=10&inc=name,gender,email,nat,picture&noinfo"
    )
      .then((res) => res.json())
      .then((body) => {
        setData([...data, ...body.results]);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    loadMoreData();
  }, []);
  function toggleDropdown() {
    setIsActive(!isActive);
  }

  const handleChangeDateRange = (e) => {
    if (e) {
      setYearRange({
        start_date: e[0]?.$y,
        end_date: e[1]?.$y,
      });
    } else {
      currentYear = new moment().format("YYYY");
      setYearRange({
        start_date: currentYear,
        end_date: currentYear,
      });
    }
  };

  useEffect(() => {
    if (hospitalID) {
      postData(
        "total_hospital_appointments",
        {
          start_date: yearRange?.start_date,
          end_date: yearRange?.end_date,
          hospital_id: hospitalID,
        },
        (response) => {
          setAppointmentsData(response?.data);
        }
      );
    }
  }, [hospitalID, yearRange]);

  return (
    <>
      <div className="row px- py-3 m-0">
        <div className="col-4 d-flex justify-content-start align-items-center">
          <p className="mb-0  appoinment-text">All Appointment </p>
        </div>

        <div className="col-8 text-right dashboardDateRange">
          <RangePicker picker="year" onChange={handleChangeDateRange} />
        </div>

        <div className="col-12 text-right d-flex justify-content-end align-items-center mt-3 mb-2">
          <div className=" w-100">
            {/* <CustomDropDown selectLabel='Adan Hospital' option={optionHostpital} handleChangeSelect={handleChangeSelect} /> */}
            <CustomDropDown
              option={hospitalsData}
              handleChangeSelect={handleChangeSelect}
              value={hospitalID ? hospitalID : "Select Hospital"}
              allowClear={false}
            />
            <>
              {/* <Select
    showSearch
    style={{ width: 200 }}
    placeholder="Select a person"
    optionFilterProp="children"
    onChange={onChange}
    onFocus={onFocus}
    onBlur={onBlur}
    onSearch={onSearch}
    filterOption={(input, option) =>
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  >
    <Option value="jack">Jack</Option>
    <Option value="lucy">Lucy</Option>
    <Option value="tom">Tom</Option>
  </Select>, */}
            </>
          </div>
        </div>
      </div>
      <div className="px-3 pb-3">
        <LinearChart
          appointsmentData={appointsmentData}
          selectedHospital={hospitalID}
        />
      </div>
    </>
  );
};
export default App;
