import React from 'react'
import ServiceForm from '../../components/HomeServiceProvider/ServiceForm/ServiceForm'
import { useParams } from 'react-router-dom'

const EditService = () => {

  const { id } = useParams();

  return (
    <ServiceForm serviceId={id} />
  )
}

export default EditService;