import React, { useRef, useState } from "react";

// css file
import "../../assets/css/doctor.scss";
import "../../assets/css/pharmacy.scss";
import "../../App.scss";
import moment from "moment";

// img svg
import RightArrow from "../../assets/images/doctor/RightArrow.svg";
import CameraIcon from "../../assets/images/doctor/CameraIcon.svg";
import AddRoleIcon from "../../assets/images/doctor/AddRoleIcon.svg";
import ClockIcon from "../../assets/images/doctor/ClockIcon.svg";
import DocRoleCrossIcon from "../../assets/images/doctor/DocRoleCrossIcon.svg";
import Location from "../../atoms/Location/Location.js";
import GoogleMap from "../common/GoogleMap.js";

// img png
import LinkedInInput from "../../assets/images/doctor/LinkedInInput.png";
import InstaInput from "../../assets/images/doctor/InstaInput.png";
import FacebookInput from "../../assets/images/doctor/FacebookInput.png";
import TwitterInput from "../../assets/images/doctor/Twitter.png";
import WebInput from "../../assets/images/doctor/website.png";
import {
  optionCountry,
  optionRoleType,
  optionState,
} from "../../Data/DoctorData";
import CustomDropDown from "../../atoms/CustomDropDown/Index";
import CustomDropDownMulti from "../../atoms/CustomDropDown/CustomDropDownMulti";
import usePost from "../../customHook/usePost";
import SelectCountry from "../../atoms/Country";
import SelectState from "../../atoms/State";
import useDeleteData from "../../customHook/useDelete";
import { useEffect } from "react";
import Phone from "../../atoms/phone";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import ButtonLoader from "../../atoms/buttonLoader";
import { CustomToast } from "../../atoms/toastMessage";
import MuiltiplesImages from "../../atoms/MuiltiplesImages/MuiltiplesImages";
import AddRole from "../../pages/Role/AddRole";
import { useNavigate } from "react-router-dom";
import { Modal, Select, TimePicker } from "antd";
import SearchMap from "../common/SearchMap";
import CertificateUploadModal from "../../atoms/CertificateUploadModal/CertificateUploadModal.js";
import TimeTableModal from "../../molecules/TimeTableModal/index.jsx";
import ArabicInput from "../../molecules/ArabicInput/index.jsx";
import { useTranslation } from "react-i18next";
import ArabicTextArea from "../../molecules/ArabicTextArea/ArabicTextArea.jsx";
import SpinnerLoaderModal from "../common/spinnerloadermodal.jsx";
import { handlePhoneCode } from "../common/functionalityhelper.js";
import BreadCrum from "../../atoms/breadcrum/BreadCrum.js";
import { yupResolver } from "@hookform/resolvers/yup";
import formschemas from "../../formvalidation/formschemas.js";
import ErrorMessage from "../../formvalidation/errormessage.jsx";
import SocialInput from "../../molecules/SocialInput/SocialInput.jsx";
import TextInput from "../../molecules/TextInput/TextInput.jsx";
import { toast } from "react-toastify";

const AddHospital = ({
  Id,
  timeSetApi = "set_hospital_slots",
  timeGetApi = "get_hospital_slots",
  entityType = "hospital",
}) => {
  console.log("IDDDD", Id);
  const customData = useDeleteData();
  const [nameData, setNameData] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImagesCall, setSelectedImagesCall] = useState(true);

  const [image, setImage] = useState(null);
  const navigate = useNavigate();
  const [roleCategoryId, setRoleCategoryId] = useState("");
  const [modal2Open, setModal2Open] = useState(false);
  const [addRole, setAddRole] = useState({ country: "Kuwait" });
  const [roleParentValidation, setRoleParentValidation] = useState(false);

  const [addHospitalData, setAddHospitalData] = useState({
    country: "Kuwait",
    lat: 31.48544799999999,
    long: 74.3046761,
  });

  const [hospitalType, setHospitalType] = useState([
    {
      label: "Hospital",
      value: "Hospital",
    },
    {
      label: "Clinic",
      value: "Clinic",
    },
    {
      label: "Small Clinic",
      value: "Small Clinic",
    },
  ]);

  // Time Table code

  const [addTimePostReq, setaddTimePostReq] = useState({
    doctor_id: 131,
    schedules: [{ day: 0, time_slots: [{ start_time: "", end_time: "" }] }],
  });

  const savePharmacySchedule = (res) => {
    const requestBody = {
      [`${entityType}_id`]: res.id,
      schedules: addTimePostReq.schedules,
    };

    postData(timeSetApi, requestBody, () => {});
  };

  // Time Table code ends

  const specializationData = useSelector(
    (state) => state.specialization.specializationData
  );
  const { data, isLoading, error, postData } = usePost();

  const specialization = useMemo(() => {
    return specializationData?.data?.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  }, [specializationData]);
  
  const [showMap, setShowMap] = useState(false);

  const handleLocationIconClick = () => {
    !showMap ? setShowMap(true) : setShowMap(false);
  };
  const AddRoleHook = usePost();

  const handleDoctorImageClick = () => {
    
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (event) => {
      const file = event.target.files[0];
      setValue('profile_picture', file, { shouldValidate: true });
      setAddHospitalData({ ...addHospitalData, profile_picture: file });
      setImage(URL.createObjectURL(file));
    };

    setValue('profile_picture', watch('profile_picture'), { shouldValidate: true });
    input.click();
  };

  const formatTimeTo24Hour = (time) => {
    if (!time) return "";
    const [hours, minutes] = time.split(":");
    const parsedTime = new Date(0, 0, 0, parseInt(hours), parseInt(minutes));
    const formattedTime = parsedTime.toTimeString().slice(0, 5);

    return formattedTime;
  };

  const handleChangeHospital = (e) => {
    const { name, value } = e.target;
    if (name === "start_time" || name === "end_time") {
      setAddHospitalData((prevData) => ({
        ...prevData,
        [name]: formatTimeTo24Hour(value),
      }));
    } else if (name === "country") {
      setAddHospitalData({ ...addHospitalData, [name]: value, state: "" });
    } else {
      setAddHospitalData({ ...addHospitalData, 
        [name]: name === 'phone_no' || name === 'support_phone_no' ? handlePhoneCode(name, value, setValue) : value
      });
    }
  };

  const handleChangeSelect = (value, name) => {
    setAddHospitalData({ ...addHospitalData, [name]: value });
  };

  const role = JSON.parse(localStorage.getItem("userRoles"));
  const allowedhost = Object.keys(role).includes("hospitaladmin");
  const isSuperAdmin = Object.keys(role).length === 0;

  const { deleteData } = useDeleteData();

  useEffect(() => {
    if (Id) {
      customData.deleteData(
        `${process.env.REACT_APP_DELETE_HOSPITAL_DETAIL}/${Id}`,
        (val) => {
          console.log("val", val);
          setAddHospitalData({
            ...val?.data,
            specialties: val?.data?.specialities?.map((l) => l.id),
            facebook: val?.data?.facebook || '',
            instagram: val?.data?.instagram || '',
            linkedin: val?.data?.linkedin || '',
            twitter: val?.data?.twitter || '',
            website: val?.data?.website || ''
          });
          Object.entries(val?.data).forEach(([fieldName, fieldValue]) => {
            setValue(fieldName, fieldValue ?? '');
          });
          setSelectedImages(
            val?.data?.images?.map((image) => ({
              id: image.id,
              imageUrl: "https://api.maweidi.com.kw/" + image.image,
            }))
          );
          setValue("specialties", val?.data?.specialities?.map((l) => l.id), { shouldValidate: true });
        }
      );

      deleteData(`${timeGetApi}/${Id}`, (response) => {
        console.log("response", response);
        setaddTimePostReq(response?.data);
      });
    }
  }, [Id, selectedImagesCall]);

  useEffect(() => {
    setValue("address", addHospitalData?.address);
  }, [addHospitalData?.address]);

  const HandleTempStartEndTime = () => {
    let date = new Date();
    date = moment(date).format("HH:mm");

    setAddHospitalData((addHospitalData) => ({
      ...addHospitalData,
      start_time: date,
      end_time: date,
    }));
  };

  const handleHospitalSubmit = (event) => {

    HandleTempStartEndTime();

    const updatedPostData1 = {
      ...addHospitalData,
      address: addHospitalData?.address,
      location: addHospitalData?.location,
      support_email: addHospitalData?.support_email,
      lat: addHospitalData?.lat,
      long: addHospitalData?.long,
      updated_at: "2023-07-17 10:30:00",
      created_at: "2023-07-16 14:20:00",
    };

    const formData = new FormData();

    for (const key in updatedPostData1) {
      if (key === "specialties" && Array.isArray(updatedPostData1[key])) {
        updatedPostData1[key].forEach((value) => {
          formData.append(`${key}[]`, value);
        });
      } else {
        formData.append(key, updatedPostData1[key]);
      }
    }

    selectedImages.map((m) => formData.append("images[]", m));

        postData(
          Id
            ? `${process.env.REACT_APP_UPDATE_HOSPITAL_DATA}/${Id}`
            : `${process.env.REACT_APP_ADD_HOSPITAL_DATA}`,
          formData,
          (res) => {
            if (res?.success === true) {
              savePharmacySchedule(res?.data);

              if (!Id) {
                setRoleCategoryId(res?.data?.id);
                setAddRole({ ...addRole, join_id: res?.data?.id });

                const updateRoleData = {
                  ...addRole,
                  join_id: res?.data?.id,
                };

                const formData = new FormData();
                for (const key in updateRoleData) {
                  if (Array.isArray(updateRoleData[key])) {
                    updateRoleData[key].forEach((value) => {
                      formData.append(`${key}[]`, value);
                    });
                  } else {
                    formData.append(key, updateRoleData[key]);
                  }
                }

                AddRoleHook?.postData(
                  `${process.env.REACT_APP_ADD_ROLE}`,
                  formData,
                  (response) => {
                    if (response?.success === true) {
                      CustomToast({
                        type: "success",
                        message: `${
                          Id
                            ? "Hospital updated successfully."
                            : "Hospital added successfuly."
                        }`,
                      });
                      CustomToast({
                        type: "success",
                        message: `Role added successfully.`,
                      });

                      !Id && setAddHospitalData({});
                      !Id && reset();
                      !Id && setImage("");

                      navigate("/hospitals");
                    }
                  }
                );
              } else {
                CustomToast({
                  type: "success",
                  message: `Hopsital updated successfully.`,
                });
                navigate("/hospitals");
              }
            }
          }
        );
  };

  const { t } = useTranslation();

  const breadCrumbItems = [
    {
      title: 'Hospitals'
    },
    {
      title: Id ? 'Edit Hospital' : 'Add Hospital',
      href: '/hospitals/add'
    }
  ];

  const {
    handleSubmit,
    setValue,
    reset,
    watch,
    control,
    formState: { errors }
  } = useForm({
    mode: 'all',
    resolver: yupResolver(formschemas?.hospitalSchema(Id))
  });

  return (
    <div className="mb-5 pb-5">

      {Id && <SpinnerLoaderModal isDataLoading={customData?.isLoading} />}

      <div className="row  px-2 pt-4 pb-5 ">
        <div className="col-12  ">
          <p className="mb-0 dashboard-com-top-text">{Id ? 'Edit' : 'Add'} Hospital</p>
        </div>

        <div className="col-12 pt-2">
          <BreadCrum breadCrumbItems={breadCrumbItems} />
        </div>

        <div className="col-12"></div>
      </div>
      <form onSubmit={handleSubmit(handleHospitalSubmit)}>
        <div className="row  pt-lg-3 ">
          <div className="col-lg-12   ">
            <div className="row mx-0 px-2 add-doc-left-col">
              <div className="col-md-6 pt-2 d-flex align-items-center doc-cam">
                <div
                  className="mt-4 mb-md-4 mb-0 d-flex align-items-center justify-content-center add-doc-camera-upload cursor-pointer"
                  onClick={handleDoctorImageClick}
                >
                  {image ? (
                    <img
                      className="add-doc-camera-upload-1st"
                      src={image}
                      alt="Uploaded image"
                    />
                  ) : addHospitalData.profile_picture ? (
                    <div className="add-doc-camera-upload">
                      <img
                        className="w-100 h-100 add-doc-camera-upload-1st"
                        src={
                          process.env.REACT_APP_IMAGE_URL +
                          addHospitalData.profile_picture
                        }
                        alt=""
                      />
                    </div>
                  ) : (
                    <img src={CameraIcon} alt="" />
                  )}
                </div>

                <span className="pl-4 ml-2 pt-lg-0 pt-4 doc-cam-text">
                  {`Profile Picture / ${t("Hospital.ProfilePicture")}`}
                </span>
              </div>

              <div className="col-md-6 pt-2 d-flex justify-content-md-end justify-content-center align-items-center "></div>
              
              <div className="col-12" style={{ marginTop: "-20px" }}>
                <ErrorMessage error={errors?.profile_picture?.message} />
              </div>

              <div className="col-12 mt-3">
                <div className="row">
                  <div className="col-6  doc-setting-input pr-1">
                    <p className="mb-2">
                      Name<span className="error-message">*</span>{" "}
                    </p>

                    <Controller
                      name="name"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <>
                          <input
                            type="text"
                            name="name"
                            {...field}
                            value={field.value || ""}
                            onChange={(e) => {
                              field.onChange(e.target.value);
                              setNameData(e.target.value);
                              handleChangeHospital(e);
                            }}
                          />

                          <ErrorMessage error={errors?.name?.message} />
                        </>
                      )}
                    />
                  </div>

                  <ArabicInput
                    divClass="doc-setting-input col-6 pl-1"
                    label={t("Hospital.Name")}
                    formStateData={addHospitalData}
                    setFormStateData={setAddHospitalData}
                    name="name_ar"
                  />
                </div>

                <div className="row mt-4">
                  <div className="col-lg-6 pr-lg-1 doc-setting-input">
                    <div className="d-flex justify-content-between">
                      <p className="mb-2">
                        Email<span className="error-message">*</span>
                      </p>
                      <p className="mb-2">{`${t("Common.Email")}`}</p>
                    </div>
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: true,
                        pattern:
                          /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
                      }}
                      render={({ field }) => (
                        <input
                          className=""
                          type="text"
                          name="email"
                          {...field}
                          value={field.value || ""}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            handleChangeHospital(e);
                          }}
                        />
                      )}
                    />
                    <ErrorMessage error={errors?.email?.message} />
                  </div>

                  <div className="col-lg-6 mt-lg-0 mt-4 pl-lg-1 doc-setting-input">
                    <Controller
                      name="phone_no"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <>
                          <Phone
                            name="phone_no"
                            field={field}
                            value={field.value}
                            handleChange={(e) => {
                              field.onChange(e);
                              handleChangeHospital(e);
                            }}
                            arLabel={`${t("Hospital.PhoneNo")}`}
                          />
                          
                          <ErrorMessage error={errors?.phone_no?.message} />
                        </>
                      )}
                    />
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-lg-6 pr-lg-1 doc-setting-input">
                    <div className="d-flex justify-content-between">
                      <p className="mb-2">Customer Support Email</p>
                      <p className="mb-2">{`${t("Hospital.CustomerSupportEmail")}`}</p>
                    </div>
                    
                    <Controller
                      name="support_email"
                      control={control}
                      rules={{
                        required: false,
                        pattern:
                          /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
                      }}
                      render={({ field }) => (
                        <input
                          className=""
                          type="text"
                          name="support_email"
                          {...field}
                          value={field.value || ""}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            handleChangeHospital(e);
                          }}
                        />
                      )}
                    />
                    
                    <ErrorMessage error={errors?.support_email?.message} />
                  </div>

                  <div className="col-lg-6 mt-lg-0 mt-4 pl-lg-1 doc-setting-input">
                    <Controller
                      name="support_phone_no"
                      control={control}
                      rules={{
                        required: false,
                      }}
                      render={({ field }) => (
                        <>
                          <Phone
                            label="Customer Support No"
                            name="support_phone_no"
                            field={field}
                            value={field.value}
                            handleChange={(e) => {
                              field.onChange(e);
                              handleChangeHospital(e);
                            }}
                            arLabel={`${t("Hospital.CustomerSupportNo")}`}
                          />
                          
                          <ErrorMessage error={errors?.support_phone_no?.message} />
                        </>
                      )}
                    />
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-lg-6 pr-lg-1 doc-setting-input">
                    <Controller
                      name="country"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      defaultValue="Kuwait"
                      render={({ field }) => (
                        <>
                          <SelectCountry
                            handleChangeSelect={(value, name) => {
                              field.onChange(value);
                              //   handleChange(name, value);
                              handleChangeSelect(value, name);
                            }}
                            name="country"
                            field={field}
                            value={field.value}
                            onBlur={field.onBlur}
                            isDisabled={true}
                            arLabel={t("Hospital.Country")}
                          />

                          <ErrorMessage error={errors?.country?.message} />
                        </>
                      )}
                    />
                  </div>

                  <SelectState
                    name="state"
                    dropDownDivClass={'col-lg-6 mt-lg-0 mt-4 pl-lg-1 doc-setting-input'}
                    country={addHospitalData?.country}
                    disabled={!addHospitalData?.country}
                    value={addHospitalData?.state || ""}
                    handleChange={handleChangeSelect}
                    arLabel={t("Hospital.State")}
                    isController={true}
                    control={control}
                    error={errors?.state?.message}
                  />
                </div>

                <div className="row mt-4">

                  <CustomDropDownMulti
                    label={'Specialization'}
                    labelClass={'mb-2'}
                    arLabel={t('Hospital.Specialization')}
                    dropDownDivClass={'col-lg-6 pr-lg-1 doc-setting-input'}
                    handleChangeSelect={(value, name) => {
                      handleChangeSelect(value, name);
                    }}
                    option={specialization || []}
                    name="specialties"
                    mode="multiple"
                    value={addHospitalData?.specialties}
                    isNewItem={true}
                    newItemButtonText={"Add Specialization"}
                    newItemPlaceholder={"Enter New Specilization"}
                    isController={true}
                    control={control}
                    error={errors?.specialties?.message}
                  />

                  <CustomDropDown 
                    name={'hospital_type'}
                    dropDownDivClass='col-lg-6 pl-lg-1 doc-setting-input' 
                    label={'Hospital Type'} 
                    arLabel={t('Hospital.HospitalType')}
                    value={addHospitalData?.hospital_type}
                    option={hospitalType || []}
                    handleChangeSelect={(value, name) => {
                      setAddHospitalData((addHospitalData) => ({
                        ...addHospitalData,
                        hospital_type: value,
                      }));
                    }}
                    isController={true}
                    control={control}
                    error={errors?.hospital_type?.message}
                />

                </div>

                <div className="row mt-1">

                  <SocialInput
                    label={'Facebook'} 
                    arLabel={t('Common.Facebook')} 
                    socialIcon={FacebookInput} 
                    name={'facebook'} 
                    value={addHospitalData?.facebook} 
                    inputDivClass='col-lg-6 mt-lg-0 mt-4 pr-lg-1 pl-3 doc-setting-input' 
                    handleOnChange={handleChangeHospital} 
                    control={control}
                    error={errors?.facebook?.message}
                  />

                  <SocialInput
                    label={'Instagram'} 
                    arLabel={t('Common.Instagram')} 
                    socialIcon={InstaInput} 
                    name={'instagram'} 
                    value={addHospitalData?.instagram} 
                    inputDivClass='col-lg-6 mt-lg-0 mt-4 pl-lg-1 pr-3 doc-setting-input' 
                    handleOnChange={handleChangeHospital} 
                    control={control}
                    error={errors?.instagram?.message}
                  />

                </div>
                <div className="row mt-4">

                  <SocialInput
                    label={'Twitter'} 
                    arLabel={t('Common.Twitter')} 
                    socialIcon={TwitterInput} 
                    name={'twitter'} 
                    value={addHospitalData?.twitter} 
                    inputDivClass='col-lg-6 mt-lg-0 mt-4 pr-lg-1 pl-3 doc-setting-input' 
                    handleOnChange={handleChangeHospital} 
                    control={control}
                    error={errors?.twitter?.message}
                  />

                  <SocialInput 
                    label={'Website URL'} 
                    arLabel={t('Common.WebsiteURL')} 
                    socialIcon={WebInput} 
                    name={'website'} 
                    value={addHospitalData?.website} 
                    inputDivClass='col-lg-6 mt-lg-0 mt-4 pl-lg-1 pr-3 doc-setting-input' 
                    handleOnChange={handleChangeHospital} 
                    control={control}
                    error={errors?.website?.message}
                  />

                </div>

                <div className="row mt-4">

                  <SocialInput 
                    label={'Linkedin'} 
                    arLabel={t('Common.Linkedin')} 
                    socialIcon={LinkedInInput} 
                    name={'linkedin'} 
                    value={addHospitalData?.linkedin} 
                    inputDivClass='col-lg-6 mt-lg-0 mt-4 pr-lg-1 pl-3 doc-setting-input' 
                    handleOnChange={handleChangeHospital} 
                    control={control}
                    error={errors?.linkedin?.message}
                  />

                </div>

                <div className="row mt-4">
                  <div className="col-lg-6 mt-lg-0 mt-4 pr-lg-1 doc-setting-input ">
                    <div className="d-flex justify-content-between">
                      <p className="mb-2">
                        Address
                        <span className="error-message">*</span>
                      </p>
                      <p className="mb-2">{`${t("Hospital.Address")}`}</p>
                    </div>
                    <div className="d-flex  ">
                      <Controller
                        name="address"
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field }) => (
                          <>
                            <input
                              type="text"
                              name="address"
                              {...field}
                              value={field.value || ""}
                              onChange={(e) => {
                                field.onChange(e.target.value);
                                handleChangeHospital(e);
                              }}
                            />
                          </>
                        )}
                      />
                    </div>
                    
                    <ErrorMessage error={errors?.address?.message} />
                  </div>

                  <div className="col-lg-6 mt-lg-0 mt-4 pl-lg-1 doc-setting-input">
                    
                    <div className="d-flex justify-content-between">
                      <p className="mb-2">Location</p>
                      <p className="mb-2">{`${t("Hospital.Location")}`}</p>
                    </div>

                    <div onClick={() => setModal2Open(true)}>
                      <Location
                        handleLocation={handleLocationIconClick}
                        locationProp={addHospitalData?.location}
                      />
                    </div>
                    {showMap && (
                      <>
                        {/* <GoogleMap
                        locationProp={locationProp}
                        setLocationProp={setLocationProp}
                      /> */}
                        <Modal
                          className="location-modal"
                          centered
                          open={modal2Open}
                          onOk={() => setModal2Open(false)}
                          onCancel={() => setModal2Open(false)}
                          width={735}
                          footer={<div className=""></div>}
                        >
                          <div className="row   position-relative">
                            <div className="col-lg-12  ">
                              <SearchMap
                                addHospitalData={addHospitalData}
                                setAddHospitalData={setAddHospitalData}
                              />
                            </div>
                            <div
                              className="col-12 position-absolute  d-flex justify-content-end"
                              style={{
                                top: "415px",
                                left: "0",
                              }}
                            >
                              <button
                                style={{ height: "36.6px", width: "100px" }}
                                className="apply-filter add-doc-changes"
                                onClick={() => {
                                  setModal2Open(false);
                                }}
                              >
                                Done
                              </button>
                            </div>
                          </div>
                        </Modal>
                      </>
                    )}
                  </div>
                </div>

                <div className="row mt-4">

                  <TextInput 
                    label={'About Hospital'} 
                    inputDivClass='col-6 mt-lg-0 mt-0 pl-3 doc-setting-input pr-1 mb-3' 
                    type='text' 
                    name='about' 
                    textArea={true} 
                    value={addHospitalData?.about} 
                    handleOnChange={handleChangeHospital} 
                    control={control} 
                    error={errors?.about?.message} 
                  />

                  <ArabicTextArea
                    divClass="doc-setting-input col-6 pl-1"
                    label={t("Hospital.AboutHospital")}
                    formStateData={addHospitalData}
                    setFormStateData={setAddHospitalData}
                    name="about_ar"
                  />
                </div>

                <TimeTableModal
                  label="Hospital Timings"
                  arLabel={` / ${t("Hospital.HospitalTimings")}`}
                  addTimePostReq={addTimePostReq}
                  setaddTimePostReq={setaddTimePostReq}
                  id={Id}
                />

                <MuiltiplesImages
                  selectedImages={selectedImages}
                  setSelectedImages={setSelectedImages}
                  Id={Id}
                  addUrl="add_hospital_images"
                  deleteUrl="delete_hospital_images"
                  selectedImagesCall={selectedImagesCall}
                  setSelectedImagesCall={setSelectedImagesCall}
                />

                {(allowedhost || isSuperAdmin) && !Id ? (
                  <div className="col-lg-8 mt-5 mb-5 px-0 add-appointment-card-wrapper" style={{border: '1px solid #d9d9d9', borderRadius: '5px'}}>

                    <div className="title-header px-3">
                      <div className="title">Add a Role</div>
                    </div>
                    
                    <hr />

                    <AddRole
                      upperData={false}
                      roleParent={[
                        { value: 1, label: "Hospital Admin" },
                      ]}
                      setAddRole={setAddRole}
                      addRole={addRole}
                      roleParentValidation={roleParentValidation}
                      roleCategoryId={roleCategoryId}
                      navigateLink="hospitals"
                      formDataState={addHospitalData}
                      control={control}
                      errors={errors?.role}
                      setValue={setValue}
                    />

                  </div>
                ) : null}

                <div className="row my-5 pt-2 pb-3 ">
                  <div className="col-lg-6">
                    <button
                      className="apply-filter add-doc-changes"
                      disabled={isLoading}
                      onClick={handleSubmit}
                    >
                      {!isLoading ? (
                        Id ? (
                          "Update Hospital"
                        ) : (
                          "Add Hospital"
                        )
                      ) : (
                        <ButtonLoader />
                      )}
                    </button>
                  </div>

                  <div className="col-lg-6"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 mt-lg-0 mt-4 "></div>
        </div>
      </form>
    </div>
  );
};

export default AddHospital;
