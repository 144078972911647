import React from 'react'
import { Controller, useForm } from 'react-hook-form';
import ErrorMessage from '../../formvalidation/errormessage';

const SocialInput = ({label, arLabel, socialIcon, name, value, placeholder, inputDivClass, handleOnChange, control, error}) => {

  const {
    control: formControl,
  } = useForm({
    mode: 'all'
  });

  return (
    <div className={`${inputDivClass} mt-lg-0 doc-setting-input p-0 mb-0`}>

        <div className="d-flex justify-content-between">
          <p className="mb-2">{label}</p>
          <p className="mb-2">{arLabel && arLabel}</p>
        </div>

        <div className="d-flex ">
            <img src={socialIcon} />

            <Controller
              name={name}
              control={control || formControl}
              render={({ field }) => {
                return <input
                  {...field}
                  className="add-doc-social-input"
                  type="text"
                  placeholder={placeholder || "Username"}
                  name={name}
                  value={value ?? ''}
                  onChange={(e) => {
                    handleOnChange(e);
                    field && field?.onChange(e?.target?.value);
                  }}
                />
              }}
            />

        </div>

        <ErrorMessage error={error} />
    </div>
  )
}

export default SocialInput;