import React, { useEffect, useState } from "react";
import { Switch, TimePicker, Button } from "antd";
import moment from "moment";
import dayjs from "dayjs";

const PharmacyTimings = ({ addTimePostReq, setaddTimePostReq, EquipmentID }) => {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  useEffect(() => {
    if(EquipmentID){
      setToggleStates(addTimePostReq?.schedules?.map((schedule) => (
        {
          day: daysOfWeek[schedule?.day - 1],
          toggle: schedule?.time_slots[0]?.start_time ? true : false,
          openingTime: schedule?.time_slots[0]?.start_time,
          closingTime: schedule?.time_slots[0]?.end_time
        }
      )))
    }
  },[])

  const [toggleStates, setToggleStates] = useState(
    daysOfWeek.map((day) => ({
      day,
      toggle: false,
      openingTime: null,
      closingTime: null,
    }))
  );

  const handleToggle = (day) => {
    const updatedStates = toggleStates.map((item) =>
      item.day === day ? { ...item, toggle: !item.toggle } : item
    );
    setToggleStates(updatedStates);

    const updatedSchedules = updatedStates.map((item) => {
      return {
        day: daysOfWeek.indexOf(item.day) + 1,
        time_slots: item.toggle
          ? [
              {
                start_time: item.openingTime || "",
                end_time: item.closingTime || "",
              },
            ]
          : [],
      };
    });

    setaddTimePostReq((prev) => ({
      ...prev,
      schedules: updatedSchedules,
    }));
  };

  const handleOpeningTimeChange = (day, time) => {
    const updatedStates = toggleStates.map((item) =>
      item.day === day
        ? { ...item, openingTime: time ? time.format("HH:mm") : null }
        : item
    );
    setToggleStates(updatedStates);

    setaddTimePostReq((prev) => ({
      ...prev,
      schedules: prev.schedules.map((schedule) =>
        schedule.day === daysOfWeek.indexOf(day) + 1
          ? {
              ...schedule,
              time_slots: [
                {
                  start_time: time ? time.format("HH:mm") : "",
                  end_time: schedule.time_slots[0]?.end_time || "",
                },
              ],
            }
          : schedule
      ),
    }));
  };

  const handleClosingTimeChange = (day, time) => {

    const updatedStates = toggleStates.map((item) =>
      item.day === day
        ? { ...item, closingTime: time ? time.format("HH:mm") : null }
        : item
    );
    setToggleStates(updatedStates);

    setaddTimePostReq((prev) => ({
      ...prev,
      schedules: prev.schedules.map((schedule) =>
        schedule.day === daysOfWeek.indexOf(day) + 1
          ? {
              ...schedule,
              time_slots: [
                {
                  start_time: schedule.time_slots[0]?.start_time || "",
                  end_time: time ? time.format("HH:mm") : "",
                },
              ],
            }
          : schedule
      ),
    }));
  };

  return (
    <div>
      {toggleStates.map(({ day, toggle, openingTime, closingTime }) => (
        <div
          key={day}
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
            justifyContent: 'space-between'
          }}
        >
          <div className="d-flex justify-content-between" style={{width: '32%'}}>
            <span
              className="time-edit-text1"
            >
              {day}
            </span>
            <Switch
              checked={toggle}
              onChange={() => handleToggle(day)}
            />
          </div>
          {toggle && (
            <div className="pharmacy-schedule d-flex" style={{width: '63%', gap: '5px' }}>
              <div className="border  w-100" style={{borderRadius:'5px'}}>
              <TimePicker
                onChange={(time) => handleOpeningTimeChange(day, time)}
                format="HH:mm"
                style={{ border:'none', height:'36px' }}
                placeholder="Opening Time"
                value={openingTime ? dayjs(openingTime, "HH:mm") : null}
              />
              </div>
              <div className="border w-100" style={{borderRadius:'5px'}}>
              <TimePicker
              style={{ border:'none', height:'36px'}}
                onChange={(time) => handleClosingTimeChange(day, time)}
                format="HH:mm"
                placeholder="Closing Time"
                value={closingTime ? dayjs(closingTime, 'HH:mm') : null}
              />
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default PharmacyTimings;
