import React, { useEffect, useState } from 'react'
import RoomsTableListHeader from '../../molecules/HospitalRoomManagement/RoomsTableListHeader';
import { DatePicker, TimePicker } from 'antd';
import moment from 'moment';
import { toast } from 'react-toastify';
import usePost from '../../customHook/usePost';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../molecules/CustomButton/CustomButton';
import ArabicTextArea from '../../molecules/ArabicTextArea/ArabicTextArea';
import { useTranslation } from 'react-i18next';
import InputLabel from '../../molecules/TextInput/InputLabel';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import formschemas from '../../formvalidation/formschemas';
import TextInput from '../../molecules/TextInput/TextInput';
import ErrorMessage from '../../formvalidation/errormessage';

const NewsForm = () => {

    const [newsData, setNewsData] = useState({});
    const { postData, isLoading } = usePost();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const onOk = (date) => {
        const formattedDate = moment(date?.$d).format('YYYY-MM-DD HH:mm:ss');
        setNewsData({...newsData, expiry_date: formattedDate});
        setValue('expiry_date', formattedDate, { shouldValidate: true });
    };

    const onChange = (date, dateString) => {
        
    };

    const handleNewsChange = (e) => {
        setNewsData({...newsData, news: e.target.value});
    }


    const handleNewsSubmit = () => {

        if(!newsData?.news){
            toast.error('Please enter news.');
            return;
        }

        if(!newsData?.expiry_date){
            toast.error('Please select news expiry date.');
            return;
        }

        postData('add_news', newsData, (response) => {

            if(response?.success === true){
                toast.success('Successfully added news.');
                navigate('/news');
            }
            
        })
    }

    const breadCrumbItems = [
        {
          title: 'News'
        },
        {
          title: 'Add News',
          href: ''
        }
    ];

    const {
        handleSubmit,
        setValue,
        control,
        formState: { errors }
    } = useForm({
        mode: 'all',
        resolver: yupResolver(formschemas.newsSchema)
    });

  return (
    <div className='row pl-4 pr-4 pt-3 roomslistBody pb-5'>

        <RoomsTableListHeader
            pageTitle='Add News'
            breadCrumbItems={breadCrumbItems}
        />

        <div className='col-6 add-doc-left-col py-3'>

            <TextInput
                label={'News'} 
                name='news' 
                type='text' 
                textArea={true}
                required={true}
                inputDivClass='doc-setting-input inputSpacing mb-3' 
                value={newsData?.news} 
                control={control} 
                handleOnChange={handleNewsChange} 
                error={errors?.news?.message} 
            />

            <ArabicTextArea
              divClass="doc-setting-input inputSpacing mb-3"
              label={t("News.News")}
              formStateData={newsData}
              setFormStateData={setNewsData}
              name="news_ar"
            />

            <div className='doc-setting-input inputSpacing mb-3'>

                <InputLabel
                  label={'Expiry Date & Time'}
                  arLabel={t('News.ExpiryDate')}
                  labelClass={'mb-2 doc-add-filter-text text-dark'}
                  required={true}
                />

                <Controller 
                    name='expiry_date'
                    control={control}
                    render={({field}) => (
                        <DatePicker
                            showTime
                            onChange={onChange}
                            onOk={onOk}
                            style={{width: '100%'}}
                        />
                    )}
                />

                <ErrorMessage error={errors?.expiry_date?.message} />
            </div>

            <CustomButton label='Add News' buttonWidth='24%' buttonHeight='42px' buttonDivClass={'col-12 p-0 mt-5'} handleOnClick={handleSubmit(handleNewsSubmit)} isLoading={isLoading} />

        </div>
      
    </div>
  )
}

export default NewsForm;