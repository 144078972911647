import React, { useMemo } from "react";
import { useState } from "react";
import useFetch from "../../customHook/useFetch";
import usePost from "../../customHook/usePost";
import { useEffect } from "react";
import Phone from "../../atoms/phone";
import CustomDropDown from "../../atoms/CustomDropDown/Index";
import CustomDropDownMulti from "../../atoms/CustomDropDown/CustomDropDownMulti";
import IncreDecreBtn from "../../components/doctors/IncreDecreBtn";

// img png
import LinkedInInput from "../../assets/images/doctor/LinkedInInput.png";
import InstaInput from "../../assets/images/doctor/InstaInput.png";
import FacebookInput from "../../assets/images/doctor/FacebookInput.png";
import TwitterInput from "../../assets/images/doctor/Twitter.png";
import WebInput from "../../assets/images/doctor/website.png";
import CameraIcon from "../../assets/images/doctor/CameraIcon.svg";

// scss
import "../../assets/css/doctor.scss";
import { Controller, useForm } from "react-hook-form";
import ButtonLoader from "../../atoms/buttonLoader";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CustomToast } from "../../atoms/toastMessage";
import AddRoleIcon from "../../assets/images/doctor/AddRoleIcon.svg";

// img svg
import ConsutancyFee from "../../components/doctors/ConsutancyFee";
import AddRole from "../../pages/Role/AddRole";
import { Switch } from "antd";
import CertificateInput from "../../molecules/CertificateInput/CertificateInput";
import { useTranslation } from "react-i18next";
import ArabicInput from "../../molecules/ArabicInput";
import DoctorTimeTable from "../../molecules/DoctorTimeTable/DoctorTimeTable";
import { toast } from "react-toastify";
import ArabicTextArea from "../../molecules/ArabicTextArea/ArabicTextArea";
import DoctorTimeTableHelper from "../../molecules/DoctorTimeTable/helper";
import { handlePhoneCode } from "../../components/common/functionalityhelper";
import SocialInput from "../../molecules/SocialInput/SocialInput";
import { yupResolver } from "@hookform/resolvers/yup";
import formschemas from "../../formvalidation/formschemas";
import ErrorMessage from "../../formvalidation/errormessage";
import TextInput from "../../molecules/TextInput/TextInput";

const DoctorForm = ({
  id,
  rawData
}) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth);
  const [addRole, setAddRole] = useState({ country: "Kuwait" });
  const [roleParentValidation, setRoleParentValidation] = useState(false);
  const [renderToggle, setRenderToggle] = useState(false);
  const [roleCategoryId, setRoleCategoryId] = useState("");
  const [formDataState, setFormDataState] = useState({
    documents: [],
    remove_document_ids: [],
    is_online: 0,
    is_onsite: 0,
    fees: []
  });
  const [hospitalOption, setHospitalOption] = useState([]);
  const [image, setImage] = useState(null);
  const [showDoctorFee, setShowDoctorFee] = useState(false);
  const [feeData, setFeeData] = useState([]);
  const [selectdedHospital, setSelectdedHospital] = useState("");
  const navigate = useNavigate();
  const realHospitalData = useFetch(process.env.REACT_APP_GET_HOSPITAL_DATA);
  const lang = useFetch(process.env.REACT_APP_GET_LANGUAGES);
  const specializationData = useSelector(
    (state) => state.specialization.specializationData
  );
  const [selectedFiles, setSelectedFiles] = useState([]);

  const {
    addTimePostReq,
    setaddTimePostReq,
    selectDay,
    setSelectDay,
    specialistOptions,
    setspecialistOptions,
    isTimeSlotError,
    setIsTimeSlotError,
    modal2Open,
    setModal2Open,
    postDoctorAvalibility,
    HandlePostTimingsApi,
    handleGetTimeSlots,
  } = DoctorTimeTableHelper();

  const { isLoading, postData } = usePost();
  const AddRoleHook = usePost();

  const language = useMemo(() => {
    return lang?.data?.data?.map((l) => ({ label: l.name, value: l.id }));
  }, [lang]);
  
  const specialization = useMemo(() => {
    return specializationData?.data?.map((l) => ({
      label: l.name,
      value: l.id,
    }));
  }, [specializationData]);

  useEffect(() => {
    setSelectdedHospital(formDataState?.hospitalsData);
  }, [formDataState?.hospitalsData]);

  useEffect(() => {
    if (realHospitalData?.data?.data?.length > 0) {
      const opt =
        realHospitalData?.data?.data &&
        realHospitalData?.data?.data?.map((val) => ({
          label: val?.name,
          value: val?.id,
        }));
      setHospitalOption(opt);
    }
  }, [realHospitalData?.data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormDataState({ ...formDataState, 
      [name]: name === 'contact' || name === 'support_phone_no' ? handlePhoneCode(name, value, setValue) : value 
    });
  };

  const handleSelect = (value, name) => {
    setFormDataState({ ...formDataState, [name]: value });
    if (name === "hospitals") {
      const opt = hospitalOption?.filter((hospital) =>
        value.includes(hospital.value)
      );

      // If setSelectdedHospital comes as a prop, use it to update the state
      if (typeof setSelectdedHospital === "function") {
        setSelectdedHospital(opt);
      }
    }
  };

  useEffect(() => {
    if (id) {
      handleGetTimeSlots(process.env.REACT_APP_GET_DOCTOR_TIMETABLE, id);
    }
  }, []);

  const onSubmit = () => {
    const formData = new FormData();

    const TimingsPayload = postDoctorAvalibility();

    const propertiesToRemove = [
      "id",
      "name",
      "email_verified_at",
      "age",
      "longitude",
      "longitude",
      "device_token",
      "user_type",
      "session_id",
      "updated_at",
      "created_at",
      "",
    ];

    for (const property of propertiesToRemove) {
      delete formDataState[property];
    }
    for (const key in formDataState) {
      if (
        (key === "hospitals" || key === "languages" || key === "documents") &&
        Array.isArray(formDataState[key])
      ) {
        formDataState[key].forEach((value) => {
          formData.append(`${key}[]`, value);
        });
      } else if (
        key === "remove_document_ids" &&
        Array.isArray(formDataState[key])
      ) {
        if (id) {
          formDataState[key].forEach((value) => {
            formData.append(`${key}[]`, value);
          });
        }
      }
      else if (
        (key === "fees") &&
        Array.isArray(formDataState[key])
      ) {
        formDataState[key].forEach((value, index) => {
          Object?.keys(value)?.forEach((name) => {
            formData.append(`fees[${index}][${name}]`, value[name]);
          })
        });
      }
      
      else {
        formData.append(key, formDataState[key]);
      }
    }

    if (TimingsPayload) {
        postData(
          id
            ? `${process.env.REACT_APP_UODATE_DOCTORS}/${id}`
            : `${process.env.REACT_APP_ADD_DOCTORS}`,
          formData,
          (res) => {
            if (res?.success === true) {
              HandlePostTimingsApi(
                process.env.REACT_APP_SET_DOCTOR_AVaAILABILITY,
                "doctor_id",
                res?.data?.id,
                TimingsPayload
              );

              if (!id) {
                setRoleCategoryId(res?.data?.id);
                setAddRole({ ...addRole, join_id: res?.data?.id });

                const updateRoleData = {
                  ...addRole,
                  join_id: res?.data?.id,
                };

                const formData = new FormData();

                for (const key in updateRoleData) {
                  if (Array.isArray(updateRoleData[key])) {
                    updateRoleData[key].forEach((value) => {
                      formData.append(`${key}[]`, value);
                    });
                  } else {
                    formData.append(key, updateRoleData[key]);
                  }
                }

                AddRoleHook?.postData(
                  `${process.env.REACT_APP_ADD_ROLE}`,
                  formData,
                  (response) => {
                    if (response?.success === true) {
                      CustomToast({
                        type: "success",
                        message: "Doctor added successfuly!",
                      });

                      CustomToast({
                        type: "success",
                        message: `Doctor role added successfully!`,
                      });

                      navigate("/doctors");
                    }
                  }
                );
              } else {
                CustomToast({
                  type: "success",
                  message: "Doctor updated successfully!",
                });
                navigate("/doctors");
              }
            }
            else{
              res?.response?.email[0] && toast?.error(res?.response?.email[0]);
            }
          }
        );
    }
  };

  const handleDoctorImageClick = () => {

    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    
    input.onchange = (event) => {
      const file = event.target.files[0];
      setValue('profile_pic', file, { shouldValidate: true });
      setFormDataState({ ...formDataState, profile_pic: file });
      setImage(URL.createObjectURL(file));
    };

    input.click();
    setValue('profile_pic', watch('profile_pic'), { shouldValidate: true });
  };

  const role = JSON.parse(localStorage.getItem("userRoles"));
  const allowedhost = Object.keys(role).includes("hospitaladmin");
  const isSuperAdmin = Object.keys(role).length === 0;

  useEffect(() => {
    if (id && rawData) {
      setspecialistOptions(
        rawData?.hospitals?.map((i) => {
          return { value: i.name, label: i.name, id: i.id };
        })
      );

      const tempFee = rawData?.fees?.map((fee) => ({ hospital_id: fee?.hospital_id, fees: Number(fee?.fees) }));

      const nameParts = rawData?.user?.name.split(" ");
      setFormDataState({
        ...formDataState,
        profile_pic: `${process.env.REACT_APP_IMAGE_URL}/${rawData?.user?.profile_pic}`,
      });
      setImage(
        `${process.env.REACT_APP_IMAGE_URL}/${rawData?.user?.profile_pic}`
      );
      setFormDataState({
        ...formDataState,
        ...rawData?.user,
        first_name: rawData?.user?.first_name,
        last_name: rawData?.user?.last_name,
        experience_years: rawData?.experience_years,
        facebook: rawData?.facebook || '',
        linkedin: rawData?.linkedin || '',
        instagram: rawData?.instagram || '',
        certificate: rawData?.certificate,
        support_email: rawData?.support_email ?? '',
        support_phone_no: rawData?.support_phone_no,
        about: rawData?.about,
        twitter: rawData?.twitter || '',
        website: rawData?.website || '',
        specialization_id: rawData?.specialization_id,
        council_registration_no: rawData?.council_registration_no ?? '',
        languages: rawData?.user?.languages?.map((language) => language?.id),
        hospitals: rawData?.hospitals?.map((hospital) => hospital?.id),
        hospitalsData: rawData?.hospitals?.map((hospital) => ({
          label: hospital?.name,
          value: hospital?.id,
        })),
        documents: rawData?.documents?.map((document, index) => document),
        fees: [...tempFee],
        is_online: rawData?.is_online,
        is_onsite: rawData?.is_onsite,
        about_ar: rawData?.about_ar || '',
      });

      Object.entries(rawData?.user).forEach(([fieldName, fieldValue]) => {
        setValue(fieldName, fieldValue ?? '');
      });
      setValue("first_name", rawData?.user?.first_name);
      setValue("last_name", rawData?.user?.last_name);
      setValue("qualification", rawData?.qualification);
      setValue('experience_years', rawData?.experience_years);
      setValue("specialization_id", rawData?.specialization_id);
      setValue("support_email", rawData?.support_email ?? '');
      setValue("support_phone_no", rawData?.support_phone_no);
      setValue("twitter", rawData?.twitter);
      setValue("website", rawData?.website || '');
      setValue("council_registration_no", rawData?.council_registration_no ?? '');
      setValue("gender", rawData?.user?.gender === 1 ? "Male" : "Female");
      setValue('about', rawData?.about);
      setValue('fees', [...tempFee]);

      const temp = [];
      rawData?.is_online && temp?.push(rawData?.is_online);
      rawData?.is_onsite && temp?.push(rawData?.is_onsite);

      console.log('Doctor: temp', temp);
      setAvailabilitySchema([...temp]);
      setValue('availability', [...temp]);
      setValue(
        "hospitals",
        rawData?.hospitals?.map((hospital) => hospital?.id)
      );
      setValue(
        "languages",
        rawData?.user?.languages?.map((language) => language?.id)
      );
    }
  }, [id, rawData, renderToggle]);

  useEffect(() => {
    setTimeout(() => {
      setRenderToggle(!renderToggle);
    });
  }, []);

  const [availabilitySchema, setAvailabilitySchema] = useState([]);

  const handleConsultationAvailability = (e, name) => {
    const temp = [...availabilitySchema];
    if (e) {
      setFormDataState((formDataState) => ({
        ...formDataState,
        [name]: 1,
      }));
      temp?.push(1);
    } else {
      setFormDataState((formDataState) => ({
        ...formDataState,
        [name]: 0,
      }));
      temp?.pop();
    }

    setAvailabilitySchema([...temp]);
    setValue('availability', [...temp], { shouldValidate: true });
  };

  useEffect(() => {
    if(user?.role === 'hospitaladmin'){
      setValue('hospitals', hospitalOption[0]?.value);
    }
  },[hospitalOption])

  const {
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors }
  } = useForm({
    mode: 'all',
    resolver: yupResolver(formschemas?.doctorSchema(id))
  });

  const hospNames = realHospitalData?.data?.data?.reduce((acc, item) => {
    acc[item?.id] = item?.name;
    return acc;
  }, {});

  const handleSetHospDefaultCharges = (hospitals) => {

    let temp = [...formDataState?.fees];

    temp = hospitals?.map((id) => {

      const check = temp.find((item) => item?.hospital_id === id);
      return check ?? { hospital_id: id, fees: 0 }
      
    })

    setFormDataState(formDataState => ({...formDataState, fees: [...temp]}));
    setValue('fees', [...temp], { shouldValidate: true });

  }

  useEffect(() => {
    errors?.fees && setShowDoctorFee(true);
  },[errors?.fees])

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row px-2">
          <div className="col-md-12 pt-2 d-flex align-items-center doc-cam">
            <div
              className="mt-4 mb-md-4 mb-0 d-flex align-items-center justify-content- 
               center 
        add-doc-camera-upload cursor-pointer"
              onClick={handleDoctorImageClick}
            >
              {image ? (
                <img
                  className="add-doc-camera-upload-1st "
                  src={image}
                  alt="Uploaded image"
                />
              ) : (
                <div className="d-flex text-center justify-content-center w-100">
                  <img src={CameraIcon} alt="upload" />
                </div>
              )}
            </div>

            <span className="pl-4 ml-2 doc-cam-text">Profile Picture / {`${t('Common.ProfilePicture')}`}</span>
          </div>

          <div className="col-12" style={{ marginTop: "-20px" }}>
            <ErrorMessage error={errors?.profile_pic?.message} />
          </div>

          <div className="col-12 mt-3">
            <div className="row">
              <div className="col-lg-3 pr-lg-1 doc-setting-input">
                <p className="mb-2">
                  First Name<span className="text-danger">*</span>
                </p>
                <Controller
                  name="first_name"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <>
                      <input
                        type="text"
                        name="first_name"
                        {...field}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          handleChange(e);
                        }}
                      />

                      <ErrorMessage error={errors?.first_name?.message} />
                    </>
                  )}
                />
              </div>

              <ArabicInput
                divClass="doc-setting-input col-3"
                label={t("Doctor.FirstName")}
                formStateData={formDataState}
                setFormStateData={setFormDataState}
                name="first_name_ar"
              />

              <div className="col-lg-3 mt-lg-0 mt-4 pl-lg-1 pr-1 doc-setting-input">
                <p className="mb-2">
                  {" "}
                  Last Name<span className="text-danger">*</span>{" "}
                </p>
                <Controller
                  name="last_name"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <>
                      <input
                        type="text"
                        name="last_name"
                        {...field}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          handleChange(e);
                        }}
                      />

                      <ErrorMessage error={errors?.last_name?.message} />
                    </>
                  )}
                />
              </div>

              <ArabicInput
                divClass="doc-setting-input col-3"
                label={t("Doctor.LastName")}
                formStateData={formDataState}
                setFormStateData={setFormDataState}
                name="last_name_ar"
              />
            </div>

            <div className="row mt-3">
              <div className="col-lg-6 pr-lg-1 doc-setting-input">

                <div className="d-flex justify-content-between">
                  <p className="mb-2">
                    Email<span className="text-danger">*</span>
                  </p>
                  <p className="mb-2">{`${t("Common.Email")}`}</p>
                </div>

                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: true,
                    pattern:
                      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
                  }}
                  render={({ field }) => (
                    <input
                      className=""
                      type="text"
                      name="email"
                      {...field}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        handleChange(e);
                      }}
                    />
                  )}
                />
                <ErrorMessage error={errors?.email?.message} />
              </div>

              <div className="col-lg-6 mt-lg-0 mt-4 pl-lg-1 doc-setting-input">
                <Controller
                  name="contact"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <>
                      <Phone
                        arLabel={t('Common.PhoneNo')}
                        name="contact"
                        field={field}
                        value={field.value}
                        handleChange={(e) => {
                          field.onChange(e);
                          handleChange(e);
                        }}
                      />
                      <ErrorMessage error={errors?.contact?.message} />
                    </>
                  )}
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-lg-6 pr-lg-1 doc-setting-input">

                <div className="d-flex justify-content-between">
                  <p className="mb-2">Customer Support Email</p>
                  <p className="mb-2">{`${t("Common.CustomerSupportEmail")}`}</p>
                </div>

                <Controller
                  name="support_email"
                  control={control}
                  rules={{
                    required: false,
                    pattern:
                      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
                  }}
                  render={({ field }) => (
                    <input
                      className=""
                      type="text"
                      name="support_email"
                      {...field}
                      value={field.value == "null" ? "" : field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        handleChange(e);
                      }}
                    />
                  )}
                />
                <ErrorMessage error={errors?.support_email?.message} />
              </div>

              <div className="col-lg-6 mt-lg-0 mt-4 pl-lg-1 doc-setting-input">
                <Controller
                  name="support_phone_no"
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field }) => (
                    <>
                      <Phone
                        arLabel={t('Common.CustomerSupportNo')}
                        label="Customer Support No"
                        name="support_phone_no"
                        field={field}
                        value={field.value}
                        handleChange={(e) => {
                          field.onChange(e);
                          handleChange(e);
                        }}
                      />
                      <ErrorMessage error={errors?.support_phone_no?.message} />
                    </>
                  )}
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-lg-6 mt-lg-0 mt-4 pr-lg-1 doc-setting-input">

                <div className="d-flex justify-content-between">
                  <p className="mb-2">
                    Gender<span className="text-danger">*</span>
                  </p>
                  <p className="mb-2">{`${t("Common.Gender")}`}</p>
                </div>

                <Controller
                  name="gender"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <>
                      <CustomDropDown
                        handleChangeSelect={(value, name) => {
                          field.onChange(value);
                          handleSelect(value, name);
                        }}
                        option={[
                          {
                            label: "Male​​",
                            value: "1",
                          },
                          {
                            label: "Female",
                            value: "0",
                          },
                        ]}
                        name="gender"
                        field={field}
                        value={field.value}
                        onBlur={field.onBlur}
                      />

                      <ErrorMessage error={errors?.gender?.message} />
                    </>
                  )}
                />
              </div>

              <CustomDropDownMulti
                label={'Hospital'}
                arLabel={t('Common.Hospital')}
                labelClass={'mb-2'}
                dropDownDivClass={'col-lg-6 mt-lg-0 mt-4 pl-lg-1 doc-setting-input'}
                handleChangeSelect={(value, name) => {
                  handleSelect(value, name);
                  handleSetHospDefaultCharges(value);
                }}
                option={hospitalOption || []}
                name="hospitals"
                mode="multiple"
                value={formDataState?.hospitals}
                required={true}
                isController={true}
                control={control}
                error={errors?.hospitals?.message}
              />

            </div>

            <div className="row mt-3">
                
              <CustomDropDown
                label={'Specialization'}
                arLabel={t("Common.Specialization")}
                dropDownDivClass={'col-lg-4 pr-lg-1 doc-setting-input'}
                handleChangeSelect={(value, name) => {
                  handleSelect(value, name);
                }}
                option={specialization || []}
                name="specialization_id"
                value={formDataState?.specialization_id}
                required={true}
                isController={true}
                control={control}
                error={errors?.specialization_id?.message}
              />

              <div className="col-lg-4 pr-lg-1 pl-1 mt-lg-0 mt-3 doc-setting-input">

                <div className="d-flex justify-content-between">
                  <p className="mb-2">
                    Qualification<span className="text-danger">*</span>
                  </p>
                  <p className="mb-2">{`${t("Doctor.Qualification")}`}</p>
                </div>
                
                <Controller
                  name="qualification"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <>
                      <input
                        type="text"
                        name="qualification"
                        {...field}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          handleChange(e);
                        }}
                      />

                      <ErrorMessage error={errors?.qualification?.message} />
                    </>
                  )}
                />
              </div>

              <div className="col-lg-4 mt-lg-0 mt-3 pl-lg-1 doc-setting-input ">

                <div className="d-flex justify-content-between">
                  <p className="mb-2">
                    Experience in years<span className="text-danger">*</span>{" "}
                  </p>
                  <p className="mb-2">{`${t("Doctor.ExperienceInYears")}`}</p>
                </div>

                <IncreDecreBtn
                  formDataState={formDataState}
                  setFormDataState={setFormDataState}
                  setValue={setValue}
                />
                <ErrorMessage error={errors?.experience_years?.message} />
              </div>
            </div>

            <div className="row mt-3">

              <TextInput
                label={'Council Registration no'} 
                arLabel={t('Doctor.CouncilRegistrationNo')} 
                name='council_registration_no' 
                inputDivClass='col-lg-6 pr-lg-1 doc-setting-input pl-3' 
                type='text' 
                value={formDataState?.council_registration_no} 
                handleOnChange={handleChange} 
                control={control} 
                error={errors?.council_registration_no?.message}
              />

              <SocialInput 
                label={'Facebook'} 
                arLabel={t('Common.Facebook')} 
                socialIcon={FacebookInput} 
                name={'facebook'} 
                value={formDataState?.facebook} 
                inputDivClass='col-lg-6 mt-lg-0 pl-lg-1 pr-3 mb-3' 
                handleOnChange={handleChange} 
                control={control}
                error={errors?.facebook?.message}
              />
              
            </div>

            <div className="row">

              <SocialInput 
                label={'Instagram'} 
                arLabel={t('Common.Instagram')} 
                socialIcon={InstaInput} 
                name={'instagram'} 
                value={formDataState?.instagram} 
                inputDivClass='col-lg-6 mt-lg-0 pr-1 pl-3 mb-3' 
                handleOnChange={handleChange} 
                control={control}
                error={errors?.instagram?.message}
              />

              <SocialInput 
                label={'Linkedin'} 
                arLabel={t('Common.Linkedin')} 
                socialIcon={LinkedInInput} 
                name={'linkedin'} 
                value={formDataState?.linkedin} 
                inputDivClass='col-lg-6 mt-lg-0 pr-3 pl-1 mb-3' 
                handleOnChange={handleChange} 
                control={control}
                error={errors?.linkedin?.message}
              />
            </div>

            <div className="row">

              <SocialInput 
                label={'Twitter'} 
                arLabel={t('Common.Twitter')} 
                socialIcon={TwitterInput} 
                name={'twitter'} 
                value={formDataState?.twitter} 
                inputDivClass='col-lg-6 mt-lg-0 pl-3 pr-1 mb-3' 
                handleOnChange={handleChange} 
                control={control}
                error={errors?.twitter?.message}
              />

              <SocialInput 
                label={'Website URL'} 
                arLabel={t('Common.WebsiteURL')} 
                name={'website'} 
                placeholder='Link'
                socialIcon={WebInput} 
                value={formDataState?.website} 
                inputDivClass='col-lg-6 mt-lg-0 pl-lg-1 doc-setting-input pr-3' 
                handleOnChange={handleChange} 
                control={control}
                error={errors?.website?.message}
              />

            </div>

            <div className="row">
              <div className="col-lg-6 pr-lg-1 doc-setting-input">

                <div className="d-flex justify-content-between">
                  <p className="mb-2"> Certificates </p>
                  <p className="mb-2">{`${t("Doctor.Certificates")}`}</p>
                </div>

                <CertificateInput
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                  formDataState={formDataState}
                  setFormDataState={setFormDataState}
                  objectKey={"documents"}
                />
              </div>

                      <CustomDropDownMulti
                        label={'Language'}
                        arLabel={t('Common.Language')}
                        labelClass={'mb-2'}
                        dropDownDivClass={'col-lg-6 mt-lg-0 pl-lg-1'}
                        handleChangeSelect={(value, name) => {
                          handleSelect(value, name);
                        }}
                        option={language || []}
                        name="languages"
                        mode="multiple"
                        value={formDataState?.languages}
                        required={true}
                        isController={true}
                        control={control}
                        error={errors?.languages?.message}
                      />


              <TextInput
                label={'About Doctor'} 
                inputDivClass='col-lg-6 mt-3 pr-lg-1 doc-setting-input pl-3 mb-3' 
                type='text' 
                name='about' 
                textArea={true} 
                value={formDataState?.about} 
                handleOnChange={handleChange} 
                control={control} 
                error={errors?.about?.message} 
              />

              <ArabicTextArea
                divClass="doc-setting-input col-6 mt-3 pl-1"
                label={t("Doctor.AboutDoctor")}
                formStateData={formDataState}
                setFormStateData={setFormDataState}
                name="about_ar"
              />

              {/* Availability */}

              <div className="col-lg-6 mt-4 pr-lg-1 doc-setting-input mb-2">
                <p className="mb-3">Availability / {`${t("Common.Availability")}`}</p>

                <div className="d-flex justify-content-between mb-4">
                  <p className="mb-0">Video Call</p>
                  <Switch
                    checked={formDataState?.is_online}
                    onClick={(e) =>
                      handleConsultationAvailability(e, "is_online")
                    }
                  />
                </div>

                <div className="d-flex justify-content-between">
                  <p className="mb-0">Appointment</p>
                  <Switch
                    checked={formDataState?.is_onsite}
                    onClick={(e) =>
                      handleConsultationAvailability(e, "is_onsite")
                    }
                  />
                </div>

                <ErrorMessage error={errors?.availability?.message} />
              </div>

              <div className="col-9 mt-3">
                <DoctorTimeTable
                  Id={id}
                  label={'Doctor Timings'}
                  arLabel={` / ${t('Doctor.DoctorTimings')}`}
                  addTimePostReq={addTimePostReq}
                  setaddTimePostReq={setaddTimePostReq}
                  selectDay={selectDay}
                  setSelectDay={setSelectDay}
                  isTimeSlotError={isTimeSlotError}
                  setIsTimeSlotError={setIsTimeSlotError}
                  modal2Open={modal2Open}
                  setModal2Open={setModal2Open}
                  specialistOptions={specialistOptions}
                  setspecialistOptions={setspecialistOptions}
                  selectedArrayItems={formDataState?.hospitals}
                  itemLabel={"Hospital"}
                  itemIdLabel={"hospital_id"}
                  postDoctorAvalibility={postDoctorAvalibility}
                />
              </div>

            </div>

            <div className="row">
              <div className="col-9 py-4 d-flex align-items-center">
                {!showDoctorFee ? (
                  <div onClick={() => setShowDoctorFee(true)}>
                    <img className="cursor-pointer" src={AddRoleIcon} alt="" />{" "}
                    <span className="cursor-pointer add-doc-role pl-3 ">
                      Set Consultancy Fee
                    </span>
                  </div>
                ) : (
                  <ConsutancyFee
                    setShowDoctorFee={setShowDoctorFee}
                    setFeeData={setFeeData}
                    feeData={feeData}
                    id={id}
                    selectdedHospital={selectdedHospital}
                    formDataState={formDataState}
                    setFormDataState={setFormDataState}
                    error={errors?.fees?.message}
                    setValue={setValue}
                    hospNames={hospNames}
                  />
                )}
              </div>
            </div>

            {(isSuperAdmin || allowedhost) && !id ? (

              <div className="col-lg-10 mt-3 px-0 add-appointment-card-wrapper" style={{border: '1px solid #d9d9d9', borderRadius: '5px'}}>

                <div className="title-header px-3">
                  <div className="title">Add a Role</div>
                </div>
                
                <hr />

                <AddRole
                  upperData={false}
                  roleParent={[{ value: 2, label: "Doctor Admin" }]}
                  setAddRole={setAddRole}
                  addRole={addRole}
                  roleParentValidation={roleParentValidation}
                  roleCategoryId={roleCategoryId}
                  navigateLink="doctor"
                  formDataState={{
                    name: `${formDataState?.first_name ? formDataState?.first_name : ""} 
                    ${ formDataState?.last_name ? formDataState?.last_name : "" }`,
                    email: `${ formDataState?.email ? formDataState?.email : "" }`,
                  }}
                  control={control}
                  errors={errors?.role}
                  setValue={setValue}
                />

              </div>

            ) : null}

            <div className="row my-5 pt-2 pb-3 ">
              <div className="col-lg-6">
                <button
                  className="apply-filter add-doc-changes"
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <ButtonLoader />
                  ) : (
                    <>{id ? "Update" : "Add"} Doctor</>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DoctorForm;
