import React from 'react'
import RoomsTableListHeader from '../../../molecules/HospitalRoomManagement/RoomsTableListHeader';
import DynamicRoomsDataTable from '../../../molecules/HospitalRoomManagement/DynamicRoomsDataTable';
import ServiceProviderListHelper from './helper';

const ServiceProviderDataTable = () => {

  const { 

    providerTableColumns,
    providersList,
    paginationData,
    handleChangePage,
    isLoading,

    filterStates: { filtersData },
    filterFuncs: { handleDeleteList, handleOnFilterChange }

  } =  ServiceProviderListHelper('Service Provider List', 'Post Delete');

  const breadCrumbItems = [
    {
      title: 'Home Service Provider'
    },
    {
      title: 'Service Provider List',
      href: ''
    }
  ];

  const filtersOrder = [
    {
      type: 'search',
      name: 'title',
      value: filtersData?.title,
      divClass: 'col-5 p-0',
      placeholder: 'Search by name',
      onChange: handleOnFilterChange
    }
  ];

  return (
    <div className='row pl-4 pr-4 pt-3 roomslistBody'>

      <RoomsTableListHeader
        pageTitle='Service Provider List'
        breadCrumbItems={breadCrumbItems}
        filtersOrder={filtersOrder}
      />

      <div className='col-12 p-0 mt-2' style={{overflowX: "hidden" }}>
        <DynamicRoomsDataTable tableColumns={providerTableColumns} tableRows={providersList} isRowsDataLoading={isLoading} paginationData={paginationData} handleChangePage={handleChangePage} handleDeleteList={handleDeleteList} deleteApiEndPoint={'deleteServiceProvider'} updateListPath='/service-provider/edit/' />
      </div>
      
    </div>
  )
}

export default ServiceProviderDataTable;