import React, { useEffect, useState } from "react";
import { Select, Input } from "antd";
import usePost from "../../customHook/usePost";
import ButtonLoader from "../../atoms/buttonLoader";
import InputLabel from "../../molecules/TextInput/InputLabel";
import ErrorMessage from "../../formvalidation/errormessage";
import { Controller } from "react-hook-form";

const { Option } = Select;

const CustomDropDown = ({
  mode,
  value,
  selectLabel = "Select",
  option = [],
  handleChangeSelect,
  disabled,
  name,
  field,
  hospitalDopDown,
  dayId,
  isNewItem,
  // Reusbale Elements
  dropDownDivClass,
  label,
  arLabel,
  labelTag,
  labelClass,
  labelDivClass,
  required,
  error,
  newItemButtonText,
  newItemPlaceholder,
  isController,
  control
}) => {
  const { isLoading, postData } = usePost();
  const role = JSON.parse(localStorage.getItem("userRoles"));
  const isSuperAdmin = role && Object.keys(role).length === 0;

  const selectAllOption = { value: "all", label: "Select All" };
  const updatedOptions =
    mode === "multiple" ? [selectAllOption, ...option] : option;

  useEffect(() => {
    setTimeout(() => {
      handleSelectZero();
    }, 1);
  }, []);

  const handleSelectZero = () => {
    const allOptions = [];
    handleChangeSelect(allOptions, name);
  };

  const handleSelectAll = (field) => {
    const allOptions = option.map((item) => item.value);
    handleChangeSelect(allOptions, name);
    isController && field?.onChange(allOptions);
  };

  const handleSelectChange = (val, field) => {
    if (Array.isArray(val) && val.includes("all")) {
      handleSelectAll(field);
    } else {
      handleChangeSelect(val, name);
      isController && field?.onChange(val);
      if (typeof hospitalDopDown === "function") {
        hospitalDopDown(val, dayId);
      }
    }
  };

  const renderOptions = updatedOptions?.map((opt) => {
    if (opt && opt.label) {
      return (
        <Option key={opt.value} value={opt.value}>
          {opt.label}
        </Option>
      );
    }
    return null;
  });

  const [NewSpecialization, setNewSpecialization] = useState("");

  const HanldeAddNewSpecialization = () => {
    console.log("helo i am here");

    const formData = new FormData();
    formData.append("name", NewSpecialization);
    console.log("name", name);

    if (NewSpecialization !== "") {
      postData(
        name === "specialities"
          ? "add_specialities"
          : name === "provider_category" && "addServiceProviderCategory",
        formData,
        (response) => {
          if (response?.success) {
            option.push({
              label: response?.data?.name,
              value: response?.data?.id,
            });
            setNewSpecialization("");
          }
        }
      );
    }
  };

  const renderMultiDropDown = (field) => (
    <Select
      {...field}
      defaultValue={mode === "multiple" ? value : selectLabel}
      className="custom-dropDown"
      name={name}
      mode={mode}
      value={value || []}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children &&
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={disabled}
      allowClear
      placeholder={mode && selectLabel}
      style={{
        width: "100%",
      }}
      onChange={(e) => {
        handleSelectChange(e, field);
      }}
      rules={{
        required: {
          value: true,
          message: "Please select at least one",
        },
      }}
      dropdownRender={
        isSuperAdmin && isNewItem
          ? (menu) => (
              <>
                {menu}
                <div
                  className="bg-  d-flex justify-content-between align-items-center px-2"
                  style={{ height: "50px", background: "#eeecec" }}
                >
                  <input
                    type="text"
                    placeholder={newItemPlaceholder}
                    style={{ width: "74%", fontSize: "12px" }}
                    className="px-2"
                    value={NewSpecialization}
                    onChange={(e) => {
                      setNewSpecialization(e.target.value);
                    }}
                  ></input>
                  <button
                    style={{
                      outline: "none",
                      border: "none",
                      background: "rgb(9, 73, 99)",
                      color: "white",
                      fontSize: "12px",
                      borderRadius: "5px",
                      width: "25%",
                      height: "68%",
                    }}
                    onClick={HanldeAddNewSpecialization}
                    className="d-flex align-items-center justify-content-center"
                  >
                    {isLoading ? <ButtonLoader /> : newItemButtonText}
                  </button>
                </div>
              </>
            )
          : null
      }
    >
      {renderOptions}
    </Select>
  );

  return (
    <div
      className={`${
        dropDownDivClass && `${dropDownDivClass} doc-setting-input mb-3`
      }`}
    >

      {dropDownDivClass && 

      <InputLabel
        label={label}
        arLabel={arLabel}
        required={required}
        labelTag={labelTag}
        labelClass={labelClass}
        labelDivClass={labelDivClass}
      />
      
      }

      {
        !isController ? renderMultiDropDown(field) : 

        <Controller
          name={name}
          control={control}
          render={({ field }) => renderMultiDropDown(field)}
        />
      }

      <ErrorMessage error={error} />
    </div>
  );
};

export default CustomDropDown;
