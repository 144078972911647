import Chevron from "../../assets/images/common/chevron-right.svg";
import DoctorsList from "../../components/patients/addpateints/DoctorsList";
import AddAppointmentCard from "./../../components/patients/addpateints/AddAppointmentCard";
import AddNewPatientForm from "../../components/patients/addpateints/AddNewPatientForm";
import "../../assets/css/patients/addpatients/addpatientsheader.scss";

import AddRoleIcon from "../../assets/images/doctor/AddRoleIcon.svg";
import { useState } from "react";
import BreadCrum from "../../atoms/breadcrum/BreadCrum";
import { useEffect } from "react";
import useDeleteData from "../../customHook/useDelete";

const AddPatient = ({ id }) => {

  const breadCrumbItems = [
    {
      title: 'Patients'
    },
    {
      title: id ? 'Edit Patient' : 'Add Patient',
      href: ''
    }
  ];

  return (
    <>
      <div className="row px-3  pt-4 addpatient-tab">
        <div className="col-12">
          <p className="mb-0 addpatient-heading">{id ? 'Edit' : 'Add'} Patient</p>
        </div>

        <div className="col-12 my-4">
          <div className="row ">
            <div className="col-lg-12 ">
              <BreadCrum breadCrumbItems={breadCrumbItems} />
            </div>
          </div>
        </div>

        <div class="wrapper">
          <div class="row  m-0 first-row px-3">
            <div class="col-lg-12 bg-white border px-0 " style={{borderRadius:"5px"}}>
              <div class="left-div">
                <AddNewPatientForm Id={id} />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 ml-2">
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPatient;
