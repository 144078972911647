import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// img svg
import RightArrow from "../../assets/images/doctor/RightArrow.svg";
import "./AddRole.scss";
import useDeleteData from "../../customHook/useDelete";
import { CustomToast } from "../../atoms/toastMessage";
import Phone from "../../atoms/phone";
import { Controller, useForm } from "react-hook-form";
import SelectCountry from "../../atoms/Country";
import SelectState from "../../atoms/State";
import CustomDropDown from "../../atoms/CustomDropDown/Index";
import UploadProfile from "../../atoms/uploadProfile/UploadProfile";
import usePost from "../../customHook/usePost";
import { useEffect } from "react";
import ImageUploader from "../../atoms/uploadProfile/UploadProfile";
import { handlePhoneCode } from "../../components/common/functionalityhelper";
import { useTranslation } from "react-i18next";
import InputLabel from "../../molecules/TextInput/InputLabel";
import ErrorMessage from "../../formvalidation/errormessage";

const AddRole = ({
  role_Id,
  upperData = true,
  roleParent,
  setAddRole,
  addRole,
  roleParentValidation = true,
  roleCategoryId,
  navigateLink,
  formDataState,
  control,
  errors,
  setValue,
  click
}) => {
  // const [addRole, setAddRole] = useState({ country: "Kuwait" })
  const [roleType, setRoleType] = useState([
    { value: 1, label: "Hospital Admin" },
    { value: 2, label: "Doctor Admin" },
    { value: 3, label: "Pharmacy Admin" },
    { value: 4, label: "Laboratory Admin" },
    { value: 7, label: "Medical Equipment Admin" },
    { value: 8, label: "X-ray Admin" },
    { value: 9, label: "Service Provider Admin" },
  ]);

  const [roleParentChild, setRoleParentChild] = useState(roleParent);
  const [role_IdParentChild, setRole_IdParentChild] = useState(role_Id);
  const [parentJoinId, setParentJoinId] = useState(roleCategoryId);

  useEffect(() => {
    // setValue('email', '')
    // setValue('password', '')
    // setAddRole((pre)=>({...pre, 'email': '', 'password': ''}))
    // reset()
  }, []);

  useEffect(() => {
    if (
      roleParent &&
      (roleParent[0]?.label == "Hospital Admin" ||
        roleParent[0]?.label == "Doctor Admin" ||
        roleParent[0]?.label == "Pharmacy Admin" ||
        roleParent[0]?.label == "Medical Equipment Admin" ||
        roleParent[0]?.label == "X-ray Admin" ||
        roleParent[0]?.label == "Service Provider Admin" ||
        roleParent[0]?.label == "Laboratory Admin")
    ) {
      dropDownChange(roleParent[0]?.value, "role_type_id");
      // dropDownChange(roleParent[0]?.label, "role_type")
      setRoleType(roleParent);
      setValue("role.role_type_id", roleParent[0]?.value, { shouldValidate: true });
    }

    // dropDownChange(roleCategoryId, "join_id")
  }, [roleParentChild]);

  useEffect(() => {
    if (
      roleParent &&
      (roleParent[0]?.label == "Hospital Admin" ||
        roleParent[0]?.label == "Doctor Admin" ||
        roleParent[0]?.label == "Pharmacy Admin" ||
        roleParent[0]?.label == "Medical Equipment Admin" ||
        roleParent[0]?.label == "X-ray Admin" ||
        roleParent[0]?.label == "Service Provider Admin" ||
        roleParent[0]?.label == "Laboratory Admin")
    ) {
      dropDownChange(roleParent[0]?.value, "role_type_id");
      // dropDownChange(roleCategoryId, "join_id")
      setRoleType(roleParent);
      setValue("role.role_type_id", roleParent[0]?.value);
    }
  }, [parentJoinId, roleParentValidation, click]);

  const [dropDownData, setDropDownData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const { isLoading, error, deleteData } = useDeleteData();
  const AddRoleHook = usePost();
  const [passwordType, setPasswordType] = useState("password");
  const navigate = useNavigate();

  const customData = useDeleteData();

  const handleChangeSelect = (value, name) => {
    setAddRole({ ...addRole, [name]: value });
  };

  const dropDownChange = (value, name) => {
    const selectedOption = roleType.find((option) => option.value === value);
    if (selectedOption?.label === "Hospital Admin") {
      setAddRole((prv) => ({ ...prv, role_type: "hospitaladmin" }));
    } else if (selectedOption?.label === "Doctor Admin") {
      setAddRole((prv) => ({ ...prv, role_type: "doctor" }));
    } else if (selectedOption?.label === "Pharmacy Admin") {
      setAddRole((prv) => ({ ...prv, role_type: "pharmacist" }));
    } else if (selectedOption?.label === "Laboratory Admin") {
      setAddRole((prv) => ({ ...prv, role_type: "technologist" }));
    } else if (selectedOption?.label === "Medical Equipment Admin") {
      setAddRole((prv) => ({ ...prv, role_type: "storeadmin" }));
    } else if (selectedOption?.label === "X-ray Admin") {
      setAddRole((prv) => ({ ...prv, role_type: "radiologic" }));
    } else if (selectedOption?.label === "Service Provider Admin") {
      setAddRole((prv) => ({ ...prv, role_type: "serviceprovider" }));
    } else {
      setAddRole((prv) => ({ ...prv, [name]: value }));
    }
    setAddRole((prv) => ({ ...prv, [name]: value }));
    if (
      (value === 1 && name === "role_type_id") ||
      (value === 2 && name === "role_type_id") ||
      (value === 3 && name === "role_type_id") ||
      (value === 4 && name === "role_type_id") ||
      (value === 9 && name === "role_type_id") ||
      (value === 7 && name === "role_type_id") ||
      (value === 8 && name === "role_type_id")
    ) {
      deleteData(
        `${
          value === 1
            ? `get_hospitals`
            : value === 2
            ? `get_doctors`
            : value === 3
            ? `list_pharmacies?status=1`
            : value === 4
            ? `list_laboratories?is_laboratory=1`
            : value === 9
            ? `list_of_service_provider`
            : value === 7
            ? `list_pharmacies?status=0`
            : value === 8
            ? `list_laboratories?is_laboratory=0`
            : null
        }`,
        (response) => {
          let transformedData = [];
          if (value == 9) {
            transformedData = response?.data?.service_providers?.map(
              (item) => ({
                value: item?.id,
                label: item?.name,
              })
            );
          } else {
            transformedData = response?.data?.map((item) => ({
              value: value == 2 ? item?.id : item?.id,
              label: value == 2 ? item?.user?.name : item?.name,
            }));
          }

          setDropDownData(transformedData);
        }
      );
    }
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  useEffect(() => {
    if (role_Id) {
      customData.deleteData(
        `${process.env.REACT_APP_GET_ROLE_BY_ID}/${role_Id}`,
        (val) => {
          dropDownChange(val?.data?.role_type_id, "role_type_id");
          setAddRole({
            // ...val?.data,
            // specialties: val?.data?.specialities?.map((l) => l.id),
            join_id: val?.data?.join_type?.id,
            role_type_id: val?.data?.role_type_id,
            country: "Kuwait",
            state: val?.data?.state,
          });
          //
          //   dropDownChange(val?.data?.join_type?.id, 'join_id')
          setSelectedImage(val?.data?.profile_pic);
          Object.entries(val?.data).forEach(([fieldName, fieldValue]) => {
            setValue(`role.${fieldName}`, fieldValue);
            // setAddRole({ ...addRole, [fieldName]: fieldValue});
          });
          setValue("role.join_id", val?.data?.join_type?.id);
          //   setValue(
          //     "specialties",
          //     val?.data?.specialities?.map((l) => l.id)
          //   );
        }
      );
    }
  }, [role_Id]);

  const handleHospitalSubmit = (event) => {
    const formData = new FormData();
    for (const key in addRole) {
      if (key === "specialties" && Array.isArray(addRole[key])) {
        addRole[key].forEach((value) => {
          formData.append(`${key}[]`, value);
        });
      } else {
        formData.append(key, addRole[key]);
      }
    }

    AddRoleHook?.postData(
      role_Id
        ? `${process.env.REACT_APP_UPDATE_ROLE}/${role_Id}`
        : `${process.env.REACT_APP_ADD_ROLE}`,
      formData,
      (response) => {
        if (response?.success === true) {
          CustomToast({
            type: "success",
            message: `Data added successfully`,
          });
          // reset();
          setAddRole({ country: "Kuwait", role_type_id: "" });
          setValue("");
          setDropDownData("");
          setSelectedImage("");
          if (upperData) {
            navigate("/allroles");
          }
          if (navigateLink === "doctor") {
            navigate("/doctors");
          }
          if (navigateLink === "pharmacy") {
            navigate("/pharmacy");
          }
          if (navigateLink === "hospitals") {
            navigate("/hospitals");
          }
          if (navigateLink === "laboratory") {
            navigate("/laboratory");
          }
          if (navigateLink === "xray") {
            navigate("/xray/list");
          }
          if (navigateLink === "serviceProvider") {
            navigate("/service-provider/list");
          }
        } else {
          CustomToast({
            type: "error",
            message: `${
              response?.message?.role_type
                ? response?.message?.role_type[0]
                : response?.message
            }`,
          });
        }
      }
    );
  };

  useEffect(() => {

      setAddRole((addRole) => ({ ...addRole, join_id: formDataState?.name }));
      setValue('role.join_id', formDataState?.name);

  }, [formDataState?.name]);

  useEffect(() => {

      setAddRole((addRole) => ({ ...addRole, email: formDataState?.email }));
      setValue('role.email', formDataState?.email);
    
  }, [formDataState?.email]);

  const AdminLabel = (
    addRole?.role_type === "hospitaladmin" ||
    addRole?.role_type_id === 1
    ? "Hospital"
    : addRole?.role_type === "doctor" ||
      addRole?.role_type_id === 2
    ? "Doctor"
    : addRole?.role_type === "technologist" ||
      addRole?.role_type_id === 4
    ? "Laboratory"
    : addRole?.role_type_id === "pharmacist" ||
      addRole?.role_type_id === 3
    ? "Pharmacy"
    : addRole?.role_type_id === "storeadmin" ||
      addRole?.role_type_id === 7
    ? "Medical Equipment"
    : addRole?.role_type_id === "radiologic" ||
      addRole?.role_type_id === 8
    ? "X-ray"
    : addRole?.role_type === "serviceprovider" ||
      addRole?.role_type_id === 9
    ? "Service Provider"
    : "Select Category"
  );

  const { t } = useTranslation();

  return (
    <div className="row px-0 m-0">
      {upperData ? (
        <>
          <div className="col-12  ">
            <p className="mb-0 dashboard-com-top-text">Manage Roles</p>
          </div>

          <div className="col-12  ">
            <div className="row d-flex align-items-end">
              <div className="col-lg-6 col-12 mt-lg-0 mt-2">
                <p className="mb-0 doctor-header-top-text">
                  <Link className="doc-link " to="/">
                    DASHBOARD
                  </Link>
                  <img
                    className="mx-lg-3 ml-2 pr-1 pb-1"
                    src={RightArrow}
                    alt=""
                  />
                  <span style={{ color: "#4FA6D1" }}>MANAGE ROLES</span>
                </p>
              </div>

              <div className="col-lg-6 col-12 mt-lg-0 mt-3 d-flex justify-content-end ">
                <Link className="add-doc-link-color" to="/allroles">
                  <button className="btn-add-doc-filter mr-2">
                    <span className="  btn-add-doc-filter-text">All Roles</span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      <form className="w-100">
        <div className="col-12 px-0 mb-5 pb-0">
          <div className="row px-0 m-0">
            <div className="col-lg-12 pr-lg-1 doc-setting-input">
              <ImageUploader
                serviceProviderData={addRole}
                setServiceProviderData={setAddRole}
                arLabel={` / ${t('Common.ProfilePicture')}`}
                error={errors?.profile_picture?.message} 
                setValue={setValue}
                controlName='role.profile_picture'
                notRequired={true}
              />
            </div>
          </div>
          <div className="row mt-4 px-0 m-0">

              <Controller
                name="role.role_type_id"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <>
                    <CustomDropDown
                      label={'Role Type'}
                      arLabel={t('Role.RoleType')}
                      dropDownDivClass={'col-lg-6 pr-lg-1 doc-setting-input'}
                      handleChangeSelect={(value, name) => {
                        field.onChange(value);
                        dropDownChange(value, name);
                      }}
                      required={true}
                      option={roleType || []}
                      name="role_type_id"
                      field={field}
                      value={field.value || null}
                      onBlur={field.onBlur}
                      disabled={!upperData}
                      error={errors?.role_type_id?.message}
                    />
                    
                  </>
                )}
              />

            <div className="col-lg-6 mt-lg-0 mt-4 pl-lg-1 doc-setting-input">

                <InputLabel
                  arLabel={t(`Common.${AdminLabel}`)}
                  labelClass={'mb-2'}
                  label={AdminLabel}
                  required={true}
                />

                <Controller
                  name="role.join_id"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <>
                      {!formDataState ?
                      
                      <CustomDropDown
                        handleChangeSelect={(value, name) => {
                          field.onChange(value);
                          dropDownChange(value, name);
                        }}
                        option={dropDownData || []}
                        name="join_id"
                        field={field}
                        value={field.value || ""}
                        onBlur={field.onBlur}
                        disabled={
                          !upperData ? upperData : dropDownData?.length < 1
                        }
                      />
                      :
                      <input type="text" name='join_id' value={field.value || ""} disabled></input>}

                      <ErrorMessage error={!formDataState && errors?.join_id?.message} />
                    </>
                  )}
                />

              {!upperData === false ? (
                isLoading ? (
                  <span style={{ fontSize: "12px", color: "grey" }}>
                    Loading...
                  </span>
                ) : null
              ) : null}
            </div>
          </div>

          <div className="row m-0 px-0 mt-4">
            <div className="col-12 doc-setting-input">

              <InputLabel
                label={'Name'}
                arLabel={t(`Common.Name`)}
                labelClass={'doc-add-filter-text mb-2'}
                required={true}
              />

              <Controller
                name="role.name"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <>
                    <input
                      type="text"
                      name="name"
                      {...field}
                      value={field.value || ""}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setAddRole({ ...addRole, name: e.target.value });
                      }}
                      autocomplete="off"
                    />

                    <ErrorMessage error={errors?.name?.message} />
                  </>
                )}
              />
            </div>
          </div>
          <div className="row m-0 px-3 mt-4" style={{display: 'flex'}}>
            <div
              className="col-4 doc-setting-input  p-0 add-role-default-email"
              style={{ border: "none" }}
            >
              <InputLabel
                label={'Email'}
                arLabel={t(`Common.Email`)}
                labelClass={'doc-add-filter-text mb-2'}
                required={true}
              />

              <Controller
                name="role.email"
                control={control}
                rules={{
                  required: true,
                  pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
                }}
                render={({ field }) => (
                  <input
                    className=""
                    type="text"
                    name="email"
                    {...field}
                    value={field?.value || ''}
                    // onChange={(e) => {
                    //   field.onChange(e.target.value);
                    //   setAddRole({ ...addRole, email: e.target.value });
                    // }}
                    disabled
                    autoComplete="off"
                  />
                )}
              />
              <ErrorMessage error={!formDataState && errors?.email?.message} />
            </div>

            <div
              className="col-4 p-0 pt-4 pt-lg-0 doc-setting-input px-2"
              style={{ border: "none" }}
            >
              
              <InputLabel
                label={'Password'}
                arLabel={t(`Role.Password`)}
                labelClass={'doc-add-filter-text mb-2'}
                required={true}
              />

              <div
                className="d-flex border px-2 add-role-default-password"
                style={{
                  height: "36px",
                  borderRadius: "5px",
                  backgroundColor: "white",
                }}
              >
                <Controller
                  name="role.password"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <div className="w-100">
                      <div
                        className="d-flex justify-content-between loginPasswordPositionBottom input-group-btn w-100 "
                        style={{ height: "36px" }}
                      >
                        <input
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            paddingBottom: "5px",
                          }}
                          type={passwordType}
                          name="password"
                          {...field}
                          value={field.value || ""}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            setAddRole({
                              ...addRole,
                              password: e.target.value,
                            });
                          }}
                        />

                        <h1
                          className="eyeBtn btn "
                          onMouseUp={togglePassword}
                          onMouseDown={togglePassword}
                          onTouchStart={togglePassword}
                          ontouchend={togglePassword}
                        >
                          <p
                            style={{
                              width: "10px",
                              height: "5px",
                              color: "Black",
                              border: "none",
                            }}
                          >
                            {passwordType === "password" ? (
                              <i class="fa fa-eye-slash" aria-hidden="true"></i>
                            ) : (
                              <i class="fa fa-eye" aria-hidden="true"></i>
                            )}
                          </p>
                        </h1>
                      </div>
                    </div>
                  )}
                />
              </div>
              <ErrorMessage error={errors?.password?.message} />
            </div>
            
              <Controller
                name="role.contact"
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field }) => (
                  <>
                    <Phone
                      label="Contact"
                      arLabel={t('Role.Contact')}
                      labelClass='doc-add-filter-text mb-2'
                      inputDivClass='col-4 pt-4 pt-lg-0 border-0'
                      name="contact"
                      field={field}
                      value={field.value}
                      handleChange={(e) => {
                        field.onChange(e);
                        setAddRole({ ...addRole, contact: handlePhoneCode(e?.target?.name, e?.target?.value, setValue)});
                      }}
                      error={errors?.contact?.message}
                    />
                  </>
                )}
              />
          </div>

          <div className="row m-0 mt-4 px-0 ">
            <div className="col-lg-6  pr-lg-1 doc-setting-input">
              <Controller
                name="role.country"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue="Kuwait"
                render={({ field }) => (
                  <>
                    <SelectCountry
                      arLabel={t('Common.Country')}
                      notRequired={false}
                      handleChangeSelect={(value, name) => {
                        field.onChange(value);
                        //   handleChange(name, value);
                        handleChangeSelect(value, name);
                      }}
                      name="country"
                      field={field}
                      value={field.value}
                      onBlur={field.onBlur}
                      isDisabled={true}
                    />

                    <ErrorMessage error={errors?.country?.message} />
                  </>
                )}
              />
            </div>

              <SelectState
                arLabel={t('Common.State')}
                dropDownDivClass={'col-lg-6 mt-lg-0 mt-4 pl-lg-1 doc-setting-input'}
                country={addRole?.country}
                disabled={!addRole?.country}
                name="state"
                value={addRole?.state || ""}
                handleChange={handleChangeSelect}
                isController={true}
                control={control}
                controlName='role.state'
                error={errors?.state?.message}
              />
          </div>

          {roleParentValidation ? (
            <div className="row mt-4 pl-2 ml-2">
              <button
                className="Add-role-btn px-5 py-2"
                // onClick={handleSubmit(handleHospitalSubmit)}
              >
                {" "}
                {role_Id ? "Edit" : "Add"} Role
              </button>
            </div>
          ) : null}
        </div>
      </form>
    </div>
  );
};

export default AddRole;
