import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Modal,
  Rate,
  Select,
  Slider,
  DatePicker,
  TimePicker,
} from "antd";
import { Controller, useForm } from "react-hook-form";

// img svg
import RightArrow from "../../assets/images/doctor/RightArrow.svg";
import CalenderIcon from "../../assets/images/doctor/CalenderIcon.svg";
import ClockIcon from "../../assets/images/doctor/ClockIcon.svg";

import CustomDropDown from "../../atoms/CustomDropDown/Index";
import CustomDropDownMulti from "../../atoms/CustomDropDown/CustomDropDownMulti";
import Time from "../../atoms/Time/Time";
import UploadFile from "../../molecules/UploadFile/UploadFile";
import { useState } from "react";
import { useEffect } from "react";
import usePost from "../../customHook/usePost";
import { CustomToast } from "../../atoms/toastMessage";
import ButtonLoader from "../../atoms/buttonLoader";
import useDeleteData from "../../customHook/useDelete";
import ArabicInput from "../../molecules/ArabicInput";
import { useTranslation } from "react-i18next";
import ArabicTextArea from "../../molecules/ArabicTextArea/ArabicTextArea";
import { toast } from "react-toastify";
import SpinnerLoaderModal from "../../components/common/spinnerloadermodal";
import BreadCrum from "../../atoms/breadcrum/BreadCrum";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { current } from "@reduxjs/toolkit";
import moment from "moment";
import schema from "../../formvalidation/schema";
import ErrorMessage from "../../formvalidation/errormessage";
import InputLabel from "../../molecules/TextInput/InputLabel";
import formschemas from "../../formvalidation/formschemas";
import DateTimeHelper from "../../formvalidation/DateTimeHelper";

const AddPromo = ({ Id }) => {

  const {
    currentDate,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    disabledStartDate,
    disabledEndDate,
    handleDisableTime
  } = DateTimeHelper();

  const [bannerData, setBannerData] = useState({});
  const [errorData, setErrorData] = useState({});
  const [dropDownData, setDropDownData] = useState([]);
  const AddBanner = usePost();
  const navigate = useNavigate();
  const customData = useDeleteData();
  const [fileUrl, setFileUrl] = useState("");
  const [roleType, setRoleType] = useState([
    { value: 1, label: "Laboratory" },
    { value: 2, label: "Pharmacy" },
    { value: 3, label: "X-Ray" },
    { value: 4, label: "Equipment" },
    { value: 5, label: "Services" },
  ]);
  const { t } = useTranslation();

  const {
    setValue,
    handleSubmit,
    trigger,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(formschemas?.promoSchema)
  });

  useEffect(() => {

    setValue('start_date', startDate?.dateText);
    setValue('start_time', startDate?.dateObj?.format('HH:mm'));
    setValue('end_date', endDate?.dateText);
    setValue('end_time', endDate?.dateObj?.format('HH:mm'));

    setBannerData(bannerData => ({
      ...bannerData,
      start_date: startDate?.dateText,
      start_time: startDate?.dateObj?.format('HH:mm'),
      end_date: endDate?.dateText,
      end_time: endDate?.dateObj?.format('HH:mm')
    }))

  },[])

  useEffect(() => {
    setValue("placements", null);
  }, []);

  //Remaining Coupon
  const remainingCoupon = bannerData.total_coupon - bannerData.used_coupon;

  useEffect(() => {
    if (Id) {
      setTimeout(() => {
        customData.deleteData(
          `${process.env.REACT_APP_LIST_PROMO_BY_ID}/${Id}`,
          (val) => {
            if (val?.data) {
              setFileUrl(val.data.image);
              setBannerData({
                ...val.data,
                specialties: val.data.specialities?.map((l) => l.id),
              });
              Object.entries(val.data).forEach(([fieldName, fieldValue]) => {
                setValue(fieldName, fieldValue);
              });

              setValue(
                "promo_locations",
                val.data.promo_placements?.map((item) => item.promo_location_id)
              );
              setValue("promo_type_id", val.data.promo_type?.id);
              setValue(
                "promo_category_id",
                val.data.promo_placements[0]?.promo_category_id ?? 9
              );
              setBannerData((pre) => ({
                ...pre,
                promo_type_id: val.data.promo_type?.id,
                promo_category_id:
                  val.data.promo_placements[0]?.promo_category_id ?? 9,
              }));
              dropDownChange(
                val.data.promo_placements[0]?.promo_category_id,
                "promo_category_id"
              );
              if (val.data.start_date === null) {
                setBannerData((pre) => ({
                  ...pre,
                  start_date: "",
                  end_date: "",
                }));
              }

            } else {
              console.log("Data not found or is null.");
            }
          }
        );
      }, 3);
    }
  }, [Id]);

  const dropDownChange = (value, name) => {
    const selectedOption = roleType.find((option) => option.value === value);
    if (selectedOption?.label === "Hospital Admin") {
      setBannerData((prv) => ({ ...prv, role_type: "hospitaladmin" }));
    } else if (selectedOption?.label === "Doctor Admin") {
      setBannerData((prv) => ({ ...prv, role_type: "doctor" }));
    } else if (selectedOption?.label === "Pharmacy Admin") {
      setBannerData((prv) => ({ ...prv, role_type: "pharmacist" }));
    } else if (selectedOption?.label === "Laboratory Admin") {
      setBannerData((prv) => ({ ...prv, role_type: "technologist" }));
    } else if (selectedOption?.label === "Medical Equipment Admin") {
      setBannerData((prv) => ({ ...prv, role_type: "storeadmin" }));
    } else if (selectedOption?.label === "X-ray Admin") {
      setBannerData((prv) => ({ ...prv, role_type: "radiologic" }));
    } else {
      setBannerData((prv) => ({ ...prv, [name]: value }));
    }
    setBannerData((prv) => ({ ...prv, [name]: value }));
  };

  const handleBannerSubmit = () => {

    const updateRoleData = {
      ...bannerData
    };

    const formData = new FormData();
    for (const key in updateRoleData) {
      if (Array.isArray(updateRoleData[key])) {
        updateRoleData[key].forEach((value) => {
          formData.append(`${key}[]`, value);
        });
      } else {
        formData.append(key, updateRoleData[key]);
      }
    }

    AddBanner?.postData(
      Id
        ? `${process.env.REACT_APP_UPDATE_PROMO}/${Id}`
        : `${process.env.REACT_APP_ADD_PROMO}`,
      formData,
      (response) => {
        if (response?.success === true) {
          navigate("/promo");
          CustomToast({
            type: "success",
            message: Id ? 'Promo updated successfully.' : `Promo added successfully.`,
          });
        } else {
          toast?.error(response?.response?.slice(0, 60));
        }
      }
    );
  };

  const breadCrumbItems = [
    {
      title: 'Promo'
    },
    {
      title: Id ? 'Edit Promo' : 'Add Promo',
      href: ''
    }
  ];

  return (
    <div className="row  px-2 pt-4 mb-5 pb-5">

      {Id && <SpinnerLoaderModal isDataLoading={customData?.isLoading} />}

      <div className="col-12  ">
        <p className="mb-0 dashboard-com-top-text">{Id ? 'Edit Promo' : 'Add Promo'}</p>
      </div>
      <div className="col-12 mt-lg-0 mt-2 pt-2">
        <BreadCrum breadCrumbItems={breadCrumbItems} />
      </div>
      <div className="col-12 mt-4 ">
        <div
          className="mt-3 pt-2"
          style={{
            backgroundColor: "white",
            borderRadius: "5px",
            border: "1px solid #e4e3e4",
          }}
        >
          <div className="row px-3 mt-4">
            <div className="col-lg-3 pt-lg-0 pt-4 doc-setting-input pr-0">
              <p className=" doc-add-filter-text">
                Title<span className="text-danger">*</span>
              </p>

              {/* <input type="text" /> */}
              <Controller
                name="title"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <>
                    <input
                      type="text"
                      name="title"
                      {...field}
                      value={field.value || ""}
                      onBlur={field.onBlur}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setBannerData((pre) => ({
                          ...pre,
                          title: e.target.value,
                        }));
                      }}
                      
                    />

                    <ErrorMessage error={errors?.title?.message} />
                  </>
                )}
              />
            </div>

            <ArabicInput
              divClass="doc-setting-input col-3 pr-0 pl-3"
              label={t("Promo.Title")}
              labelClass="doc-add-filter-text mb-3"
              formStateData={bannerData}
              setFormStateData={setBannerData}
              name="title_ar"
            />

              <Controller
                name="status"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <>
                    <CustomDropDown
                      label={'Status'}
                      arLabel={t('Common.Status')}
                      dropDownDivClass={'col-lg-6 doc-setting-input'}
                      labelClass={'mb-3'}
                      required={true}
                      handleChangeSelect={(value, name) => {
                        field.onChange(value);
                        setBannerData((pre) => ({ ...pre, status: value }));
                      }}
                      option={[
                        {
                          label: "Enable",
                          value: 1,
                        },
                        {
                          label: "Disable",
                          value: 2,
                        },
                      ]}
                      field={field}
                      value={field.value || ""}
                      onBlur={field.onBlur}
                      error={errors?.status?.message}
                    />
                  </>
                )}
              />

          </div>

          <div className="row mt-4 px-3">

              <Controller
                name="promo_category_id"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <>
                    <CustomDropDown
                      label={'Promo Placement'}
                      arLabel={t('Promo.PromoPlacement')}
                      dropDownDivClass={'col-lg-6 pt-lg-0 pt-4 doc-setting-input pr-0'}
                      labelClass={'mb-3'}
                      required={true}
                      handleChangeSelect={(value, name) => {
                        field.onChange(value);
                        dropDownChange(value, name);
                      }}
                      option={roleType || []}
                      name="promo_category_id"
                      field={field}
                      value={field.value || null}
                      onBlur={field.onBlur}
                      error={errors?.promo_category_id?.message}
                    />
                  </>
                )}
              />

            <div className="col-lg-6 pt-lg-0 pt-4  doc-setting-input ">

              <div className="d-flex justify-content-between">
                <p className=" doc-add-filter-text">
                  Upload Image<span className="text-danger">*</span>
                </p>
                <p className="mb-2">{`${t("Common.UploadImage")}`}</p>
              </div>

              <UploadFile
                formDataState={bannerData}
                setFormDataState={setBannerData}
                fileUrl={fileUrl}
                name={'image'}
                setValue={setValue}
              />

              <ErrorMessage error={errors?.image?.message} />
            </div>
          </div>

          <div className="row px-3 mt-4">
            
              <Controller
                name="promo_type_id"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <>
                    <CustomDropDown
                      label={'Promo Type'}
                      arLabel={t('Promo.PromoType')}
                      dropDownDivClass={'col-lg-6 pt-lg-0 pt-4 doc-setting-input pr-0'}
                      labelClass={'doc-add-filter-text mb-3'}
                      required={true}
                      handleChangeSelect={(value, name) => {
                        field.onChange(value);
                        setBannerData((pre) => ({
                          ...pre,
                          promo_type_id: value,
                        }));
                      }}
                      option={[
                        {
                          label: "Every Order Booking",
                          value: 1,
                        },
                        {
                          label: "1st Order Booking",
                          value: 2,
                        },
                      ]}
                      field={field}
                      value={field.value}
                      onBlur={field.onBlur}
                      error={errors?.promo_type_id?.message}
                    />
                  </>
                )}
              />

            <div className="col-lg-6 doc-setting-input">

              <div className="d-flex justify-content-between">
                <p className=" doc-add-filter-text">
                  Discount %<span className="text-danger">*</span>
                </p>
                <p className="mb-2">{`${t("Promo.Discount")}`}</p>
              </div>

              <Controller
                name="discount_percentage"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <>
                    <input
                      type="text"
                      name="discount_percentage"
                      {...field}
                      value={field.value || ""}
                      onBlur={field?.onBlur}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setBannerData((pre) => ({
                          ...pre,
                          discount_percentage: e.target.value,
                        }));
                      }}
                    />
                    <ErrorMessage error={errors?.discount_percentage?.message} />
                  </>
                )}
              />
            </div>
          </div>

          {/* Time and date  */}

          <>
            {" "}
            <div className="row px-3 mt-4">
              <div className="col-lg-6 pt-lg-0 pt-4 doc-setting-input pr-0">

                <div className="d-flex justify-content-between">
                  <p className=" doc-add-filter-text ">
                    Start Date<span className="text-danger">*</span>
                  </p>
                  <p className="mb-2">{`${t("Common.StartDate")}`}</p>
                </div>

                <div className="border" style={{ borderRadius: "5px" }}>
                  <Controller
                    name="start_date"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <DatePicker
                        placeholder={"Start Date"}
                        format="YYYY/MM/DD"
                        style={{
                          border: "none",
                          width: "100%",
                          height: "36.5px",
                        }}
                        onChange={(dateObj, dateString) => {

                          field.onChange(dateObj);
                          setBannerData((pre) => ({
                            ...pre,
                            start_date: dateString,
                            end_date: null,
                            start_time: null,
                            end_time: null
                          }));
                          setStartDate({ 
                            dateObj: dateString === currentDate?.dateText ? dayjs(`${dateString} ${dayjs().format('HH:mm:ss')}`, 'YYYY/MM/DD HH:mm:ss') : dateObj,
                            dateText: dateString
                          });
                          setEndDate({ 
                            dateObj: dayjs(`${dateString} ${dayjs().format('HH:mm:ss')}`, 'YYYY/MM/DD HH:mm:ss'), 
                            dateText: dateString
                          });
                          setValue('end_date', null);
                          setValue('start_time', null);
                          setValue('end_time', null);
                          trigger(['start_time', 'end_date', 'end_time']);

                        }}
                        field={field}
                        value={field?.value ? dayjs(field?.value) : null }
                        onBlur={field.onBlur}
                        disabledDate={disabledStartDate}
                      />
                    )}
                  />
                </div>

                <ErrorMessage error={errors?.start_date?.message} />
              </div>
              <div className="col-lg-6 pt-lg-0 pt-4 doc-setting-input ">

                <div className="d-flex justify-content-between">
                  <p className=" doc-add-filter-text">
                    Start Time<span className="text-danger">*</span>
                  </p>
                  <p className="mb-2">{`${t("Common.StartTime")}`}</p>
                </div>

                <div
                  className="d-flex justify-content-between"
                  style={{ backgroundColor: "white" }}
                >
                  <Controller
                    name="start_time"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <TimePicker
                        format="HH:mm"
                        placeholder={"Start Time"}
                        style={{
                          border: "1px solid #e4e3e4",
                          height: "36px",
                          width: "100%",
                        }}
                        onChange={(timeObj, timeString) => {
                          field.onChange(timeObj);
                          setBannerData((pre) => ({
                            ...pre,
                            start_time: timeString,
                          }));
                          setStartDate({...startDate, dateObj: timeObj});

                          if(startDate?.dateText === endDate?.dateText){
                            setValue('end_time', null);
                            trigger(['end_time']);
                            setBannerData(bannerData => ({...bannerData, end_time: null}));
                          }

                        }}
                        field={field}
                        value={field?.value ? dayjs(field?.value, 'HH:mm') : null}
                        onBlur={field.onBlur}
                        disabledTime={(time) => handleDisableTime(time, 'start')}
                      />
                    )}
                  />
                </div>

                <ErrorMessage error={errors?.start_time?.message} />
              </div>
            </div>
            <div className="row px-3 mt-4">
              <div className="col-lg-6 pt-lg-0 pt-4 doc-setting-input pr-0">

                <InputLabel 
                  label={'End Date'}
                  arLabel={t('Common.EndDate')}
                  labelClass={'doc-add-filter-text'}
                  required={true}
                />

                <div className="border" style={{ borderRadius: "5px" }}>
                  <Controller
                    name="end_date"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <DatePicker
                        format="YYYY/MM/DD"
                        placeholder={"End Date"}
                        style={{
                          border: "none",
                          width: "100%",
                          height: "36.5px",
                        }}
                        onChange={(dateObj, dateString) => {
                          field.onChange(dateObj);
                          setBannerData((pre) => ({
                            ...pre,
                            end_date: dateString,
                            end_time: null
                          }));
                          setEndDate({ 
                            dateObj: dayjs(`${dateString} ${dayjs().format('HH:mm:ss')}`, 'YYYY/MM/DD HH:mm:ss'), 
                            dateText: dateString
                          });
                          setValue('end_time', null);
                          trigger('end_time');
                        }}
                        field={field}
                        value={field?.value ? dayjs(field?.value) : null}
                        onBlur={field.onBlur}
                        disabledDate={disabledEndDate}
                      />
                    )}
                  />
                </div>

                <ErrorMessage error={errors?.end_date?.message} />
              </div>
              <div className="col-lg-6 pt-lg-0 pt-4 pl-3 doc-setting-input">

                <InputLabel 
                  label={'End Time'}
                  arLabel={t('Common.EndTime')}
                  labelClass={'doc-add-filter-text'}
                  required={true}
                />

                <div
                  className="d-flex justify-content-between"
                  style={{ backgroundColor: "white" }}
                >
                  <Controller
                    name="end_time"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <TimePicker
                        format="HH:mm"
                        placeholder={"End Time"}
                        style={{
                          border: "1px solid #e4e3e4",
                          height: "36px",
                          width: "100%",
                        }}
                        onChange={(timeObj, timeString) => {
                          field.onChange(timeObj);
                          setBannerData((pre) => ({
                            ...pre,
                            end_time: timeString,
                          }));
                        }}
                        field={field}
                        value={field?.value ? dayjs(field?.value, 'HH:mm') : null}
                        onBlur={field.onBlur}
                        disabledTime={(time) => handleDisableTime(time, 'end')}
                      />
                    )}
                  />
                </div>

                <ErrorMessage error={errors?.end_time?.message} />
              </div>
            </div>{" "}
          </>

          <div className="row px-3 mt-4">
            <div className="col-lg-6 pr-0 doc-setting-input">

              <InputLabel 
                label={'Total Coupon'}
                arLabel={t('Promo.TotalCoupon')}
                labelClass={'doc-add-filter-text'}
                required={true}
              />

              <Controller
                name="total_coupon"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <>
                    <input
                      type="text"
                      name="total_coupon"
                      {...field}
                      value={field.value || ""}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setBannerData((pre) => ({
                          ...pre,
                          total_coupon: e.target.value,
                        }));
                      }}
                    />

                    <ErrorMessage error={errors?.total_coupon?.message} />
                  </>
                )}
              />
            </div>
            {/* Display remaining coupon value */}
            {Id && (
              <div className="col-lg-6 pr-0 doc-setting-input">
                <p className=" doc-add-filter-text">Remaining Coupon</p>
                {/* Remaining Coupon value */}
                <input type="text" value={remainingCoupon} disabled={true} />
              </div>
            )}
          </div>

          <div className="row px-3 mt-4">
            <div className="col-lg-6 pr-lg-0 doc-setting-input">
              <p className=" doc-add-filter-text">
                Description<span className="text-danger">*</span>{" "}
              </p>

              {/* <textarea id="w3review" name="w3review" rows="4" style={{ width: "98%" }} /> */}
              <Controller
                name="description"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <>
                    <textarea
                      type="text"
                      name="description"
                      style={{ height: "138px" }}
                      {...field}
                      value={field.value || ""}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        // setNameData(e.target.value);
                        setBannerData((pre) => ({
                          ...pre,
                          description: e.target.value,
                        }));
                        // handleChangeHospital(e);
                      }}
                    />

                    <ErrorMessage error={errors?.description?.message} />
                  </>
                )}
              />
            </div>

            <ArabicTextArea
              divClass="doc-setting-input col-6"
              label={t("Promo.Description")}
              labelClass="doc-add-filter-text mb-3"
              formStateData={bannerData}
              setFormStateData={setBannerData}
              name="description_ar"
            />

            <div className="col-12 pt-3 pb-2 d-flex justify-content-center mt-3">
              <button
                disabled={AddBanner?.isLoading}
                className="apply-filter submit-save-banner"
                onClick={handleSubmit(handleBannerSubmit)}
              >
                {!AddBanner?.isLoading ? (
                  Id ? (
                    "Edit Promo"
                  ) : (
                    "Add Promo"
                  )
                ) : (
                  <div className="pb-3">
                    <ButtonLoader />
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPromo;
