import React, { useState } from "react";

import "../BookedHospitalRooms/bookedhospitalrooms.scss";
import "../../../assets/css/common/datatable.scss";
import RoomsTableListHeader from "../../../molecules/HospitalRoomManagement/RoomsTableListHeader";
import DynamicRoomsDataTable from "../../../molecules/HospitalRoomManagement/DynamicRoomsDataTable";
import HospitalRoomsListHelper from "./helper";
import { Modal } from "antd";
import TextInput from "../../../molecules/TextInput/TextInput";
import bread from "../../../utilities/data/list.breadcrumbs";

const HospitalRoomsDataTable = () => {
  const {
    hospRoomTableColumns,
    hospRoomsList,
    roomSpecifications,
    isLoading,

    // Filter States

    filterStates: { filtersData, hospitals },
    filterFuncs,

    // Pagination States
    paginationData,
    setPaginationData,
    handleChangePage,
  } = HospitalRoomsListHelper("Hosp Rooms List", "Get Delete");

  const [isViewDetailModalOpen, setIsViewDetailModalOpen] = useState(null);

  const filtersOrder = [
    {
      type: 'select',
      name: 'hospital_id',
      value: filtersData?.hospital_id,
      divClass: 'col-5 p-0',
      placeholder: 'Select Hospital',
      options: hospitals,
      onChange: filterFuncs?.handleOnFilterChange
    }
  ];

  return (
    <div className="row pl-4 pr-4 pt-3 roomslistBody">
      <RoomsTableListHeader
        pageTitle="Hospital Rooms"
        formButtonLabel="Add a Room"
        addFormPathName="/hospitals/hospitalroom/add"
        breadCrumbItems={bread?.hospRoomCrumbs}
        filtersOrder={filtersOrder}
      />

      <div className="col-12 p-0 mt-3" style={{ overflowX: "hidden" }}>
        <DynamicRoomsDataTable
          tableColumns={hospRoomTableColumns}
          tableRows={hospRoomsList}
          isRowsDataLoading={isLoading}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          handleChangePage={handleChangePage}
          handleDeleteList={filterFuncs?.handleDeleteList}
          deleteApiEndPoint={"delete_room"}
          setIsViewDetailModalOpen={setIsViewDetailModalOpen}
          updateListPath="/hospitals/hospitalroom/edit/"
        />
      </div>

      <Modal
        centered
        open={isViewDetailModalOpen !== null}
        className="statusModal"
        onCancel={() => setIsViewDetailModalOpen(null)}
        closeIcon={null}
        footer={null}
        bodyStyle={{
          height: "200px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="col-12">
          {roomSpecifications[isViewDetailModalOpen]?.map((room, index) => (
            <div className="row justify-content-between">
              <TextInput
                label={index === 0 && "Floor Title"}
                disabled={true}
                inputDivClass="col-5 mb-1"
                type="text"
                name="floor_title"
                value={room?.floor_title}
              />
              <TextInput
                label={index === 0 && "Total Rooms"}
                disabled={true}
                inputDivClass="col-5 mb-1"
                type="text"
                name="total_rooms"
                value={room?.total_rooms}
              />
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default HospitalRoomsDataTable;
