import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Dashboard from '../pages/Dashboard';
import AllPatients from '../pages/Patients/AllPatients';
import AddPatient from '../pages/Patients/AddPatient';
import EditPatient from '../pages/Patients/EditPatient';
import Appointments from '../pages/Appointment/Appointments';
import AddAppointments from '../pages/Appointment/AddAppointments';
import AppointmentVideoCall from '../pages/AppointmentVideoCalling/AppointmentVideoCall';
import Hospital from '../pages/Hospital/Hospital';
import AddHospital from '../components/Hospital/AddHospital';
import EditHospital from '../components/Hospital/EditHospital';
import HospitalRooms from '../pages/Hospital/HospitalRooms';
import HospitalRoomForm from '../pages/Hospital/HospitalRoomForm';
import BookingHospitalRooms from '../pages/Hospital/BookingHospitalRooms';
import AllDoctor from '../pages/Doctor/AllDoctor';
import AddDoctor from '../pages/Doctor/AddDoctor';
import ViewDoctor from '../components/doctors/ViewDoctor';
import Pharmacy from '../pages/Pharmacy/pharmacy';
import AddPharmacy from '../pages/Pharmacy/AddPharmacy';
import EditPharmacy from '../pages/Pharmacy/EditPharmacy';
import PharmacyShop from '../pages/Pharmacy/PharmacyShop';
import PharmacyShopDetail from '../pages/Pharmacy/PharmacyShopDetail';
import PharmacyViewOrderlistDetail from '../pages/Pharmacy/PharmacyViewOrderlistDetail';
import MedicalEquipment from '../pages/MedicalEquipment/MedicalEquipment';
import AddMedicalEquipment from '../pages/MedicalEquipment/AddMedicalEquipment';
import EditMedicalEquipment from '../pages/MedicalEquipment/EditMedicalEquipment';
import MedicalEquipmentShop from "../pages/MedicalEquipment/MedicalEquipmentShop";
import MedicalEquipmentShopDetail from "../pages/MedicalEquipment/MedicalEquipmentShopDetail";
import MedicalEquipmentViewOrderlistDetail from "../pages/MedicalEquipment/MedicalEquipmentViewOrderlistDetail";
import BloodDonation from '../pages/Blood Donation/BloodDonation';
import AddServiceProvider from '../pages/HomeServiceProvider/AddServiceProvider';
import ServiceProviderList from '../pages/HomeServiceProvider/ServiceProviderList';
import EditServiceProvider from '../pages/HomeServiceProvider/EditServiceProvider';
import ServiceList from '../pages/HomeServiceProvider/ServiceList';
import AddService from '../pages/HomeServiceProvider/AddService';
import EditService from '../pages/HomeServiceProvider/EditService';
import ServiceRequestList from '../pages/HomeServiceProvider/ServiceRequestList';
import SalesPromotionList from '../pages/SalesPromotions/salesPromotionList';
import AddSalesPromotion from '../pages/SalesPromotions/salespromotionform';
import EditSalesPromotion from '../pages/SalesPromotions/EditSalesPromotion';
import SalesPromotionResquestListTable from "../components/salesPromotion/salesPromotionResquestListTable";
import NeedyPatientsList from "../pages/TreatmentSponsor/NeedyPatientsList";
import AddNeedyPatient from '../pages/TreatmentSponsor/AddNeedyPatient';
import BannerPromo from "../pages/BannerPromo/BannerPromo";
import AddBannerPromo from '../pages/BannerPromo/AddBannerPromo';
import EditBannerPromo from '../pages/BannerPromo/EditBannerPromo';
import Promo from '../pages/BannerPromo/Promo';
import AddPromo from '../pages/BannerPromo/AddPromo';
import EditPromo from '../pages/BannerPromo/EditPromo';
import NewsLists from '../pages/News/NewsLists';
import AddNews from '../pages/News/AddNews';
import UploadNotifications from '../pages/Broadcast Notifications/UploadNotifications';
import LaboratoryList from '../pages/Laboratory/LaboratoryList';
import AddLab from '../pages/Laboratory/AddLab';
import EditLab from '../pages/Laboratory/EditLab';
import BloodTest from '../pages/Laboratory/bloodtest/BloodTest';
import LabShopDetail from '../pages/Pharmacy/LabShopDetail';
import LabViewOrderlistDetail from '../pages/Pharmacy/LabViewOrderlistDetail';
import XrayList from '../pages/Laboratory/XrayList';
import AddXray from '../pages/Laboratory/AddXray';
import EditXray from '../pages/Laboratory/EditXray';
import XrayLab from '../pages/Laboratory/bloodtest/XrayLab';
import XRayShopDetail from '../pages/Pharmacy/XRayShopDetail';
import XrayViewOrderlistDetail from '../pages/Pharmacy/XrayViewOrderlistDetail';
import ManageRoles from '../components/DashboardComponents/ManageRoles';
import AllRoles from '../components/ManageRoles/AllRoles';
import ChangePassword from '../pages/User Profile/ChangePassword';
import DashboardCom from '../components/DashboardComponents/DashboardCom';
import { useSelector } from 'react-redux';

const AdminRoutes = () => {

    const user = useSelector((state) => state.auth);
    const isSuperAdmin = user && user?.role === "super admin";

    const routes = [

        { path: '', component: <DashboardCom /> },
        { path: 'dashboard', component: <DashboardCom /> },
        { path: 'change-password', component: <ChangePassword /> },

        ...(isSuperAdmin || user?.role === 'hospitaladmin' || user?.role === 'doctor' ? [
            { path: 'appointment', component: <Appointments /> },
            { path: 'appointment/add', component: <AddAppointments /> },
            { path: 'appointment/video-call/:id', component: <AppointmentVideoCall /> }
            ] : []
        ),

        ...(isSuperAdmin || user?.role === 'hospitaladmin' ? [
            { path: 'hospitals', component: <Hospital /> },
            { path: 'hospitals/edit/:id', component: <EditHospital /> },
            { path: 'hospitals/hospitalrooms', component: <HospitalRooms /> },
            { path: 'hospitals/hospitalroom/add', component: <HospitalRoomForm /> },
            { path: 'hospitals/hospitalroom/edit/:id', component: <HospitalRoomForm /> },
            { path: 'hospitals/bookedhospitalrooms', component: <BookingHospitalRooms /> }
            ] : []
        ),

        ...(isSuperAdmin || user?.role === 'doctor' || user?.role === 'hospitaladmin' ? [
            { path: 'doctors', component: <AllDoctor /> },
            { path: 'doctors/detail/:id', component: <ViewDoctor /> }
            ] : []
        ),

        ...(isSuperAdmin || user?.role === 'hospitaladmin' ? [
            { path: 'patients', component: <AllPatients /> },
            { path: 'patients/add', component: <AddPatient /> },
            { path: 'patients/edit/:id', component: <EditPatient /> },
            ] : []
        ),
        
        ...(isSuperAdmin || user?.role === 'pharmacist' ? [
            { path: 'pharmacy', component: <Pharmacy /> },
            { path: 'pharmacy/update/:id', component: <EditPharmacy /> },
            { path: 'pharmacy/shop', component: <PharmacyShop /> },
            { path: 'pharmacy/shop/detail', component: <PharmacyShopDetail /> },
            { path: 'pharmacy/detail/:id', component: <PharmacyViewOrderlistDetail /> }
            ] : []
        ),

        ...(isSuperAdmin || user?.role === 'storeadmin' ? [
            { path: 'medical/equipment', component: <MedicalEquipment /> },
            { path: 'medical/equipment/update/:id', component: <EditMedicalEquipment /> },
            { path: 'medical/equipment/shop', component: <MedicalEquipmentShop /> },
            { path: 'medical/equipment/shop/detail', component: <MedicalEquipmentShopDetail /> },
            { path: 'medical/equipment/detail/:id', component: <MedicalEquipmentViewOrderlistDetail /> }
            ] : []
        ),

        ...(isSuperAdmin || user?.role === 'technologist' ? [
            { path: 'laboratory', component: <LaboratoryList /> },
            { path: 'laboratory/update/:id', component: <EditLab /> },
            { path: 'laboratory/tests', component: <BloodTest /> },
            { path: 'laboratory/bloodtest/orderlist', component: <LabShopDetail /> },
            { path: 'laboratory/bloodtest/detail/:id', component: <LabViewOrderlistDetail /> }
            ] : []
        ),

        ...(isSuperAdmin || user?.role === 'radiologic' ? [
            { path: 'xray/list', component: <XrayList /> },
            { path: 'xray/update/:id', component: <EditXray /> },
            { path: 'xray', component: <XrayLab /> },
            { path: 'xray/orderlist', component: <XRayShopDetail /> },
            { path: 'xray/detail/:id', component: <XrayViewOrderlistDetail /> },
            ] : []
        ),

        ...(isSuperAdmin || user?.role === 'serviceprovider' ? [
            { path: 'service-provider/list', component: <ServiceProviderList /> },
            { path: 'service-provider/edit/:id', component: <EditServiceProvider /> },
            { path: 'service-provider/service/list', component: <ServiceList /> },
            { path: 'service-provider/service/add', component: <AddService /> },
            { path: 'service-provider/service/edit/:id', component: <EditService /> },
            { path: 'service-provider/service-request-list', component: <ServiceRequestList /> },
            ] : []
        ),

        ...(isSuperAdmin || user?.role === 'hospitaladmin' ? [
            { path: 'doctors/add', component: <AddDoctor /> },
            { path: 'blood-donation', component: <BloodDonation /> }
            ] : []
        ),

        ...(isSuperAdmin ? [
            { path: 'hospitals/add', component: <AddHospital /> },
            { path: 'pharmacy/add', component: <AddPharmacy /> },
            { path: 'medical/equipment/add', component: <AddMedicalEquipment /> },
            { path: 'laboratory/add', component: <AddLab /> },
            { path: 'xray/add', component: <AddXray /> },
            { path: 'service-provider/add', component: <AddServiceProvider /> },
            { path: 'sales-promotion', component: <SalesPromotionList /> },
            { path: 'sales-promotion/add', component: <AddSalesPromotion /> },
            { path: 'sales-promotion/edit/:id', component: <EditSalesPromotion /> },
            { path: 'sales-promotion/sales-promotion-request-list', component: <SalesPromotionResquestListTable /> },
            { path: 'needy-patient/list', component: <NeedyPatientsList /> },
            { path: 'needy-patient/add', component: <AddNeedyPatient /> },
            { path: 'needy-patient/edit/:id', component: <AddNeedyPatient /> },
            { path: 'banner-promo', component: <BannerPromo /> },
            { path: 'banner-promo/add', component: <AddBannerPromo /> },
            { path: 'banner-promo/edit/:id', component: <EditBannerPromo /> },
            { path: 'promo', component: <Promo /> },
            { path: 'promo/add', component: <AddPromo /> },
            { path: 'promo/edit/:id', component: <EditPromo /> },
            { path: 'news', component: <NewsLists /> },
            { path: 'news/add', component: <AddNews /> },
            { path: 'notifications/add', component: <UploadNotifications /> },
            { path: 'manageroles', component: <ManageRoles /> },
            { path: 'allroles', component: <AllRoles /> },
            ] : []
        ),
        
    ];

  return (
    <Routes>
        <Route path="/" element={<Dashboard />} >

        {routes?.map(({ path, component}) => (
            <Route path={path} element={component} />
        ))}

        </Route>
    </Routes>
  )
}

export default AdminRoutes;