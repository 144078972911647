import React from 'react'
import AddBannerPromo from './AddBannerPromo';
import { useParams } from 'react-router-dom';

const EditBannerPromo = () => {

  const { id } = useParams();

  return <AddBannerPromo Id={id} />
}

export default EditBannerPromo;