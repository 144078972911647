import React, { useEffect, useState } from 'react'

// svg
import NotificationIcon from "../../assets/images/dashboard/NotificationIcon.svg";
import MedicineIcon from "../../assets/images/dashboard/MedicineIcon.svg";
import MicroscopeIcon from "../../assets/images/dashboard/MicroscopeIcon.svg";
import PatientNameIcon from "../../assets/images/dashboard/PatientNameIcon.svg";
import FirstAidBoxIcon from "../../assets/images/dashboard/FirstAidBoxIcon.svg";

// png
import SampleImg from "../../assets/images/dashboard/SampleImg.png";
import axios from 'axios';
import moment from 'moment';

const NotificationDropDown = () => {

    const token = localStorage.getItem("token");
    const [Notifications, setNotifications] = useState([]);

    useEffect(() => {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios.get('https://api.maweidi.com.kw/api/get_user_notifications?per_page=100&page=1', config)
    .then((response) => {
        if(response?.data?.data?.data){
            setNotifications(response?.data?.data?.data);
        }
    })
    .catch((error) => {
        console.log('Dashboard: notifications error', error);
    })

    },[])

    return (
        <>
            <div class="dropdown">
                <div
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className=" cursor-pointer menu-button p-4 mr-2 d-flex align-items-center justify-content-center"
                >
                    <img src={NotificationIcon} alt="" />
                </div>

                <div
                    class="notification-drop-down-body dropdown-menu py-0"
                    aria-labelledby="dropdownMenuButton"
                    style={{maxHeight: '402px', overflowY: 'scroll', overflowX: 'hidden'}}
                >
                    {Notifications?.map((notification, index) => {
                        const parsedDate = moment(notification?.created_at).format('MMM DD, YYYY hh:mm A');
                        return (
                            <div className="row px-3">
                                <div className={`col-2 pt-4 ${Notifications?.length !== index + 1 && 'border-bottom'}`}>
                                    <img
                                        className="noti-drop-down-img"
                                        src={PatientNameIcon}
                                        alt=""
                                    />
                                </div>

                                <div className={`col-10 pt-4 px-0 d-flex justify-content-center align-items-start flex-column ${Notifications?.length !== index + 1 && 'border-bottom'}`}>
                                    <p className="mb-0 pr-4 noti-drop-down-text1">{notification?.message}</p>
                                    <p className="mt-1 mb-3 pr-4  noti-drop-down-text1" style={{fontSize: '12px', color: 'rgb(9, 73, 99)'}} >{parsedDate}</p>
                                </div>
                            </div>
                        )
                    })}
                    
                    {Notifications?.length === 0 && (
                        <div className='text-center py-3'>
                            <p className="mb-0 pr-4 noti-drop-down-text1">No data</p>
                        </div>
                    )}

                </div>
            </div>
        </>
    )
}

export default NotificationDropDown