import React from "react";
import { Line } from "@ant-design/plots";

const LinearChart = ({ appointsmentData, selectedHospital }) => {
  const config = {
    data: appointsmentData,
    padding: "auto",
    xField: "date",
    yField: "appointments",
    xAxis: {
      tickCount: 5,
    },
  };

  return <Line {...config} />;
};

export default LinearChart;
