import { TableCell } from '@mui/material'
import React from 'react'

const SortTableCell = ({ label, handleSortByAge, handleSortIcons, ascKey, descKey, align }) => (

    <TableCell
        align={align || "left"}
        onClick={() => handleSortByAge(ascKey, descKey)}
        className="tableCellHover"
        >
        <i
            class={`fa-solid ${handleSortIcons(
            ascKey,
            descKey
            )} mr-2`}
        ></i>
        {label}
    </TableCell>

)

export default SortTableCell;