import React, { useEffect, useState } from 'react'
import { handlePhoneCode } from '../components/common/functionalityhelper';
import useDeleteData from '../customHook/useDelete';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import formschemas from "../../src/formvalidation/formschemas";
import usePost from '../customHook/usePost';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const useAppointmentForm = () => {

    const {
        handleSubmit,
        setValue,
        control,
        formState: { errors }
    } = useForm({
        mode: 'all',
        resolver: yupResolver(formschemas.appointmentSchema)
    });

    const [appointmentData, setAppointmentData] = useState({});
    const [appointmentState, setAppointmentState] = useState({
        start_date: moment()?.format('YYYY-MM-DD'),
        end_date: moment()?.endOf('year')?.format('YYYY-MM-DD')
    });
    const [otherStates, setOtherStates] = useState({
        isTimingsModalOpen: false
    });
    const { deleteData } = useDeleteData();
    const fetchDoctorTimingsApiHook = usePost();
    const addAppointmentApiHook = usePost();
    const fetchKwdIDApiHook = useDeleteData();
    const navigate = useNavigate();

    // Hanlders

    const handleOnChange = (e) => {
        const { name, value } = e.target ? e.target : e;
        setAppointmentState({...appointmentState, 
            [name]: name === 'phone_no' || name === 'support_phone_no' ? handlePhoneCode(name, value) : value
        });
        
        if(name === 'hospital_id' || name === 'specialization_id' || name === 'doctor_id') handleRemoveValues(name, value);
    }

    const handleRemoveValues = (name, value) => {

        if (name === "hospital_id") {
    
            setAppointmentState((appointmentState) => ({
                ...appointmentState,
                doctor_id: null,
                specialization_id: null,
            }));
            setValue("doctor_id", null, { shouldValidate: true });
            setValue("specialization_id", null, { shouldValidate: true });
            setValue("fees", '', { shouldValidate: true });

            // Fetching selected hospital specializations

            value && deleteData(`${process.env.REACT_APP_GET_SPECIALIZATIONS}/${value}`, (response) => {    
                if(response?.success){
                    setAppointmentData(appointmentData => ({
                        ...appointmentData,
                        doctors: [],
                        doctor_availabilities: [],
                        hospitalDoctors: response?.data?.doctors,
                        specialities: response?.data?.specializations?.map((speciality) => ({
                            label: speciality?.name,
                            value: speciality?.id
                        }))
                    }));
                }
            })

        } 
        else if (name === "specialization_id") {
        
            setAppointmentState((appointmentState) => ({
                ...appointmentState,
                doctor_id: null,
            }));
            setValue("doctor_id", null, { shouldValidate: true });
            setValue("fees", '', { shouldValidate: true });

            setAppointmentData(appointmentData => ({
                ...appointmentData,
                doctor_availabilities: [],
                doctors: appointmentData?.hospitalDoctors
                ?.filter((doctor) => doctor?.specialization_id === value)
                ?.map((doctor) => ({ 
                    label: doctor?.user?.name, 
                    value: doctor?.id,
                    is_online: doctor?.is_online,
                    is_onsite: doctor?.is_onsite,
                    fees: doctor?.fees ?? 0
                }))
            }))

        }
        else if(name === 'doctor_id'){

            // Finding selected doctor consultancy process options

            const doctor = appointmentData?.doctors?.find((doctor) => doctor?.value === value);
            const temp = [];

            if(doctor?.is_online) temp?.push({ label: 'Video Call', value: 'Video Call' });
            if(doctor?.is_onsite) temp?.push({ label: 'Appointment', value: 'Appointment' });

            setAppointmentData(appointmentData => ({
                ...appointmentData,
                doctor_availabilities: [...temp]
            }));

            setAppointmentState(appointmentState => ({
                ...appointmentState,
                fees: parseInt(doctor?.fees)
            }));

            setValue("fees", parseInt(doctor?.fees), { shouldValidate: true });

            // Fetching selected doctor availablities

            const payload = {
                hospital_id: appointmentState?.hospital_id,
                doctor_id: value,
                start_date: appointmentState?.start_date,
                end_date: appointmentState?.end_date
            }

            value && fetchDoctorTimingsApiHook?.postData(process.env.REACT_APP_GET_DOCTOR_AVAILABILITIES, payload, (response) => {
                if(response?.success) setAppointmentData(appointmentData => ({...appointmentData, doctor_slots: response?.data}));
            })
        }
    
        setAppointmentState((appointmentState) => ({
          ...appointmentState,
          doctor_availability: '',
          selected_date: "",
          start_slot: "",
          end_slot: "",
          doctor_availability_slot_id: null
        }));
    
        setValue('doctor_availability', null, { shouldValidate: true });
        setValue('time', '', { shouldValidate: true });
        setValue('date', '', { shouldValidate: true });

    };

    const fetchPatientByKwdID = () => {

        fetchKwdIDApiHook?.deleteData(

            `${process.env.REACT_APP_GET_PATIENT_DETAIL_BY_KWD_ID}/${appointmentState?.kwdId}`,
            (response) => {
              if (response?.success) {

                const { user } = response?.data;
                
                const patient = {
                    kwdId: user?.kwd_id,
                    patient_id: user?.id,
                    patient_name: user?.name,
                    patient_name_ar: user?.first_name_ar ?? '' + user?.last_name_ar ?? '',
                    phone: user?.contact,
                    age: user?.age,
                    email: user?.email
                };

                setAppointmentState(appointmentState => ({
                    ...appointmentState,
                    ...patient
                }));

                setOtherStates(otherStates => ({...otherStates, fetchedPatientKwdID: user?.kwd_id}));

                Object?.entries(patient)?.forEach(([key, value]) => {
                    setValue(key, value, { shouldValidate: true });
                });

                toast?.success("Patient record found.");
              } else {
                toast?.error("Invalid KWD ID.");
              }
            }
        );
    }

    const onHandleSubmit = () => {

        if(appointmentState?.kwdId !== otherStates?.fetchedPatientKwdID){
            toast?.error("KWD ID must match the current patient's KWD ID.");
            return;
        }

        const payload = {
            ...appointmentState,
            note: '',
            is_family_member: 0,
            date: appointmentState.selected_date,
            is_online: appointmentState?.doctor_availability === 'Video Call' ? 1 : 0,
            is_onsite: appointmentState?.doctor_availability === 'Appointment' ? 1 : 0
        };

        addAppointmentApiHook?.postData(`${process.env.REACT_APP_ADD_APPOINTMENT}`, payload, (response) => {
                if(response?.success){
                    toast?.success('Appointment booked successfully.');
                    navigate("/appointment");
                }
                else toast?.error(response?.response);
            }
        );

    };

    // UseEffects

    useEffect(() => {
        deleteData(process.env.REACT_APP_GET_HOSPITAL_DATA, (response) => {
            setAppointmentData(appointmentData => ({
                ...appointmentData,
                hospitals: response?.data?.map((hospital) => ({
                    label: hospital?.name,
                    value: hospital?.id
                }))
            }));
        })
    },[]);

    // Return States and Handlers

    const appointmentStates = {
        appointmentData,
        appointmentState,
        otherStates
    }

    const appointmentFuncs = {
        setAppointmentData,
        setAppointmentState,
        setOtherStates,
        handleOnChange,
        fetchPatientByKwdID,
        onHandleSubmit
    }

    const useFormProps = {
        control,
        errors,
        setValue,
        handleSubmit
    }

    const apiHooks = {
        fetchKwdIDApiHook,
        fetchDoctorTimingsApiHook,
        addAppointmentApiHook
    }

    return {
        appointmentStates,
        appointmentFuncs,
        useFormProps,
        apiHooks
    }

}

export default useAppointmentForm;