import React, { useEffect, useState } from "react";
import useDeleteData from "../../../customHook/useDelete";
import usePost from "../../../customHook/usePost";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const HospitalRoomHelper = (hospitalRoomID, setValue) => {
  console.log("hospitalRoomID hospitalRoomID", hospitalRoomID);
  const [roomData, setRoomData] = useState({
    images: [],
    remove_images_ids: [],
    remove_room_specification_ids: [],
    room_specifications: [
      {
        floor_title: "",
        total_rooms: "",
      },
    ],
  });
  const { deleteData } = useDeleteData();
  const { postData } = usePost();
  const navigate = useNavigate();
  const [roomState, setRoomState] = useState({
    hospitals: [],
    roomTypes: [],
    roomFacilities: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const cancelationDurations = [
    { label: "24 Hours", value: "24" },
    { label: "48 Hours", value: "48" },
    { label: "72 Hours", value: "72" },
    { label: "0 Time", value: "0" },
  ];

  const handleAddRoomSlot = (setValue) => {
    const temp = roomData?.room_specifications;
    temp?.push({ floor_title: "", total_rooms: "" });
    setRoomData({ ...roomData, room_specifications: [...temp] });
    setValue('room_specifications', [...temp], { shouldValidate: true });
  };

  const handleRemoveRoomSlot = (index, setValue) => {

    const temp = roomData?.room_specifications;

    if (temp[index]?.total_booked_rooms > 0) {
      toast?.error(
        "Floor " +
          temp[index]?.floor_title +
          " has already rooms booked so it cannot be removed."
      );
      return;
    } else {
      if (roomData?.room_specifications[index]?.id) {
        setRoomData((roomData) => ({
          ...roomData,
          remove_room_specification_ids: [
            ...roomData?.remove_room_specification_ids,
            roomData?.room_specifications[index]?.id,
          ],
        }));
      }

      if (roomData?.room_specifications?.length !== 1) {
        temp?.splice(index, 1);
        setRoomData((roomData) => ({ ...roomData, room_specifications: [...temp] }));
        setValue('room_specifications', [...temp], { shouldValidate: true });
      } else {
        setRoomData((roomData) => ({
          ...roomData,
          room_specifications: [{ floor_title: "", total_rooms: "" }],
        }));
        setValue('room_specifications', [{ floor_title: "", total_rooms: "" }], { shouldValidate: true });
      }
    }

  };

  const handleOnChange = (e) => {
    const { name, value } = e?.target ? e?.target : e;
    setRoomData({ ...roomData, [name]: value });
  };

  const [array, setArray] = useState([]);
  
  const handleFloorOnChange = (e, index, setValue) => {
    const { name, value } = e?.target;
    const temp = roomData?.room_specifications;
    const totalRooms = [...array];

    if (name === "total_rooms" && temp[index]?.total_booked_rooms) {
      if (value >= temp[index]?.total_booked_rooms && value !== "") {
        temp[index][name] = value;
      } else {
        if (value !== "") {
          toast?.error(
            `Floor ${temp[index]?.floor_title} has already ${temp[index]?.total_booked_rooms} rooms booked so it can only add more rooms.`
          );
          temp[index][name] = totalRooms[index];
        } else {
          temp[index][name] = "";
        }
      }
    } else {
      temp[index][name] = value;
    }
    setRoomData({ ...roomData, room_specifications: [...temp] });
    setValue('room_specifications', [...temp], { shouldValidate: true });
  };

  const handleOnSubmit = () => {

    if(roomData?.images?.length <= 0){
      toast?.error("At least one room image is required.");
      return;
    }

    if (!roomData?.hospital_id) {
      toast?.error("Hospital name is required.");
      return;
    }

    let isEmptySpecification = false;

    roomData?.room_specifications?.map((specification) => {
      if (!specification?.floor_title || !specification?.total_rooms) {
        isEmptySpecification = true;
      }
    });

    if (isEmptySpecification) {
      toast?.error(
        "Room Specification's floor title or rooms must not be empty."
      );
      return;
    }

    if (!roomData?.room_type_id) {
      toast?.error("Room type is required.");
      return;
    }

    if (!roomData?.per_day_fee) {
      toast?.error("Per day fee is required.");
      return;
    }

    if (!roomData?.booking_cancelation_duration) {
      toast?.error("Booking cancelation duration is required.");
      return;
    }

    if (
      !roomData?.room_facility_id ||
      roomData?.room_facility_id?.length === 0
    ) {
      toast?.error("Atleast one room facility is required.");
      return;
    }

    if (!roomData?.description) {
      toast?.error("Room description is required.");
      return;
    }

    setIsLoading(true);
    const formData = new FormData();

    for (const key in roomData) {
      if (key === "room_facility_id") {
        roomData[key]?.forEach((item) => {
          formData.append(`${key}[]`, item);
        });
      } else if (
        key === "images" ||
        key === "remove_images_ids" ||
        key === "remove_room_specification_ids"
      ) {
        roomData[key]?.forEach((item, index) => {
          formData.append(`${key}[${key === "images" ? index : ""}]`, item);
        });
      } else if (key === "room_specifications") {
        roomData[key]?.forEach((specification, index) => {
          Object?.keys(specification)?.map((key) => {
            
            key !== 'total_booked_rooms' && formData.append(
              `room_specifications[${index}][${key}]`,
              specification[key]
            );
            
          });
        });
      } else if (key !== "roomFacilities") {
        !hospitalRoomID
          ? formData?.append(`room_details[0][${key}]`, roomData[key])
          : formData?.append(`${key}`, roomData[key]);
      }
    }

    formData?.append(
      !hospitalRoomID ? "room_details[0][description_ar]" : "description_ar",
      "نبذة عن المستشفى"
    );

    postData(
      hospitalRoomID ? `update_room/${hospitalRoomID}` : "add_room",
      formData,
      (response) => {
        if (response?.success) {
          navigate("/hospitals/hospitalrooms");
          toast?.success(
            `Successfully ${
              hospitalRoomID ? "updated" : "added"
            } hospital room.`
          );
          setIsLoading(false);
        }
        else{
          setIsLoading(false);
        }
      }
    );
  };

  useEffect(() => {
    let tempData = {
      hospitals: [],
      roomTypes: [],
      roomFacilities: [],
    };

    deleteData("get_hospitals", (response) => {
      setRoomState((roomState) => ({
        ...roomState,
        hospitals: response?.data?.map((hospital) => ({
          label: hospital?.name,
          value: hospital?.id,
        })),
      }));
    });

    deleteData("room_types", (response) => {
      setRoomState((roomState) => ({
        ...roomState,
        roomTypes: response?.data?.map((type) => ({
          label: type?.title,
          value: type?.id,
        })),
      }));
    });

    deleteData("room_facilities", (response) => {
      setRoomState((roomState) => ({
        ...roomState,
        roomFacilities: response?.data?.map((facility) => ({
          label: facility?.title,
          value: facility?.id,
          toggle: false,
        })),
      }));

      tempData.roomFacilities = response?.data?.map((facility) => ({
        label: facility?.title,
        value: facility?.id,
        toggle: false,
      }));

      if (hospitalRoomID) {
        deleteData(`get_room_by_id/${hospitalRoomID}`, (response) => {
          setRoomData((roomData) => ({
            ...roomData,
            hospital_id: response?.data?.hospital?.id,
            room_type_id: response?.data?.room_type?.id,
            per_day_fee: response?.data?.per_day_fee,
            booking_cancelation_duration:
              response?.data?.booking_cancelation_duration,
            description: response?.data?.description,
            roomFacilities: response?.data?.room_facilities?.map(
              (facility) => ({
                label: facility?.facility?.title,
                value: facility?.facility?.id,
                toggle: true,
              })
            ),
            room_facility_id: response?.data?.room_facilities?.map(
              (facility) => facility?.room_facility_id
            ),
            images: response?.data?.room_images?.map((image) => ({...image?.image, id: image?.id})),
            room_specifications: response?.data?.room_specifications?.map(
              (specification) => {
                setArray((array) => [...array, specification?.total_rooms]);
                return {
                  id: specification?.id,
                  floor_title: specification?.floor_title,
                  total_rooms: specification?.total_rooms,
                  total_booked_rooms: specification?.total_booked_rooms,
                };
              }
            ),
          }));

          response?.data?.room_facilities?.map((facility) => {
            tempData.roomFacilities[
              facility?.room_facility_id - 1
            ].toggle = true;
          });

          setRoomState((roomState) => ({
            ...roomState,
            roomFacilities: [...tempData.roomFacilities],
          }));

          setIsDataLoading(false);
        });
      }
    });
  }, []);

  useEffect(() => {}, [roomData]);

  useEffect(() => {}, [roomState]);

  return {
    roomFormStates: {
      roomData,
      setRoomData,
      roomState,
      setRoomState,
      isDataLoading,
      isLoading,
      cancelationDurations,
    },

    roomFormFuncs: {
      handleAddRoomSlot,
      handleRemoveRoomSlot,
      handleOnChange,
      handleFloorOnChange,
      handleOnSubmit,
    }

  };
};

export default HospitalRoomHelper;
