import React from 'react'
import AddXray from './AddXray'
import { useParams } from 'react-router-dom'

const EditXray = () => {

  const { id } = useParams();

  return <AddXray Id={id} />
}

export default EditXray;