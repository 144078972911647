import React, { useState } from "react";
import CameraIcon from "../../assets/images/doctor/CameraIcon.svg";
import "./uploadProfile.scss";
import ErrorMessage from "../../formvalidation/errormessage";

function ImageUploader({
  setServiceProviderData,
  serviceProviderData,
  selectedImage,
  setSelectedImage,
  role_Id,
  arLabel,
  setValue,
  name,
  controlName,
  notRequired,
  error,
  watch
}) {
  const [errorMessage, setErrorMessage] = useState("");

  const handleDoctorImageClick = () => {

    console.log('Provider: required', notRequired);
    !notRequired && setValue(controlName || name, watch && watch(controlName || name), {shouldValidate: true});

    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";

    input.onchange = (event) => {
      const file = event.target.files[0];
      setValue && setValue(controlName || name, file, {shouldValidate: true});
      setServiceProviderData({ ...serviceProviderData, [name || 'profile_picture']: file });
    };

    input.click();
  };

  return (
    <>
      <div className="col-md-12 p-0 py-4">
        <div className="d-flex align-items-center doc-cam p-0">
          <div
            className=" d-flex align-items-center justify-content-center add-doc-camera-upload cursor-pointer"
            onClick={handleDoctorImageClick}
          >
            {serviceProviderData?.[name || 'profile_picture'] ? (
              <img
                className="add-doc-camera-upload-1st"
                src={
                  serviceProviderData?.[name || 'profile_picture']?.name
                    ? URL.createObjectURL(serviceProviderData?.[name || 'profile_picture'])
                    : `${process.env.REACT_APP_IMAGE_URL}/${serviceProviderData?.[name || 'profile_picture']}`
                }
                alt="Uploaded image"
              />
            ) : (
              <div className="d-flex text-center justify-content-center w-100">
                <img src={CameraIcon} alt="upload" />
              </div>
            )}
          </div>
          <div className="d-flex">
            <span className="pl-4 ml-2 doc-cam-text">Profile Picture{arLabel && arLabel}</span>
          </div>
        </div>
        <ErrorMessage error={error} />
      </div>
    </>
  );
}

export default ImageUploader;
