import React from 'react'
import RoomsTableListHeader from '../../../molecules/HospitalRoomManagement/RoomsTableListHeader'
import DynamicRoomsDataTable from '../../../molecules/HospitalRoomManagement/DynamicRoomsDataTable'
import ServiceProviderListHelper from '../ServiceProviderList/helper';
import RightArrowSpec from "../../../assets/images/doctor/RightArrowSpec.svg";
import listSelect from '../../../utilities/data/list.select';
import dropdown from '../../../utilities/data/list.dropdown';

const ServiceRequestDataTable = () => {

  const { 
    providersList,
    tempRequestsList,
    serviceRequestTableColumns,
    setListID,
    isLoading,
    paginationData,
    handleChangePage,

    providerStates: { listData },
    filterStates: { filtersData, isSortedItemSelected, hospitals },
    filterFuncs: { handleOnFilterChange, handleSortByAge, handleSortByClick, handleSortIcons, handleChangeListStatus },
    apiHooks: { listStatusApiHook }
  
  } = ServiceProviderListHelper('Service Request List', '', dropdown?.serviceRequestItems);

  const breadCrumbItems = [
    {
      title: 'Home Service Provider'
    },
    {
      title: 'Service Request List',
      href: ''
    }
  ];

  const details = {
    first_name: 'First Name',
    last_name: 'Last Name',
    address: 'Address',
    charges: 'Charges',
    phone: 'Contact',
    service_request: 'Service Name',
    date: 'Request Date',
    service_detail: 'Service Detail'
  }

  const handleRequestDetail = (listID) => {

    if(!listID) return;

    const request = tempRequestsList?.find(request => request?.id === listID);

    const temp = ['id', 'status'];

    if(!request) return;

    return (
      <div className='mb-3 row' style={{width: '100%', height: '215px', overflow: 'auto'}}>
        {Object?.keys(request)?.map((key) => {

          return !temp?.includes(key) && <div className="mb-3 col-6 p-0 pr-1">
            <p className="mb-2 view-doc-special" style={{fontSize: '14px'}}>
              {details[key]}:
            </p>
            <div
              className="d-flex pr-2"
              style={{ alignItems: "baseline" }}
            >
              <img
                className="pr-3 view-doc-sub-special "
                src={RightArrowSpec}
                alt=''
              />
              <span className="view-doc-sub-special" style={{width: '90%', fontSize: '14px'}}>
                {request[key] || '-'}
              </span>
            </div>
          </div>
        
        })}
        
      </div>
    )
  }

  const filtersOrder = [
    {
      type: 'search',
      name: 'title',
      value: filtersData?.title,
      divClass: 'col-4 p-0 pr-1',
      placeholder: 'Search by name',
      onChange: handleOnFilterChange
    },
    {
      type: 'select',
      name: 'service_provider_id',
      value: filtersData?.service_provider_id,
      divClass: 'col-3 pr-1 p-0',
      placeholder: 'Select Service Provider',
      onChange: handleOnFilterChange,
      options: hospitals
    },
    {
      type: 'dropdown',
      value: isSortedItemSelected,
      divClass: 'col-2 p-0 pr-1',
      placeholder: 'Sort By',
      onChange: handleSortByClick,
      options: dropdown?.serviceRequestItems
    },
    {
      type: 'select',
      name: 'status',
      value: filtersData?.status,
      divClass: 'col-3 p-0',
      placeholder: 'Select Status',
      onChange: handleOnFilterChange,
      options: listSelect?.completeStatus
    },
  ];

  return (
    <div className='row pl-4 pr-4 pt-3 roomslistBody'>

      <RoomsTableListHeader
        pageTitle='Service Request List'
        breadCrumbItems={breadCrumbItems}
        filtersOrder={filtersOrder}
      />

      <div className='col-12 p-0 mt-2' style={{overflowX: "hidden" }}>
        <DynamicRoomsDataTable 
          tableColumns={serviceRequestTableColumns} 
          tableRows={providersList} 
          isRowsDataLoading={isLoading} 
          paginationData={paginationData} 
          setListID={setListID} 
          handleChangePage={handleChangePage} 
          statusApiEndPoint={'update_request_service'} 
          handleChangeListStatus={handleChangeListStatus} 
          handleRequestDetail={handleRequestDetail} 
          isCompleted={true} 
          isEdit={true} 
          isDel={true} 
          handleSortByAge={handleSortByAge}
          handleSortIcons={handleSortIcons}
          isStatusLoading={listStatusApiHook?.isLoading}
          statusOptions={listData?.statusOptions}
        />
      </div>
      
    </div>
  )
}

export default ServiceRequestDataTable;