import React, { useEffect, useState } from "react";

import dayjs from "dayjs";
import { DatePicker, Space } from "antd";

//svg
import HospitalIcon from "../../assets/images/dashboard/HospitalIconBlue.svg";
import PulseIcon from "../../assets/images/dashboard/PulseIconBlue.svg";
import PatientIcon from "../../assets/images/dashboard/PatientIconBlue.svg";
import LaboratoryIcon from "../../assets/images/dashboard/LaboratoryBlueIcon.svg";
import PharmacyIcon from "../../assets/images/dashboard/PharmacyIcon.svg";
import BloodIcon from "../../assets/images/dashboard/BloodIcon.svg";
import DashboardBarChart from "./DashboardBarChart";
import DashboardRadialBar from "./DashboardRadialBar";
import Appoinment from "./Appoinment";
import Doctor from "./Doctor";
import { gender, optionHostpital } from "../../Data/DoctorData";
import CustomDropDownMulti from "../../atoms/CustomDropDown/CustomDropDownMulti";

//scss
import "../../assets/css/dashboard.scss";
import PieChart from "../../atoms/PieChart/PieChart";
import { Link } from "react-router-dom";
import axios from "axios";
import usePost from "../../customHook/usePost";
import AnalyticsCards from "./AnalyticsCards";
import { useSelector } from "react-redux";
import useDeleteData from "../../customHook/useDelete";
import moment from "moment";

const DashboardCom = () => {

  const { postData } = usePost();
  const { deleteData } = useDeleteData();

  const user = useSelector((state) => state.auth);
  const isSuperAdmin = user && user?.role == "super admin";
  const [dashboardData, setDashboardData] = useState({
    totalRevenue: 0,
    completedOrders: 0,
    ordersRevenue: 0,
    totalUsers: 0,
    revenueData: [],
    usersData: [],
    revenuePercentage: 0,
    orderRevenuePercentage: 0,
    totalOrdersPercentage: 0,
  });
  const date = new Date();
  const [selectedDate, setSelectedDate] = useState(
    moment(date).format("YYYY-MM-DD")
  );
  const [monthDate, setMonthDate] = useState(null);
  const [yearDate, setYearDate] = useState(null);
  const [isYearSelected, setIsYearSelected] = useState(true);
  const [chartDateRange, setChartDateRange] = useState(
    moment(selectedDate).format("YYYY")
  );
  const [latestAppointments, setLatestAppointments] = useState([]);
  const [latestLabel, setLatestLabel] = useState("Latest Appointments");

  const names = {
    doctor: "Doctors",
    hospitaladmin: "Hospitals",
    pharmacist: "Pharmacies",
    storeadmin: "Medical Equipments",
    technologist: "Laboratories",
    radiologic: "X-rays",
    serviceprovider: "Service Providers",
  };

  const months = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };

  const [userRoles, setUserRoles] = useState(() => {
    return user?.role !== "super admin"
      ? [names[user?.role]]
      : [
          "Doctors",
          "Hospitals",
          "Pharmacies",
          "Medical Equipments",
          "Laboratories",
          "X-ray",
          "Service Providers",
        ];
  });

  const superAdminRevenueName = {
    doctor: "Doctors",
    hospital: "Hospitals",
    pharmacy: "Pharmacies",
    equipment_store: "Medical Equipments",
    laboratory: "Laboratories",
    xray_lab: "X-rays",
    service_provider: "Service Providers",
  };

  useEffect(() => {
    (async () => {
      if (
        isSuperAdmin
      ) {
        // Total Revenue for Super Admin
        postData(
          "all_revenue",
          {
            date: selectedDate,
            ...(isYearSelected ? { year: true } : { month: true }),
          },
          (response) => {
            let totalRevenue = 0;
            let temp = [];

            Object.keys(response?.revenue)?.map((role) => {
              let roleRevenue = Object.values(response?.revenue[role]).reduce(
                (accumulator, currentValue) =>
                  accumulator + parseFloat(currentValue),
                0
              );
              totalRevenue += roleRevenue;
              temp.push({
                range: chartDateRange,
                revenue: roleRevenue,
                role: superAdminRevenueName[role],
              });
            });

            setDashboardData((dashboardData) => ({
              ...dashboardData,
              totalRevenue: totalRevenue,
              completedOrders: response?.totalOrders,
              ordersRevenue: response?.orderRevenue,
              revenueData: [...temp],
              revenuePercentage: response?.revenuePercentage,
              totalOrdersPercentage: response?.totalOrdersPercentage,
              orderRevenuePercentage: response?.orderRevenuePercentage,
            }));

            // All Users for Super Admin

            deleteData("total_users", (response) => {
              setDashboardData((dashboardData) => ({
                ...dashboardData,
                totalUsers: response?.data?.reduce(
                  (accumulator, currentValue) =>
                    accumulator + currentValue?.value,
                  0
                ),
              }));
            });

            // Admin Users for Super Admin

            temp = [];

            postData(
              "total_admin_users",
              {
                // date: selectedDate,
                // ...(isYearSelected ? { year: true } : { month: true })
              },
              (response) => {
                if (response?.success === true) {
                  response?.data?.map((role) => {
                    temp?.push({
                      type: names[role?.type === 'service Provider' ? 'serviceprovider' : role?.type],
                      value: role?.value,
                    });
                  });

                  setDashboardData((dashboardData) => ({
                    ...dashboardData,
                    usersData: [...temp],
                  }));
                }
              }
            );

            // Latest Appointments for Super Admin

            deleteData("latest_appointments", (response) => {
              setLatestAppointments(response?.data);
            });
          }
        );
      } else if (user?.role == "pharmacist") {
        fetchPharmacistData(
          "revenue_by_pharmacy",
          "pharmacy_latest_order",
          "revenue_data",
          "is_pharmacy",
          "Pharmacies",
          "total_orders",
          "pharmacy_id",
          1
        );
      } else if (user?.role == "storeadmin") {
        fetchPharmacistData(
          "revenue_by_equipment_store",
          "pharmacy_latest_order",
          "revenue_data",
          "is_pharmacy",
          "Medical Equipments",
          "total_orders",
          "equipment_store_id",
          0
        );
      } else if (user?.role == "technologist") {
        fetchPharmacistData(
          "revenue_by_laboratory",
          "latest_test",
          "revenue_data",
          "is_laboratory",
          "Laboratories",
          "total_test_orders",
          "laboratory_id",
          1
        );
      } else if (user?.role == "radiologic") {
        fetchPharmacistData(
          "revenue_by_xray_lab",
          "latest_test",
          "revenue",
          "is_laboratory",
          "X-rays",
          "total_test_orders",
          "xray_lab_id",
          0
        );
      } else if (user?.role == "doctor") {
        fetchPharmacistData(
          "revenue_by_doctor",
          "latest_booking_appointments",
          "revenue_data",
          "",
          "Doctors",
          "total_doctor_appointments",
          "id",
          ""
        );
      } 
      else if (user?.role == "hospitaladmin") {
        fetchPharmacistData(
          "revenue_by_hospital",
          "latest_booked_room",
          "revenue_data",
          "",
          "Hospitals",
          "total_hospital_orders",
          "hospital_id",
          ""
        );
      }
       else if (user?.role == "serviceprovider") {
        fetchPharmacistData(
          "revenue_by_service_provider",
          "latest_request_services",
          "revenue_data",
          "",
          "Service Providers",
          "total_service_request",
          "service_provider_id",
          ""
        );
      }
    })();
  }, [selectedDate, isYearSelected]);

  const fetchPharmacistData = (
    totalRevenueEndPoint,
    latestOrdersEndPoint,
    totalRevenueState,
    isItemLabel,
    role,
    completedOrdersEndPoint,
    itemName,
    isItem
  ) => {
    let pharmacistData = {
      ordersRevenue: 0,
      totalRevenue: 0,
      revenueData: [],
      completedOrders: 0,
    };

    const filtersPayload = {
      date: selectedDate,
      ...(isYearSelected ? { year: true } : { month: true }),
    };

    postData(
      `${totalRevenueEndPoint}/${user?.item}`,
      filtersPayload,
      (totalRevenueResponse) => {
        if (totalRevenueResponse?.success === true) {
          Object.keys(totalRevenueResponse?.[totalRevenueState]).map(
            (key, index) => {
              pharmacistData.totalRevenue += parseInt(
                totalRevenueResponse?.[totalRevenueState][key]
              );
              pharmacistData.revenueData.push({
                range: isYearSelected ? months[key] : `Week-${index + 1}`,
                role: role,
                revenue: parseInt(
                  totalRevenueResponse?.[totalRevenueState][key]
                ),
              });
            }
          );
          pharmacistData.ordersRevenue = totalRevenueResponse?.orderRevenue;
          pharmacistData.revenuePercentage =
            totalRevenueResponse?.totalRevenuePercentage;
          pharmacistData.orderRevenuePercentage =
            totalRevenueResponse?.orderRevenuePercentage;
        }
      }
    );

    const formData = new FormData();

    formData.append("date", selectedDate);
    formData.append(isYearSelected ? "year" : "month", true);
    formData.append("id", user?.item);

    postData(
      completedOrdersEndPoint,
      role !== "Doctors"
        ? {
            ...filtersPayload,
            ...(role !== "Doctors" && { [isItemLabel]: isItem }),
            [itemName]: user?.item,
          }
        : formData,
      (ordersResponse) => {
        if (ordersResponse?.success === true) {
          pharmacistData.completedOrders =
            role == "Doctors"
              ? ordersResponse?.data
              : ordersResponse?.total_order;
          pharmacistData.totalOrdersPercentage =
            role == "Doctors"
              ? ordersResponse?.totalAppointmentsPercentage
              : role == "Laboratories" || role == "X-rays"
              ? ordersResponse?.total_test_order_percentage
              : ordersResponse?.total_order_percentage;
        }
      }
    );

    postData(
      latestOrdersEndPoint,
      {
        ...(role !== "Doctors" && { [isItemLabel]: isItem }),
        ...(role == "Doctors"
          ? { doctor_id: user?.item }
          : { [itemName]: user?.item }),
      },
      (latestOrdersResponse) => {
        if (latestOrdersResponse?.success === true) {
          let tempLatestOrders = [];

          if(role === "Hospitals"){
            tempLatestOrders = latestOrdersResponse?.latest_booking?.map((booking) => (
              {
                link: booking?.user?.profile_pic || "",
                hospital: 'Floor ' + booking?.room?.room_specifications?.floor_title,
                doctor: `Room at floor ${booking?.room?.room_specifications?.floor_title}`,
                patient: booking?.user?.name,
                date: booking?.total_amount + ' KWD',
                start_time: booking?.start_date,
                end_time: booking?.end_date
              }
            ))
            setLatestLabel("Latest Booked Rooms");
          } else if(role === "Service Providers"){
              tempLatestOrders = latestOrdersResponse?.latest_order?.map((servicerequest) => (
                {
                  link: `${servicerequest?.user?.profile_pic}` || "",
                  hospital: servicerequest?.services?.provider_name,
                  doctor: servicerequest?.services?.provider_name,
                  patient: servicerequest?.first_name + ' ' + servicerequest?.last_name,
                  date: servicerequest?.date,
                  start_time: `${servicerequest?.charges} KWD`
                }
              ))
              setLatestLabel("Latest Service Requests");
            }
          else if (role !== "Doctors") {
            latestOrdersResponse?.latest_order?.map((order) => {
              if (order?.order_items?.length > 0) {
                if (role == "Pharmacies" || role == "Medical Equipments") {
                  let count = 0;

                  order?.order_items?.map((order_item) => {
                    count += order_item?.quantity;
                  });

                  tempLatestOrders.push({
                    link:
                      order?.order_items[0]?.product?.images?.length > 0
                        ? "/" + order?.order_items[0]?.product?.images[0]?.image
                        : "",
                    hospital: order?.order_items[0]?.product?.title,
                    doctor:
                      count > 1
                        ? `With ${count - 1} more product`
                        : order?.order_items[0]?.product?.title,
                    patient: order?.user?.name,
                    date: order?.date,
                    start_time: `${order?.total_amount} KWD`,
                  });
                  setLatestLabel("Latest Product Orders");
                } else {
                  tempLatestOrders.push({
                    link: order?.user?.profile_pic || "",
                    hospital: order?.order_items[0]?.bloodtest?.title,
                    doctor:
                      order?.order_items?.length > 1
                        ? `With ${order?.order_items?.length - 1} more test`
                        : order?.order_items[0]?.bloodtest?.title,
                    patient: order?.user?.name,
                    date: order?.date,
                    start_time: `${order?.total_amount} KWD`,
                  });
                  setLatestLabel("Latest Test Orders");
                }
              }
            });
          } else {
            latestOrdersResponse?.latest_appointment?.map((appointment) => {
              tempLatestOrders.push({
                link: appointment?.hospital?.profile_picture || "",
                hospital: appointment?.hospital?.name,
                doctor: appointment?.doctor?.user?.name,
                patient: appointment?.user?.name,
                date: appointment?.date,
                start_time: moment(appointment?.doctor_availability_slots?.start_time, 'HH:mm').format('HH:mm'),
                end_time: moment(appointment?.doctor_availability_slots?.end_time, 'HH:mm').format('HH:mm'),
              });
            });
            setLatestLabel("Latest Doctor Appointments");
          }

          setLatestAppointments([...tempLatestOrders]);
        }
      }
    );

    setDashboardData(pharmacistData);
  };

  const handleMonthChange = (date) => {
    if (date) {
      const formattedDate = moment(date?.$d).format("YYYY-MM");
      setChartDateRange(moment(date?.$d).format("MMMM"));
      setSelectedDate(moment(date?.$d).format("YYYY-MM-DD"));
      setMonthDate(date);
      setIsYearSelected(false);
    } else {
      setChartDateRange(moment().format("YYYY"));
      setSelectedDate(moment().format("YYYY-MM-DD"));
      setMonthDate(null);
      setIsYearSelected(true);
    }

    setYearDate(null);
  };

  const handleYearChange = (date) => {
    if (date) {
      const formattedDate = moment(date?.$d).format("YYYY");
      setChartDateRange(formattedDate);
      setSelectedDate(moment(date?.$d).format("YYYY-MM-DD"));
      setYearDate(date);
    } else {
      setChartDateRange(moment().format("YYYY"));
      setSelectedDate(moment().format("YYYY-MM-DD"));
      setYearDate(null);
    }

    setIsYearSelected(true);
    setMonthDate(null);
  };

  return (
    <>
      <div className="row  px-2 pt-4" style={{ overflowX: "hidden" }}>
        <div className="col-12 mb-3 mb-lg-0">
          <p className="mb-0 dashboard-com-top-text">Dashboard</p>
        </div>

        <div className="col-12 mt-1">
          <div className="row px-3 space-x-2">

            <AnalyticsCards
              text={"Total Revenue"}
              progress={dashboardData?.revenuePercentage}
              total={dashboardData?.totalRevenue}
              role={user?.role}
            />

            <AnalyticsCards
              text={"Completed Orders"}
              progress={dashboardData?.totalOrdersPercentage}
              total={dashboardData?.completedOrders}
              role={user?.role}
            />

            {user?.role !== "doctor" && (
              <AnalyticsCards
                text={"Orders Revenue"}
                progress={dashboardData?.orderRevenuePercentage}
                total={dashboardData?.ordersRevenue}
                role={user?.role}
              />
            )}

            {(isSuperAdmin) && (
              <AnalyticsCards
                text={"App Users"}
                total={dashboardData?.totalUsers}
                role={user?.role}
              />
            )}

          </div>
        </div>

        <div className="col-12 mt-lg-4 mt-2 pt-lg-2 ">
          <div className="row ">
            <div
              className={` ${
                isSuperAdmin
                  ? "col-lg-8"
                  : "col-lg-12"
              }`}
            >
              <div className=" bar-chart pb-4 ">
                <div className="d-flex justify-content-between align-items-center px-4 mx-2 py-4">
                  <span class="mb-0 bar-chart-text1 ">Revenue by entity</span>
                  <div
                    className="d-flex dashboardDateRange"
                    style={{ width: "275px", gap: "10px" }}
                  >
                    <DatePicker
                      value={monthDate}
                      placeholder="Month"
                      onChange={handleMonthChange}
                      picker="month"
                    />
                    <DatePicker
                      value={yearDate}
                      placeholder="Year"
                      onChange={handleYearChange}
                      picker="year"
                    />

                  </div>
                </div>

                <div className="bar-chart-padding">
                  <div className="pt-3">
                    <DashboardBarChart
                      userRoles={userRoles}
                      BarChartData={dashboardData?.revenueData}
                    />
                  </div>
                </div>

                <p class="bar-chart-text2">Revenue</p>
              </div>
            </div>

            {(isSuperAdmin) && (
              <div className="col-lg-4 mt-lg-0 mt-4">
                <div className="Radial-bar-border  pt-2">
                  <p class="mb-0 bar-chart-text1 pt-3 pb-4 d-flex justify-content-between align-items-center px-3">
                    <div className="d-flex justify-content-between w-100 flex-wrap align-items-center">
                      <div>Admin Users</div>
                      <div
                        className=" "
                        style={{ width: "210px", borderRadius: "5px" }}
                      >
                      </div>
                    </div>
                
                  </p>

                  <div
                    className="d-flex justify-content-center align-items-center "
                    style={{ minHeight: "85%" }}
                  >
                    <div className="mx-3" style={{ width: "100%" }}>
                      <PieChart
                        userRoles={userRoles}
                        PieChartData={dashboardData?.usersData}
                      />
                    </div>
                  </div>

                  <div></div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="col-12 my-lg-5 pt-4 pb-lg-5">
          <div className="row pb-lg-5">
            <div className="col-lg-6 pb-lg-5 mt-lg-0 mt-0 dashboardCom-scroller-appoinment ">
              <div className="appoinment pb-lg-4 ">
                <Appoinment
                  latestAppointments={latestAppointments}
                  latestLabel={latestLabel}
                />
              </div>
            </div>

            {(isSuperAdmin) && (
              <div className="col-lg-6 pb-lg-5 mt-lg-0 mt-4 mb-lg-0 mb-5 pb-lg-5 pb-5 dashboardCom-scroller-doctor">
                <div className="doctor ">
                  <Doctor />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardCom;
