import React, { useState } from "react";

import IncreIcon from "../../assets/images/doctor/IncreIcon.svg";
import DcreIcon from "../../assets/images/doctor/DcreIcon.svg";

const IncreDecreBtn = ({ setFormDataState, formDataState, setValue }) => {

  const handleIncrease = () => {

    const years = formDataState?.experience_years ?? 0;

    setFormDataState({
      ...formDataState,
      experience_years: years + 1,
    });
    setValue('experience_years', years + 1, { shouldValidate: true });
    
  };

  const handleDecrease = () => {

    const years = formDataState?.experience_years ?? 0;

    if (years > 0) {
      setFormDataState({
        ...formDataState,
        experience_years: years - 1,
      });
      setValue('experience_years', years - 1, { shouldValidate: true });
    }

  };

  return (
    <>
      <div className="d-flex doc-setting-input-counter">
        <button
          onClick={handleDecrease}
          type="button"
          className="doc-setting-incre-decre-btn border-right d-flex align-items-center px-2"
        >
          {" "}
          <img src={DcreIcon} alt="DcreIcon" />{" "}
        </button>
        <input
          className="doc-setting-input-count text-center"
          type="text"
          disabled
          value={formDataState?.experience_years ?? 0}
        />
        <button
          onClick={handleIncrease}
          type="button"
          className="doc-setting-incre-decre-btn border-left d-flex align-items-center px-2"
        >
          {" "}
          <img src={IncreIcon} alt="IncreIcon" />{" "}
        </button>
      </div>
    </>
  );
};

export default IncreDecreBtn;
