import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Modal,
  Rate,
  Select,
  Slider,
  DatePicker,
  TimePicker,
} from "antd";
import { Controller, useForm } from "react-hook-form";

// img svg
import RightArrow from "../../assets/images/doctor/RightArrow.svg";
import CalenderIcon from "../../assets/images/doctor/CalenderIcon.svg";
import ClockIcon from "../../assets/images/doctor/ClockIcon.svg";

import CustomDropDown from "../../atoms/CustomDropDown/Index";
import CustomDropDownMulti from "../../atoms/CustomDropDown/CustomDropDownMulti";
import Time from "../../atoms/Time/Time";
import UploadFile from "../../molecules/UploadFile/UploadFile";
import { useState } from "react";
import { useEffect } from "react";
import usePost from "../../customHook/usePost";
import { CustomToast } from "../../atoms/toastMessage";
import ButtonLoader from "../../atoms/buttonLoader";
import useDeleteData from "../../customHook/useDelete";
import ArabicInput from "../../molecules/ArabicInput";
import { useTranslation } from "react-i18next";
import ArabicTextArea from "../../molecules/ArabicTextArea/ArabicTextArea";
import SpinnerLoaderModal from "../../components/common/spinnerloadermodal";
import BreadCrum from "../../atoms/breadcrum/BreadCrum";
import { yupResolver } from "@hookform/resolvers/yup";
import formschemas from "../../formvalidation/formschemas";
import ErrorMessage from "../../formvalidation/errormessage";
import DateTimeHelper from "../../formvalidation/DateTimeHelper";
// import CertificateInput from "../../molecules/CertificateInput/CertificateInput.jsx";
import dayjs from "dayjs";

const AddBannerPromo = ({ Id }) => {
  const [errorData, setErrorData] = useState({});
  const [bannerData, setBannerData] = useState({
    is_mobile_banner: 1
  });
  const AddBanner = usePost();
  const navigate = useNavigate();
  const customData = useDeleteData();
  const [fileUrl, setFileUrl] = useState("");
  //   const [SelectedFiles, setSelectedFiles] = useState([]);
  //   const [BannerCertificateSelected, setBannerCertificateSelected] = useState([]);
  const { t } = useTranslation();

  const {
    currentDate,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    disabledStartDate,
    disabledEndDate,
    handleDisableTime
  } = DateTimeHelper();

  const {
    handleSubmit,
    setValue,
    watch,
    trigger,
    control,
    formState: { errors }
  } = useForm({
    mode: 'all',
    resolver: yupResolver(formschemas?.bannerSchema)
  });

  useEffect(() => {

    setValue('start_date', startDate?.dateText);
    setValue('start_time', startDate?.dateObj?.format('HH:mm'));
    setValue('end_date', endDate?.dateText);
    setValue('end_time', endDate?.dateObj?.format('HH:mm'));

    setBannerData(bannerData => ({
      ...bannerData,
      start_date: startDate?.dateText,
      start_time: startDate?.dateObj?.format('HH:mm'),
      end_date: endDate?.dateText,
      end_time: endDate?.dateObj?.format('HH:mm')
    }))

  },[])

  useEffect(() => {
    setValue("placement_id", null);
    setValue('is_mobile_banner', 1);
  }, []);

  useEffect(() => {
    if (Id) {
      customData.deleteData(
        `${process.env.REACT_APP_LIST_BANNER_BY_ID}/${Id}`,
        (val) => {
          setBannerData({
            ...val?.data,
          });
          Object.entries(val?.data).forEach(([fieldName, fieldValue]) => {
            setValue(fieldName, fieldValue);
          });
          const fileName = val?.data?.image;
          setFileUrl(val?.data?.image);
          fetchPlacementList(val?.data?.placement_id);

          if(val?.data?.link?.startsWith(`https://marketing.maweidi.com.kw/${placementLinks[val?.data?.placement_id]?.name}?id=`)){
            const urlObject = new URL(val?.data?.link);
            setValue('placement_list_id', parseInt(urlObject?.searchParams?.get('id')));
            setBannerData(bannerData => ({...bannerData, placement_list_id: parseInt(urlObject?.searchParams?.get('id'))}));
          }
          
        }
      );
    }
  }, [Id]);

  const handleBannerSubmit = () => {

      const updateRoleData = {
        ...bannerData,
      };
      const formData = new FormData();
      for (const key in updateRoleData) {
        if (Array.isArray(updateRoleData[key])) {
          updateRoleData[key].forEach((value) => {
            formData.append(`${key}[]`, value);
          });
        } else {
          formData.append(key, updateRoleData[key]);
        }
      }

      AddBanner?.postData(
        Id
          ? `${process.env.REACT_APP_UPDATE_BANNER}/${Id}`
          : `${process.env.REACT_APP_ADD_BANNER}`,
        formData,
        (response) => {
          // navigate("/banner-promo");
          // alert('sdf')
          if (response?.success === true) {
            navigate("/banner-promo");
            CustomToast({
              type: "success",
              message: response?.response,
            });
          }
        }
      );
  };

  const breadCrumbItems = [
    {
      title: 'Banner'
    },
    {
      title: Id ? 'Edit Banner' : 'Add Banner',
      href: ''
    }
  ];

  const placementLinks = {
    1: { name: 'laboratory', endPoint: 'list_laboratories?is_laboratory=1'},
    2: { name: 'pharmacy', endPoint: 'list_pharmacies?status=1'},
    3: { name: 'xray', endPoint: 'list_laboratories?is_laboratory=0'},
    4: { name: 'equipment', endPoint: 'list_pharmacies?status=0'},
    5: { name: 'service-provider', endPoint: 'list_of_service_provider'},
    6: { name: 'hospitals', endPoint: 'get_hospitals'},
    7: { name: 'doctors', endPoint: 'get_doctors'}
  };

  const { deleteData } = useDeleteData();
  const [placementList, setPlacementList] = useState([]);

  const fetchPlacementList = (placementId) => {
    
    deleteData(placementLinks[placementId]?.endPoint, (response) => {
      setPlacementList((placementId === 5 ? response?.data?.service_providers : response?.data)?.map((item) => ({
        label: placementId === 7 ? item?.user?.name :  item?.name,
        value: item?.id
      })))
    })

  }

  return (
    <div className="row  px-2 pt-4 mb-5 pb-5">

      {Id && <SpinnerLoaderModal isDataLoading={customData?.isLoading} />}

      <div className="col-12  ">
        <p className="mb-0 dashboard-com-top-text">{Id ? 'Edit Banner' : 'Add Banner'}</p>
        {/* Banner tab and promo tab are different in sidebar, crud also different,
                extra add promo location in promo tab, not in banner. ( category by-default payload ) */}
      </div>
      <div className="col-12 mt-lg-0 mt-2 pt-2">
        <BreadCrum breadCrumbItems={breadCrumbItems} />
      </div>
      <div className="col-12 mt-4 ">
        <div
          className="mt-3 pt-2"
          style={{
            backgroundColor: "white",
            borderRadius: "5px",
            border: "1px solid #e4e3e4",
          }}
        >
          <div className="row px-3 mt-4">
            <div className="col-lg-6 doc-setting-input">
              <p className=" doc-add-filter-text">
                Title<span className="text-danger">*</span>
              </p>

              {/* <input type="text" /> */}
              <Controller
                name="title"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <>
                    <input
                      type="text"
                      name="title"
                      {...field}
                      value={field.value || ""}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setBannerData((pre) => ({
                          ...pre,
                          title: e.target.value,
                        }));
                      }}
                    />

                    <ErrorMessage error={errors?.title?.message} />
                  </>
                )}
              />
            </div>

            <ArabicInput
              divClass="doc-setting-input col-6"
              label={t("Banner.Title")}
              labelClass="doc-add-filter-text mb-3"
              formStateData={bannerData}
              setFormStateData={setBannerData}
              name="title_ar"
            />
          </div>

          <div className="row px-3 mt-4">
            
                    <CustomDropDown
                      label={'Banner Placement'}
                      arLabel={t('Banner.BannerPlacement')}
                      name={'placement_id'}
                      labelClass='mb-3'
                      dropDownDivClass={'col-lg-6 pr-lg-0 doc-setting-input'}
                      handleChangeSelect={(value, name) => {
                        setBannerData((pre) => ({
                          ...pre,
                          placement_id: value,
                        }));

                        setPlacementList([]);
                        setValue('placement_list_id', null);
                        setValue('link', '')
                        setBannerData((pre) => ({ ...pre, link: '', placement_list_id: null }));
                        fetchPlacementList(value);
                      }}
                      option={[
                        {
                          label: "Laboratory List​",
                          value: 1,
                        },
                        {
                          label: "Pharmacy List​",
                          value: 2,
                        },
                        {
                          label: "X-ray List​",
                          value: 3,
                        },
                        {
                          label: "Medical Equipment List​",
                          value: 4,
                        },
                        {
                          label: "Services List​",
                          value: 5,
                        },
                        {
                          label: "Hospital List​",
                          value: 6,
                        },
                        {
                          label: "Doctor List​",
                          value: 7,
                        }
                      ]}
                      value={bannerData?.placement_id || null}
                      allowClear={false}
                      required={true}
                      isController={true}
                      control={control}
                      error={errors?.placement_id?.message}
                    />

              <Controller
                name="placement_list_id"
                control={control}
                render={({ field }) => (
                  <>
                    <CustomDropDown
                      label={'Placement List'}
                      arLabel={t('Banner.PlacementList')}
                      dropDownDivClass={'col-lg-6 pt-lg-0 pt-4 doc-setting-input'}
                      labelClass={'mb-3'}
                      handleChangeSelect={(value, name) => {
                        field.onChange(value);
                        const tempLink = `https://marketing.maweidi.com.kw/${placementLinks[bannerData?.placement_id]?.name}?id=${value}`; 
                        setValue('link', tempLink, { shouldValidate: true })
                        setBannerData((pre) => ({ ...pre, link: tempLink, placement_list_id: value }));
                      }}
                      option={placementList}
                      field={field}
                      value={field.value || ""}
                      disabled={placementList?.length === 0}
                      onBlur={field.onBlur}
                      allowClear={false}
                      required={false}
                    />

                  </>
                )}
              />

          </div>
          <div className="row px-3 mt-4">
            <div className="col-lg-6 pt-lg-0 pt-4 doc-setting-input pr-0">

              <div className="d-flex justify-content-between">
                <p className=" doc-add-filter-text ">
                  Start Date<span className="text-danger">*</span>
                </p>
                <p className="mb-2">{`${t("Common.StartDate")}`}</p>
              </div>

              <div className="border" style={{ borderRadius: "5px" }}>
                <Controller
                  name="start_date"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <DatePicker
                      placeholder={"Start Date"}
                      format="YYYY/MM/DD"
                      style={{
                        border: "none",
                        width: "100%",
                        height: "36.5px",
                      }}
                      onChange={(dateObj, dateString) => {
                        field.onChange(dateObj);
                        setBannerData((pre) => ({
                          ...pre,
                          start_date: dateString,
                          end_date: null,
                          start_time: null,
                          end_time: null
                        }));
                        setStartDate({ 
                          dateObj: dateString === currentDate?.dateText ? dayjs(`${dateString} ${dayjs().format('HH:mm:ss')}`, 'YYYY/MM/DD HH:mm:ss') : dateObj,
                          dateText: dateString
                        });
                        setEndDate({ 
                          dateObj: dayjs(`${dateString} ${dayjs().format('HH:mm:ss')}`, 'YYYY/MM/DD HH:mm:ss'), 
                          dateText: dateString
                        });
                        setValue('end_date', null);
                        setValue('start_time', null);
                        setValue('end_time', null);
                        trigger(['start_time', 'end_date', 'end_time']);
                      }}
                      field={field}
                      value={field?.value ? dayjs(field?.value) : null }
                      onBlur={field.onBlur}
                      disabledDate={disabledStartDate}
                    />
                  )}
                />
              </div>
              <ErrorMessage error={errors?.start_date?.message} />
            </div>

            <div className="col-lg-6 pt-lg-0 pt-4 doc-setting-input ">

              <div className="d-flex justify-content-between">
                <p className=" doc-add-filter-text">
                  Start Time<span className="text-danger">*</span>
                </p>
                <p className="mb-2">{`${t("Common.StartTime")}`}</p>
              </div>

              <div
                className="banner-time-border d-flex justify-content-between"
                style={{ backgroundColor: "white" }}
              >
                <Controller
                  name="start_time"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <TimePicker
                      format="HH:mm"
                      placeholder={"Start Time"}
                      style={{
                        border: "1px solid #e4e3e4",
                        height: "36px",
                        width: "100%",
                      }}
                      onChange={(timeObj, timeString) => {
                        field.onChange(timeObj);
                        setBannerData((pre) => ({
                          ...pre,
                          start_time: timeString,
                        }));
                        setStartDate({...startDate, dateObj: timeObj});

                        if(startDate?.dateText === endDate?.dateText){
                          setValue('end_time', null);
                          trigger(['end_time']);
                          setBannerData(bannerData => ({...bannerData, end_time: null}));
                        }

                      }}
                      field={field}
                      value={field?.value ? dayjs(field?.value, 'HH:mm') : null}
                      onBlur={field.onBlur}
                      disabledTime={(time) => handleDisableTime(time, 'start')}
                    />
                  )}
                />
              </div>
              <ErrorMessage error={errors?.start_time?.message} />
            </div>

          </div>

          <div className="row px-3 mt-4">

            <div className="col-lg-6 pt-lg-0 pt-4 doc-setting-input pr-0">

              <div className="d-flex justify-content-between">
                <p className=" doc-add-filter-text ">
                  End Date<span className="text-danger">*</span>
                </p>
                <p className="mb-2">{`${t("Common.EndDate")}`}</p>
              </div>

              <div className="border" style={{ borderRadius: "5px" }}>
                <Controller
                  name="end_date"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <DatePicker
                      format="YYYY/MM/DD"
                      placeholder={"End Date"}
                      style={{
                        border: "none",
                        width: "100%",
                        height: "36.5px",
                      }}
                      onChange={(dateObj, dateString) => {
                        field.onChange(dateObj);
                        setBannerData((pre) => ({
                          ...pre,
                          end_date: dateString,
                          end_time: null
                        }));
                        setEndDate({ 
                          dateObj: dayjs(`${dateString} ${dayjs().format('HH:mm:ss')}`, 'YYYY/MM/DD HH:mm:ss'), 
                          dateText: dateString
                        });
                        setValue('end_time', null);
                        trigger('end_time');
                      }}
                      field={field}
                      value={field?.value ? dayjs(field?.value) : null}
                      onBlur={field.onBlur}
                      disabledDate={disabledEndDate}
                    />
                  )}
                />
              </div>
              <ErrorMessage error={errors?.end_date?.message} />
            </div>

            <div className="col-lg-6 pt-lg-0 pt-4  doc-setting-input">

              <div className="d-flex justify-content-between">
                <p className=" doc-add-filter-text">
                  End Time<span className="text-danger">*</span>
                </p>
                <p className="mb-2">{`${t("Common.EndTime")}`}</p>
              </div>

              <div
                className="banner-time-border d-flex justify-content-between"
                style={{ backgroundColor: "white" }}
              >
                <Controller
                  name="end_time"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <TimePicker
                      format="HH:mm"
                      placeholder={"End Time"}
                      style={{
                        border: "1px solid #e4e3e4",
                        height: "36px",
                        width: "100%",
                      }}
                      onChange={(timeObj, timeString) => {
                        field.onChange(timeObj);
                        setBannerData((pre) => ({
                          ...pre,
                          end_time: timeString,
                        }));
                      }}
                      field={field}
                      value={field?.value ? dayjs(field?.value, 'HH:mm') : null}
                      onBlur={field.onBlur}
                      disabledTime={(time) => handleDisableTime(time, 'end')}
                    />
                  )}
                />
              </div>
              <ErrorMessage error={errors?.end_time?.message} />
            </div>
          </div>

          <div className="row px-3 mt-4">

            <div className="col-lg-6 pr-0 pt-lg-0 pt-4 doc-setting-input">

              <div className="d-flex justify-content-between" style={{paddingTop: '13px'}}>
                <p className=" doc-add-filter-text ">
                  Banner Display<span className="text-danger">*</span>
                </p>
                <p className="">{`${t("Banner.BannerDisplay")}`}</p>
              </div>

              <Controller
                name="is_mobile_banner"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <>
                    <CustomDropDown
                      handleChangeSelect={(value, name) => {
                        field.onChange(value);
                        setBannerData((pre) => ({ ...pre, is_mobile_banner: value, image: null }));
                        setValue && setValue('image', null, { shouldValidate: true });
                      }}
                      option={[
                        {
                          label: "Mobile App",
                          value: 1,
                        },
                        {
                          label: "Marketing Site",
                          value: 2,
                        }
                      ]}
                      field={field}
                      value={field.value || ""}
                      onBlur={field.onBlur}
                      allowClear={false}
                    />

                    <ErrorMessage error={errors?.is_mobile_banner?.message} />
                  </>
                )}
              />
            </div>

            <div className="col-lg-6 pt-lg-0 pt-4 doc-setting-input">

              <div className="d-flex justify-content-between">
                <p className=" doc-add-filter-text mb-0">
                  Upload Image<span className="text-danger">*</span>
                </p>
                <p className="mb-0">{`${t("Common.UploadImage")}`}</p>
              </div>
              
              <p className="mb-0 my-1" style={{fontSize: '11px', fontWeight: '500', color: 'rgb(186, 185, 185)'}}>
              {bannerData?.is_mobile_banner === 1 ? 'Banner image size for mobile app must be in a range of 600 x 250 to 800 x 360.' 
                : 'Banner image size for marketing website must be in a range of 1300 x 200 to 1450 x 250.'}
              </p>
            
              <UploadFile
                formDataState={bannerData}
                setFormDataState={setBannerData}
                fileUrl={fileUrl}
                setErrorData={setErrorData}
                isBanner={true}
                isBannerDisplay={bannerData?.is_mobile_banner}
                setValue={setValue}
                name='image'
              />
              <ErrorMessage error={errors?.image?.message} />
            </div>
          </div>

          <div className="row mt-4 px-3">
            <div className="col-lg-6 pr-0 doc-setting-input">

              <div className="d-flex justify-content-between">
                <p className=" doc-add-filter-text">
                  Link<span className="text-danger">*</span>
                </p>
                <p className="mb-2">{`${t("Common.Link")}`}</p>
              </div>

              <Controller
                name="link"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <>
                    <input
                      type="text"
                      name="link"
                      {...field}
                      value={field.value || ""}
                      disabled={bannerData?.placement_list_id && true}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setBannerData((pre) => ({
                          ...pre,
                          link: e.target.value,
                        }));
                      }}
                    />

                    <ErrorMessage error={errors?.link?.message} />
                  </>
                )}
              />
            </div>

            <CustomDropDown
              label={'Status'}
              arLabel={t('Common.Status')}
              dropDownDivClass={'col-lg-6 pt-lg-0 pt-4 doc-setting-input'}
              labelClass={'mb-3'}
              handleChangeSelect={(value, name) => {
                setBannerData((pre) => ({ ...pre, status: value }));
              }}
              option={[
                {
                  label: "Enable",
                  value: 1,
                },
                {
                  label: "Disable",
                  value: 2,
                }
              ]}
              name="status"
              value={bannerData?.status || ""}
              required={true}
              isController={true}
              control={control}
              error={errors?.status?.message}
            />
          </div>

          <div className="row px-3 mt-4">
            <div className="col-lg-6 pr-lg-0 doc-setting-input">
              <p className=" doc-add-filter-text">
                Description<span className="text-danger">*</span>{" "}
              </p>

              {/* <textarea id="w3review" name="w3review" rows="4" style={{ width: "98%" }} /> */}
              <Controller
                name="description"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <>
                    <textarea
                      type="text"
                      name="description"
                      style={{ height: "138px", paddingTop: "10px" }}
                      {...field}
                      value={field.value || ""}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        // setNameData(e.target.value);
                        setBannerData((pre) => ({
                          ...pre,
                          description: e.target.value,
                        }));
                        // handleChangeHospital(e);
                      }}
                    />

                    <ErrorMessage error={errors?.description?.message} />
                  </>
                )}
              />
            </div>

            <ArabicTextArea
              divClass="doc-setting-input col-6"
              label={t("Banner.Description")}
              labelClass="doc-add-filter-text mb-3"
              formStateData={bannerData}
              setFormStateData={setBannerData}
              name="description_ar"
            />

            <div className="col-12 pt-3 pb-2 d-flex justify-content-center mt-3">
              <button
                disabled={AddBanner?.isLoading}
                className="apply-filter submit-save-banner"
                onClick={handleSubmit(handleBannerSubmit)}
              >
                {!AddBanner?.isLoading ? (
                  Id ? (
                    "Edit Banner"
                  ) : (
                    "Add Banner"
                  )
                ) : (
                  <div className="pb-3">
                    <ButtonLoader />
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBannerPromo;
