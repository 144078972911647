import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import {
  Button,
  Modal,
  Rate,
  Select,
  Slider,
  DatePicker,
  Space,
  TimePicker,
} from "antd";
import sohaibavatar from "../../assets/images/dashboard/sohaibavatar.png";
import "../../assets/css/common/datatable.scss";
import { Box, Typography } from "@mui/material";
import CustomPagination from "../common/CustomPagination";
import prescriptionSVG from "../../assets/images/common/prescription.svg";
import Edit from "../common/Edit.js";
import Delete from "../common/Delete.js";
import Cross from "../common/Cross";
import Tick from "../common/Tick.js";

import "../../assets/css/Banner/banner.scss";
import TimeChanger from "../doctors/TimeChanger";

// img svg
import DeleteIcon from "../../assets/images/pharmacy/DeleteIcon.svg";
import EditIcon from "../../assets/images/pharmacy/EditIcon.svg";
import CameraIcon from "../../assets/images/doctor/CameraIcon.svg";
import GalleryIcon from "../../assets/images/Banner/GalleryIcon.svg";
import CalenderIcon from "../../assets/images/doctor/CalenderIcon.svg";
import ClockIcon from "../../assets/images/doctor/ClockIcon.svg";

// images png
import pic1 from "../../assets/images/doctor/doc1.png";
import pic2 from "../../assets/images/doctor/doc2.png";
import pic3 from "../../assets/images/doctor/doc3.png";
import pic4 from "../../assets/images/doctor/doc4.png";
import pic5 from "../../assets/images/doctor/doc5.png";
import pic6 from "../../assets/images/doctor/doc6.png";
import pic7 from "../../assets/images/doctor/doc7.png";
import pic8 from "../../assets/images/doctor/doc8.png";
import { Link } from "react-router-dom";
import CustomSelect from "../common/CustomSelect";
import Time from "../../atoms/Time/Time";
import UploadFile from "../../molecules/UploadFile/UploadFile";
import CustomDropDown from "../../atoms/CustomDropDown/Index";
import useFetch from "../../customHook/useFetch";
import ListSkeleton from "../../molecules/ListSkeleton/ListSkeleton";
import useDeleteData from "../../customHook/useDelete";
import { CustomToast } from "../../atoms/toastMessage";
import ButtonLoader from "../../atoms/buttonLoader";
import ImagePreview from "../../atoms/ImagePreview/ImagePreview.js";
import ListHeader from "../../molecules/ListHeader/ListHeader.js";
import RoomsTableListHeader from "../../molecules/HospitalRoomManagement/RoomsTableListHeader.jsx";
import FiltersHelper from "../../molecules/HospitalRoomManagement/helper.js";
import dropdown from "../../utilities/data/list.dropdown.jsx";
import bread from "../../utilities/data/list.breadcrumbs.jsx";

const SalesPromotionDataTable = ({ searchQuery, paramTitle, isSortBy }) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [modal1Open, setModal1Open] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [errorData, setErrorData] = useState(0);
  const [uniqueId, setUniqueId] = useState(false);
  const [image, setImage] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const { filterStates, filterFuncs } = FiltersHelper('', dropdown?.salesPromotionItems);
  const { filtersData, isSortedItemSelected } = filterStates;
  const { handleOnFilterChange, handleSortByClick, handleTruncateText, handleSortByAge, handleSortIcons } = filterFuncs;

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleDoctorImageClick = () => {
    // Create a file input element and trigger a click event
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    // input.accept = 'image/png,image/jpeg';  // its just show png and jpeg file rather then other
    input.onchange = (event) => {
      const file = event.target.files[0];
      if (!file) {
        setErrorData(0);
        return;
      }
      const fileType = file.type;
      if (fileType !== "image/png" && fileType !== "image/jpeg") {
        // alert('Please select a PNG or JPEG file');
        setErrorData(1);
        return;
      } else {
        setErrorData(0);
      }
      // Set the selected image as the state of the component
      setImage(URL.createObjectURL(file));
    };
    input.click();
  };

  const getBannerList = useFetch(
    `${"list_sales_promotion"}?per_page=${rowsPerPage}&page=${page}&title=${filtersData?.title}&sort_by=${filtersData?.isSortBy}`
  );

  useEffect(() => {
    setPage(1);
  }, [filtersData?.title, filtersData?.isSortBy]);

  const rows = getBannerList.data;
  const totalRows = rows?.data?.total;
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  const startIndex = page * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage, totalRows);
  const visibleRows = rows?.data?.data;

  const deleteProductData = useDeleteData();
  const deleteData = deleteProductData.deleteData;

  const handleDelete = (Id) => {
    deleteData(`${"delete_salespromotions"}/${Id}`, () => {
      // setDeleteModal(false)
      getBannerList?.fetchPaginatedData(
        `${process.env.REACT_APP_SALES_LIST_PROMO}?per_page=${rowsPerPage}&page=${page}`
      );
      // const filter = rows?.data?.data?.filter(val => val.id !== deleteState)
      CustomToast({
        type: "success",
        message: "Sales promotion deleted successfuly.",
      });
      setDeleteModal(false);
      // setRows(filter)
    });
  };
  const handleImageClick = (imageUrl) => {
    setImagePreviewUrl(imageUrl);
    setIsPreviewOpen(true);
  };
  // Function to close the preview
  const closeImagePreview = () => {
    setIsPreviewOpen(false);
    setImagePreviewUrl(null);
  };

  const filtersOrder = [
    {
      type: 'search',
      name: 'title',
      value: filtersData?.title,
      divClass: 'col-5 p-0 pr-1',
      placeholder: 'Search',
      onChange: handleOnFilterChange
    },
    {
      type: 'dropdown',
      value: isSortedItemSelected,
      divClass: 'col-1 p-0',
      placeholder: 'Sort By',
      onChange: handleSortByClick,
      options: dropdown?.salesPromotionItems
    }
  ];

  return (
    <>
      <div className="row px-4 roomslistBody pt-4">
        <RoomsTableListHeader
          pageTitle="All Sales Promotions"
          formButtonLabel="Add Sales Promotion"
          addFormPathName="/sales-promotion/add"
          breadCrumbItems={bread?.promotionCrumbs}
          filtersOrder={filtersOrder}
        />

        <div className="col-12 row p-0 m-0 mt-2" style={{ overflowX: "hidden" }}>
          <ImagePreview
            imagePreviewUrl={imagePreviewUrl}
            closeImagePreview={closeImagePreview}
            isPreviewOpen={isPreviewOpen}
            width="30%"
          />
          <TableContainer
            component={Paper}
            sx={{ backgroundColor: "#FFFFFF" }}
            className="custom-scroll"
          >
            <Table aria-label="simple table">
              <TableHead
                sx={{
                  "& th": {
                    color: "#193F52",
                    whiteSpace: "nowrap",
                    wordWrap: "break-word",
                  },
                }}
              >
                <TableRow>
                  <TableCell className="number" align="left">
                    #
                  </TableCell>
                  <TableCell align="left">Title</TableCell>
                  <TableCell 
                    align="left"
                    onClick={() => handleSortByAge("status_asc", "status_desc")}
                    className="tableCellHover"
                  >
                    <i
                      class={`fa-solid ${handleSortIcons(
                        "status_asc",
                        "status_desc"
                      )} mr-2`}
                    ></i>
                    Status</TableCell>
                  <TableCell align="left">Start Date</TableCell>
                  <TableCell align="left">Start Time</TableCell>
                  <TableCell align="left">End Date</TableCell>
                  <TableCell align="left">End Time</TableCell>
                  <TableCell 
                    align="left"
                    onClick={() => handleSortByAge("actual_price_asc", "actual_price_desc")}
                    className="tableCellHover"
                    >
                    <i
                      class={`fa-solid ${handleSortIcons(
                        "actual_price_asc",
                        "actual_price_desc"
                      )} mr-2`}
                    ></i>
                    Actual Price
                  </TableCell>
                  <TableCell 
                    align="left"
                    onClick={() => handleSortByAge("discounted_price_asc", "discounted_price_desc")}
                    className="tableCellHover"
                    >
                    <i
                      class={`fa-solid ${handleSortIcons(
                        "discounted_price_asc",
                        "discounted_price_desc"
                      )} mr-2`}
                    ></i>
                    Discounted Price
                  </TableCell>
                  <TableCell align="left">Company Name</TableCell>
                  <TableCell align="left">Compny Email</TableCell>
                  <TableCell align="left">Promo Description</TableCell>
                  <TableCell align="left">Image</TableCell>
                  <TableCell align="left">Edit</TableCell>
                </TableRow>
              </TableHead>

              <TableBody
                sx={{
                  "& td": {
                    color: "#767676",
                    whiteSpace: "nowrap",
                    wordWrap: "break-word",
                  },
                }}
              >
                {!getBannerList?.isLoading ? (
                  visibleRows?.length > 0 ? visibleRows?.map(
                    (
                      {
                        id,
                        title,
                        status,
                        start_time,
                        end_time,
                        start_date,
                        end_date,
                        actual_price,
                        discounted_price,
                        company_name,
                        company_email,
                        description,
                        image,
                      },
                      index
                    ) => (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left" className="number">
                          {(page - 1) * rowsPerPage + index + 1}
                        </TableCell>

                        <TableCell align="left">{handleTruncateText(title, 15)}</TableCell>
                        {/* <TableCell align="left">{Category}</TableCell> */}
                        <TableCell align="left">
                          {status == 1 ? "Enabled" : "Disabled"}
                        </TableCell>
                        {/* <TableCell align="left">{Promo1}</TableCell> */}
                        <TableCell align="center">
                          <div>{start_date || "-"}</div>
                        </TableCell>
                        <TableCell align="center">
                          <div>{start_time?.slice(0, 5) || "-"} </div>
                        </TableCell>
                        <TableCell align="center">
                          <div>{end_date || "-"} </div>
                        </TableCell>
                        <TableCell align="center">
                          <div>{end_time?.slice(0, 5) || "-"} </div>
                        </TableCell>
                        <TableCell align="left">{actual_price}</TableCell>
                        <TableCell align="left">{discounted_price}</TableCell>
                        <TableCell align="left">{handleTruncateText(company_name, 15)}</TableCell>
                        <TableCell align="left">{company_email}</TableCell>
                        <TableCell align="left">{handleTruncateText(description, 30)}</TableCell>
                        <TableCell>
                          <img
                            className=""
                            style={{
                              width: "40px",
                              height: "40px",
                              cursor: "pointer",
                            }}
                            src={`${process.env.REACT_APP_IMAGE_URL}/${image}`}
                            onClick={() =>
                              handleImageClick(
                                `${process.env.REACT_APP_IMAGE_URL}/${image}`
                              )
                            }
                          />
                        </TableCell>
                        {/* <TableCell>
                        <img
                          className=""
                          style={{
                            width: "40px",
                            height: "40px",
                            cursor: "pointer",
                          }}
                          src={`${process.env.REACT_APP_IMAGE_URL}/${image}`}
                          onClick={() =>
                            handleImageClick(
                              `${process.env.REACT_APP_IMAGE_URL}/${image}`
                            )
                          }
                        />
                      </TableCell> */}
                        <TableCell align="left">
                          <Link to={`/sales-promotion/edit/${id}`}>
                            <img
                              className=""
                              src={EditIcon}
                              onClick={() => setModal1Open(true)}
                            />
                          </Link>
                          <img
                            className=""
                            onClick={() => {
                              if (id) {
                                // Add a null check
                                setDeleteModal(true);
                                setUniqueId(id);
                              }
                            }}
                            src={DeleteIcon}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  )

                  :

                  <TableRow>
                    <TableCell align="center" colSpan={14}>
                      No data found.
                    </TableCell>
                  </TableRow>

                ) : (
                  <TableRow>
                    <TableCell colSpan={14}>
                      <ListSkeleton totalRow={5} totalCol={14} image={false} />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Modal
            className="doctor-filter-modal"
            centered
            open={modal1Open}
            // onOk={() => setModal2Open(false)}
            onCancel={() => setModal1Open(false)}
            width={837}
            footer={
              <div className="row px-3 mt-lg-4 mb-lg-4">
                <div className="col-12 pt-3 pb-2 d-flex justify-content-center mt-3">
                  <button className="apply-filter submit-save-banner">
                    Edit Banner and Promo
                  </button>
                </div>
              </div>
            }
          >
            <div className="row px-3 border-bottom">
              <div className="col-12 ">
                <p className="doc-add-filter">Edit Banner and Promo</p>
              </div>
            </div>

            <div className="row px-3 mt-4">
              <div className="col-lg-12 doc-setting-input">
                <p className=" doc-add-filter-text">Title</p>

                <input type="text" />
              </div>
            </div>

            <div className="row px-3 mt-4">
              <div className="col-lg-4 pr-lg-0 doc-setting-input">
                <p className=" doc-add-filter-text">Category Type</p>
                <CustomDropDown
                  option={[{ label: "Banner" }, { label: "Sales Promotion​" }]}
                />
                {/* <Select
                                // defaultValue="lucy"
                                style={{
                                    width: "100%",
                                }}
                                onChange={() => { }}
                                options={[
                                    {
                                        label: "Banner ",
                                    },
                                    {
                                        label: "Sales Promotion​",
                                    },
                                ]}
                            /> */}
              </div>

              <div className="col-lg-4 pt-lg-0 pt-4 doc-setting-input">
                <p className=" doc-add-filter-text ">Status</p>

                <Select
                  // defaultValue="lucy"
                  style={{
                    width: "100%",
                  }}
                  onChange={() => {}}
                  options={[
                    {
                      label: "Enable",
                    },
                    {
                      label: "Disable",
                    },
                  ]}
                />
              </div>

              <div className="col-lg-4 pt-lg-0 pt-4 pl-lg-0 doc-setting-input">
                <p className=" doc-add-filter-text">Banner Placement</p>

                {/* <Select
                                        // defaultValue="lucy"
                                        style={{
                                            width: "100%",
                                        }}
                                        onChange={() => { }}
                                        options={[
                                            {
                                                label: "Laboratory Home",
                                            },
                                            {
                                                label: "Pharmacy Listing",
                                            },
                                            {
                                                label: "Main Home",
                                            }
                                        ]}
                                    /> */}
                <CustomDropDown
                  option={[
                    { label: "Laboratory Home" },
                    { label: "Pharmacy Listing​​" },
                    { label: "Main Home​" },
                  ]}
                />
              </div>
            </div>

            <div className="row px-3 mt-4">
              <div className="col-lg-4 pt-lg-0 pt-4 doc-setting-input pr-0">
                <p className=" doc-add-filter-text">Promo Type</p>
                <CustomDropDown />
              </div>
              <div className="col-lg-4 pt-lg-0 pt-4 doc-setting-input ">
                <p className=" doc-add-filter-text ">Start Date</p>
                <div className="d-flex justify-content-between align-items-center datapicker-border">
                  <DatePicker
                    className=" rounded-0"
                    // placeholder={"start"}
                    format={"DD/MM/YYYY"}
                    style={{ border: "0", outline: "none" }}
                  />

                  <img className="pr-1" src={CalenderIcon} alt="" />
                </div>
              </div>
              <div className="col-lg-4 pt-lg-0 pt-4  doc-setting-input pl-0">
                <p className=" doc-add-filter-text">Start Time</p>

                <div className="banner-time-border d-flex justify-content-between">
                  <Time />
                  <img className="pr-1" src={ClockIcon} alt="" />
                </div>
              </div>
            </div>

            {/* pl-lg-0 */}
            <div className="row px-3 mt-4">
              <div className="col-lg-4 pt-lg-0 pt-4 doc-setting-input pr-0">
                <p className=" doc-add-filter-text ">End Date</p>
                <div className="d-flex justify-content-between align-items-center datapicker-border">
                  <DatePicker
                    className=" rounded-0"
                    // placeholder={"start"}
                    format={"DD/MM/YYYY"}
                    style={{ border: "0", outline: "none" }}
                  />

                  <img className="pr-1" src={CalenderIcon} alt="" />
                </div>
              </div>
              <div className="col-lg-4 pt-lg-0 pt-4  doc-setting-input">
                <p className=" doc-add-filter-text">End Time</p>

                <div className="banner-time-border d-flex justify-content-between">
                  <Time />
                  <img className="pr-1" src={ClockIcon} alt="" />
                </div>
              </div>
              <div className="col-lg-4 pt-lg-0 pt-4  doc-setting-input pl-0">
                <p className=" doc-add-filter-text">Upload</p>

                <UploadFile />
              </div>
            </div>

            <div className="row px-3 mt-4">
              <div className="col-lg-12 pr-lg-0 doc-setting-input">
                <p className=" doc-add-filter-text">Description </p>

                <textarea
                  id="w3review"
                  name="w3review"
                  rows="4"
                  style={{ width: "98%" }}
                />
              </div>
            </div>
          </Modal>

          <Modal
            className="doctor-filter-modal"
            centered
            open={deleteModal}
            // onOk={() => setModal2Open(false)}
            onCancel={() => setDeleteModal(false)}
            width={514}
            footer={null}
            closable={false}
          >
            <div className="row pb-1">
              <div className="col-12 d-flex flex-column align-items-center justify-content-center pharmacy-delete">
                <p className="mb-0 pt-lg-5 pt-3 pb-4 mt-lg-3">
                  Are you sure you want to delete?
                </p>
                <button
                  disabled={deleteProductData?.isLoading}
                  className="mt-lg-4 mt-1 mb-lg-5 mb-2"
                  onClick={() => {
                    handleDelete(uniqueId);
                  }}
                >
                  {deleteProductData?.isLoading ? (
                    <div className="pb-3">
                      <ButtonLoader />{" "}
                    </div>
                  ) : (
                    "Delete"
                  )}
                </button>
              </div>
            </div>
          </Modal>
        </div>

        <div className="col-12 pagination-container px-md-3 ml-md-1 mt-md-2 ">
          <div className="pagination-detail">
            Showing {(page - 1) * rowsPerPage + 1} - {rows?.data?.to} of{" "}
            {rows?.data?.total}
          </div>
          <CustomPagination
            page={page}
            totalPages={totalPages}
            onChangePage={handleChangePage}
          />
        </div>
      </div>
    </>
  );
};

export default SalesPromotionDataTable;
