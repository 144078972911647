import React, { useMemo, useState } from "react";
import { Country, State } from "country-state-city";
import CustomDropDown from "../CustomDropDown/Index";
import { Controller } from "react-hook-form";
import ErrorMessage from "../../formvalidation/errormessage";

const SelectState = ({
  value,
  handleChange,
  country,
  name,
  disabled = false,
  req,
  dropDownDivClass,
  labelClass,
  arLabel,
  isController,
  control,
  controlName,
  error
}) => {
  const [disabledState, setDisabledState] = useState(false);
  const Countries = Country.getAllCountries();
  const selectedCountry = () => {
    const res = Countries.find((val) => val.name === country);
    return res;
  };
  const stat = useMemo(() => selectedCountry(), [country]);
  const States = useMemo(() => State.getStatesOfCountry(stat?.isoCode), [stat]);

  const Cont = () => {
    const res = States.map((val) => ({
      label: val.name,
      value: val.name,
    }));

    return res;
  };

  const renderState = (field) => (
    <CustomDropDown
      // {...(field || {})}
      field={field || {}}
      option={option}
      handleChangeSelect={(val) => {
        handleChange(val, name);
        field && field?.onChange(val);
      }}
      value={value}
      disabled={option?.length < 1 || disabled}
    />
  );

  const option = useMemo(() => Cont(), [stat]);
  return (
    <>
      <div className={`${dropDownDivClass}`}>

        <div className="d-flex justify-content-between">
          <p className={`doc-add-filter-text ${labelClass}`}>
            State{req === true ? <span className="text-danger">*</span> : null}
          </p>
          <p className="mb-2">{arLabel && arLabel}</p>
        </div>

        {
          !isController ? renderState() : 

          <Controller 
            name={controlName || name}
            control={control}
            render={({ field }) => renderState(field)}
          />
        }

        <ErrorMessage error={error} />

      </div>
    </>
  );
};

export default SelectState;
