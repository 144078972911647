import React, { useState } from 'react'
import RoomsTableListHeader from '../../../molecules/HospitalRoomManagement/RoomsTableListHeader';
import ImageUploader from '../../../atoms/uploadProfile/UploadProfile';
import TextInput from '../../../molecules/TextInput/TextInput';
import CustomDropDown from '../../../atoms/CustomDropDown/Index';
import CertificateInput from '../../../molecules/CertificateInput/CertificateInput';
import CustomButton from '../../../molecules/CustomButton/CustomButton';
import DoctorTimeTable from '../../../molecules/DoctorTimeTable/DoctorTimeTable';
import serviceFormHelper from './helper';
import SpinnerLoaderModal from '../../common/spinnerloadermodal';
import ArabicInput from '../../../molecules/ArabicInput';
import { useTranslation } from 'react-i18next';
import ArabicTextArea from '../../../molecules/ArabicTextArea/ArabicTextArea';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import formschemas from '../../../formvalidation/formschemas';

const ServiceForm = ({serviceId}) => {

    const {

        serviceFormStates: {
            serviceData, 
            setServiceData,
            serviceProviders,
            categories,
            isDataLoading,
            isLoading,
            serviceDurations
        },

        serviceFormFuncs: {
            handleOnChange,
            handleOnSubmit
        },

        // Time Table States

        addTimePostReq,
        setaddTimePostReq,
        selectDay,
        setSelectDay,
        specialistOptions,
        setspecialistOptions,
        isTimeSlotError,
        setIsTimeSlotError,
        modal2Open,
        setModal2Open,
        postDoctorAvalibility

    } = serviceFormHelper(serviceId);

    const breadCrumbItems = [
        {
          title: 'Home Service Provider'
        },
        {
          title: serviceId ? 'Edit Service' : 'Add Service',
          href: ''
        }
    ];

    const { t } = useTranslation();

    const {
        handleSubmit,
        setValue,
        control,
        watch,
        formState: { errors }
      } = useForm({
        mode: 'all',
        values: {...serviceData},
        resetOptions: {
          keepErrors: true
        },
        resolver: yupResolver(formschemas.serviceSchema)
      });

  return (
    <div className='row pl-4 pr-4 pt-3 roomslistBody mb-5 pb-5'>

        {serviceId && <SpinnerLoaderModal isDataLoading={isDataLoading} />}

        <RoomsTableListHeader
            pageTitle={serviceId ? 'Edit Service' : 'Add Service'}
            breadCrumbItems={breadCrumbItems}
        />

        <div className='col-8 row m-0 add-doc-left-col py-4'>

            <ImageUploader name={'profile_picture'} arLabel={` / ${t('Common.ProfilePicture')}`} serviceProviderData={serviceData} setServiceProviderData={setServiceData} setValue={setValue} error={errors?.profile_picture?.message} watch={watch} />

            <TextInput label={'Service Title'} control={control} inputDivClass='col-6 mt-3 pr-1 mb-3' type='text' name='provider_name' handleOnChange={handleOnChange} value={serviceData?.provider_name} placeholder={serviceId && 'Service Title'} error={errors?.provider_name?.message} />

            <ArabicInput
                divClass="col-6 mt-3 doc-setting-input inputSpacing"
                label={t("HomeServiceProvider.ServiceTitle")}
                labelClass='mb-2'
                formStateData={serviceData}
                setFormStateData={setServiceData}
                name="provider_name_ar"
            />

            <TextInput label={'Service Charges'} control={control} arLabel={t('HomeServiceProvider.Service.ServiceCharges')} inputDivClass='col-6 pr-1 mb-3' type='text' name='amount_per_hour' handleOnChange={handleOnChange} value={serviceData?.amount_per_hour} error={errors?.amount_per_hour?.message} />
            <TextInput label={'Experience'} control={control} arLabel={t('HomeServiceProvider.Service.Experience')} inputDivClass='col-6 pl-1 mb-3' type='text' name='experience' handleOnChange={handleOnChange} value={serviceData?.experience} error={errors?.experience?.message} />
            <CustomDropDown
                name='provider_id'
                dropDownDivClass='col-12 p-0 pr-1 mb-3' 
                label={'Service Providers'} 
                arLabel={t('HomeServiceProvider.Service.ServiceProviders')}
                option={serviceProviders}
                handleChangeSelect={(value, name) => handleOnChange({value, name})}
                value={serviceData?.provider_id}
                isController={true}
                control={control}
                error={errors?.provider_id?.message}
                disabled={serviceProviders?.length === 0 || serviceId}
            />

            <CustomDropDown 
                name={'provider_category_id'}
                dropDownDivClass='col-6 p-0 pr-1 mb-3' 
                label={'Categories'} 
                arLabel={t('Common.Categories')}
                value={serviceData?.provider_category_id}
                option={categories}
                handleChangeSelect={(value, name) => handleOnChange({value, name})}
                disabled={!serviceData?.provider_id}
                isController={true}
                control={control}
                error={errors?.provider_category_id?.message}
            />

            <CustomDropDown 
                name={'slot_gap'}
                dropDownDivClass='col-6 p-0 pl-1 mb-3' 
                label={'Service Duration'} 
                arLabel={t('HomeServiceProvider.Service.ServiceDuration')}
                value={serviceData?.slot_gap}
                option={serviceDurations || []}
                handleChangeSelect={(value, name) => handleOnChange({value, name})}
                isController={true}
                control={control}
                error={errors?.slot_gap?.message}
            />

            <div className='col-12 p-0 doc-setting-input mb-3'>
                <div className="d-flex justify-content-between">
                    <p className="mb-2">Upload Documents</p>
                    <p className="mb-2">{`${t("Common.UploadDocuments")}`}</p>
                </div>
                <CertificateInput formDataState={serviceData} setFormDataState={setServiceData} objectKey={'documents'} />
            </div>

            <TextInput label={'Service Details'} control={control} inputDivClass='col-6 pr-1 mb-3' type='text' name='service_detail' handleOnChange={handleOnChange} value={serviceData?.service_detail} textArea={true} error={errors?.service_detail?.message} />

            <ArabicTextArea
                divClass="col-6 doc-setting-input inputSpacing pl-1"
                label={t("HomeServiceProvider.ServiceDetails")}
                labelClass='mb-2'
                formStateData={serviceData}
                setFormStateData={setServiceData}
                name="service_detail_ar"
            />
        
            <DoctorTimeTable
                Id={serviceId} 
                label={'Service Timings'}
                arLabel={` / ${t('HomeServiceProvider.Service.ServiceTimings')}`}
                addTimePostReq={addTimePostReq}
                setaddTimePostReq={setaddTimePostReq}
                selectDay={selectDay}
                setSelectDay={setSelectDay}
                isTimeSlotError={isTimeSlotError}
                setIsTimeSlotError={setIsTimeSlotError}
                modal2Open={modal2Open}
                setModal2Open={setModal2Open}
                specialistOptions={specialistOptions}
                setspecialistOptions={setspecialistOptions}
                selectedArrayItems={serviceData?.providers ? serviceData?.providers : []}
                isNotDoctorTimeTable={true}
                itemLabel={'Service Provider'}
                itemIdLabel={'provider_id'}
                postDoctorAvalibility={postDoctorAvalibility}
                // isEditSlots={false}
            />


            <CustomButton label='Save Changes' buttonWidth='20%' buttonHeight='40px' buttonDivClass={'col-12 p-0 mt-5'} handleOnClick={handleSubmit(handleOnSubmit)} isLoading={isLoading} />
        </div>
        
    </div>
  )
}

export default ServiceForm;