import React, { useEffect, useState } from 'react'
import CopyRightIcon from '../../assets/images/dashboard/CopyRightIcon.svg'
import HeartIcon from '../../assets/images/dashboard/HeartIcon.svg'

const Footer = () => {

    const [date, setDate] = useState("");

    useEffect(() => {

        var d = new Date();
        setDate(d.getFullYear());
        
    }, [])
    
    return (
        <>

            <div className='row m-0 site-footer d-flex justify-content-center align-items-center' style={{ width: "100%" }}>

                <span>
                    {date} &copy; Maweidi - Health Care
                    {/* . Design with   <img className='pb-1' src={HeartIcon} alt="" /> */}
                </span>

            </div>

        </>
    )
}

export default Footer