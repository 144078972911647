import React from 'react'
import SalesPromotionForm from '../../components/salesPromotion/salesPromotionForm';
import { useParams } from 'react-router-dom';

const EditSalesPromotion = () => {

  const { id } = useParams();

  return <SalesPromotionForm Id={id} />
}

export default EditSalesPromotion;