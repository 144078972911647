import React, { useEffect, useState } from "react";
import BreadCrum from "../../atoms/breadcrum/BreadCrum";
import CustomDropDown from "../../atoms/CustomDropDown/Index";
import { useNavigate } from "react-router-dom";
import Searchbar from "../../components/common/Searchbar";
import { Dropdown } from "antd";
import { CSVLink } from "react-csv";

const RoomsTableListHeader = ({
  pageTitle,
  crumLinkOne,
  crumLinkTwo,
  formButtonLabel,
  addFormPathName,
  hospOptions,
  statusOptions,
  isSortedItemSelected,
  isSearch,
  searchDivClass,
  hospDropDownDivClass,
  statusDropDownDivClass,
  sortByDropDownDivClass,
  searchPlaceholder,
  hospitalsList,
  filtersData,
  items,
  handleOnFilterChange,
  handleSortByClick,
  breadCrumbItems,

  filtersOrder
}) => {

  const navigate = useNavigate();

  const search = (item) => (
    <Searchbar
      placeholder={item?.placeholder}
      value={item?.value}
      inputDivClass={item?.divClass}
      handleOnFilterChange={item?.onChange}
    />
  );

  const select = (item) => (
    <CustomDropDown
      name={item?.name}
      dropDownDivClass={item?.divClass}
      value={item?.value}
      selectLabel={item?.placeholder}
      option={item?.options}
      handleChangeSelect={(value, name) =>
        item?.onChange({ value, name })
      }
    />
  );

  const dropdown = (item) => (
    <div className={`${item?.divClass} p-0 list-header`}>
      <Dropdown
        menu={{
          items: item?.options,
          onClick: item?.onChange,
          selectedKeys: [item?.value],
        }}
      >
        <button className="dateButton w-100">{item?.placeholder || 'Sort By'}</button>
      </Dropdown>
    </div>
  );

  const csv = (item) => (
    <div className={`${item?.divClass} p-0`}>
      <CSVLink filename={item?.fileName} data={item?.data}>
        <button disabled={item?.disabled} className="list-header-btn1 w-100">
          <i
            class="fa-solid fa-arrow-down pr-2 pt-1"
            style={{ color: "#6D7482", fontSize: "14.5px" }}
          ></i>
          {item?.placeholder || 'Export'}
        </button>
      </CSVLink>
    </div>
  );

  const handlers = {
    search: search,
    select: select,
    dropdown: dropdown,
    csv: csv
  };

  return (
    <>
      <div className="col-12 p-0">
        <span className="listHeading">{pageTitle}</span>
      </div>

      <div className="col-6 p-0 pt-2 mb-5">
        <BreadCrum firstLink={crumLinkOne} firstText={crumLinkTwo} breadCrumbItems={breadCrumbItems} />
      </div>

      <div className="col-6 text-right p-0 mb-5">
        {formButtonLabel && (
          <button
            className="addRoomButton"
            onClick={() => navigate(addFormPathName)}
          >
            {formButtonLabel}
          </button>
        )}
      </div>

      {filtersOrder && filtersOrder?.map((filter) => handlers[filter?.type](filter) )}

      {isSearch && (
        <Searchbar
          placeholder={searchPlaceholder ? searchPlaceholder : "Search"}
          value={filtersData?.title}
          inputDivClass={searchDivClass}
          handleOnFilterChange={handleOnFilterChange}
        />
      )}

      {hospOptions && (
        <div className={`${hospDropDownDivClass} p-0`}>
          <CustomDropDown
            name={"hospital_id"}
            value={filtersData?.hospital_id}
            selectLabel="Select Hospital"
            option={hospitalsList}
            handleChangeSelect={(value, name) =>
              handleOnFilterChange({ value, name })
            }
          />
        </div>
      )}

      {items && (
        <div className={`${sortByDropDownDivClass} p-0 list-header`}>
          <Dropdown
          menu={{
            items,
            onClick: handleSortByClick,
            selectedKeys: [isSortedItemSelected],
          }}
        >
          <button className="dateButton w-100">Sort By</button>
        </Dropdown>
        </div>
      )}

      {statusOptions && (
        <div className={statusDropDownDivClass}>
          <CustomDropDown
            name={"status"}
            value={filtersData?.status}
            selectLabel="Select Status"
            option={statusOptions}
            handleChangeSelect={(value, name) =>
              handleOnFilterChange({ value, name })
            }
          />
        </div>
      )}
    </>
  );
};

export default RoomsTableListHeader;
