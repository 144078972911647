import React, { useEffect, useState } from 'react'
import RoomsTableListHeader from '../../../molecules/HospitalRoomManagement/RoomsTableListHeader.jsx';
import ImageUploader from '../../../atoms/uploadProfile/UploadProfile.js';
import TextInput from '../../../molecules/TextInput/TextInput.jsx';
import Phone from '../../../atoms/phone/index.js';
import CustomDropDown from '../../../atoms/CustomDropDown/Index.js';
import CustomDropDownMulti from '../../../atoms/CustomDropDown/CustomDropDownMulti.js';
import SocialInput from '../../../molecules/SocialInput/SocialInput.jsx';

// Imported Images

import LinkedinIcon from "../../../assets/images/doctor/LinkedInInput.png";
import InstagramIcon from "../../../assets/images/doctor/InstaInput.png";
import FacebookIcon from "../../../assets/images/doctor/FacebookInput.png";
import TwitterIcon from "../../../assets/images/doctor/Twitter.png";
import WebIcon from "../../../assets/images/doctor/website.png";
import RoleOpenIcon from "../../../assets/images/doctor/AddRoleIcon.svg";
import RoleCloseIcon from "../../../assets/images/common/close.svg";

import Location from '../../../atoms/Location/Location.js';
import { Modal } from 'antd';
import SearchMap from '../../common/SearchMap.js';
import MuiltiplesImages from '../../../atoms/MuiltiplesImages/MuiltiplesImages.js';
import { useSelector } from 'react-redux';
import AddRole from '../../../pages/Role/AddRole.js';
import ServiceProviderFormHelper from './serviceProviderFormHelper.js';
import SelectState from '../../../atoms/State/index.js';
import SelectCountry from '../../../atoms/Country/index.js';
import CustomButton from '../../../molecules/CustomButton/CustomButton.jsx';
import SpinnerLoaderModal from '../../common/spinnerloadermodal.jsx';
import ArabicInput from '../../../molecules/ArabicInput/index.jsx';
import { useTranslation } from 'react-i18next';
import ArabicTextArea from '../../../molecules/ArabicTextArea/ArabicTextArea.jsx';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import formschemas from '../../../formvalidation/formschemas.js';

const ServiceProviderForm = ({serviceProviderId}) => {

  const user = useSelector(state => state.auth);
  const isSuperAdmin = user && user?.role == 'super admin';
  const [modal2Open, setModal2Open] = useState(false);

  const {providerFormStates, providerFormFuncs : { handleOnChange, handleOnSubmit } } = ServiceProviderFormHelper(serviceProviderId);
  const { serviceProviderData, setServiceProviderData, categoryList, isLoading, isDataLoading, addRole, setAddRole } = providerFormStates;

  const breadCrumbItems = [
    {
      title: 'Home Service Provider'
    },
    {
      title: serviceProviderId ? 'Edit Service Provider' :  'Add Service Provider',
      href: ''
    }
  ];

  const { t } = useTranslation();

  const {
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors }
  } = useForm({
    mode: 'all',
    values: {...serviceProviderData, ...(!serviceProviderId && { role: {...addRole} })},
    resetOptions: {
      keepErrors: true
    },
    resolver: yupResolver(formschemas.serviceProviderSchema(serviceProviderId))
  });

  return (
    <div className='row pl-4 pr-4 pt-3 roomslistBody mb-5 pb-5'>

      {serviceProviderId && <SpinnerLoaderModal isDataLoading={isDataLoading} />}

      <RoomsTableListHeader
          pageTitle={serviceProviderId ? 'Edit Service Provider' :  'Add Service Provider'}
          breadCrumbItems={breadCrumbItems}
      />

      <div className='col-8 row m-0 add-doc-left-col py-4'>

        <ImageUploader 
          name='profile_picture' 
          arLabel={` / ${t('Common.ProfilePicture')}`} 
          serviceProviderData={serviceProviderData} 
          setServiceProviderData={setServiceProviderData}
          error={errors?.profile_picture?.message} 
          setValue={setValue}
          watch={watch}
        />

        <TextInput 
          label={'Name'} 
          inputDivClass='col-6 mt-3 pr-1 mb-3' 
          type='text' 
          name='name' 
          handleOnChange={handleOnChange} 
          value={serviceProviderData?.name} 
          control={control} 
          error={errors?.name?.message} 
        />

        <ArabicInput
          divClass="col-6 mt-3 doc-setting-input inputSpacing"
          label={t("HomeServiceProvider.Name")}
          labelClass='mb-2'
          formStateData={serviceProviderData}
          setFormStateData={setServiceProviderData}
          name="name_ar"
        />

        <TextInput 
          label={'Email'} 
          arLabel={t('Common.Email')} 
          inputDivClass='col-6 pr-1 mb-3' 
          type='email' 
          name='email' 
          value={serviceProviderData?.email} 
          handleOnChange={handleOnChange} 
          control={control} 
          error={errors?.email?.message}
          />

        <Phone
          label="Phone No"
          arLabel={t('Common.PhoneNo')}
          name="phone_no"
          inputDivClass='col-6 pl-1'
          value={serviceProviderData?.phone_no}
          handleChange={(e) => handleOnChange(e)}
          isController={true}
          control={control} 
          error={errors?.phone_no?.message}
        />

        <TextInput 
          label={'Customer Support Email'} 
          arLabel={t('Common.CustomerSupportEmail')} 
          inputDivClass='col-6 pr-1 mb-3' 
          type='email' 
          name='support_email' 
          value={serviceProviderData?.support_email} 
          handleOnChange={handleOnChange} 
          control={control} 
          error={errors?.support_email?.message}
        />

        <Phone
          label="Customer Support No"
          arLabel={t('Common.CustomerSupportNo')}
          name="support_phone_no"
          inputDivClass='col-6 pl-1'
          value={serviceProviderData?.support_phone_no}
          handleChange={(e) => handleOnChange(e)}
          isController={true}
          control={control} 
          error={errors?.support_phone_no?.message}
        />

        <SelectCountry
          labelClass='mb-2'
          arLabel={t('Common.Country')}
          dropDownDivClass='col-6 p-0 pr-1 mb-3 doc-setting-input'
          handleChangeSelect={(value, name) => {}}
          name="country"
          value={serviceProviderData?.country}
          isDisabled={true}
        />

        <SelectState
          labelClass='mb-2'
          arLabel={t('Common.State')}
          dropDownDivClass='col-6 p-0 pl-1 doc-setting-input'
          country={serviceProviderData?.country}
          disabled={!serviceProviderData?.country}
          name="state"
          value={serviceProviderData?.state || ""}
          handleChange={(value, name) => { setServiceProviderData({...serviceProviderData, [name]: value }) }}
          isController={true}
          control={control}
          error={errors?.state?.message}
        />

        <CustomDropDownMulti
          handleChangeSelect={(value, name) => {
            handleOnChange({name, value});
          }}
          option={categoryList}
          name="provider_category"
          mode="multiple"
          dropDownDivClass='col-12 p-0'
          label='Categories'
          arLabel={t('Common.Categories')}
          value={serviceProviderData?.provider_category || []}
          isNewItem={true}
          newItemButtonText='Add Category'
          newItemPlaceholder='Enter New Category'
          isController={true}
          control={control}
          error={errors?.provider_category?.message}
        />

        <SocialInput 
          label={'Facebook'} 
          arLabel={t('Common.Facebook')} 
          socialIcon={FacebookIcon} 
          name={'facebook'} 
          value={serviceProviderData?.facebook} 
          inputDivClass='col-6 pr-1 mb-3' 
          handleOnChange={handleOnChange} 
          control={control}
          error={errors?.facebook?.message}
        />

        <SocialInput 
          label={'Instagram'} 
          arLabel={t('Common.Instagram')} 
          socialIcon={InstagramIcon} 
          name={'instagram'} 
          value={serviceProviderData?.instagram} 
          inputDivClass='col-6 pl-1 mb-3' 
          handleOnChange={handleOnChange}
          control={control}
          error={errors?.instagram?.message}
        />

        <SocialInput 
          label={'Twitter'} 
          arLabel={t('Common.Twitter')} 
          socialIcon={TwitterIcon} 
          name={'twitter'} 
          value={serviceProviderData?.twitter} 
          inputDivClass='col-6 pr-1 mb-3' 
          handleOnChange={handleOnChange} 
          control={control}
          error={errors?.twitter?.message}
        />

        <SocialInput 
          label={'Website URL'} 
          arLabel={t('Common.WebsiteURL')} 
          socialIcon={WebIcon} 
          name={'website'} 
          value={serviceProviderData?.website} 
          inputDivClass='col-6 pl-1 mb-3' 
          handleOnChange={handleOnChange} 
          control={control}
          error={errors?.website?.message}
        />
        
        <div className='col-12 p-0'>
          <SocialInput 
            label={'Linkedin'} 
            arLabel={t('Common.Linkedin')} 
            socialIcon={LinkedinIcon} 
            name={'linkedin'} 
            value={serviceProviderData?.linkedin} 
            inputDivClass='col-6 pr-1 mb-3' 
            handleOnChange={handleOnChange} 
            control={control}
            error={errors?.linkedin?.message}
          />
        </div>

        <TextInput 
          label={'Address'} 
          arLabel={t('Common.Address')} 
          inputDivClass='col-6 pr-1 mb-3' 
          type='text' 
          name='address' 
          value={serviceProviderData?.address} 
          handleOnChange={handleOnChange} 
          control={control} 
          error={errors?.address?.message}
        />

        <div className="col-lg-6 mt-lg-0 p-0 pl-lg-1 doc-setting-input">

          <div className="d-flex justify-content-between">
            <p className="mb-2"> Location </p>
            <p className="mb-2">{`${t("Common.Location")}`}</p>
          </div>

          <div>
            <Location 
            handleLocation={() => setModal2Open(true)}
            locationProp={serviceProviderData?.location}
            />
          </div>
          {modal2Open && (
            <>
              <Modal
                className="location-modal"
                centered
                open={modal2Open}
                onOk={() => setModal2Open(false)}
                onCancel={() => setModal2Open(false)}
                width={735}
                footer={<div className=""></div>}
              >
                <div className="row   position-relative">
                  <div className="col-lg-12  ">
                    <SearchMap 
                      addHospitalData={serviceProviderData}
                      setAddHospitalData={setServiceProviderData}
                    />
                  </div>
                  <div className="col-12 position-absolute  d-flex justify-content-end"
                    style={{
                      top: '415px', left: '0',

                    }}>
                    <button style={{ height: '36.6px', width: '100px' }}
                      className="apply-filter add-doc-changes"
                      onClick={() => {
                        setModal2Open(false)
                      }}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </Modal>
            </>
          )}
        </div>

        <TextInput label={'About Company'} inputDivClass='col-6 pr-1 mb-3' type='text' name='about' value={serviceProviderData?.about} textArea={true} handleOnChange={handleOnChange} control={control} error={errors?.about?.message} />

        <ArabicTextArea
          divClass="col-6 doc-setting-input inputSpacing pl-1"
          label={t("HomeServiceProvider.AboutCompany")}
          labelClass='mb-2'
          formStateData={serviceProviderData}
          setFormStateData={setServiceProviderData}
          name="about_ar"
        />
        
        {isSuperAdmin && !serviceProviderId ? 
          <div className="col-lg-11 mt-2 mb-5 px-0 add-appointment-card-wrapper" style={{border: '1px solid #d9d9d9', borderRadius: '5px'}}>

            <div className="title-header px-3">
              <div className="title">Add a Role</div>
            </div>
            
            <hr />

            <AddRole 
              upperData={false} 
              roleParent={[
              { value: 9, label: "Service Provider Admin" }
              ]}
              setAddRole={setAddRole} addRole={addRole}
              roleParentValidation={false}
              roleCategoryId={''}
              navigateLink='serviceProvider'
              formDataState={serviceProviderData}
              control={control}
              errors={errors?.role}
              setValue={setValue}
            />

          </div>
        : null}

        <CustomButton label='Save Changes' buttonWidth='20%' buttonHeight='40px' buttonDivClass={'col-12 p-0 mt-5'} handleOnClick={handleSubmit(handleOnSubmit)} isLoading={isLoading} />
      
      </div>
      
    </div>
  )
}

export default ServiceProviderForm;