import React from 'react'
import AddLab from './AddLab'
import { useParams } from 'react-router-dom'

const EditLab = () => {

  const { id } = useParams();

  return <AddLab Id={id} />
}

export default EditLab;