
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, onMessage, getToken, onBackgroundMessage } from 'firebase/messaging';


const firebaseConfig = {
  apiKey: "AIzaSyDk832eePRvQjCvxhHvqxtuGqzyjrFEGEY",
  authDomain: "maweidi-403707.firebaseapp.com",
  projectId: "maweidi-403707",
  storageBucket: "maweidi-403707.appspot.com",
  messagingSenderId: "110679969310",
  appId: "1:110679969310:web:ff8a458e78473df577280f",
  measurementId: "G-P5FVZYVGE8"
};

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const messaging = getMessaging(app); 

export const  RequestForToken = async () => {
  return Notification.requestPermission().then((permission) => {
    if(permission === 'granted'){
      console.log('Notification user permission granted...');

      return getToken(messaging, { vapidKey: 'BJ1OABkGQwenOMBl4IAClzHH08d-fgNdyOnOgjGj87osKUKwQ3coYby_Q5k6rHQ57DQB5v3UKLtRYGR0cHlsMD0' })
      .then((currentToken) => {
          console.log('Token Generating 1');
          console.log('messaging: ', messaging);

        if (currentToken) {
          console.log('current token for client: ', currentToken);
          return currentToken;
          // Perform any other neccessary action with the token
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
        }
        
      })
      .catch((err) => {
        console.log('Token Generating 2');
        console.log('An error occurred while retrieving token. ', err);
      });

    }
    else{
      console.log('Notification user permission denied...');
    }
  })
  .catch(err => console.log('Error generating notification permission'));
    
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("Payload 1: ", payload)
      resolve(payload);
    });
  });

// export const onBackgroundMessageListener = () => {
//   return new Promise((resolve) => {
//     onBackgroundMessage(messaging, (payload) => {
//       resolve(payload);
//     })
//   })
// }