import React, { useEffect, useState } from 'react'
import RoomsTableListHeader from '../../molecules/HospitalRoomManagement/RoomsTableListHeader';
import usePost from '../../customHook/usePost';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CustomButton from '../../molecules/CustomButton/CustomButton';
import InputLabel from '../../molecules/TextInput/InputLabel';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import formschemas from '../../formvalidation/formschemas';
import TextInput from '../../molecules/TextInput/TextInput';

const NotificationsForm = () => {

  const [notificationsData, setNotificationsData] = useState({});
  const { postData, isLoading } = usePost();
  const navigate = useNavigate();

  const handleOnChange = (e) => {
      const { name, value } = e.target;
      setNotificationsData({...notificationsData, [name]: value});
  }

  const handleNotificationSubmit = () => {

      postData('broadcast_notification', notificationsData, (response) => {

          if(response?.success === true){
              navigate('/');
          }
          
      })
      
  }

  const breadCrumbItems = [
      {
        title: 'Notifications'
      },
      {
        title: 'Send Broadcast Notifications',
        href: ''
      }
  ];

  const { t } = useTranslation();

  const {
      handleSubmit,
      control,
      formState: { errors }
  } = useForm({
      mode: 'all',
      resolver: yupResolver(formschemas?.notificationsSchema)
  });
    
  return (
    <div className='row pl-4 pr-4 pt-3 roomslistBody'>

        <RoomsTableListHeader
            pageTitle='Send Broadcast Notifications'
            breadCrumbItems={breadCrumbItems}
        />

        <div className='col-6 add-doc-left-col py-3'>

          <TextInput
            label={'Title'} 
            arLabel={t('Common.Title')}
            labelClass={'mb-2 doc-add-filter-text text-dark'}
            name='title' 
            type='text' 
            required={true}
            inputDivClass='doc-setting-input inputSpacing mb-3' 
            value={notificationsData?.title} 
            control={control} 
            handleOnChange={handleOnChange} 
            error={errors?.title?.message} 
          />

          <TextInput
            label={'Body'} 
            arLabel={t('Common.Body')}
            labelClass={'mb-2 doc-add-filter-text text-dark'}
            name='body' 
            type='text' 
            textArea={true}
            rows={10}
            required={true}
            inputDivClass='doc-setting-input inputSpacing mb-3' 
            value={notificationsData?.body} 
            control={control} 
            handleOnChange={handleOnChange} 
            error={errors?.body?.message} 
          />

          <CustomButton label='Send Notification' buttonWidth='31%' buttonHeight='42px' buttonDivClass={'col-12 p-0 mt-5'} handleOnClick={handleSubmit(handleNotificationSubmit)} isLoading={isLoading} />

        </div>
      
    </div>
  )
}

export default NotificationsForm;