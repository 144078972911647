import "@livekit/components-styles";
import React, { useEffect, useState } from "react";
import {
  LiveKitRoom,
  RoomAudioRenderer,
  VideoTrack,
  useTracks,
  useParticipants
} from "@livekit/components-react";
import ReconnectingCall from "./ReconnectingCall";
import CustomControlBar from "./CustomControlBar";

const NewLiveKitRoom = ({
  serverUrl,
  Token,
  setTempParticipants,
  patient,
  isDisconnected,
  setIsDisconnected,
  room
}) => {
  const [isMicMute, setIsMicMute] = useState(false);
  const [isVideoMute, setIsVideoMute] = useState(false);
  const [isMiniView, setIsMiniView] = useState(true);
  const [roomState, setRoomState] = useState('connected');

  const handleToggleMic = async (e) => {
    if(room?.state == 'connected'){
      await room?.localParticipant?.setMicrophoneEnabled(isMicMute);
    }
    setIsMicMute(isMicMute => !isMicMute);
  };

  const handleToggleVideo = async (e) => {
    if(room?.state == 'connected'){
      await room?.localParticipant?.setCameraEnabled(isVideoMute);
    }
    setIsVideoMute(isVideoMute => !isVideoMute);
  };

  const handleDisconnect = async () => {

    if(room?.state === 'connected'){
      await room.localParticipant.enableCameraAndMicrophone(false);
      room.disconnect();
      setIsDisconnected(!isDisconnected);
      setRoomState('disconnected');
    }
    else{
      setIsDisconnected(!isDisconnected);
      setRoomState('disconnected');
    }
    
  };

  const handleToggleScreens = () => {
    setIsMiniView(isMiniView => !isMiniView);
  };

  const VideoScreen = ({participants}) => {

    const localTrackPublications = participants[0]?.videoTrackPublications
      ?.values()
      .next()?.value;
    const remoteTrackPublication = participants[1]?.videoTrackPublications
      ?.values()
      .next()?.value;

    const temp = [
      {
        participant: participants[1] ? participants[1] : {},
        publication: remoteTrackPublication ? remoteTrackPublication : {},
        source: "camera",
      },
      {
        participant: participants[0] ? participants[0] : {},
        publication: localTrackPublications ? localTrackPublications : {},
        source: "camera",
      },
    ];

    return temp?.length > 1 ? (
      <div
        className="h-100 VideoScreenBorder"
        style={{ position: "relative", overflow: "hidden" }}
      >
        <VideoTrack trackRef={isMiniView ? temp[0] : temp[1]} height={"100%"} />

        <div
          className="d-flex w-100 mb-3 px-3"
          style={{
            position: "absolute",
            bottom: "0",
            justifyContent: "space-between",
          }}
        >
          <div className="ml-3 patientScreenText" style={{display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
            <h6>{temp[1]?.participant?.identity}</h6>
            <p>Patient</p>
          </div>
          <div style={{ height: "200px", width: '350px', borderRadius: '5px' }} onClick={handleToggleScreens}>
            <VideoTrack
              trackRef={isMiniView ? temp[1] : temp[0]}
              height={"100%"}
              width={'100%'}
              style={{borderRadius: '5px'}}
            />
          </div>
        </div>
      </div>
    ) : null;
  };

  const VideoComponent = () => {

    const participants = useParticipants();

    return (
      <>
        {Token && room && participants?.length > 1 && roomState === 'connected' ? (
          <>
            <VideoScreen participants={participants} />
            <RoomAudioRenderer />
            <CustomControlBar
              isMicMute={isMicMute}
              isVideoMute={isVideoMute}
              isDisconnected={isDisconnected}
              setIsDisconnected={setIsDisconnected}
              handleToggleMic={handleToggleMic}
              handleToggleVideo={handleToggleVideo}
              handleDisconnect={handleDisconnect}
            />
          </>
        ) : (
          <>
            <ReconnectingCall patient={patient} isDisconnected={isDisconnected} />
            <CustomControlBar
            isMicMute={isMicMute}
            isVideoMute={isVideoMute}
            isDisconnected={isDisconnected}
            setIsDisconnected={setIsDisconnected}
            handleToggleMic={handleToggleMic}
            handleToggleVideo={handleToggleVideo}
            handleDisconnect={handleDisconnect}
          />
          </>
        )}
        
      </>
    );
  };

  return (
    <>
    {
      roomState == 'connected' ?

      <LiveKitRoom
        serverUrl={serverUrl}
        token={Token}
        data-lk-theme="default"
        style={{ height: "100vh" , borderRadius: '5px'}}
        room={room}
        onDisconnected={handleDisconnect}
      >

        <VideoComponent />

      </LiveKitRoom>

      :

      <>
        <ReconnectingCall patient={patient} isDisconnected={isDisconnected} />
        <CustomControlBar
          isMicMute={isMicMute}
          isVideoMute={isVideoMute}
          isDisconnected={isDisconnected}
          setIsDisconnected={setIsDisconnected}
          handleToggleMic={handleToggleMic}
          handleToggleVideo={handleToggleVideo}
          handleDisconnect={handleDisconnect}
        />
      </>
    }
      
    </>
  );
};

export default NewLiveKitRoom;
