import React, { useEffect, useState } from 'react'
import PaginationHelper from '../../../molecules/tablepagination/paginationhelper';
import useDeleteData from '../../../customHook/useDelete';
import { toast } from 'react-toastify';
import usePost from '../../../customHook/usePost';
import FiltersHelper from '../../../molecules/HospitalRoomManagement/helper';

const HospitalRoomsListHelper = (ListName, deleteApiMethod, DropDownItems) => {

    const { paginationData, setPaginationData, handleChangePage } = PaginationHelper();
    const { filterStates, filterFuncs, apiHooks } = FiltersHelper(deleteApiMethod, DropDownItems, ListName);
    const getApiHook = useDeleteData();
    const postApiHook = usePost();
    const [listData, setListData] = useState({
        statusOptions: {
          0: 'Pending',
          1: 'Approved',
          2: 'Declined'
        }
    });

    const hospRoomTableColumns = [
        { name: '#', align: 'left', className: 'number' },
        { name: 'Room Type', align: 'left' },
        { name: 'Hospital', align: 'left', textTruncLength: 20 },
        { name: 'Total Rooms', align: 'center', rowColDir: 'center' },
        { name: 'Charges', align: 'center', rowColDir: 'center' },
        { name: 'Booking Duration', align: 'center', rowColDir: 'center' },
        { name: 'Details', align: 'left' },
        { name: 'Edit', align: 'left' }
    ];

    const bookedRoomTableColumns = [
        { name: '#', align: 'left', className: 'number' },
        { name: 'Booked By', align: 'left', textTruncLength: 20 },
        { name: 'Email', align: 'left' },
        { name: 'Hospital', align: 'left', textTruncLength: 20 },
        { name: 'Room Type', align: 'left' },
        { name: 'Room Number', align: 'left', rowColDir: 'center' },
        { name: 'Start Date', align: 'left' },
        { name: 'End Date', align: 'left' },
        { name: 'Check-In-Time', align: 'left' },
        { name: 'Total Charges', align: 'left', rowColDir: 'center', sortBy: true, ascKey: 'total_amount_asc', descKey: 'total_amount_desc' },
        { name: 'Cancelation Duration', align: 'left' },
        { name: 'Status', align: 'left' }
    ];

    const [hospRoomsList, setHospRoomsList] = useState([]);
    const [bookHospRoomsList, setBookHospRoomsList] = useState([]);
    const [reRender, setReRender] = useState(false);
    const [roomSpecifications, setRoomSpecifications] = useState([]);

    // UseEffects

    useEffect(() => {

        if(ListName === 'Hosp Rooms List'){
            getApiHook?.deleteData(`get_rooms?page=${paginationData?.page}&per_page=10&hospital_id=${filterStates?.filtersData?.hospital_id}`, (response) => {
                setHospRoomsList(response?.data?.data?.map((room, index) => {

                    let tempTotalRooms = 0;
                    room?.room_specifications?.forEach(specification => tempTotalRooms += specification?.total_rooms);

                    setRoomSpecifications(roomSpecifications => ({
                        ...roomSpecifications,
                        [index]: room?.room_specifications?.map(specification => ({
                            floor_title: specification?.floor_title,
                            total_rooms: specification?.total_rooms
                        }))
                    }))

                    return {
                        id: room?.id,
                        room_type: room?.room_type?.title,
                        hospital: room?.hospital?.name,
                        total_rooms: tempTotalRooms,
                        charges: room?.per_day_fee,
                        booking_duration: room?.booking_cancelation_duration,
                        details: ''
                    }
                }))
    
                setPaginationData({
                    ...paginationData,
                    from: response?.data?.from,
                    to: response?.data?.to,
                    totalRows: response?.data?.total,
                    totalPages:  Math.ceil(response?.data?.total / 10)
                });
            })
        }
        else if(ListName === 'Booked Hosp Rooms List'){

            const payloadFilters = {
                page: paginationData?.page,
                per_page: 10,
                title: filterStates?.filtersData?.title,
                hospital_id: filterStates?.filtersData?.hospital_id,
                sort_by: filterStates?.filtersData?.isSortBy,
                status: filterStates?.filtersData?.status
            };

            postApiHook?.postData(`list_booking_rooms`, payloadFilters, (response) => {
                setBookHospRoomsList(response?.data?.data?.map((room) => ({
                    id: room?.id,
                    bookedBy: room?.user?.name,
                    email: room?.user?.email,
                    hospital: room?.room?.room_specifications?.room_details?.hospital?.name,
                    roomType: room?.room?.room_specifications?.room_details?.room_type?.title,
                    roomNo: room?.room?.id,
                    start_date: room?.start_date,
                    end_date: room?.end_date,
                    check_in_time: room?.check_in_time,
                    total_amount: room?.total_amount,
                    booking_cancelation_duration: room?.room?.room_specifications?.room_details?.booking_cancelation_duration ? room?.room?.room_specifications?.room_details?.booking_cancelation_duration + ' Hours' : '0 Time',
                    status: (room?.status)
                })));
    
                setPaginationData({
                    ...paginationData,
                    from: response?.data?.from,
                    to: response?.data?.to,
                    totalRows: response?.data?.total,
                    totalPages:  Math.ceil(response?.data?.total / 10)
                });
            })
        }

    },[paginationData?.page, reRender])

    // Handlers

    useEffect(() => {
        if(paginationData?.page !== 1){
          setPaginationData({...paginationData, page: 1});
        }
        else{
          setReRender(!reRender);
        }
    },[filterStates?.isDeleted, filterStates?.filtersData])

  return {

    hospRoomTableColumns,
    bookedRoomTableColumns,
    hospRoomsList,
    bookHospRoomsList,
    roomSpecifications,
    ...(ListName === 'Hosp Rooms List' ? {isLoading: getApiHook?.isLoading} : {isLoading: postApiHook?.isLoading}),

    hospStates: {
        listData
    },

    hospFuncs: {
        setListData
    },

    // Filter Helper States

    filterStates,
    filterFuncs,
    apiHooks,

    // Pagination Helper States

    paginationData,
    setPaginationData,
    handleChangePage

  }
}

export default HospitalRoomsListHelper;