import React, { useMemo } from "react";
import { Country } from "country-state-city";
import CustomDropDown from "../CustomDropDown/Index";

const Countries = Country.getAllCountries();

const SelectCountry = ({ value, name, handleChange, isDisabled, notRequired=true, labelClass, dropDownDivClass, arLabel }) => {
  const Cont = () => {
    const res = Countries.map((val) => ({
      label: val.name,
      value: val.name,
    }));
    return res;
  };

  const option = useMemo(() => Cont(), []);

  return (
    <>
      <div className={`${dropDownDivClass}`}>
        <div className="d-flex justify-content-between">
          <p className={`doc-add-filter-text ${labelClass}`}>
            Country{notRequired === true?<span className="error-message">*</span> : null}{" "}
          </p>
          <p className="mb-2">{arLabel && arLabel}</p>
        </div>
        <CustomDropDown
          option={option}
          disabled={isDisabled}
          handleChangeSelect={(val) => handleChange(val, name)}
          value={value}
        />
      </div>
    </>
  );
};

export default SelectCountry;
