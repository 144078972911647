import React from "react";
import { Link } from "react-router-dom";

const AnalyticsCards = ({ text, progress, total, role }) => {
  const a = progress?.toString()?.includes(".");
  progress = a ? progress?.toFixed(2) : progress;

  // Check if total is a number before calling toFixed()
  if (typeof total === "number") {
    const tempTotal = total?.toString()?.includes(".");
    total = tempTotal ? total?.toFixed(2) : total;
  }

  return (
    <div
      className={` ${
        role == "super admin"
          ? "col-xl-3 col-md-4 col-sm-6 "
          : role == "doctor"
          ? "col-6"
          : "col-4"
      }`}
    >
      <Link to="/" className="h-100 d-block">
        <div className="dashboard-right-side-top-card w-100 box-shadow-hover d-flex pl-1 py-3">
          <div className="px-1 w-100 ">
            <div className="d-flex justify-content-between align-items-center pb-3">
              <span
                className=" m-0 p-0   dashboard-left-icon-top-text1"
                style={{ color: "#111827", fontSize: "12.5px" }}
              >
                {text}
              </span>

              {progress !== undefined && (
                <div
                  className="px-2 d-flex"
                  style={{
                    backgroundColor: progress > 0 ? "#D1FAE5" : "#FEE2E2",
                    color: progress > 0 ? "#059669" : "#DC2626",
                    fontSize: "12px",
                    borderRadius: "10px",
                    alignItems: "baseline",
                  }}
                >
                  {progress > 0 ? (
                    <i
                      class="fa-solid fa-arrow-up pr-1"
                      style={{ fontSize: "10px" }}
                    ></i>
                  ) : (
                    <i
                      class="fa-solid fa-arrow-down pr-1"
                      style={{ fontSize: "10px" }}
                    ></i>
                  )}
                  {progress}%
                </div>
              )}
            </div>

            <hr className="m-0 " />

            <div className="dashboard-left-icon-top-text2 pt-3 d-flex justify-content-center w-100">
              <div className="" style={{ color: "#111827", fontSize: "18px" }}>
                {" "}
                {text === "Total Revenue" || text === "Orders Revenue"
                  ? "KWD"
                  : ""}{" "}
                {total}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default AnalyticsCards;
