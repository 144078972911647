import React from "react";
import "../../assets/css/common/common.scss";

const Searchbar = ({ handleOnFilterChange, value, placeholder="Search by Name or Civil ID", inputDivClass, setIsSearchBy }) => {

  return (
    <div className={`search-field ${inputDivClass}`}>
      <input
        name="title"
        placeholder={placeholder}
        onChange={handleOnFilterChange}
        value={value}
        className="w-100"
      />
    </div>
  );
};

export default Searchbar;
