import React from 'react'
import AddMedicalEquipment from './AddMedicalEquipment';
import { useParams } from 'react-router-dom';

const EditMedicalEquipment = () => {

  const { id } = useParams();

  return <AddMedicalEquipment Id={id} />
}

export default EditMedicalEquipment;