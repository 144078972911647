import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import "../assets/css/Signin.scss";
// import maweidi_logo from '../assets/images/common/maweidi_logo.svg'

// Image
import maweidi_logo from "../assets/images/common/maweidi_logo.svg";
import usePost from "../customHook/usePost";
import { toast } from "react-toastify";
import CustomButton from "../molecules/CustomButton/CustomButton";

const ResetPassword = () => {

  const { postData, isLoading } = usePost();
  const [resetPassword, setResetPassword] = useState({});
  const [error, setError] = useState(0);
  const navigate = useNavigate();

  const location = useLocation();
  // Parse the query parameters
  const params = new URLSearchParams(location?.search);
  // Extract the value of the 'token' parameter
  const token = params.get('token');

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setResetPassword({...resetPassword, [name]: value});
    setError(0);
  }

  const handleResetPassword = async () => {

    if(!resetPassword?.password){
        setError(1);
        return;
    }

    if(!resetPassword?.confirm_password){
        setError(2);
        return;
    }

    if(resetPassword?.password !== resetPassword?.confirm_password){
        setError(3);
        return;
    }

    postData('submit_new_password', {
        password: resetPassword?.password,
        token: token
    }, (response) => {
        if(response?.success){
            toast.success('Reset password successful.');
            navigate('/signin');
        }
    }, 'https://api.maweidi.com.kw/api');

  };

  useEffect(() => {
    if(!token){
        navigate('/signin');
    }
  },[])

  return (
    <>
      <div className="fluid-container ">
        <div className="sign-page">
          <div className="sign-page-background d-flex align-items-center">
            <div className="row m-0 sign-page-background-row ">
              <div className="col-1 col-lg-3  "></div>

              <div className="col-10 col-lg-6 px-lg-5 maweidi-logo d-flex justify-content-center flex-column">
                <img className="mb-3" src={maweidi_logo} alt="" />

                <div className="  ">
                  <div className="row  mx-lg-5 px-lg-4 pt-3 pb-2 siginup-inner">
                    <div className="col-12 my-4 d-flex justify-content-center forgot-text1">
                      <p className="mb-1">Reset Password</p>
                    </div>

                    <div className="col-12 mt-3 signup-input-label">
                      <p className="mb-2 ">
                        New Password
                        <span className="signup-staric-color">*</span>
                      </p>
                      <input
                        type="password"
                        name="password"
                        onChange={handlePasswordChange}
                      />
                      {error === 1 ? (
                        <p className="mb-0 ">
                          <span className="error_message">
                            Password is required
                          </span>
                        </p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="col-12 mt-3 signup-input-label">
                      <p className="mb-2 ">
                        Confirm Password
                        <span className="signup-staric-color">*</span>
                      </p>
                      <input
                        type="password"
                        name="confirm_password"
                        onChange={handlePasswordChange}
                      />
                      {(error === 2 || error === 3) && (
                        <p className="mb-0 ">
                          <span className="error_message">
                            {error === 2 ? 'Confirm Password is required' : error === 3 && 'Passwords must match one another'}
                          </span>
                        </p>
                      )}
                    </div>

                    <CustomButton label='Reset' buttonWidth='100%' buttonHeight='54px' buttonDivClass={'col-12 m-0 mt-4 mb-5 resgister-button'} handleOnClick={handleResetPassword} isLoading={isLoading} />
                    
                  </div>
                </div>
              </div>

              <div className="col-1 col-lg-3  "></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;