import * as Yup from "yup";
import validationmessages from "./validationmessages";

const alphabetsRegex = /^[A-Za-z\s]+$/;
const alphaNumericNoSpacesRegex = /^$|^[a-zA-Z0-9]+$/;
const alphaNumericWithSpacesRegex = /^(?!\s)(?!.*\s\s)[a-zA-Z0-9 ]+$/;
const numericRegex = /^[0-9]*$/;
const emailRegex = /^$|^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const usernameRegex = /^(?:[a-zA-Z0-9._]+)?$/;
const websiteUrlRegex = /^(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\/[^\s]*)?$|^$/;
const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]+$/;

const PROFILE_SUPPORTED_FORMATS = ['image/png', 'image/jpeg', 'image/jpg'];

const number = Yup?.number()?.required(validationmessages?.required);
const string = Yup?.string()?.required(validationmessages?.required);
const email = Yup?.string()?.matches(emailRegex, validationmessages?.email)?.required(validationmessages?.required);
const contact = Yup?.string()?.length(15, validationmessages?.stringLimitedLength(12))?.required(validationmessages?.required);
const kwdID = Yup?.string()?.matches(alphaNumericNoSpacesRegex, validationmessages?.kwdID)?.length(12, validationmessages?.stringLimitedLength(12))?.required(validationmessages?.required);
const website = Yup?.string()?.matches(websiteUrlRegex, validationmessages?.website);

const tempState = {
  alphanumeric: { pattern: alphaNumericWithSpacesRegex, message: validationmessages?.kwdID },
  password: { pattern: passwordRegex, message: validationmessages?.password },
  username: { pattern: usernameRegex, message: validationmessages?.username },
  alphabets: { pattern: alphabetsRegex, message: validationmessages?.alphabets },
  email: { pattern: emailRegex, message: validationmessages?.email },
  website: { pattern: websiteUrlRegex, message: validationmessages?.website },
  kwdID: { pattern: alphaNumericNoSpacesRegex, message: validationmessages?.kwdID }
}

const alphanumericString = (key, min, max, length) => {
  
  let temp = Yup?.string()?.required(validationmessages?.required);
  if(key) temp = temp?.matches(tempState[key]?.pattern, tempState[key]?.message);
  if(min) temp = temp?.min(min, validationmessages?.stringMinLength(min));
  if(max) temp = temp?.max(max, validationmessages?.stringMaxLength(max));
  if(length) temp = temp.test(
    'required-length',
    validationmessages?.stringLimitedLength(length),
    (value) => !value || value.length === length
  );

  return temp;

}

const profilePicture = (notRequired) => Yup?.mixed()?.test('fileType', validationmessages?.profilePicture, (file) => {
    if(!file && notRequired === true) return true;
    else return file && (PROFILE_SUPPORTED_FORMATS.includes(file.type) || typeof file === "string" );
  })
  ?.test('fileSize', 'File size must be less than or equal to 1mb', (file) => {
    if(!file && notRequired === true) return true;
    return typeof file === "string" || file?.size <= 1000000;
  })
  ?.required(validationmessages?.required);

const multipleFiles = (min, max, FILE_SUPPORTED_FORMATS) => {
  return Yup.array()
  .of(
    Yup.mixed()
      .test("fileType", validationmessages?.profilePicture, (file) => {
        return file && (FILE_SUPPORTED_FORMATS?.includes(file.type) || file?.id);
      })
      .test("fileSize", 'File size must be less than or equal to 1mb', (file) => {
        return file && (file?.size <= 1000000 || file?.id);
      })
  )
  .required(validationmessages?.required)
  .min(min, validationmessages?.filesMinLength(min))
  .max(max, validationmessages?.filesMaxLength(max))
}

const doctorFeesArray = (min, max, minFee, maxFee) => {

  let temp = Yup?.array()?.required('required', validationmessages?.required)
  ?.test('pattern', validationmessages?.numeric, (items) => {
    const check = items?.find((item) => !numericRegex?.test(item?.fees));
    return check ? false : true;
  })
  ?.test('numeric-range', validationmessages?.numericRange(minFee, maxFee), (items) => {
    const check = items?.find((item) => item?.fees === '' || item?.fees < minFee || item?.fees > maxFee);
    return check ? false : true;
  })

  if(min) temp = temp?.min(min, validationmessages?.arrayRange(min, 'min'));
  if(max) temp = temp?.max(max, validationmessages?.arrayRange(max, 'max'));

  return temp;
}

const arrayRange = (min, max) => {

  let temp = Yup?.array()?.test('required', validationmessages?.required, (items) => {
    return items?.length > 0;
  });

  if(min) temp = temp?.min(min, validationmessages?.arrayRange(min, 'min'));
  if(max) temp = temp?.max(max, validationmessages?.arrayRange(max, 'max'));

  return temp;

}

const stringRange = (min, max) => {
  return Yup?.string()?.required(validationmessages?.required)?.min(min, validationmessages?.stringMinLength(min))?.max(max, validationmessages?.stringMaxLength(max))?.matches(/^[A-Za-z\s]+$/, validationmessages?.alphabets);
}

const numericRange = (min, max) => {
  return Yup?.string()?.required(validationmessages?.required)?.matches(/^[0-9]*$/, validationmessages?.numeric)?.test(
    'is-in-range',
    validationmessages?.numericRange(min, max),
    (value) => {
      const num = Number(value);
      return num >= min && num <= max;
    }
  )
}

const roomSpecificationsArray = (min, max) => {

  let temp = Yup?.array()
  
  temp = temp?.test('required', 'Floor Title and Total Rooms are both required', (items) => {
    if(!items || (items?.length <= max && items?.find((item) => item?.floor_title === '' || item?.total_rooms === ''))){
      return false;
    }
    else return true;
  })

  if(max) temp = temp?.max(max, validationmessages?.arrayRange(max, 'max'));
  
  temp = temp?.test('pattern', function (items) {

    const { path, createError } = this;

    if(items?.find((item) => !alphaNumericWithSpacesRegex?.test(item?.floor_title))){
      return createError({
        path,
        message: 'Floor Title must contain alphanumeric values',
      });
    }
    else if(items?.find((item) => !numericRegex?.test(item?.total_rooms))){
      return createError({
        path,
        message: 'Total Rooms must contain numeric values',
      });
    }

    return true;
  })
  ?.test('length', function (items) {
    const { path, createError } = this;

    if(items?.find((item) => item?.floor_title?.length > 20)){
      return createError({
        path,
        message: `Floor Title must be at most 20 characters`,
      });
    }
    else if(items?.find((item) => item?.total_rooms < 1 || item?.total_rooms > 100)){
      return createError({
        path,
        message: 'Total Rooms must be between 1 and 100',
      });
    }

    return true;
  })

  return temp;

}

export default {
  number,
  string,
  email,
  contact,
  kwdID,
  website,
  profilePicture,
  stringRange,
  numericRange,
  multipleFiles,
  arrayRange,
  alphanumericString,
  roomSpecificationsArray,
  doctorFeesArray
};

// Unsed Commented Required Code

// const contactNumberRegex = /^\+965\d{7,8}$/;