import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./Phone.scss";
import { Controller } from "react-hook-form";
import ErrorMessage from "../../formvalidation/errormessage";

const Phone = ({
  value,
  handleChange,
  name,
  field,
  disabled,
  label = "Phone No",
  inputDivClass,
  arLabel,
  isController,
  control,
  error
}) => {
  const handleInputChange = (phone, field) => {
    handleChange(phone, name);
    isController && field && field.onChange(phone);
  };

  const renderPhoneInput = (field) => {
    return (
      <PhoneInput
        disabled={disabled}
        disableDropdown // Add this prop to disable the country code dropdown
        inputProps={{
          name: name,
          ref: field && field.ref,
          onChange: (e) => handleInputChange(e, field),
          onBlur: field && field.onBlur,
        }}
        country={"kw"}
        value={value}
        inputStyle={{ width: "36.6px" }}
        inputClass="w-100"
        onBlur={field && field.onBlur}
        placeholder="+965-XXXX-XXXX"
        onlyCountries={['kw']}
      />
    )
  }

  return (
    <>
      <div
        className={`${
          inputDivClass ? `${inputDivClass} doc-setting-input p-0` : ""
        }`}
      >
        <div className="d-flex justify-content-between">
          <p className="mb-2">
            {label}
            <span className="text-danger">*</span>
          </p>
          <p className="mb-2">{arLabel && arLabel}</p>
        </div>

        {
          !isController ? renderPhoneInput(field) : 

          <Controller 
            name={name}
            control={control}
            render={({field}) => renderPhoneInput(field)}
          />
        }

        <ErrorMessage error={error} />
      </div>
    </>
  );
};

export default Phone;
