import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react'
import { Controller, useForm } from 'react-hook-form';
import ErrorMessage from '../../formvalidation/errormessage';
import InputLabel from './InputLabel';

const TextInput = ({label, arLabel, name, type, value, placeholder, disabled, inputDivClass, textArea, cols, rows, required, labelTag, labelClass, labelDivClass, control, handleOnChange, error}) => {

  const {
    control: formControl,
    formState: { errors }
  } = useForm({
    mode: 'all',
  });

  const onChange = (e, field) => {
    handleOnChange(e);
    field && field?.onChange(e.target.value);
  }

  return (
    <div className={`doc-setting-input inputSpacing ${inputDivClass}`}>

        <InputLabel
          label={label}
          arLabel={arLabel}
          required={required}
          labelTag={labelTag}
          labelClass={labelClass || 'mb-2'}
          labelDivClass={labelDivClass}
        />

        <Controller 
          name={name}
          control={control || formControl}
          render={({ field }) => {
            return !textArea ? 
              <input {...field} type={type} name={name} onChange={(e) => onChange(e, field)} disabled={disabled} value={value ?? ''} placeholder={placeholder && placeholder} ></input>
              :
              <textarea {...field} cols={cols || "30"} rows={rows || "7"} name={name} value={value ?? ''} placeholder={placeholder && placeholder} onChange={(e) => onChange(e, field)}></textarea>
          }}
        />

        <ErrorMessage error={error} />

    </div>
  )
}

export default TextInput;