import { Avatar, Divider, List, Skeleton } from "antd";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import CameraIcon from "../../assets/images/doctor/CameraIcon.svg";

// css
import "../../assets/css/dashboard.scss";
import moment from "moment";

const App = ({latestAppointments, latestLabel}) => {
  // const [loading, setLoading] = useState(false);
  // const [data, setData] = useState([]);
  // const loadMoreData = () => {
  //   if (loading) {
  //     return;
  //   }
  //   setLoading(true);
  //   fetch(
  //     "https://randomuser.me/api/?results=10&inc=name,gender,email,nat,picture&noinfo"
  //   )
  //     .then((res) => res.json())
  //     .then((body) => {
  //       setData([...data, ...body.results]);
  //       setLoading(false);
  //     })
  //     .catch(() => {
  //       setLoading(false);
  //     });
  // };
  // useEffect(() => {
  //   loadMoreData();
  // }, []);

  return (
    <>
      <div className="row  px-4 py-4 my-1 ">
        <div className="col-8 d-flex justify-content-start align-items-center">
          <p className="mb-0  appoinment-text">{latestLabel}</p>
        </div>

        <div className="col-4 d-flex justify-content-end align-items-center">
          {/* <p className="mb-0 appoinment-count-text">{data.length} Patients</p> */}
        </div>
      </div>

      {/* <div
        className="border-top mx-2 pt-3 remove-x-overflow-doc-list"
        id="scrollableDiv"
        style={{
          height: 401,
          overflow: "auto",
          padding: "0 8px",
        }}
      >
        <InfiniteScroll
          dataLength={data.length}
          next={loadMoreData}
          hasMore={data.length < 50}
          loader={
            <Skeleton
              avatar
              paragraph={{
                rows: 1,
              }}
              active
            />
          }
          endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
          scrollableTarget="scrollableDiv"
        >
          <List
            dataSource={data}
            renderItem={(item) => (
              <>
                <div className="row  py-1">
                  <div className="col-12">
                    <div className="d-flex appoinment-detail align-items-center">
                      <img
                        className="appoinment-detail-img cursor-pointer"
                        src={item.picture.large}
                        alt=""
                      />

                      <div className="appoinment-detail-text pl-4 d-flex justify-content-center flex-column">
                        <p className="mb-0 appoinment-detail-text-1">
                          {item.name.last}
                        </p>
                        <p className="mb-0 appoinment-detail-text-2">
                          Booking on 27th Nov, 2020
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          />
        </InfiniteScroll>
      </div> */}
      <div
        className="border-top mx-2 pt-3 remove-x-overflow-doc-list"
        id="scrollableDiv"
        style={{
          height: 375,
          overflowX: "hidden",
          padding: "0 8px",
        }}
      >

        {latestAppointments?.map((appointment) => <div className="row  py-1">
          <div className="col-12">
            <div className="d-flex appoinment-detail align-items-center">
              <img
                className="appoinment-detail-img cursor-pointer object-fit-cover"
                src={appointment?.link ? process.env.REACT_APP_IMAGE_URL + appointment?.link : CameraIcon}
                alt=""
              />

              <div className="appoinment-detail-text pl-4 d-flex justify-content-center flex-column">
                <p className="mb-0 appoinment-detail-text-1">
                  {appointment?.hospital}
                </p>
                <p className="mb-0 appoinment-detail-text-2">
                  {appointment?.doctor} booked by {appointment?.patient}
                </p>
                <p className="mb-0 appoinment-detail-text-3">
                  {appointment?.date}

                  {appointment?.start_time &&  ` - ${appointment?.start_time}`}{appointment?.end_time && ` to ${appointment?.end_time}`}
                </p>
              </div>
            </div>
          </div>
        </div>
        )}

      </div>
    </>
  );
};
export default App;
