import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

const ArabicTextArea = ({divClass, label, labelTag, name, rows, labelClass, textAreaClass, formStateData, setFormStateData}) => {

const HandleValidateArabicInput =  (e) =>  {
  const arabicPattern = /^[\u0600-\u06FF\s]*$/;
  // const arabicPattern = /^[\u0600-\u06FF\s\d!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]*$/;
  let currentText = e.target.value;

  if (!arabicPattern.test(currentText)) {
    currentText = currentText.replace(/[^\u0600-\u06FF\s]/g, ''); 
    alert('Please enter only arabic characters.');
  }

  setFormStateData(formStateData => ({...formStateData, [name]: currentText}));
}

  const Tag = labelTag || 'p';

  return (
    <div className={`text-right ${divClass}`}>
        <Tag className={`mb-2 ${labelClass}`} >{label}</Tag>
        <textarea name={name} className={`pr-2 ${textAreaClass}`} cols={30} rows={rows || 7} dir='rtl' onChange={HandleValidateArabicInput} value={formStateData?.[name] == null || formStateData?.[name] == 'null' || formStateData?.[name] == undefined ? '' : formStateData?.[name]} ></textarea>
    </div>
  )
}

export default ArabicTextArea;