import React from "react";
import { Modal } from "antd";
import "./ImagePreview.scss"; // Import your custom CSS file

const ImagePreview = ({
  imagePreviewUrl,
  closeImagePreview,
  isPreviewOpen,
  width,
}) => {
  // Render the component
  return (
    <Modal
      visible={isPreviewOpen}
      closeIcon={null} // Remove the close icon
      footer={null}
      wrapClassName="image-preview-modal" // Apply custom CSS class
      mask={false} // Disable the mask (background overlay)
      onCancel={closeImagePreview} // Handle the close functionality
      style={{
        background: "",
        padding: "0",
        borderRadius: "12px",
        overflow: "hidden",
      }}
      centered
    >
      <div className="image_container text-center">
        <img src={imagePreviewUrl} alt="Preview" />
      </div>
    </Modal>
  );
};

export default ImagePreview;
