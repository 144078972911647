import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Column } from "@ant-design/plots";

const DemoColumn = ({userRoles, BarChartData}) => {

  const config = {
    data: BarChartData,
    xField: "range",
    yField: "revenue",
    seriesField: "role",
    isGroup: true,
    isStack: false,
    animation: false,

    // legend: {
    //   position: "bottom",
    //   itemFormatter(val) {
    //     return val + " %";
    //   },
    //   marker: {
    //     symbol: 'circle',
    //   },
    // },
    legend: false,

    columnStyle: {
      radius: [8, 8, 0, 0],
    },

    color: ({ role }) => {
      if (role === "Hospitals") {
        return "#5588FF";
      }
      if (role === "Laboratories") {
        return "#FFCE00";
      }
      if (role === "Pharmacies") {
        return "#11B3CF";
      }
      if (role === "Medical Equipments") {
        return "#6555C5";
      }
      if (role === "X-rays") {
        return "#A501F4";
      }
      if (role === "Service Providers") {
        return "#BAC94A";
      }
      if (role === "Doctors") {
        return "#35A28F";
      }
    },

  };

  return <>

    <Column {...config} />

    <div className="col-12 px-2 pt-4 mt-2">
     <div className="row">
     {userRoles?.map((item) => {
        return (
          <div className=" flex-nowrap  d-flex align-items-center">
            <span className="dot " style={{ backgroundColor: item === 'Hospitals' ? '#5588FF' : 
            item === "Doctors" ? "#35A28F" : 
            item === "Pharmacies" ? "#11B3CF" : 
            item === "Medical Equipments" ? "#6555C5" : 
            item === "X-ray" ? "#A501F4" : 
            item === "Service Providers" ? "#BAC94A" : 
            item === "Laboratories" ? "#FFCE00" : 
            'black' }}></span> <span className="pl-2 pr-4">{item}</span>
          </div>
        )
      })}
     </div>
    </div>

  </>;
};

export default DemoColumn;
