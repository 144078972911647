import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageResources from "./translation/translation";
import { log } from "@antv/g2plot/lib/utils";

const STORE_LANGUAGE_KEY = "settings.lang";

const languageDetectorPlugin = {
  type: "languageDetector",
  async: true,
  init: () => {},
  detect: async function (callback) {
    try {
      // get stored language from localStorage
      const language = localStorage.getItem(STORE_LANGUAGE_KEY);
      if (language) {
        // If language was stored before, use this language in the app
        return callback(language);
      } else {
        // If language was not stored yet, use the browser language as a fallback
        const browserLanguage = navigator.language.split("-")[0];
        return callback(browserLanguage);
      }
    } catch (error) {
      console.log("Error reading language", error);
    }
  },
  cacheUserLanguage: async function (language) {
    try {
      console.log("language: cacheUserLanguage", language);
      // Save a user’s language choice in localStorage
      localStorage.setItem(STORE_LANGUAGE_KEY, language);
    } catch (error) {}
  },
};

i18next
  .use(initReactI18next)
  .use(languageDetectorPlugin)
  .init({
    compatibilityJSON: "v3",
    fallbackLng: "ar",
    resources: LanguageResources,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;

export const changeLanguage = (language) => {
  console.log("language: changeLanguage", language);
  i18next.changeLanguage(language);
};
