import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

// css
import "../../assets/css/dashboard.scss";

// svg
import ClockIcon from "../../assets/images/dashboard/ClockIcon.svg";
import PulseIconBlue from "../../assets/images/dashboard/PulseIconBlue.svg";
import DoctorIconBlue from "../../assets/images/dashboard/DoctorIconBlue.svg";
import HospitalIconBlue from "../../assets/images/dashboard/HospitalIconBlue.svg";
import PharmacyIcon from "../../assets/images/dashboard/PharmacyIcon.svg";
import LaboratoryIconBlue from "../../assets/images/dashboard/LaboratoryIconBlue.svg";
import Equipments from "../../assets/images/dashboard/Equipments.png";
import EquipmentWhitePng from "../../assets/images/dashboard/EquipmentWhitePng.png";
import Xrays from "../../assets/images/dashboard/Xrays.png";
import XraysWhite from "../../assets/images/dashboard/XraysWhite.png";
import PatientIconBlue from "../../assets/images/dashboard/PatientIconBlue.svg";
import BloodIconBlue from "../../assets/images/dashboard/BloodIconBlue.svg";
import HomeServiceIconBlue from "../../assets/images/dashboard/HomeServiceIconBlue.svg";
import TreatmentSponsorBlueIcon from "../../assets/images/dashboard/treatment-sponsor-blue.svg";
import bluePromo from "../../assets/images/dashboard/Promo.svg";
import ClockIconWhite from "../../assets/images/dashboard/ClockIconWhite.svg";
import PulseIconWhite from "../../assets/images/dashboard/PulseIconWhite.svg";
import DoctorIconWhite from "../../assets/images/dashboard/DoctorIconWhite.svg";
import HoispitalIconWhite from "../../assets/images/dashboard/HoispitalIconWhite.svg";
import PatientIconWhite from "../../assets/images/dashboard/PatientIconWhite.svg";
import PharmacyIconWhite from "../../assets/images/dashboard/PharmacyIconWhite.svg";
import LaboratoryIconWhite from "../../assets/images/dashboard/LaboratoryIconWhite.svg";
import BloodIconWhite from "../../assets/images/dashboard/BloodIconWhite.svg";
import HomeServiceIconWhite from "../../assets/images/dashboard/HomeServiceIconWhite.svg";
import TreatmentSponsorIconWhite from "../../assets/images/dashboard/treatment-sponsor-icon-white.svg";
import DownArrowIconWhite from "../../assets/images/dashboard/DownArrowIconWhite.svg";
import UpArrowIconWhite from "../../assets/images/dashboard/UpArrowIconWhite.svg";
import PromoIconWhite from "../../assets/images/dashboard/PromoIconWhite.svg";
import NewspaperIconWhite from "../../assets/images/dashboard/Newspaper White.png";
import NewspaperIconBlue from "../../assets/images/dashboard/Newspaper blue.png";
import NotificationIconWhite from "../../assets/images/dashboard/Notification white.png";
import NotificationIconBlue from "../../assets/images/dashboard/Notification blue.png";
import BannerIconWhite from "../../assets/images/dashboard/whitebanner.png";
import BannerIconBlue from "../../assets/images/dashboard/bluebanner.png";
import { useSelector } from "react-redux";

const FullScreenMenu = ({
  menuDropDownFullScreen,
  setMenuDropDownFullScreen,
  menuLeftText,
  menuIconCenter,
  menuIconLeftPadding,
  menuLeftRightDropDown1,
  menuLeftRightDropDown2,
  setMobileMenu1,
  MobileMenu1,
}) => {

  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth);
  const isSuperAdmin = user && user?.role == "super admin";
  const role = JSON.parse(localStorage.getItem("userRoles"));
  const allowedhost =
    role &&
    Object.keys(role).includes("hospitaladmin") &&
    user?.role == "hospitaladmin";
  const allowedlab =
    role &&
    Object.keys(role).includes("technologist") &&
    user?.role == "technologist";
  const allowedphar =
    role &&
    Object.keys(role).includes("pharmacist") &&
    user?.role == "pharmacist";
  const allowedStore =
    role &&
    Object.keys(role).includes("storeadmin") &&
    user?.role == "storeadmin";
  const allowedradio =
    role &&
    Object.keys(role).includes("radiologic") &&
    user?.role == "radiologic";
  const alloweddoc =
    role && Object.keys(role).includes("doctor") && user?.role == "doctor";
  const isServiceProviderAdmin = user && user?.role == "serviceprovider";

  const [menuItems, setMenuItems] = useState([
    {
      menuHeading: "Dashboard",
      menuPath: '/',
      menuWhiteIcon: ClockIconWhite,
      menuBlueIcon: ClockIcon,
      admins: [allowedhost, alloweddoc, allowedphar, allowedStore, allowedlab, allowedradio, isServiceProviderAdmin]
    },
    {
      menuHeading: "Appointments",
      menuPath: '/appointment',
      menuWhiteIcon: PulseIconWhite,
      menuBlueIcon: PulseIconBlue,
      admins: [alloweddoc, allowedhost]
    },
    {
      menuHeading: "Hospitals",
      menuWhiteIcon: HoispitalIconWhite,
      menuBlueIcon: HospitalIconBlue,
      admins: [allowedhost],
      subMenuItems: [
        {
          subMenuHeading: "All Hospitals",
          subMenuPath: "/hospitals"
        },
        {
          subMenuHeading: "Add Hospital",
          subMenuPath: "/hospitals/add",
          hideMenuItemFor: [allowedhost]
        },
        {
          subMenuHeading: "Hospital Rooms",
          subMenuPath: "/hospitals/hospitalrooms"
        },
        {
          subMenuHeading: "Booking Rooms",
          subMenuPath: "/hospitals/bookedhospitalrooms"
        }
      ],
    },
    {
      menuHeading: "Doctors",
      menuWhiteIcon: DoctorIconWhite,
      menuBlueIcon: DoctorIconBlue,
      admins: [alloweddoc, allowedhost],
      subMenuItems: [
        {
          subMenuHeading: "All Doctors",
          subMenuPath: "/doctors"
        },
        {
          subMenuHeading: "Add Doctor",
          subMenuPath: "/doctors/add",
          hideMenuItemFor: [alloweddoc]
        }
      ],
    },
    {
      menuHeading: "Patients",
      menuWhiteIcon: PatientIconWhite,
      menuBlueIcon: PatientIconBlue,
      admins: [allowedhost],
      subMenuItems: [
        {
          subMenuHeading: "All Patients",
          subMenuPath: "/patients"
        },
        {
          subMenuHeading: "Add Patient",
          subMenuPath: "/patients/add"
        }
      ],
    },
    {
      menuHeading: "Pharmacy",
      menuWhiteIcon: PharmacyIconWhite,
      menuBlueIcon: PharmacyIcon,
      admins: [allowedphar],
      subMenuItems: [
        {
          subMenuHeading: "Pharmacy List",
          subMenuPath: "/pharmacy"
        },
        {
          subMenuHeading: "Add Pharmacy",
          subMenuPath: "/pharmacy/add",
          hideMenuItemFor: [allowedphar]
        },
        {
          subMenuHeading: "Pharmacy Shop",
          subMenuPath: "/pharmacy/shop"
        },
        {
          subMenuHeading: "Shop Orders",
          subMenuPath: "/pharmacy/shop/detail"
        }
      ],
    },
    {
      menuHeading: "Medical Equipment",
      menuWhiteIcon: EquipmentWhitePng,
      menuBlueIcon: Equipments,
      admins: [allowedStore],
      subMenuItems: [
        {
          subMenuHeading: allowedStore ? 'Company Profile' : 'Medical Equipment List',
          subMenuPath: "/medical/equipment"
        },
        {
          subMenuHeading: "Add Medical Equipment",
          subMenuPath: "/medical/equipment/add",
          hideMenuItemFor: [allowedStore]
        },
        {
          subMenuHeading: "Equipment Shop",
          subMenuPath: "/medical/equipment/shop"
        },
        {
          subMenuHeading: "Shop Orders",
          subMenuPath: "/medical/equipment/shop/detail"
        }
      ],
    },
    {
      menuHeading: "Laboratory",
      menuWhiteIcon: LaboratoryIconWhite,
      menuBlueIcon: LaboratoryIconBlue,
      admins: [allowedlab],
      subMenuItems: [
        {
          subMenuHeading: 'Laboratory List',
          subMenuPath: "/laboratory"
        },
        {
          subMenuHeading: "Add Laboratory",
          subMenuPath: "/laboratory/add",
          hideMenuItemFor: [allowedlab]
        },
        {
          subMenuHeading: "Laboratory Tests",
          subMenuPath: "/laboratory/tests"
        },
        {
          subMenuHeading: "Test Requests",
          subMenuPath: "laboratory/bloodtest/orderlist"
        }
      ],
    },
    {
      menuHeading: "Xray",
      menuWhiteIcon: XraysWhite,
      menuBlueIcon: Xrays,
      admins: [allowedradio],
      subMenuItems: [
        {
          subMenuHeading: 'Xray List',
          subMenuPath: "/xray/list"
        },
        {
          subMenuHeading: "Add Xray",
          subMenuPath: "/xray/add",
          hideMenuItemFor: [allowedStore]
        },
        {
          subMenuHeading: "Xray Tests",
          subMenuPath: "/xray"
        },
        {
          subMenuHeading: "Test Requests",
          subMenuPath: "/xray/orderlist"
        }
      ],
    },
    {
      menuHeading: "Blood Donation",
      menuPath: '/blood-donation',
      menuWhiteIcon: BloodIconWhite,
      menuBlueIcon: BloodIconBlue,
      admins: [allowedhost]
    },
    {
      menuHeading: "Home Service Provider",
      menuWhiteIcon: HomeServiceIconWhite,
      menuBlueIcon: HomeServiceIconBlue,
      admins: [isServiceProviderAdmin],
      subMenuItems: [
        {
          subMenuHeading: "Service Provider List",
          subMenuPath: "service-provider/list"
        },
        {
          subMenuHeading: "Add New Service Provider",
          subMenuPath: "service-provider/add",
          hideMenuItemFor: [isServiceProviderAdmin]
        },
        {
          subMenuHeading: "Add New Service",
          subMenuPath: "service-provider/service/add"
        },
        {
          subMenuHeading: "Service List",
          subMenuPath: "service-provider/service/list"
        },
        {
          subMenuHeading: "Service Request List",
          subMenuPath: "service-provider/service-request-list"
        },
      ],
    },
    {
      menuHeading: "Needy Patients",
      menuWhiteIcon: TreatmentSponsorIconWhite,
      menuBlueIcon: TreatmentSponsorBlueIcon,
      subMenuItems: [
        {
          subMenuHeading: 'Needy Patients',
          subMenuPath: "/needy-patient/list"
        }
      ],
    },
    {
      menuHeading: "Banner",
      menuWhiteIcon: BannerIconWhite,
      menuBlueIcon: BannerIconBlue,
      subMenuItems: [
        {
          subMenuHeading: 'All Banners',
          subMenuPath: "/banner-promo"
        },
        {
          subMenuHeading: 'Add Banner',
          subMenuPath: "/banner-promo/add"
        }
      ],
    },
    {
      menuHeading: "Promo",
      menuWhiteIcon: PromoIconWhite,
      menuBlueIcon: bluePromo,
      subMenuItems: [
        {
          subMenuHeading: 'All Promo',
          subMenuPath: "/promo"
        },
        {
          subMenuHeading: 'Add Promo',
          subMenuPath: "/promo/add"
        }
      ],
    },
    {
      menuHeading: "Sales Promotion",
      menuWhiteIcon: HomeServiceIconWhite,
      menuBlueIcon: HomeServiceIconBlue,
      subMenuItems: [
        {
          subMenuHeading: "Add Sales Promotion",
          subMenuPath: "sales-promotion/add"
        },
        {
          subMenuHeading: "All Sales Promotion",
          subMenuPath: "sales-promotion"
        },
        {
          subMenuHeading: "Sales Promotion Request",
          subMenuPath: "sales-promotion/sales-promotion-request-list"
        },
      ],
    },
    {
      menuHeading: "News",
      menuWhiteIcon: NewspaperIconWhite,
      menuBlueIcon: NewspaperIconBlue,
      subMenuItems: [
        {
          subMenuHeading: 'All News',
          subMenuPath: "/news"
        },
        {
          subMenuHeading: 'Add News',
          subMenuPath: "/news/add"
        }
      ],
    },
    {
      menuHeading: "Notifications",
      menuWhiteIcon: NotificationIconWhite,
      menuBlueIcon: NotificationIconBlue,
      subMenuItems: [
        {
          subMenuHeading: 'Send Broadcast Notification',
          subMenuPath: "/notifications/add"
        }
      ],
    }
  ]);

  const modules = ['/', 'appointment', 'hospitals', 'doctors', 'patients', 
    'pharmacy', 'medical', 'laboratory', 'xray', 'blood-donation',
    'service-provider', 'needy-patient',
    'banner-promo', 'promo', 'sales-promotion', 'news', 'notifications'
  ];

  useEffect(() => {

    const selectedModule = modules?.find((module) =>  pathname?.startsWith(module, (pathname === '/' ? 0 : 1)));

    if(selectedModule){

      setMenuDropDownFullScreen((menuDropDownFullScreen) => ({
        ...menuDropDownFullScreen,
        name: modules?.indexOf(selectedModule),
        toggle: true
      }));
      
    }
    else{
      setMenuDropDownFullScreen((menuDropDownFullScreen) => ({
        ...menuDropDownFullScreen,
        name: 'dashboard',
        toggle: true
      }));
    }

  },[pathname])

  return (
    <div className="hover-effect mb-5">
      {menuItems?.map((menuItem, index) => {

        if(menuItem?.admins?.some((admin) => admin) || isSuperAdmin){

          if(!menuItem?.subMenuItems){
            return <Link className="maweidi-link ml-md-auto ml-0" to={menuItem?.menuPath}>
              <div
                className={`mt-lg-2 mt-0 d-flex align-items-center dashboard-main-hover sidebarMenuBorderRadius cursor-pointer ${menuIconCenter} ${menuIconLeftPadding}
                  ${
                    menuDropDownFullScreen.name === index &&
                    "selected-menu-bg"
                  }
              `}
                onClick={() => {
                  setMenuDropDownFullScreen({
                    ...menuDropDownFullScreen,
                    name: index,
                    toggle: true,
                  });
                  setMobileMenu1(!MobileMenu1);
                }}
              >
                <div
                  className=" row"
                  style={{ display: `${menuLeftText}`, width: "100%" }}
                >
                  <div
                    className={`${menuLeftRightDropDown1} SidebarMenuPadding d-flex align-items-center ${menuIconCenter}`}
                  >
                    <div
                      className={` px-2  dashboard-left-icon ${
                        menuDropDownFullScreen.name === index &&
                        "selected-menu-icon-bg"
                      }`}
                    >
                      <img className="py-1 px-1"
                        src={`${
                          menuDropDownFullScreen.name === index
                            ? menuItem?.menuBlueIcon
                            : menuItem?.menuWhiteIcon
                        }`}
                        alt=""
                      />
                    </div>

                      <span style={{ display: `${menuLeftText}` }}>
                        <p
                          className={`mb-0 pl-lg-3 pl-1 dashboard-left-icon-text`}
                          style={{ color: "#ffffff" }}
                        >
                          {menuItem?.menuHeading}
                        </p>
                      </span>
                  </div>

                  <div
                    className={` ${menuLeftRightDropDown2}  px-0 d-flex align-items-center justify-content-end`}
                  ></div>
                </div>
              </div>
            </Link>
          }
          else return (
            <div
              className={`mt-lg-2 mt-0 d-flex align-items-center ${menuIconCenter}`}
            >
              <div
                className={`sidebarMenuBorderRadius ${
                  menuDropDownFullScreen.name === index &&
                  "selected-menu-submenu-bg"
                }`}
                style={{ display: `${menuLeftText}`, width: "100%" }}
              >
                <div
                  className={` d-flex dashboard-main-hover cursor-pointer w-100 pr-3 sidebarMenuBorderRadius
                  ${
                    menuDropDownFullScreen.name === index &&
                    "selected-menu-bg"
                  }
              `}
                  onClick={() => {
                    if (menuDropDownFullScreen.name === index) {
                      setMenuDropDownFullScreen({
                        ...menuDropDownFullScreen,
                        name: index,
                        toggle: !menuDropDownFullScreen.toggle,
                      });
                    } else {
                      setMenuDropDownFullScreen({
                        ...menuDropDownFullScreen,
                        name: index,
                        toggle: true,
                      });
                    }
                    setMobileMenu1(!MobileMenu1);
                  }}
                >
                  <Link className="maweidi-link ml-0 " style={{ width: "83%" }}>
                    <div
                      className={`${menuLeftRightDropDown1} SidebarMenuPadding d-flex align-items-center ${menuIconCenter}`}
                    >
                      <div
                        className={`dashboard-left-icon ${
                          menuDropDownFullScreen.name === index &&
                          "selected-menu-icon-bg"
                        }`}
                      >
                        <img
                          className="py-1 px-1"
                          src={`${
                            menuDropDownFullScreen.name === index
                              ? menuItem?.menuBlueIcon
                              : menuItem?.menuWhiteIcon
                          }`}
                          alt=""
                        />
                      </div>
    
                      <Link className="maweidi-link ">
                        <span style={{ display: `${menuLeftText}` }}>
                          <p
                            className={`mb-0 pl-lg-3 dashboard-left-icon-text`}
                            style={{ color: "#ffffff" }}
                          >
                            {menuItem?.menuHeading}
                          </p>
                        </span>
                      </Link>
                    </div>
                  </Link>
                  
                  {menuItem?.subMenuItems && 
                    <div
                    className={` ${menuLeftRightDropDown2} px-0 d-flex align-items-center justify-content-end`}
                    >
                      <img
                        className="size-small-screen"
                        src={`${
                          menuDropDownFullScreen.toggle &&
                          menuDropDownFullScreen.name === index
                            ? UpArrowIconWhite
                            : DownArrowIconWhite
                        }`}
                        alt=""
                        style={{ display: `${menuLeftText}` }}
                      />
                    </div>
                  }
                </div>
    
                {menuDropDownFullScreen.toggle &&
                  menuDropDownFullScreen.name === index && (
                    <>
                      <div style={{ width: "100%" }}>

                        {menuItem?.subMenuItems?.map((subMenuItem) => {
                          
                          if(subMenuItem?.hideMenuItemFor?.some((admin) => admin)){
                            return null;
                          }
                          else return (
                            <div
                              className="sub-menu1-top-padding dashboard-main-hover MenuItemsTextMargin cursor-pointer left-drop-down d-flex align-items-center"
                              onClick={() => {
                                navigate(subMenuItem?.subMenuPath);
                                setMobileMenu1(!MobileMenu1);
                              }}
                            >
                              <Link className="maweidi-link ">
                                <span
                                  className={`pl-lg-3 text-light`}
                                >
                                  {subMenuItem?.subMenuHeading}
                                </span>
                              </Link>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};

export default FullScreenMenu;
