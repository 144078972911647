import React, { useEffect, useState } from 'react'
import DoctorTimeTableHelper from '../../../molecules/DoctorTimeTable/helper';
import useDeleteData from '../../../customHook/useDelete';
import usePost from '../../../customHook/usePost';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const ServiceFormHelper = (serviceId) => {

    const [serviceData, setServiceData] = useState({
        documents: [],
        remove_document_ids: []
    });
    const [serviceProviders, setServiceProviders] = useState([]);
    const [categories, setCategories] = useState([]);
    const serviceDurations = [
        {
            label: '0.5 Hour',
            value: 30
        },
        {
            label: '1 Hour',
            value: 60
        },
        {
            label: '1.5 Hours',
            value: 90
        },
        {
            label: '2 Hours',
            value: 120
        }
    ];

    const { deleteData } = useDeleteData();
    const { postData } = usePost();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(true);
  
    const {
        
        addTimePostReq,
        setaddTimePostReq,
        selectDay,
        setSelectDay,
        specialistOptions,
        setspecialistOptions,
        isTimeSlotError,
        setIsTimeSlotError,
        modal2Open,
        setModal2Open,
        postDoctorAvalibility,
        HandlePostTimingsApi,
        handleGetTimeSlots

    } = DoctorTimeTableHelper();

    // Hanlders

    const handleOnChange = (e) => {
        const { name, value } = e?.target ? e?.target : e;
        setServiceData({
            ...serviceData, 
            [name]: value,
            ...(name === 'provider_id' && {providers: [value], provider_category_id: null})
        });
    }

    const handleOnSubmit = () => {

        if(!serviceData?.profile_picture){
            toast?.error('Profile picture is required.');
            return;
        }

        if(!serviceData?.provider_name){
            toast?.error('Service title is required.');
            return;
        }

        if(!serviceData?.amount_per_hour){
            toast?.error('Amount per hour is required.');
            return;
        }

        if(!serviceData?.provider_id){
            toast?.error('Service provider is required.');
            return;
        }

        if(!serviceData?.provider_category_id){
            toast?.error('Provider Category is required.');
            return;
        }

        if(!serviceData?.service_detail){
            toast?.error('Service detail is required.');
            return;
        }

        setIsLoading(true);

        const formData = new FormData();

        for(const key in serviceData){
            if(key === 'documents' || key === 'remove_document_ids'){
                serviceData[key]?.forEach((item) => {
                    formData.append(`${key}[]`, item);
                })
            }
            else{
                formData?.append(key, serviceData[key] || "");
            }
        }

        const TimingsPayload = postDoctorAvalibility();

        if(TimingsPayload){
            postData(serviceId ? `update_serivce_provider/${serviceId}` : 'add_service', formData, (response) => {

                if(response?.success){

                    setIsLoading(false);

                    HandlePostTimingsApi('set_service_provider_slots', 'service_provider_id', response?.data?.id, TimingsPayload, {
                        slot_gap: serviceData?.slot_gap ? serviceData?.slot_gap : 30,
                        provider_id: serviceData?.provider_id
                    });
                    toast?.success(response?.response);
                    navigate('/service-provider/service/list');

                }

            })
        }

    }

    // useEffects

    useEffect(() => {
        deleteData('list_of_service_provider', (response) => {
            setServiceProviders(response?.data?.service_providers?.map((provider) => ({
                label: provider?.name,
                value: provider?.id
            })))
        })
    },[])

    useEffect(() => {
        if(serviceData?.provider_id){
            postData('get_serviceProvider_category_list', {service_provider_id: serviceData?.provider_id}, (response) => {
                setCategories(response?.data?.map(category => ({
                    label: category?.name,
                    value: category?.id
                })))
            })
        }
    },[serviceData?.provider_id])

    useEffect(() => {
        if(serviceId){
            postData('getSingle_service', {service_id: serviceId}, (response) => {
                setServiceData({
                    ...serviceData, 
                    ...response?.data,
                    profile_picture: response?.data?.document,
                    provider_id: response?.data?.ser_provider?.id,
                    provider_category_id: response?.data?.category?.id,
                    slot_gap: response?.data?.timetable?.find(slot => slot?.slot_duration)?.slot_duration
                });

                setspecialistOptions([{
                    id: response?.data?.ser_provider?.id,
                    label: response?.data?.ser_provider?.name,
                    value: response?.data?.ser_provider?.name
                }]);

                setIsDataLoading(false);
            })

            handleGetTimeSlots('get_service_provider_slots', serviceId);
        }
    },[])

    return {

        serviceFormStates: {
            serviceData, 
            setServiceData,
            serviceProviders,
            categories,
            isDataLoading,
            isLoading,
            serviceDurations
        },

        serviceFormFuncs: {
            handleOnChange,
            handleOnSubmit
        },

        // Time Table States

        addTimePostReq,
        setaddTimePostReq,
        selectDay,
        setSelectDay,
        specialistOptions,
        setspecialistOptions,
        isTimeSlotError,
        setIsTimeSlotError,
        modal2Open,
        setModal2Open,
        postDoctorAvalibility
    }
}

export default ServiceFormHelper;