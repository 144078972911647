import React, { useRef, useState, useEffect } from "react";
import { Modal } from "antd";
import AddDocUploadImgeIcon from "../../assets/images/doctor/AddDocUploadImgeIcon.svg";
import DocRoleCrossIcon from "../../assets/images/doctor/DocRoleCrossIcon.svg";
import { toast } from "react-toastify";

const UploadFile = ({
  setFormDataState,
  formDataState,
  name,
  fileUrl,
  setErrorData,
  isBannerDisplay,
  isBanner,
  setValue
  // fileName,
}) => {
  const [infoData, setInfoData] = useState("");
  const inputCertiRef = useRef();

  // Function to extract filename from URL
  const extractFileNameFromUrl = (url) => {
    // Check if the url is a string
    if (typeof url === "string") {
      const urlParts = url.split("/");
      return urlParts[urlParts.length - 1];
    }
    // If not a string, return a default value or handle it based on your requirements
    return "UnknownFile";
  };

  useEffect(() => {
    // Extract filename from the provided fileUrl
    if (fileUrl) {
      const fileName = extractFileNameFromUrl(fileUrl);
      setInfoData(fileName);
    }
  }, [fileUrl]);

  const handleImageClick = () => {
    setValue && setValue(name, formDataState?.[name], { shouldValidate: true });
    inputCertiRef.current.click();
  };

  const handleImagesUpload = (event) => {
    const file = event.target.files[0];

    if (file) {

      if(isBanner){

        const tempImage = new Image();
        tempImage.src = URL?.createObjectURL(file);

        tempImage.onload = () => {

          if(isBannerDisplay === 1){
            if(tempImage?.width >= 600 && tempImage?.width <= 800 && tempImage?.height >= 300 && tempImage?.height <= 360){
              setFormDataState({...formDataState, [name]: file });
              setInfoData(file.name);
              setValue && setValue(name, file, { shouldValidate: true });
            }
            else{
              toast?.error('Banner image size for mobile app must be in a range of 600 x 250 to 800 x 360.');
            }
          }
          else if(isBannerDisplay === 2){
            if(tempImage?.width >= 1300 && tempImage?.width <= 1450 && tempImage?.height >= 200 && tempImage?.height <= 250){
              setFormDataState({...formDataState, [name]: file });
              setInfoData(file.name);
              setValue && setValue(name, file, { shouldValidate: true });
            }
            else{
              toast?.error('Banner image size for Marketing website must be in a range of 1300 x 200 to 1450 x 250.');
            }
          }
        }
        
      }
      else {
        setFormDataState({ ...formDataState, [name]: file });
        setValue && setValue(name, file, { shouldValidate: true });
        setInfoData(file.name);
      }
      
    }
  };

  const handleRemoveImage = () => {

    setFormDataState((prevState) => ({
      ...prevState,
      [name]: null
    }));
    setInfoData("");
    setValue && setValue(name, null, { shouldValidate: true });
    inputCertiRef.current.value = "";

  };

  // Function to display a specific format of the file name
  const getFormattedFileName = (fileName) => {
    if (fileName.length > 14) {
      return `${fileName.slice(0, 8)}...${fileName.slice(-6)}`;
    }
    return fileName;
  };

  useEffect(() => {
    if(isBanner && !formDataState?.[name]){
      setInfoData('');
    }
  },[formDataState?.[name]])

  return (
    <>
      <div
        className="doc-setting-input-certificates d-flex justify-content-between align-items-center w-100"
        style={{ backgroundColor: "white" }}
      >
        <div style={{ whiteSpace: "nowrap" }}>
          {infoData ? (
            <span className="info-message pl-2">
              {getFormattedFileName(infoData)}
            </span>
          ) : (
            formDataState?.[name] && (
              <span className="info-message pl-2">
                {getFormattedFileName(
                  formDataState?.[name]?.name
                    ? formDataState?.[name]?.name
                    : formDataState?.[name]
                )}
              </span>
            )
          )}
        </div>
        <div className="pr-2 d-flex justify-content-end align-items-center w-100">
          {formDataState?.[name] ? (
            <img
              className="cursor-pointer"
              src={DocRoleCrossIcon}
              onClick={handleRemoveImage}
              alt="del"
            />
          ) : (
            <img
              className="cursor-pointer"
              src={AddDocUploadImgeIcon}
              onClick={handleImageClick}
              alt=""
            />
          )}
        </div>
        <input
          type="file"
          ref={inputCertiRef}
          onChange={handleImagesUpload}
          style={{ display: "none" }}
          accept="image/*"
        />
      </div>
    </>
  );
};

export default UploadFile;
