import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

const ArabicInput = ({divClass, label, labelTag, name, labelClass, formStateData, setFormStateData, InputClass, isdisabled}) => {

const HandleValidateArabicInput =  (e) =>  {
  const arabicPattern = /^[\u0600-\u06FF\s]*$/;
  let currentText = e.target.value;

  if (!arabicPattern.test(currentText)) {
    currentText = currentText.replace(/[^\u0600-\u06FF\s]/g, ''); 
    alert('Please enter only arabic characters.');
  }

  setFormStateData(formStateData => ({...formStateData, [name]: currentText}));
}

  const Tag = labelTag || 'p';

  return (
    <div className={`text-right pl-1 ${divClass}`}>
        <Tag className={`mb-2 ${labelClass}`} >{label}</Tag>
        <input type='text' className={`${InputClass}`} name={name} dir='rtl' onChange={HandleValidateArabicInput} value={formStateData?.[name] ?? ''} disabled={isdisabled} ></input>
    </div>
  )
}

export default ArabicInput;