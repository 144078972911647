import { useParams } from "react-router-dom";
import AddPatient from "./AddPatient";

const EditPatient = () => {

  const { id } = useParams();

  return (
    <AddPatient id={id} />
  );
};

export default EditPatient;
