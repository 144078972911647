import React from 'react'
import './videocall.scss'
import PatientVideoImage from '../../../assets/images/VideoCall/PatientVideoImage.svg'

const ReconnectingCall = ({patient, isDisconnected}) => {
  return (
    <>
    <div className='h-100 VideoScreenBorder' 
        style={{
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center', 
            alignItems: 'center',
            position: 'relative',
            overflow: 'hidden'
            }}
        >
            <div style={{
                backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL + patient?.profile_pic})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                filter: 'blur(10px)',
                }}
            >
                <div
                    style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)', // Change the alpha (last parameter) for opacity
                    }}
                    >
                </div>

            </div>

            <div className='reaconnectingTextDiv'>
                {
                    !isDisconnected ? 
                    <>
                    <p className='reconnectingUserName mb-0'>{patient?.name}</p>
                    <p className='connecting mb-0'>Connecting...</p>
                    </>
                    :
                    <p className='reconnectingUserName mb-0' >Call Ended...</p>
                }
            </div>

            <div className='reconnectingProfile'>
                <img src={process.env.REACT_APP_IMAGE_URL + patient?.profile_pic} ></img>
            </div>

            <div className='animationdiv'>

            </div>

    </div>
    </>
  )
}

export default ReconnectingCall;