import React from 'react'
import AddPromo from './AddPromo'
import { useParams } from 'react-router-dom'

const EditPromo = () => {

  const { id } = useParams();

  return <AddPromo Id={id} />
}

export default EditPromo;