import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Modal, Select, DatePicker } from "antd";
import "../../assets/css/common/datatable.scss";
import CustomPagination from "../common/CustomPagination";

import "../../assets/css/Banner/banner.scss";

// img svg
import CalenderIcon from "../../assets/images/doctor/CalenderIcon.svg";
import ClockIcon from "../../assets/images/doctor/ClockIcon.svg";
import Time from "../../atoms/Time/Time";
import UploadFile from "../../molecules/UploadFile/UploadFile";
import CustomDropDown from "../../atoms/CustomDropDown/Index";
import useFetch from "../../customHook/useFetch";
import ListSkeleton from "../../molecules/ListSkeleton/ListSkeleton";
import useDeleteData from "../../customHook/useDelete";
import { CustomToast } from "../../atoms/toastMessage";
import ButtonLoader from "../../atoms/buttonLoader";
import RoomsTableListHeader from "../../molecules/HospitalRoomManagement/RoomsTableListHeader.jsx";
import usePost from "../../customHook/usePost.js";
import FiltersHelper from "../../molecules/HospitalRoomManagement/helper.js";
import { toast } from "react-toastify";
import listSelect from "../../utilities/data/list.select.jsx";
import dropdown from "../../utilities/data/list.dropdown.jsx";
import SortTableCell from "../common/SortTableCell.jsx";

const SalesPromotionRequestListTable = ({
  searchQuery,
  paramTitle,
  isSortBy,
}) => {

  const { 
    filterStates: { filtersData, isSortedItemSelected },
    filterFuncs: { handleOnFilterChange, handleTruncateText, handleSortByClick, handleSortByAge, handleSortIcons}
  } = FiltersHelper('deleteApiMethod', dropdown?.salesPromotionRequestItems);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [modal1Open, setModal1Open] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [errorData, setErrorData] = useState(0);
  const [uniqueId, setUniqueId] = useState(false);
  const [image, setImage] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(null);
  const { postData, isLoading } = usePost();

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const getBannerList = useFetch(
    `sales_promotion_orders?per_page=${rowsPerPage}&page=${page}&title=${filtersData?.title}&sort_by=${filtersData?.isSortBy}&status=${filtersData?.status}`
  );

  useEffect(() => {
    setPage(1);
  }, [filtersData]);

  const rows = getBannerList.data?.data;
  const totalRows = rows?.total;
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  const visibleRows = rows?.data;
  const deleteProductData = useDeleteData();
  const deleteData = deleteProductData.deleteData;

  const handleDelete = (Id) => {
    deleteData(`${"delete_salespromotions"}/${Id}`, () => {
      getBannerList?.fetchPaginatedData(
        `${process.env.REACT_APP_SALES_LIST_PROMO}?per_page=${rowsPerPage}&page=${page}`
      );
      CustomToast({
        type: "success",
        message: "Sales Promotion Delete Successfuly!",
      });
      setDeleteModal(false);
    });
  };

  const handleChangeListStatus = (id, status) => {
    postData(`change_sales_promotion_order_status/${id}`, { status: status }, (response) => {
      if(response?.success){
        toast?.success('Status updated successfully.');
        setIsStatusModalOpen(null);
        getBannerList?.fetchPaginatedData(
          `sales_promotion_orders?per_page=${rowsPerPage}&page=${page}`
        );
      }
    })
  };

  const breadCrumbItems = [
    {
      title: 'Sales Promotion'
    },
    {
      title: 'Sales Promotion Request List',
      href: ''
    }
  ];

  const filtersOrder = [
    {
      type: 'search',
      name: 'title',
      value: filtersData?.title,
      divClass: 'col-5 p-0 pr-1',
      placeholder: 'Search by name and company name',
      onChange: handleOnFilterChange
    },
    {
      type: 'dropdown',
      value: isSortedItemSelected,
      divClass: 'col-2 p-0 pr-1',
      placeholder: 'Sort By',
      onChange: handleSortByClick,
      options: dropdown?.salesPromotionRequestItems
    },
    {
      type: 'select',
      name: 'status',
      value: filtersData?.status,
      divClass: 'col-5 p-0',
      placeholder: 'Select Status',
      onChange: handleOnFilterChange,
      options: listSelect?.completeStatus
    },
  ];

  const [status, setStatus] = useState(null);

  return (
    <>
      <Modal
        centered
        open={isStatusModalOpen !== null ? true : false}
        className="statusModal"
        onCancel={() => !isLoading && setIsStatusModalOpen(null)}
        closeIcon={null}
        footer={null}
        bodyStyle={{
          height: "200px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="statusModalBody">
          <p className="">Are you sure you want to change the status ?</p>

          {isStatusModalOpen === 0 ? (
            <>
              <button
                className="acceptedStatus"
                onClick={() => {
                  handleChangeListStatus(uniqueId, 1); // Call handleChangeListStatus with status 1 for Approved
                  setStatus(1);
                }}
                disabled={isLoading}
              >
                {isLoading && status === 1 ? <ButtonLoader /> : 'Approve'}
              </button>
              <button
                className="declinedStatus"
                onClick={() => {
                  handleChangeListStatus(uniqueId, 2); // Call handleChangeListStatus with status 1 for Approved
                  setStatus(2);
                }}
                disabled={isLoading}
              >
                {isLoading && status === 2 ? <ButtonLoader /> : 'Decline'}
              </button>
            </>
          ) : (
            <button
              className="completedStatus"
              onClick={() => {
                handleChangeListStatus(uniqueId, 3);
                setStatus(3);
              }}
              disabled={isLoading}
            >
              {isLoading && status === 3 ? <ButtonLoader /> : 'Complete'}
            </button>
          )}
        </div>
      </Modal>

      <div className="row px-4 roomslistBody pt-4">
        <RoomsTableListHeader
          pageTitle="Sales Promotion Request List"
          breadCrumbItems={breadCrumbItems}
          filtersOrder={filtersOrder}
        />

        <div className=" col-12 row p-0 m-0 mt-2" style={{ overflowX: "hidden" }}>
          <TableContainer
            component={Paper}
            sx={{ backgroundColor: "#FFFFFF" }}
            className="custom-scroll"
          >
            <Table aria-label="simple table">
              <TableHead
                sx={{
                  "& th": {
                    color: "#193F52",
                    whiteSpace: "nowrap",
                    wordWrap: "break-word",
                  },
                }}
              >
                <TableRow>
                  <TableCell className="number" align="left">
                    #
                  </TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Company Name</TableCell>
                  <TableCell align="left">Date</TableCell>
                  <TableCell align="left">Product Title</TableCell>
                  <SortTableCell
                    label='Total Amount'
                    handleSortByAge={handleSortByAge}
                    handleSortIcons={handleSortIcons}
                    ascKey={'total_amount_asc'}
                    descKey={'total_amount_desc'}
                  />
                  {/* <TableCell align="left">Discounted Price</TableCell> */}
                  <TableCell align="left">Status</TableCell>
                </TableRow>
              </TableHead>

              <TableBody
                sx={{
                  "& td": {
                    color: "#767676",
                    whiteSpace: "nowrap",
                    wordWrap: "break-word",
                  },
                }}
              >
                {!getBannerList?.isLoading ? (
                  visibleRows?.length > 0 ? visibleRows?.map(
                    ({ id, status, date, total_amount, user, salepromotionrder_items }, index) => (
                      <>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="left" className="number">
                            {(page - 1) * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell align="left">{handleTruncateText(user?.name, 20)}</TableCell>
                          <TableCell align="left">
                            {salepromotionrder_items[0]?.salespromotion?.company_name}{salepromotionrder_items?.length > 1 && ` +${salepromotionrder_items?.length - 1} more`}
                          </TableCell>

                          <TableCell align="left" >{date}</TableCell>
                          <TableCell align="left">
                            {salepromotionrder_items[0]?.salespromotion?.title}
                          </TableCell>
                          <TableCell align="left" >{total_amount}</TableCell>
                          <TableCell align="left">
                            <div
                              className={`py-1 ${
                                status === 0
                                  ? "pendingStatus"
                                  : status === 1
                                  ? "acceptedStatus"
                                  : status === 3
                                  ? "completedStatus"
                                  : "declinedStatus"
                              }`}
                              style={{
                                borderRadius: "5px",
                                cursor: `${
                                  status === 0 || status === 1
                                    ? "pointer"
                                    : "no-drop"
                                }`,
                                width: "100px",
                                textAlign: "center",
                              }}
                              onClick={() => {
                                if (status === 0 || status === 1) {
                                  setIsStatusModalOpen(status); // Add this line to open the modal
                                  setUniqueId(id); // Set the ID when status is clicked
                                }
                              }}
                            >
                              {status === 0
                                ? "Pending"
                                : status === 1
                                ? "Accepted"
                                : status === 2
                                ? "Declined"
                                : status === 3
                                ? "Completed"
                                : ""}
                            </div>
                          </TableCell>
                        </TableRow>
                      </>
                    )
                  )

                  :

                  <TableRow>
                    <TableCell align="center" colSpan={11}>
                      No data found.
                    </TableCell>
                  </TableRow>

                ) : (
                  <TableRow>
                    <TableCell colSpan={11}>
                      <ListSkeleton totalRow={5} totalCol={11} image={false} />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Modal
            className="doctor-filter-modal"
            centered
            open={modal1Open}
            // onOk={() => setModal2Open(false)}
            onCancel={() => setModal1Open(false)}
            width={837}
            footer={
              <div className="row px-3 mt-lg-4 mb-lg-4">
                <div className="col-12 pt-3 pb-2 d-flex justify-content-center mt-3">
                  <button className="apply-filter submit-save-banner">
                    Edit Banner and Promo
                  </button>
                </div>
              </div>
            }
          >
            <div className="row px-3 border-bottom">
              <div className="col-12 ">
                <p className="doc-add-filter">Edit Banner and Promo</p>
              </div>
            </div>

            <div className="row px-3 mt-4">
              <div className="col-lg-12 doc-setting-input">
                <p className=" doc-add-filter-text">Title</p>

                <input type="text" />
              </div>
            </div>

            <div className="row px-3 mt-4">
              <div className="col-lg-4 pr-lg-0 doc-setting-input">
                <p className=" doc-add-filter-text">Category Type</p>
                <CustomDropDown
                  option={[{ label: "Banner" }, { label: "Sales Promotion​" }]}
                />
              </div>

              <div className="col-lg-4 pt-lg-0 pt-4 doc-setting-input">
                <p className=" doc-add-filter-text ">Status</p>

                <Select
                  // defaultValue="lucy"
                  style={{
                    width: "100%",
                  }}
                  onChange={() => {}}
                  options={[
                    {
                      label: "Enable",
                    },
                    {
                      label: "Disable",
                    },
                  ]}
                />
              </div>

              <div className="col-lg-4 pt-lg-0 pt-4 pl-lg-0 doc-setting-input">
                <p className=" doc-add-filter-text">Banner Placement</p>
                <CustomDropDown
                  option={[
                    { label: "Laboratory Home" },
                    { label: "Pharmacy Listing​​" },
                    { label: "Main Home​" },
                  ]}
                />
              </div>
            </div>

            <div className="row px-3 mt-4">
              <div className="col-lg-4 pt-lg-0 pt-4 doc-setting-input pr-0">
                <p className=" doc-add-filter-text">Promo Type</p>
                <CustomDropDown />
              </div>
              <div className="col-lg-4 pt-lg-0 pt-4 doc-setting-input ">
                <p className=" doc-add-filter-text ">Start Date</p>
                <div className="d-flex justify-content-between align-items-center datapicker-border">
                  <DatePicker
                    className=" rounded-0"
                    // placeholder={"start"}
                    format={"DD/MM/YYYY"}
                    style={{ border: "0", outline: "none" }}
                  />

                  <img className="pr-1" src={CalenderIcon} alt="" />
                </div>
              </div>
              <div className="col-lg-4 pt-lg-0 pt-4  doc-setting-input pl-0">
                <p className=" doc-add-filter-text">Start Time</p>

                <div className="banner-time-border d-flex justify-content-between">
                  <Time />
                  <img className="pr-1" src={ClockIcon} alt="" />
                </div>
              </div>
            </div>

            {/* pl-lg-0 */}
            <div className="row px-3 mt-4">
              <div className="col-lg-4 pt-lg-0 pt-4 doc-setting-input pr-0">
                <p className=" doc-add-filter-text ">End Date</p>
                <div className="d-flex justify-content-between align-items-center datapicker-border">
                  <DatePicker
                    className=" rounded-0"
                    // placeholder={"start"}
                    format={"DD/MM/YYYY"}
                    style={{ border: "0", outline: "none" }}
                  />

                  <img className="pr-1" src={CalenderIcon} alt="" />
                </div>
              </div>
              <div className="col-lg-4 pt-lg-0 pt-4  doc-setting-input">
                <p className=" doc-add-filter-text">End Time</p>

                <div className="banner-time-border d-flex justify-content-between">
                  <Time />
                  <img className="pr-1" src={ClockIcon} alt="" />
                </div>
              </div>
              <div className="col-lg-4 pt-lg-0 pt-4  doc-setting-input pl-0">
                <p className=" doc-add-filter-text">Upload</p>

                <UploadFile />
              </div>
            </div>

            <div className="row px-3 mt-4">
              <div className="col-lg-12 pr-lg-0 doc-setting-input">
                <p className=" doc-add-filter-text">Description </p>

                <textarea
                  id="w3review"
                  name="w3review"
                  rows="4"
                  style={{ width: "98%" }}
                />
              </div>
            </div>
          </Modal>

          <Modal
            className="doctor-filter-modal"
            centered
            open={deleteModal}
            // onOk={() => setModal2Open(false)}
            onCancel={() => setDeleteModal(false)}
            width={514}
            footer={null}
            closable={false}
          >
            <div className="row pb-1">
              <div className="col-12 d-flex flex-column align-items-center justify-content-center pharmacy-delete">
                <p className="mb-0 pt-lg-5 pt-3 pb-4 mt-lg-3">
                  Are you sure you want to delete?
                </p>
                <button
                  disabled={deleteProductData?.isLoading}
                  className="mt-lg-4 mt-1 mb-lg-5 mb-2"
                  onClick={() => {
                    handleDelete(uniqueId);
                  }}
                >
                  {deleteProductData?.isLoading ? (
                    <div className="pb-3">
                      <ButtonLoader />{" "}
                    </div>
                  ) : (
                    "Delete"
                  )}
                </button>
              </div>
            </div>
          </Modal>
        </div>

        <div className="col-12 pagination-container px-md-3 ml-md-1 mt-md-2 ">
          <div className="pagination-detail">
            Showing {(page - 1) * rowsPerPage + 1} - {rows?.to} of{" "}
            {rows?.total}
          </div>
          <CustomPagination
            page={page}
            totalPages={totalPages}
            onChangePage={handleChangePage}
          />
        </div>
      </div>
    </>
  );
};

export default SalesPromotionRequestListTable;
