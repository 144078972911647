import React, { useState } from 'react'
import CallDisconnectIcon from '../../../assets/images/VideoCall/CallDisconnectIcon.svg'
import MutedIcon from '../../../assets/images/VideoCall/MutedIcon.svg'
import VideoMutedIcon from '../../../assets/images/VideoCall/VideoMutedIcon.svg'
import MicUnMuted from '../../../assets/images/VideoCall/MicUnMuted.svg'
import VideoUnMute from '../../../assets/images/VideoCall/VideoUnMute.svg'
import { useNavigate } from 'react-router-dom'

const CustomControlBar = ({isMicMute, isVideoMute, isDisconnected, handleToggleMic, handleToggleVideo, handleDisconnect}) => {

    const navigate = useNavigate();
    
  return (
    <div className='ContralBarDiv d-flex justify-content-center py-2'>

        {!isDisconnected ? 

            <>
            <div className='ControlButtonsDiv' onClick={handleToggleMic}>
                <img src={isMicMute ? MutedIcon : MicUnMuted} width={'30px'} height={'30px'} ></img>
            </div>

            <div className='disconnectButton' onClick={handleDisconnect}>
                <img src={CallDisconnectIcon} width={'30px'} height={'30px'} ></img>
            </div>

            <div className='ControlButtonsDiv' onClick={handleToggleVideo}>
                <img src={isVideoMute ? VideoMutedIcon : VideoUnMute} width={'30px'} height={'30px'} ></img>
            </div>
            </>

            :

            <div className='homeScreenButton cursor-pointer' onClick={() => navigate('/appointment')}>
                Return to home screen
            </div>
        }

    </div>
  )
}

export default CustomControlBar;