import { useState, useEffect, useRef } from "react";
import "../../assets/css/Availability.scss";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const Availability = ({
  setStartDateParent,
  setEndDateParent,
  setAppointmentDate,
  appointmentDate,
  slotData,
  setModal1Open,
  setValue,
  setErrorHanding,
}) => {
  
  const emptySlotsData = slotData?.filter((dateObj) =>
    dateObj.slots.every((slot) => slot.is_available === 0)
  );

  const AvailableSlots = slotData?.filter((slot) => slot?.slots?.length > 0);

  const [slotsdate, setSlotsdate] = useState();
  const [totalDays, setTotalDays] = useState(30);
  const tempDate = new Date();

  const handleDateChange = (dates) => {
    setSelectedDate(dates);

    const day = dates.getDate().toString().padStart(2, "0");
    const month = (dates.getMonth() + 1).toString().padStart(2, "0");
    const year = dates.getFullYear();

    // Concatenate the formatted day, month, and year.
    const formattedDate = `${year}-${month}-${day}`;

    setAppointmentDate({ ...appointmentDate, selected_date: formattedDate });
    setValue('date', formattedDate, { shouldValidate: true });

    const slotDataFind = slotData?.find((item) => {
      return formattedDate === item.date;
    });

    if (slotDataFind) {
      setSlotsdate(slotDataFind.slots);
    }
  };

  const handleChange = (doctor_availability_slot_id, start_time, end_time) => {
    setAppointmentDate({
      ...appointmentDate,
      start_slot: start_time.slice(0, 5),
      end_slot: end_time.slice(0, 5),
      doctor_availability_slot_id: doctor_availability_slot_id,
    });

    setValue('time', start_time.slice(0, 5), { shouldValidate: true });
  };

  useEffect(() => {
    setSlotsdate([]);
  },[slotData])

  const [selectedDate, setSelectedDate] = useState(new Date());

  const highlightDates = (date) => {

    const currentDate = new Date();
    const timeDiff = date.getTime() - currentDate.getTime();
    const diffDays = timeDiff / (1000 * 3600 * 24); // Calculate the difference in days

    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;

    if(AvailableSlots?.some((item) => item.date === formattedDate)){
      return 'highlighted-dates';
    }
    else if(date?.getMonth() === tempDate?.getMonth() && moment(date)?.format('DD') >= moment(tempDate)?.format('DD')){
      return 'special-date'
    }
    else if(date?.getMonth() > tempDate?.getMonth()){
      return 'special-date'
    }
    
  };

  const disabledDates = emptySlotsData?.map((item) => new Date(item.date));

  return (
    <>
      <div className="Availbility-container w-100 d-flex flex-column flex-md-row">
        <div className="w-100 date-picker-availability">
          <div className="w-100" style={{ borderBottom: "1px solid #007bff", paddingBottom: "37.5px" }}>
            <h3 className="date-h3">Date</h3>
          </div>
          <div className=" w-100" style={{ margin: "auto" }}>
            <DatePicker
              open
              selected={selectedDate}
              minDate={new Date()}
              maxDate={new Date(tempDate?.getFullYear(), 11, 31)}
              format="YYYY-MM-DD" // Set the date format
              className="custom-datepicker "
              onChange={handleDateChange}
              dayClassName={highlightDates}
              excludeDates={disabledDates}
            />
          </div>
        </div>

        <div className="w-100">
          <div
            style={{
              borderBottom: "1px solid #007bff",
              paddingBottom: "37.5px",
            }}
          >
            <h3 className="date-h3 ">Time</h3>
          </div>
          <div
            className="border pl-3 py-2 three-side-shadow-hover"
            style={{
              marginTop: "50px",
              height: "14.3rem",
              overflowY: "scroll",
              borderRadius: "3px",
            }}
          >
            {slotsdate?.length > 0 ? (
              slotsdate?.map((slot, i) => {
                const {
                  start_time,
                  end_time,
                  doctor_availability_slot_id,
                  is_available,
                } = slot;

                return (
                  <div
                    className="times-selector d-flex justify-content-between align-items-center "
                    key={i}
                  >
                    <div className="d-flex align-items-center">
                      <div
                        className="d-flex"
                        style={{
                          width: "15px",
                          height: "15px",
                          marginBottom: "1.3rem",
                        }}
                      >
                        <input
                          id={doctor_availability_slot_id}
                          disabled={!is_available}
                          type="radio"
                          name="time_slot"
                          value={doctor_availability_slot_id}
                          onChange={() =>
                            handleChange(
                              doctor_availability_slot_id,
                              start_time,
                              end_time
                            )
                          }
                        />
                      </div>
                      <p className="mb-0 pl-2" style={{ fontWeight: "700" }}>
                        {start_time.slice(0, 5)} - {end_time.slice(0, 5)}
                      </p>
                    </div>
                    {is_available != 1 ? (
                      <div
                        className="mt-2 pr-3 d-flex justify-content-end"
                        style={{ width: "50%" }}
                      >
                        <h6 style={{ fontWeight: "500", color: "red" }}>
                          Not Available
                        </h6>
                      </div>
                    ) : (
                      <div
                        className="mt-2 mr-3 d-flex justify-content-end"
                        style={{ width: "50%" }}
                      >
                        <h6 style={{ fontWeight: "700", color: "green" }}>
                          Available
                        </h6>
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <div className="d-flex justify-content-center align-items-center h-100 w-100">
                Nothing to show for selected date.
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Availability;
