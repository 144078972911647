import { useState } from "react";
import axios from "axios";
import { CustomToast } from "../atoms/toastMessage";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const usePost = () => {
  const BaseURL = process.env.REACT_APP_BASE_URL;
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  // const token = "127|juaOCZEl1fB0270M5PnwASGgkiPZ18ysci19JXGj";
  const token = localStorage.getItem("token");
  // console.log("tokenlll", token)
  const postData = async (url, postData, cb, nonAdminUrl) => {
    setIsLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.post(`${nonAdminUrl ? nonAdminUrl : BaseURL}/${url}`, postData, config);
      if (response?.status === 200) {
        cb(response?.data);
      } 
      else {
        toast?.error(response?.data?.response?.slice(0, 60));
      }
      setData(response.data);
    } catch (error) {
      setError(error);
      toast?.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { data, isLoading, error, postData };
};

export default usePost;
