// AuthSlice.js

import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    token: null,
    profile_pic: null,
    role: null,
    item: null
  },
  reducers: {
    setAuthentication: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.profile_pic = action.payload.profile_pic;
      state.role = action.payload.role;
      state.item = action.payload.item;
    },
    clearAuthentication: state => {
      state.user = null;
      state.token = null;
      state.profile_pic = null;
      state.role = null;
      state.item = null;
    },
  },
});

export const { setAuthentication, clearAuthentication } = authSlice.actions;

export default authSlice.reducer;
