import React, { useState } from "react";

import DoctorForm from "../../organisms/addDoctor";
import DeletConfirmation from "../../atoms/deletConfirmation";
import useDeleteData from "../../customHook/useDelete";
import { useNavigate } from "react-router-dom";
import {CustomToast} from "../../atoms/toastMessage";

const DoctorSetting = ({ rawData, id }) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const { isLoading, error, deleteData } = useDeleteData();
  const [items, setItems] = useState([]);
  const navigate = useNavigate();

  const handleDelete = () => {
    deleteData(`${process.env.REACT_APP_DELETE_DOCTOR}/${id}`, () => {
    
      setDeleteModal(false);
      navigate("/doctors");
      CustomToast({
        type: 'success',
        message: 'Doctor Deleted Successfuly!',
      });
      // const filter = rows.filter((val) => val.id !== deleteState);
      // setRows(filter);
    });
  };

  const handleAddItem = () => {
    const newItemObject = {
      id: items.length + 1,
    };
    setItems([...items, newItemObject]);
  };
  const handleRemoveItem = (index) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
  };

  return (
    <>
      <div className="col-12 px-3 my-4 pt-lg-3">
        <p className="mb-0 doc-setting-text">Settings</p>

        <div className="row mt-4 ">
          <div className="col-lg-8">
            <div className="doc-setting-col-left">
              
              <div className="col-12 pt-2">
                <p className="mb-0 mt-4 doc-setting-col-left-text">
                  {" "}
                  Personal Information
                </p>
              </div>

              <hr className="my-1" />
              <DoctorForm id={id} rawData={rawData} items={items} handleAddItem={handleAddItem} handleRemoveItem={handleRemoveItem} />

            </div>
          </div>

          <div className="col-lg-4 mt-lg-0 mt-4">
            
          </div>
        </div>

      </div>
      <DeletConfirmation
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        handleDelete={handleDelete}
        isLoading={isLoading}
      />
    </>
  );
};

export default DoctorSetting;
