import React, { useEffect, useState } from "react";
import useFetch from "../../../customHook/useFetch";
import axios from "axios";
import NewLiveKitRoom from "./NewLiveKitRoom";
import moment from "moment";
import ReconnectingCall from "./ReconnectingCall";
import CustomControlBar from "./CustomControlBar";
import { Room } from "livekit-client";
import { toast } from "react-toastify";

const AppointmentVideoScreen = ({ id, patient }) => {
  const [isDisconnected, setIsDisconnected] = useState(false);
  const [tempParticipants, setTempParticipants] = useState([]);

  const current_time = moment().format("HH:mm:ss");
  const current_date = moment().format("YYYY-MM-DD");

  let serverUrl = "wss://gc-engine.winwithwits.com";
  const [Token, setToken] = useState(null);

  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const room = new Room({
    publishDefaults: { simulcast: false },
    adaptiveStream: true,
  });

  useEffect(() => {
    try {
      if (serverUrl && Token) {
        (async () => {
          await room.connect(serverUrl, Token);
          await room.localParticipant.enableCameraAndMicrophone(true);
        })();
      }
    } catch (err) {}
  }, [serverUrl, Token]);

  useEffect(() => {
    if (id) {
      (async () => {
        try {
          const res = await axios.get(
            `https://api.maweidi.com.kw/api/get_tokens/${id}?current_time=${current_time}&current_date=${current_date}`,
            config
          );
          setToken(res?.data?.doctor_token);
        } catch (err) {
          toast.error(err?.response?.data?.error);
        }
      })();
    }
  }, [id]);

  return (
    <div className="row px-4" style={{ paddingBottom: "200px" }}>
      <div className="col-12 py-5">Appointment</div>

      <div className="col-12 p-0 rounded-lg" style={{ height: "100vh" }}>
        <NewLiveKitRoom
          serverUrl={serverUrl}
          Token={Token}
          setTempParticipants={setTempParticipants}
          patient={patient}
          isDisconnected={isDisconnected}
          setIsDisconnected={setIsDisconnected}
          room={room}
        />
      </div>
    </div>
  );
};

export default AppointmentVideoScreen;
