import React from "react";
import Chevron from "../../assets/images/common/chevron-right.svg";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/css/blooddonation.scss";
const BreadCrum = ({ mainModuleName, breadCrumbItems, firstText, firstLink, secondText, }) => {

  const navigate = useNavigate();

  return (
    <p className="blooddonation-breadcrumb">

    {breadCrumbItems ? 
      
      breadCrumbItems?.map((item, index) => {
        return <>
          {index !== 0 && <img src={Chevron} alt="" />}
          <span 
            className={`${index === breadCrumbItems?.length - 1 && 'current-tab'}`}
            onClick={() => {
              if(item?.href){
                navigate(item?.href);
              }
            }}
            style={{ cursor: index !== 0 && 'pointer' }}
          > {item?.title}</span>
        </>
      })
      
      : 

      <>

      <Link to="/dashboard">
        <span>DASHBOARD</span>
      </Link>

      {firstLink && (
        <>
          <img src={Chevron} alt="" />
          <Link to={firstLink}>
            <span className={!secondText && 'current-tab'}> {firstText}</span>
          </Link>
        </>
      )}
      {secondText && (
        <>
          <img src={Chevron} />
          <span className="current-tab" style={{height:'100px'}}> {secondText}</span>
        </>
      )}

      </>

    }

    </p>
  );
};

export default BreadCrum;
