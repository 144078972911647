
const completeStatus = [
    { label: 'Pending', value: '0' },
    { label: 'Approved', value: 1 },
    { label: 'Declined', value: 2 },
    { label: 'Completed', value: 3 }
];

const fourAcceptedStatus = [
    { label: 'Pending', value: '0' },
    { label: 'Accepted', value: 1 },
    { label: 'Declined', value: 2 },
    { label: 'Completed', value: 3 }
];

const threeListStatus = [
    { label: 'Pending', value: '0' },
    { label: 'Approved', value: 1 },
    { label: 'Declined', value: 2 }
];

export default {
    completeStatus,
    threeListStatus,
    fourAcceptedStatus
}