import React, { useState } from "react";
import { InputNumber, Modal, Switch } from "antd";
import CustomSelect from "./../../common/CustomSelect";
import closeIcon from "../../../assets/images/common/close.svg";
import Dollar from "../../../assets/images/laboratory/bloodtest/dollar.svg";
import "../../../assets/css/laboratory/modal.scss";
import useFetch from "../../../customHook/useFetch";
import CustomDropDown from "../../../atoms/CustomDropDown/Index";
import usePost from "../../../customHook/usePost";
import { CustomToast } from "../../../atoms/toastMessage";
import { useEffect } from "react";
import useDeleteData from "../../../customHook/useDelete";
import CustomButton from "../../../molecules/CustomButton/CustomButton";
import ArabicInput from "../../../molecules/ArabicInput";
import { useTranslation } from "react-i18next";
import ArabicTextArea from "../../../molecules/ArabicTextArea/ArabicTextArea";
import InputLabel from "../../../molecules/TextInput/InputLabel";
import { yupResolver } from "@hookform/resolvers/yup";
import formschemas from "../../../formvalidation/formschemas";
import { useForm } from "react-hook-form";
import TextInput from "../../../molecules/TextInput/TextInput";

const AddXrayModal = ({
  open,
  onClose,
  xrays,
  title = "Add",
  Id,
  setId,
  setModalNav,
  modalNav,
}) => {
  const handleCancel = () => {
    setId && setId('');
    onClose();
  };
  const [addTest, setAddTest] = useState({});
  const addTestCategory = useFetch(
    `${process.env.REACT_APP_GET_BLOOD_TEST_CATEGORIES}?is_blood_test=${0}`
  );

  const addTestCategoryLab = useFetch(
    `${process.env.REACT_APP_GET_LABORATORY_DATA}?is_laboratory=${0}`
  );

  const addTestCategoryFilter = addTestCategory?.data?.data?.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const addTestLabFilter = addTestCategoryLab?.data?.data?.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const deleteProductData = useDeleteData();
  const deleteData = deleteProductData.deleteData;

  const getDataById = (Id) => {
    deleteData(
      `${process.env.REACT_APP_GET_BY_ID_BLOOD_TEST}/${Id}`,
      (response) => {
        setAddTest(response?.data);
        reset(response?.data);
      }
    );
  };

  useEffect(() => {
    if (Id) {
      getDataById(Id);
    }
  }, [Id]);

  useEffect(() => {
    if (!open) {
      setAddTest({});
      reset();
    }
  }, [open]);

  const { data, isLoading, error, postData } = usePost();

  const handleSubmitForm = () => {
    if (
      addTest.title === "" ||
      addTest.price === "" ||
      addTest.discount_price === "" ||
      addTest.xray_lab_id === "" ||
      addTest.blood_test_category_id === ""
    ) {
      CustomToast({
        type: "error",
        message: `Please fill in all fields before submitting.`,
      });
    } else {
      postData(
        Id
          ? `${process.env.REACT_APP_UPDATE_BLOOD_TEST}/${Id}`
          : `${process.env.REACT_APP_ADD_BLOOD_TEST}`,
        addTest,
        (res) => {
          if (res?.success === true) {
            CustomToast({
              type: "success",
              message: Id ? 'Test updated successfully.' : `Test added successfully.`,
            });
            handleCancel();
            setModalNav(!modalNav);
          }
        }
      );
    }
  };

  const {
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(formschemas?.labTestSchema('xray')),
  });

  const { t } = useTranslation();

  return (
    <Modal
      className="add-test-modal"
      open={open}
      centered
      closable={false}
      onCancel={handleCancel}
      destroyOnClose={true}
      footer={null}
      width={837}
    >
      <div className="modal-content-wrapper">
        <div className="title-header">
          <div className="title">{title} Test</div>
          <img src={closeIcon} alt="" onClick={() => {
            handleCancel();
          }} />
        </div>
        <hr style={{ margin: "0px " }} />

        <div class="form-wrapper">
          <div class="switch-wrapper">
            {/* <Switch
                className="switch"
                checkedChildren="Active"
                unCheckedChildren="InActive"
                defaultChecked
              /> */}
          </div>

          <div className="row ">

            <TextInput 
              label={'Test Name'} 
              inputDivClass='col-lg-3 pl-3 pr-1'
              labelTag={'label'}
              type='text' 
              name='title' 
              handleOnChange={(e) => {
                setAddTest({ ...addTest, title: e.target.value });
              }}
              value={addTest?.title} 
              required={true}
              control={control} 
              error={errors?.title?.message} 
            />

            <ArabicInput
              divClass="form-group full-width col-3 pr-1 pl-1 mb-0"
              label={t("Xray.TestName")}
              labelTag='label'
              formStateData={addTest}
              setFormStateData={setAddTest}
              name="title_ar"
            />

            <CustomDropDown
              label={'Xray Lab'}
              labelClass={'none'}
              labelTag={'label'}
              required={true}
              arLabel={t('Xray.Test.XrayLab')}
              dropDownDivClass={'col-lg-6 pr-3 pl-1'}
              defaultValue="Select"
              style={{
                width: "100%",
              }}
              name="xray_lab_id"
              value={addTest?.xray_lab_id || ""}
              handleChangeSelect={(value, name) => {
                setAddTest({ ...addTest, xray_lab_id: value });
              }}
              option={addTestLabFilter}
              isController={true}
              control={control}
              error={errors?.xray_lab_id?.message}
            />
          </div>

          <div className="row ">

            <CustomDropDown
              label={'Test Panel Section'}
              labelClass={'none'}
              labelTag={'label'}
              required={true}
              arLabel={t('Common.TestPanelSection')}
              dropDownDivClass={'col-lg-6 pl-3 pr-1'}
              defaultValue="Select"
              style={{
                width: "100%",
              }}
              name="blood_test_category_id"
              value={addTest?.blood_test_category_id || ""}
              handleChangeSelect={(value, name) => {
                setAddTest({ ...addTest, blood_test_category_id: value });
              }}
              option={addTestCategoryFilter}
              isController={true}
              control={control}
              error={errors?.blood_test_category_id?.message}
            />

            <TextInput 
              label={'Price'} 
              arLabel={t('Common.Price')}
              inputDivClass='col-lg-6 pr-3 pl-1'
              labelTag={'label'}
              required={true}
              type='text' 
              name='price' 
              handleOnChange={(e) => {
                setAddTest({ ...addTest, price: e.target.value });
              }}
              value={addTest?.price} 
              control={control} 
              error={errors?.price?.message} 
            />
            
          </div>

          <div className="row">

            <TextInput 
              label={'Description'}
              inputDivClass='col-6 form-group full-width pr-1 pl-3'
              labelTag={'label'}
              type='text' 
              textArea={true}
              name='description' 
              handleOnChange={(e) => {
                setAddTest({ ...addTest, description: e.target.value });
              }}
              value={addTest?.description} 
              control={control} 
              error={errors?.description?.message} 
            />

            <ArabicTextArea
              divClass="col-6 form-group full-width pl-1"
              label={t("Xray.Description")}
              labelTag='label'
              formStateData={addTest}
              setFormStateData={setAddTest}
              name="description_ar"
            />

          </div>


          <CustomButton label={Id ? 'Update Test' : 'Add Test'} buttonWidth='20%' buttonHeight='45px' buttonDivClass={'col-12 p-0 mt-5 d-flex justify-content-end'} handleOnClick={handleSubmit(handleSubmitForm)} isLoading={isLoading} />

        </div>
      </div>
    </Modal>
  );
};

export default AddXrayModal;
