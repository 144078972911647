import React, { useEffect, useState } from 'react'
import useDeleteData from '../../customHook/useDelete';
import usePost from '../../customHook/usePost';
import { toast } from 'react-toastify';

const FiltersHelper = (deleteApiMethod, DropDownItems, ListName) => {

    const [hospitals, setHospitals] = useState([]);
    const [isDeleted, setIsDeleted] = useState(false);
    const { deleteData } = useDeleteData();
    const { postData } = usePost();
    const [filtersData, setFiltersData] = useState({
        title: '',
        isSortBy: '',
        status: '',
        service_provider_id: '',
        hospital_id: '',
        role_type_id: ''
    });
    const [otherStates, setOtherStates] = useState({
        isStatusModalopen: false
    });
    const [isSortedItemSelected, setIsSortedItemSelected] = useState(null);
    const listStatusApiHook = usePost();

    // Handlers

    const handleOnFilterChange = (e) => {

        const { name, value } = e?.target ? e?.target : e;
        setFiltersData({...filtersData, [name]: value ? value : ''})

    }

    const handleSortByAge = (keyOne, keyTwo) => {
        if (filtersData?.isSortBy === "" || (filtersData?.isSortBy !== keyOne && filtersData?.isSortBy !== keyTwo)) {
            setFiltersData({...filtersData, isSortBy: keyOne});
        } else if (filtersData?.isSortBy === keyOne) {
            setFiltersData({...filtersData, isSortBy: keyTwo});
        } else {
            setFiltersData({...filtersData, isSortBy: keyOne});
        }
    };

    const handleSortIcons = (keyOne, keyTwo) => {

        if(filtersData?.isSortBy === '' || (filtersData?.isSortBy !== keyOne && filtersData?.isSortBy !== keyTwo)){
            return 'fa-arrow-up'
        }
        else if(filtersData?.isSortBy === keyOne){
            return 'fa-arrow-down'
        }
        else{
            return 'fa-arrow-up'
        }
    }

    const handleDeleteList = (listDeleteEndPoint, listID) => {

        if(deleteApiMethod === 'Post Delete'){

            postData(`${listDeleteEndPoint}/${listID}`, {}, (response) => {
                if(response?.success){
                    toast.success('Successfully deleted list.');
                    setIsDeleted(!isDeleted);
                }
            })

        }
        else{

            deleteData(`${listDeleteEndPoint}/${listID}`, (response) => {
                if(response?.success){
                    toast.success('Successfully deleted list.');
                    setIsDeleted(!isDeleted);
                }
            })

        }
    
    }
    
    const handleChangeListStatus = (listStatusEndPoint, statusNo, listID) => {
            
        listStatusApiHook?.postData(`${listStatusEndPoint}/${listID}`, {status: statusNo}, (response) => {
            if(response?.success){
                toast.success('Successfully updated list status.');
                setIsDeleted(!isDeleted);
            }
        })
    
    }

    const handleTruncateText = (text, truncLength) => {

        if(text){
    
          if(truncLength){
            return text?.length > truncLength ? text?.slice(0, truncLength) + "..." : text;
          }
          else return text;
    
        }
        else{
          return '-'
        }
    }

    const handleSortByClick = ({ key }) => {
        if (filtersData?.isSortBy == DropDownItems[key]?.sortBy) {
        setFiltersData({...filtersData, isSortBy: ''});
        setIsSortedItemSelected(null);
        } else {
        setFiltersData({...filtersData, isSortBy: DropDownItems[key]?.sortBy});
        setIsSortedItemSelected(key);
        }
    };

    // UseEffects

    useEffect(() => {

        if(DropDownItems){
            const check = DropDownItems?.some((item) => item?.sortBy === filtersData?.isSortBy);
            if (!check) setIsSortedItemSelected(null);
        }
        
    }, [filtersData?.isSortBy]);

    useEffect(() => {

        if(ListName === 'Booked Hosp Rooms List'){

            deleteData('get_hospitals', (response) => {
                
                setHospitals(response?.data?.map(hospital => ({
                    label: hospital?.name,
                    value: hospital?.id
                })));
    
            })

        }
        else {

            deleteData('list_of_service_provider', (response) => {
            
                setHospitals(response?.data?.service_providers?.map(provider => ({
                    label: provider?.name,
                    value: provider?.id
                })));
    
            })

        }
    },[])

    return {

        filterStates: {
            hospitals,
            filtersData,
            otherStates,
            isDeleted,
            isSortedItemSelected,
            setFiltersData,
        },

        filterFuncs: {
            setOtherStates,
            handleOnFilterChange,
            handleSortByClick,
            handleSortByAge,
            handleSortIcons,
            handleChangeListStatus,
            handleDeleteList,
            handleTruncateText
        },

        apiHooks: {
            listStatusApiHook
        }
        
    }

}

export default FiltersHelper;