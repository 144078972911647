import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import sohaibavatar from "../../assets/images/dashboard/sohaibavatar.png";
import { Box } from "@mui/material";
import CustomPagination from "../common/CustomPagination";
import prescriptionSVG from "../../assets/images/common/prescription.svg";
import Cross from "../common/Cross";
import Tick from "../common/Tick.js";
import "../../assets/css/common/datatable.scss";
import PageLoader from "../../atoms/pageLoader";
import useFetch from "../../customHook/useFetch";
import ListSkeleton from "../../molecules/ListSkeleton/ListSkeleton";
import { Modal } from "antd";
import { CustomToast } from "../../atoms/toastMessage";
import useDeleteData from "../../customHook/useDelete";
import { Link, useNavigate } from "react-router-dom";
import VideoCallIcon from "../../assets/images/VideoCall/VideoCallIcon.svg";
import moment from "moment";
import FiltersHelper from "../../molecules/HospitalRoomManagement/helper.js";
import { toast } from "react-toastify";
import usePost from "../../customHook/usePost.js";
import RightArrowSpec from "../../assets/images/doctor/RightArrowSpec.svg";
import DocumentIcon from "../../assets/images/doctor/BrowseDocumentIcon.svg";

const DataTable = ({
  filterOption,
  isLoading,
  paramTitle,
  isSortBy,
  dateRange,
}) => {
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };
  const navigate = useNavigate();
  const [statusChange, setStatusChange] = useState({});
  const [editModal, setEditModal] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataList, setDataList] = useState("");

  useEffect(() => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    if (filterOption === "Today") {
      setDataList(
        `${today.getFullYear()}-${(today.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`
      );
    } else if (filterOption === "Tomorrow") {
      setDataList(
        `${tomorrow.getFullYear()}-${(tomorrow.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${tomorrow.getDate().toString().padStart(2, "0")}`
      );
    } else if (filterOption === "Yesterday") {
      setDataList(
        `${yesterday.getFullYear()}-${(yesterday.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${yesterday.getDate().toString().padStart(2, "0")}`
      );
    } else {
      setDataList("All");
    }
  }, [filterOption]);

  const {data: getAppointment, isLoading: isFetchLoading, fetchPaginatedData} = useFetch(
    `${process.env.REACT_APP_GET_APPOINTMENTS}?per_page=${rowsPerPage}&page=${page}&title=${paramTitle}&sort_by=${isSortBy}&start_date=${dateRange?.start_date}&end_date=${dateRange?.end_date}`
  );

  useEffect(() => {
    setPage(1);
  }, [paramTitle, isSortBy, dateRange]);

  const rows = getAppointment;

  const totalRows = rows?.data?.total;
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  const startIndex = page * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage, totalRows);
  const visibleRows = rows?.data?.data;

  const customData = useDeleteData();

  const BloodStatus = (BloodId, statusId) => {
    if (statusId === 0) {
      customData.deleteData(
        `${process.env.REACT_APP_NEEDY_PATIENT_UPDATE_STATUS}/${BloodId}`,
        (val) => {
          fetchPaginatedData(
            `${process.env.REACT_APP_GET_PATIENT}?per_page=${rowsPerPage}&page=${page}`
          );
          // const filter = rows?.data?.data?.filter(val => val.id !== deleteState)
          if (val.success === true) {
            // setDeleteModal(false)
            CustomToast({
              type: "success",
              message: "Status updated successfuly!",
            });
            setEditModal(false);
          }
        }
      );
    }
  };

  const { filterFuncs: { handleTruncateText } } = FiltersHelper();
  const { postData, isLoading: isPostLoading } = usePost();

  const handleUploadPrescription = (patientID, appointmentID) => {

    if(patientID){

      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/png, image/jpeg, image/jpg';
  
      input.onchange = (event) => {
  
        const file = event.target.files[0];
  
        if(file?.type === 'image/png' || file?.type === 'image/jpeg' || file?.type === 'image/jpg'){
  
          const formData = new FormData();
          formData?.append('prescription[]', event.target.files[0]);
          formData?.append('appointment_id', appointmentID);
  
          postData(`add_prescription/${patientID}`, formData, (response) => {
            if(response?.success){
              toast?.success("Successfully uploaded patient's prescription.");
              setTimeout(() => {
                console.log('Appointment: 31211132');
                fetchPaginatedData(
                  `${process.env.REACT_APP_GET_APPOINTMENTS}?per_page=${rowsPerPage}&page=${page}`
                );
              }, 500)
            }
          })
  
        }
        else{
          toast?.error('Please upload a file using any of these formats (png / jpg / jpeg).');
        }
  
      }
  
      input.click();

    }
    else{
      toast?.error('Patient is missing.');
    }

  }

  const [isDetailsModalopen, setIsDetailsModalOpen] = useState(null);
  const OverviewHeadings = {
    note: 'Notes',
    images: 'Patient Uploaded Images',
    prescriptions: 'Current Prescriptions'
  };

  const fetchOtherDetails = (id) => {

    let temp = visibleRows?.filter((appointment) => appointment?.id === id)[0];

    temp = {
      note: temp?.note ?? '',
      images: temp?.images || [],
      prescriptions: temp?.prescriptions || []
    };

    return (
      <div className="row">
        
        {Object?.keys(temp)?.map((key) => {

          if(key === 'note') return (
            <div className="col-12">
              <p className="mb-2 view-doc-special">
                {OverviewHeadings[key]}
              </p>
              <div
                className="d-flex w-100 pr-4"
                style={{ alignItems: "baseline" }}
              >
                <img
                  className="pr-3 view-doc-sub-special "
                  src={RightArrowSpec}
                  alt=""
                />
                <span className="view-doc-sub-special w-100" style={{ wordWrap: 'break-word'}}>
                  {temp[key] || '---'}
                </span>
              </div>
            </div>
          )
          else {
            return <div className="col-12 mt-5">
              <p className="mb-2 view-doc-special">
                {OverviewHeadings[key]}
              </p>
              {temp[key]?.length > 0 ? (
                <div
                  className="row m-0"
                  style={{
                    height:
                      temp[key]?.length > 4
                        ? "127px"
                        : "63px",
                    overflowY:
                      temp[key]?.length > 4 &&
                      "scroll",
                    background: "#f5f5f5",
                    borderRadius: "5px",
                    border: "1px solid #f5f5f5",
                  }}
                >
                  {temp[key]?.map((prescription) => {
                    return (
                      <div
                        className="col-3 pt-2 d-flex"
                        style={{
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <a
                          href={`https://api.maweidi.com.kw/${prescription[key === 'images' ? 'image' : 'prescription']}`}
                          target="_blank"
                        >
                          <img
                            src={DocumentIcon}
                            className="" alt=""
                          ></img>
                        </a>
                        <span
                          className="view-doc-sub-special"
                          style={{ fontSize: "10px" }}
                        >
                          {prescription?.original_name?.slice(0, 17)}
                        </span>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div
                  className="d-flex"
                  style={{ alignItems: "baseline" }}
                >
                  <img
                    className="pr-3 view-doc-sub-special "
                    src={RightArrowSpec}
                  />
                  <span className="view-doc-sub-special">---</span>
                </div>
              )}
            </div>
          }
        })}
      </div>
    )

  }

  return (
    // isLoading ? <PageLoader /> :
    <>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: "#FFFFFF" }}
        className="custom-scroll"
      >
        <Table aria-label="simple table">
          <TableHead
            sx={{
              "& th": {
                color: "#193F52",
                whiteSpace: "nowrap",
                wordWrap: "break-word",
              },
            }}
          >
            <TableRow>
              <TableCell className="number" align="left">
                #
              </TableCell>
              <TableCell align="left">Patient Name</TableCell>
              <TableCell align="left">Patient Email</TableCell>
              <TableCell align="left">Date</TableCell>
              <TableCell align="left">Time</TableCell>
              <TableCell align="left">Hospital Name</TableCell>
              <TableCell align="left">Doctor Name</TableCell>
              <TableCell align="left">Specialization</TableCell>
              <TableCell align="left">Phone No</TableCell>
              <TableCell align="left">Appointment Type</TableCell>
              <TableCell align="left">Fees</TableCell>
              <TableCell align="left">Prescription</TableCell>
              <TableCell align="left">Other Details</TableCell>
              <TableCell align="center">Appointment Status</TableCell>
            </TableRow>
          </TableHead>

          <TableBody
            sx={{
              "& td": {
                color: "#767676",
                whiteSpace: "nowrap",
                wordWrap: "break-word",
              },
            }}
          >
            {!isFetchLoading && !isPostLoading ? 
              visibleRows?.length > 0 ? visibleRows?.map((row, index) => {
                return (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left" className="number">
                      {" "}
                      {(page - 1) * rowsPerPage + index + 1}
                    </TableCell>
                    <TableCell align="left">
                      {handleTruncateText(row?.user?.name, 10)}
                    </TableCell>
                    <TableCell align="left">
                      {row?.user?.email}
                    </TableCell>
                    <TableCell align="left">{row.date}</TableCell>
                    <TableCell align="left">
                      {row?.doctor_availability_slots?.start_time
                        ? moment(
                            row?.doctor_availability_slots?.start_time,
                            "HH:mm"
                          ).format("HH:mm")
                        : "-"}
                    </TableCell>
                    <TableCell align="center">
                      {handleTruncateText(row?.hospital?.name, 20)}
                    </TableCell>
                    <TableCell align="left">{handleTruncateText(row.doctor?.user?.name, 20)}</TableCell>
                    <TableCell align="left">
                      {row?.specialization?.name
                        ? row?.specialization?.name
                        : "-"}
                    </TableCell>
                    <TableCell align="left">
                      {row?.phone ? row?.phone : "-"}
                    </TableCell>
                    <TableCell align="center">
                      {row?.is_online === 1 ? (
                        <div className="mb-0 d-flex justify-content-center">
                          <button
                            className="py-1"
                            style={{
                              borderRadius: "5px",
                              backgroundColor: "#125A7726",
                              border: "1px solid #125A77",
                              color: "#125A77",
                              width: "90px"
                            }}
                            onClick={() =>
                              navigate(`/appointment/video-call/${row?.id}`, {
                                state: {
                                  name: row?.user?.name,
                                  profile_pic: row?.user?.profile_pic,
                                },
                              })
                            }
                          >
                            Video Call
                          </button>
                        </div>
                      ) : (
                        <div className="mb-0 d-flex justify-content-center">
                          <button
                            className="py-1"
                            disabled={true}
                            style={{
                              borderRadius: "5px",
                              backgroundColor: "#125A7726",
                              border: "1px solid #125A77",
                              color: "#125A77",
                              width: "90px",

                            }}
                          >
                            Appointment
                          </button>
                        </div>
                      )}
                    </TableCell>

                    <TableCell align="left">{row.fees}</TableCell>
                    <TableCell align="center"><img src={prescriptionSVG} alt="" className="cursor-pointer" onClick={() => handleUploadPrescription(row?.user?.id, row?.id)} ></img></TableCell>
                    <TableCell align="center"><button className="customButton" style={{width: '100px', height: '30px'}} onClick={() => setIsDetailsModalOpen(row?.id)} >View</button></TableCell>
                    <TableCell
                      align="center"
                    >
                      <div
                        className="mb-0 d-flex justify-content-center"
                        onClick={() => {
                          setStatusChange((pre) => ({
                            ...pre,
                            id: row.id,
                            status: row.status,
                          }));
                          setEditModal(true);
                        }}
                        style={{}}
                      >
                        <button
                          className=" py-1"
                          disabled={row.status == 1}
                          style={{
                            borderRadius: "5px",
                            backgroundColor:
                              row.status == 1 ? "#ccfce3" : "#f7eed0",
                            border:
                              row.status == 1
                                ? "1px solid #50CD89"
                                : "1px solid #FFC107",
                            color: row.status == 1 ? "#50CD89" : "#ffa807",
                            cursor: !row.status == 1 ? "pointer" : "no-drop",
                            width: "100px",
                          }}
                        >
                          {row.status === 1 ? "Approved" : "Pending"}
                        </button>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })

              :

                <TableRow>
                  <TableCell align="center" colSpan={11}>
                    No data found.
                  </TableCell>
                </TableRow>
              
              : (
                <TableRow>
                  <TableCell colSpan={14}>
                    <ListSkeleton totalRow={5} totalCol={14} image={false} />
                  </TableCell>
                </TableRow>
                )
            }
          </TableBody>
        </Table>
      </TableContainer>

      <div className="pagination-container px-md-3 ml-md-1 mt-md-2 ">
        <div className="pagination-detail">
          Showing {(page - 1) * rowsPerPage + 1} - {rows?.data?.to} of{" "}
          {rows?.data?.total}
        </div>
        <CustomPagination
          page={page}
          totalPages={totalPages}
          onChangePage={handleChangePage}
        />
      </div>

      <Modal
        centered
        open={isDetailsModalopen !== null}
        className=""
        onCancel={() => setIsDetailsModalOpen(null)}
        closeIcon={null}
        footer={null}
        width={'40%'}
      >
        {isDetailsModalopen && fetchOtherDetails(isDetailsModalopen)}
      </Modal>

      <Modal
        className="doctor-filter-modal"
        centered
        open={editModal}
        // onOk={() => setModal2Open(false)}
        onCancel={() => setEditModal(false)}
        width={514}
        footer={null}
        closable={false}
      >
        <div className="row pb-1">
          <div className="col-12 d-flex flex-column align-items-center justify-content-center pharmacy-delete">
            <p className="mb-0 pt-lg-5 pt-3 pb-4 mt-lg-3">
              Are you sure you want to change the status?
            </p>
            <button
              className="mt-lg-4 mt-1 mb-lg-5 mb-2"
              style={{
                borderRadius: "5px",
                backgroundColor: "#ccfce3",
                border: "1px solid #50CD89",
                color: "#50CD89",
                cursor: "pointer",
                width: "150px",
              }}
              onClick={() =>
                BloodStatus(statusChange?.id, statusChange?.status)
              }
            >
              Approved
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DataTable;
