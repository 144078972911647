import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

// css
import "../assets/css/dashboard.scss";

// png
import FullScreenMenu from "../components/DashboardComponents/FullScreenMenu";
import SiteFooter from "../organisms/Footer/Footer";
import Header from "../organisms/Header/Header";
import Privacy from "./Privacy/Privacy";
import { fetchSpecialization } from "../redux/feature/specializationSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { onMessageListener } from "../firebase/firebase";

const Dashboard = () => {
  let location = useLocation();
  const dispatch = useDispatch();
  const [menu, setMenu] = useState(0);
  const [menuLeft, setMenuLeft] = useState("col-3");
  const [menuRight, setMenuRight] = useState("col-9");
  const [menuLeftText, setMenuLeftText] = useState("");
  const [addRole, setAddRole] = useState({ country: "Kuwait" });
  const [menuLeftRightDropDown1, setMenuLeftRightDropDown1] =
    useState("col-10");
  const [menuLeftRightDropDown2, setMenuLeftRightDropDown2] = useState("col-2");
  const [menuIconCenter, setMenuIconCenter] = useState("");
  const [menuIconLeftPadding, setMenuIconLeftPadding] = useState("pl-3");
  const [MobileMenu1, setMobileMenu1] = useState(true);
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [menuDropDownFullScreen, setMenuDropDownFullScreen] = useState({
    name: "dashboard",
    toggle: false,
    subMenuMobile: {
      subname: "",
      subtoggle: false,
    },
    patient: {
      toggle: false,
    },
    doc: {
      toggle: false,
    },
  });

  useEffect(() => {
    function handleResize() {
      setIsLargeScreen(window.innerWidth > 991);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    // Dispatch fetchSpecialization thunk when component mounts
    dispatch(fetchSpecialization());
  }, [dispatch]);
  useEffect(() => {
    if (!isLargeScreen) {
      setMenuLeft("col-0");
      setMenuRight("col-12");
    } else {
      setMenuLeft("col-3");
      setMenuRight("col-9");
    }
  }, [isLargeScreen]);

  const handleMenu = () => {
    if (isLargeScreen) {
      if (menu === 0) {
        setMenu(1);
        setMenuLeft("col-0");
        setMenuRight("col-12");
        setMenuLeftText("none");
        setMenuIconCenter("justify-content-center");
        setMenuIconLeftPadding("");
        setMenuLeftRightDropDown1("col-12");
        setMenuLeftRightDropDown2("");
      } else {
        setMenu(0);
        setMenuLeft("col-3");
        setMenuRight("col-9");
        setMenuLeftText("");
        setMenuIconCenter("");
        setMenuIconLeftPadding("pl-3");
        setMenuLeftRightDropDown1("col-10");
        setMenuLeftRightDropDown2("col-2");
      }
    } else {
    }
  };

  onMessageListener()
    .then((payload) => {
      console.log("Payload 2: ", payload);

      if (payload) {
        toast.success(
          <div>
            <p className="m-0">{payload.notification.title}</p>
            <p className="m-0">{payload.notification.body}</p>
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            rtl: false,
          }
        );
      }
    })
    .catch((err) => {
      console.log("Payload Failed: ", err);
    });

  return (
    <>
      {location.pathname === "/privacy" ? (
        <Privacy />
      ) : (
        <div className="fluid-container">
          <div className="m-0 pr-0 d-flex flex-column" style={{height: "100vh"}}>
            <div
              className={
                MobileMenu1
                  ? " notheight1 notheight2"
                  : "notheight1 mobile-screen"
              }
            >
              <div
                className={
                  MobileMenu1
                    ? " notheight "
                    : "notheight  InnerClassMobileScreen headerMobileSideBar "
                }
              >
                <i
                  class=" MobileMenu1Icon  ml-2 mt-2 fa fa-times"
                  aria-hidden="true"
                  onClick={() => setMobileMenu1(!MobileMenu1)}
                ></i>

                <FullScreenMenu
                  menuDropDownFullScreen={menuDropDownFullScreen}
                  setMenuDropDownFullScreen={(newChildData) => {
                    setMenuDropDownFullScreen(newChildData);
                  }}
                  menuIconLeftPadding={menuIconLeftPadding}
                  menuIconCenter={menuIconCenter}
                  menuLeftText={menuLeftText}
                  menuLeftRightDropDown1={menuLeftRightDropDown1}
                  menuLeftRightDropDown2={menuLeftRightDropDown2}
                  MobileMenu1={MobileMenu1}
                  setMobileMenu1={(newChildData) => {
                    setMobileMenu1(newChildData);
                  }}
                />
              </div>
            </div>

            <Header
              handleMenu={handleMenu}
              isLargeScreen={isLargeScreen}
              menuDropDownFullScreen={menuDropDownFullScreen}
              setMenuDropDownFullScreen={(data) => {
                setMenuDropDownFullScreen(data);
              }}
              menu={menu}
              setMobileMenu1={(data) => {
                setMobileMenu1(data);
              }}
              MobileMenu1={MobileMenu1}
              menuLeftText={menuLeftText}
            />

            {/* className={`sidebarMenuBorderRadius ${menuDropDownFullScreen.name === 'pharmacy' && 'selected-menu-submenu-bg'}`} */}

            <div style={{flex: "1", overflowY: "auto", overflowX: "hidden"}}>
              <div className="row" style={{ height: "100%" }}>
                {isLargeScreen ? (
                  <div
                    className={`${menuLeft}  animation-dashboard`}
                    style={{ background: "#094963", height: '100%', overflowY: 'auto', scrollbarWidth: 'none', paddingLeft: menuLeft === 'col-3' && '29px' }}
                  >
                    <FullScreenMenu
                      menuDropDownFullScreen={menuDropDownFullScreen}
                      setMenuDropDownFullScreen={(newChildData) => {
                        setMenuDropDownFullScreen(newChildData);
                      }}
                      menuIconLeftPadding={menuIconLeftPadding}
                      menuIconCenter={menuIconCenter}
                      menuLeftText={menuLeftText}
                      menuLeftRightDropDown1={menuLeftRightDropDown1}
                      menuLeftRightDropDown2={menuLeftRightDropDown2}
                      setMobileMenu1={(newChildData) => {
                        "";
                      }}
                      MobileMenu1={""}
                    />
                  </div>
                ) : (
                  ""
                )}

                <div 
                  className={`${menuRight} animation-dashboard p-0`} 
                  style={{ height: "100%", overflowY: "auto", overflowX: 'hidden', position: 'relative', background: '#F8F9FA' }}
                  >

                <div
                  style={{ background: "#F8F9FA", minHeight: '100%'   }}
                  className="px-3"
                >
                  <Outlet />
                </div>
                <SiteFooter />
              </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
