import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "antd";
import FileUploadModalCross from "../../assets/images/doctor/FileUploadModalCross.svg";
import FileBrowseCloud from "../../assets/images/doctor/FileBrowseCloud.svg";
import BrowseDocumentIcon from "../../assets/images/doctor/BrowseDocumentIcon.svg";
import BrowseDocumentDeleteIcon from "../../assets/images/doctor/BrowseDocumentDeleteIcon.svg";
import { useDropzone } from "react-dropzone";

import "./uploadfilemodal.scss";

const UploadFileModal = ({
  selectedFiles,
  setSelectedFiles,
  isModalOpen,
  setIsModalOpen,
  formDataState,
  setFormDataState,
}) => {
  // const [selectedFiles, setSelectedFiles] = useState([]);
  const [unSelectedFiles, setUnSelectedFiles] = useState([]);
  const [isFileTooLarge, setIsFileTooLarge] = useState(false);
  const [isValidformat, setIsValidFormat] = useState(true);

  const onDrop = useCallback((acceptedFiles, rejectedFiles, event) => {
    if (acceptedFiles?.length > 0) {
      acceptedFiles.map((file) => {
        // setSelectedFiles(selectedFiles => [...selectedFiles, {document:file}]);
        setFormDataState((formDataState) => ({
          ...formDataState,
          documents: [...formDataState?.documents, file],
        }));
      });
    }

    if (rejectedFiles?.length > 0) {
      setUnSelectedFiles(rejectedFiles);
      rejectedFiles.map((file) => {
        if (file.file.type !== "application/pdf") {
          setIsValidFormat(false);
        } else if (file.file.size > 2048000) {
          setIsFileTooLarge(true);
        }
      });
    }
  }, []);

  useEffect(() => {}, [formDataState]);

  const onDragOver = useCallback(() => {
    setIsValidFormat(true);
    setIsFileTooLarge(false);
  }, []);

  // const onDragLeave = useCallback(() => {
  //     if(isValidformat === true) setIsValidFormat(false);
  //     if(isFileTooLarge === false) setIsFileTooLarge(true);
  // },[])

  function Validate() {
    if (!isDragActive) {
      if (!isValidformat && !isFileTooLarge) {
        return <p className="browse-text">Document format must be pdf</p>;
      } else if (isValidformat && isFileTooLarge) {
        // if(isFileTooLarge){
        return (
          <p className="browse-text">Document size must be less than 2mb</p>
        );
        // }
      } else if (!isValidformat && isFileTooLarge) {
        return (
          <>
            <p className="browse-text">Document format must be pdf</p>
            <p className="browse-text">Document size must be less than 2mb</p>
          </>
        );
      }
    }
  }

  const {
    acceptedFiles,
    rejectedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
  } = useDropzone({
    onDrop,
    multiple: true,
    onDragOver,
    // onDragLeave,
    // onDragEnter,
    minSize: 0,
    maxSize: 2048000,
    accept: { "application/pdf": [".pdf"] },
    noKeyboard: true,
  });

  const RemoveDocument = (key) => {
    if (formDataState?.documents[key]?.id) {
      setFormDataState((formDataState) => ({
        ...formDataState,
        remove_document_ids: [
          ...formDataState?.remove_document_ids,
          formDataState?.documents[key]?.id,
        ],
      }));
    }

    const array = [...formDataState?.documents];
    array.splice(key, 1);
    setFormDataState((formDataState) => ({
      ...formDataState,
      documents: [...array],
    }));
  };

  return (
    <div className="drag-drop-modal-content">
      <div className="d-flex justify-content-between dnd-modal-header">
        <p className="modal-heading">Upload Certificates</p>
        <img
          src={FileUploadModalCross}
          className="cursor-pointer"
          onClick={() => setIsModalOpen(false)}
        ></img>
      </div>

      <div className="dnd-modal-body w-100 m-auto row">
        <div className="col-6" style={{ height: "275px", overflowY: "scroll" }}>
          {formDataState?.documents?.map((obj, index) => {
            var fileName = obj?.name ? obj?.name : obj?.original_name;
            // if(fileName.length > 16 && fileName.length >= 20){
            //     fileName = fileName.slice(0,16) + '.pdf';
            // }
            // else if(fileName.length <= 17){
            //     fileName = fileName;
            // }

            if (fileName?.length <= 17) {
              fileName = fileName;
            } else {
              fileName =
                fileName?.slice(0, 8) +
                "..." +
                fileName?.slice(fileName?.length - 6, fileName?.length);
            }

            // Remaining logic for string 17 to 19 length

            return (
              <div
                className="row mb-3 pb-2"
                style={{ borderBottom: "1px solid #E4E3E4" }}
              >
                <div className="col-lg-10 d-flex p-0">
                  <div className="">
                    <img src={BrowseDocumentIcon}></img>
                  </div>
                  <div className="d-flex align-items-center">
                    <p className="document-text">{fileName}</p>
                    {/* <p className='document-text' >Medical Training Certificate</p> */}
                  </div>
                </div>

                <div className="col-lg-2 text-right p-0 pr-1">
                  <img
                    src={BrowseDocumentDeleteIcon}
                    key={index}
                    onClick={(e) => {
                      RemoveDocument(index);
                    }}
                  ></img>
                </div>
              </div>
            );
          })}
        </div>

        <div className="col-6">
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              height: "250px",
              border: "2px dashed #828282",
              borderRadius: "20px",
              background: "#ffffff",
            }}
            {...getRootProps({
              onClick: (event) => {
                setIsFileTooLarge(false);
                setIsValidFormat(true);
              },
            })}
            //   onClick={()=> {
            //     setIsFileTooLarge(false);
            //     setIsValidFormat(true);
            //   }}
          >
            <input name="abc" value={""} {...getInputProps()} />
            <div className="d-flex flex-column align-items-center">
              {!isDragActive ? (
                <>
                  <img src={FileBrowseCloud}></img>
                  <p className="browse-text">Drag and Drop or Browse</p>
                </>
              ) : (
                <p className="browse-text">Drop your files here</p>
              )}

              {Validate()}

              {/* {!isDragActive && (isInValidformat || isFileTooLarge) && (
                            <>
                                {isInValidformat && <p className='browse-text'>Document format must be application / pdf</p>}
                                {!isInValidformat && isFileTooLarge && <p className='browse-text'>Document size must be less than 2mb</p>}
                                {isInValidformat && isFileTooLarge && 
                                <>
                                <p className='browse-text'>Document format must be application / pdf</p>
                                <p className='browse-text'>Document size must be less than 2mb</p>
                                </>}
                            </>
                        )} */}
              {/* {isDragActive && !isDragReject && <p className='browse-text'>Drop your files here</p>}
                        {isDragReject &&  <p className='browse-text'>Drag Rejected</p>} */}
            </div>
          </div>
        </div>

        <div className="col-12 dnd-modal-footer">
          <button
            style={{ background: "#D7D7D7", color: "#8C8C8C" }}
            onClick={() => {
              // setSelectedFiles([])
              setFormDataState((formDataState) => ({
                ...formDataState,
                documents: [],
              }));
            }}
          >
            Remove Files
          </button>

          <button
            style={{ background: "#175F7C", color: "#FFFFFF" }}
            className="cursor-pointer"
            onClick={() => setIsModalOpen(false)}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadFileModal;
