import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import { Rate } from 'antd';
import "../../assets/css/doctor.scss";
import "../../assets/css/pharmacy.scss";

import { Button, Modal, Rate, Select, Slider } from "antd";

// img svg
import RightArrow from "../../assets/images/doctor/RightArrow.svg";
import EditIcon from "../../assets/images/pharmacy/EditIcon.svg";
import DeleteIcon from "../../assets/images/pharmacy/DeleteIcon.svg";

import BreadCrum from "../../atoms/breadcrum/BreadCrum";
import { products } from "../../Data/PharmactData";

import arslan from "../../assets/images/Arslan.jpg";
import remove from "../../assets/images/remove-icon.svg";
import CustomDropDown from "../../atoms/CustomDropDown/Index";
import CustomDropDownMulti from "../../atoms/CustomDropDown/CustomDropDownMulti";
import usePost from "../../customHook/usePost";
import { CustomToast } from "../../atoms/toastMessage";
import useFetch from "../../customHook/useFetch";
import useDeleteData from "../../customHook/useDelete";
import CustomPagination from "../../components/common/CustomPagination";
import ButtonLoader from "../../atoms/buttonLoader";
import SpinnerLoaderModal from "../../components/common/spinnerloadermodal";
import Searchbar from "../../components/common/Searchbar";
import FiltersHelper from "../../molecules/HospitalRoomManagement/helper";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import InputLabel from "../../molecules/TextInput/InputLabel";
import TextInput from "../../molecules/TextInput/TextInput";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import formschemas from "../../formvalidation/formschemas";
import ErrorMessage from "../../formvalidation/errormessage";
import validationErrors from "../../utilities/validation.errors";
import ArabicTextArea from "../../molecules/ArabicTextArea/ArabicTextArea";
const PharmacyShop = () => {

  const { filterStates: { filtersData }, filterFuncs: { handleOnFilterChange } } = FiltersHelper();
  
  const [addProduct, setAddProduct] = useState(false);
  const [addProductData, setAddProductData] = useState({
    // other properties
    images: [], // Initialize an empty array to store image data
    is_new: 0,
    is_featured: 0,
    is_popular: 0,
    remove_image_ids: [],
  });
  const [addImageData, setAddImageData] = useState({});
  const AddProductHook = usePost();
  const [editProduct, setEditProduct] = useState(false);
  const [getId, setGetId] = useState(false);
  const [getCardId, setGetCardId] = useState(false);

  const [loading, setLoading] = useState(false);

  const [image, setImage] = useState(null);

  const [errorData, setErrorData] = useState(0);

  const [fileName, setFileName] = useState([]);

  const [deleteModal, setDeleteModal] = useState(false);
  const [listDataProduct, setListDataProduct] = useState(false);

  useEffect(() => {
    setFileName([]);
    setGetId("");
    setAddProductData("");
    setAddProductData((prev) => ({
      ...prev,
      title: "",
      price: "",
      sales_price: "",
      quantity: "",
      about: '',
      about_ar: ''
    }));
    setProductLabels([]);
    reset();
  }, [addProduct == false]);

  const handleDoctorImageClick = () => {
    // Create a file input element and trigger a click event
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    trigger('images');

    input.onchange = (event) => {
      const file = event.target.files[0];

      if(fileName?.length === 4) return;

      setImage(null);
      setFileName(
        fileName.length !== 4
          ? [...fileName, URL.createObjectURL(file)]
          : fileName
      );
      setAddProductData({
        ...addProductData,
        images: [...(addProductData?.images || []), file], // Add the new file to the existing images array
      });
      setValue('images', [...(addProductData?.images || []), file], {shouldValidate: true});

      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 2000);

    };
    input.click();
  };

  const deleteProductData = useDeleteData();
  const deleteData = deleteProductData.deleteData;
  const deleteDataLoading = deleteProductData.isLoading;

  const getDataById = (Id) => {
    deleteData(
      `${process.env.REACT_APP_GET_PRODUCT_BY_ID}/${Id}`,
      (response) => {
        setGetId(response?.data);
        setAddProductData(response?.data);
        setAddProductData((prevData) => ({
          ...prevData,
          category_id: response?.data?.categories[0]?.id,
          equipment_store_id: 0,
          pharmacy_id: response?.data?.pharmacy_id,
          remove_image_ids: [],
          about: response?.data?.about ?? '',
          about_ar: response?.data?.about_ar ?? ''
        }));

        Object?.keys(response?.data)?.forEach((item) => {
          item === 'categories' ? 
          setValue('category_id', response?.data?.categories[0]?.id) : 
          setValue(item, response?.data[item])
        });

        setProductLabels([]);
        const temp = [];

        if (response?.data?.is_new === 1) {
          temp?.push('New');
        }

        if (response?.data?.is_featured === 1) {
          temp?.push('Featured');
        }

        if (response?.data?.is_popular === 1) {
          temp?.push('Popular');
        }

        setValue('label', temp);
        setProductLabels([...temp]);
        setFileName(response?.data?.images);
      }
    );
  };

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const [pharmaciesList, setPharmaciesList] = useState([]);
  const [pharmaciesLabelsList, setPharmaciesLabelsList] = useState({});

  useEffect(() => {

    deleteData(`${process.env.REACT_APP_GET_PHARMACY_DATA}?status=1`, (response) => {

      if(response?.success){
        setPharmaciesList(response?.data?.map((store) => ({
          label: store?.name,
          value: store?.id,
        })));
  
        const tempStoresList = [...response?.data];
        const temp = {};
  
        for(let i = 0; i < tempStoresList?.length; i++){
          temp[tempStoresList[i]?.id] = tempStoresList[i]?.name;
        }
        setPharmaciesLabelsList({...temp});
      }

    })

  },[])

  const { data, isLoading, error, fetchPaginatedData } = useFetch(
    `${
      process.env.REACT_APP_GET_LIST_PRODUCT
    }?per_page=${rowsPerPage}&page=${page}&pharmacy=${1}&title=${
      filtersData?.title
    }`
  );

  useEffect(() => {
    setPage(1);
  }, [filtersData?.title]);

  const rows = data;

  const totalRows = rows?.data?.total;
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  const startIndex = page * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage, totalRows);
  const visibleRows = rows?.data?.data;

  const removeIndex = (selectedIndex) => {

    const temp = [...addProductData?.images];
    temp?.splice(selectedIndex, 1);

    if (addProductData?.images[selectedIndex]?.id) {
      setAddProductData((addProductData) => ({
        ...addProductData,
        remove_image_ids: [
          ...addProductData?.remove_image_ids,
          addProductData?.images[selectedIndex]?.id,
        ],
      }));
    }

    setFileName(
      fileName.filter((value, index) => {
        return index !== selectedIndex;
      })
    );

    setAddProductData((addProductData) => ({
      ...addProductData,
      images: addProductData?.images.filter((value, index) => {
        return index !== selectedIndex;
      }),
    }));

    setValue('images', [...temp], {shouldValidate: true});
  };

  const handleSubmitForm = () => {
    const formData = new FormData();
    for (const key in addProductData) {
      if (
        (key === "images" || key === "remove_image_ids") &&
        Array.isArray(addProductData[key])
      ) {
        addProductData[key].forEach((value) => {
          formData.append(`${key}[]`, value);
        });
      } else {
        formData.append(key, addProductData[key]);
      }
    }

    if (
      fileName.length === 0 ||
      addProductData.category_id === "" ||
      addProductData.title === "" ||
      addProductData.sales_price === "" ||
      addProductData.quantity === "" ||
      addProductData.price === "" ||
      (addProductData.is_new === 0 &&
        addProductData.is_featured === 0 &&
        addProductData.is_popular === 0) ||
      addProductData?.pharmacy_id === ""
    ) {
      CustomToast({
        type: "error",
        message: `Please fill in all fields before submitting.`,
      });
    } else {
      AddProductHook?.postData(
        `${
          !getId
            ? process.env.REACT_APP_ADD_PRODUCT
            : process.env.REACT_APP_GET_UPDATE_PRODUCT + "/" + getCardId
        }`,
        formData,
        (response) => {
          if (response?.success === true) {
            CustomToast({
              type: "success",
              message: `Product ${getId ? "updated" : "added"}  successfully`,
            });
            setAddProduct(false);
            fetchPaginatedData(
              `${process.env.REACT_APP_GET_LIST_PRODUCT}?per_page=${rowsPerPage}&page=${page}`
            );
            setProductLabels([]);
          } else {
            response?.response?.["images.0"][0] &&
              toast?.error("Image size is too large.");
          }
        }
      );
    }
  };

  const handleDelete = (Id) => {
    deleteData(
      `${process.env.REACT_APP_GET_DELETE_PRODUCT}/${getCardId}`,
      () => {
        // setDeleteModal(false)
        fetchPaginatedData(
          `${process.env.REACT_APP_GET_LIST_PRODUCT}?per_page=${rowsPerPage}&page=${page}`
        );
        // const filter = rows?.data?.data?.filter(val => val.id !== deleteState)
        CustomToast({
          type: "success",
          message: "Product deleted successfuly.",
        });
        setDeleteModal(false);
        // setRows(filter)
      }
    );
  };

  // useEffect(()=>{
  //   if(getId){
  //   handleChangeSelectCategory(getId?.categories?.id, 'category_id')
  //   }
  // },[addProductData?.category_id])

  const handleChangeSelectCategory = (value, name) => {
    setAddProductData((pre) => ({ ...pre, category_id: value }));
  };

  const [ProductLabels, setProductLabels] = useState([]);

  const HandleSelectLabel = (value, name) => {
    var n = false;
    var f = false;
    var p = false;

    value?.map((product) => {
      if (product === "New") {
        n = true;
        setAddProductData((addProductData) => ({
          ...addProductData,
          is_new: 1,
        }));
      } else if (product === "Featured") {
        f = true;
        setAddProductData((addProductData) => ({
          ...addProductData,
          is_featured: 1,
        }));
      } else if (product === "Popular") {
        p = true;
        setAddProductData((addProductData) => ({
          ...addProductData,
          is_popular: 1,
        }));
      }
    });

    if (!n) {
      setAddProductData((addProductData) => ({ ...addProductData, is_new: 0 }));
    }

    if (!f) {
      setAddProductData((addProductData) => ({
        ...addProductData,
        is_featured: 0,
      }));
    }

    if (!p) {
      setAddProductData((addProductData) => ({
        ...addProductData,
        is_popular: 0,
      }));
    }
  };

  const breadCrumbItems = [
    {
      title: "Pharmacy",
    },
    {
      title: "Pharmacy Shop",
      href: "",
    },
  ];

  const { t } = useTranslation();

  const {
    reset,
    setValue,
    handleSubmit,
    control,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(formschemas?.pharmacyShopSchema('pharmacy')),
  });

  return (
    <>
      <div className="row px-2 pt-4">
        <SpinnerLoaderModal isDataLoading={isLoading} />

        <div className="col-12  ">
          <p className="mb-0 dashboard-com-top-text">Pharmacy Shop</p>
        </div>

        <div className="col-12">
          <div className="row d-flex align-items-end">
            <div className="col-lg-6 col-12 mt-lg-0 mt-2">
              <BreadCrum breadCrumbItems={breadCrumbItems} />
            </div>

            <div className="col-lg-6 col-12 mt-lg-0 mt-3 d-flex justify-content-end ">
              <button
                onClick={() => setAddProduct(true)}
                className="btn-add-new-doc"
              >
                {" "}
                Add Product{" "}
              </button>{" "}
            </div>
          </div>
        </div>

        <div className="col-12 mt-1">
          <p className="mb-0 py-4  pharmacy-view-product">
            Most Viewed Products
          </p>
        </div>

        <Searchbar
          value={filtersData?.title}
          handleOnFilterChange={handleOnFilterChange}
          placeholder="Search by name"
          inputDivClass={"col-5"}
        />

        <div className="col-12 ">
          <div className="row px-2">
            {!isLoading
              ? visibleRows?.map(
                  ({ id, images, price, title, pharmacy_id }, index) => {
                    return (
                      <>
                        <div className="px-2 py-2 position-relative pharmacy-product-card-new">
                          <div className="pharmacy-product-card">
                            <div className="pharmacy-product-card-img pt-2 px-2">
                              <img
                                src={`${process.env.REACT_APP_IMAGE_URL}/${images[0]?.image}`}
                                alt=""
                                style={{
                                  maxWidth: "100%",
                                  height: "auto",
                                  maxHeight: "100%",
                                }} // Ensure image responsiveness
                              />
                            </div>

                            <div className="py-1"></div>

                            <div className="px-2 py-2" style={{display: 'flex', flexDirection: 'column', gap: '6px', borderTop: '1px solid rgba(0, 0, 0, .1)'}}>
                              <span className="pharmacy-product-name oneLineTruncate">
                                {title}
                              </span>
                              
                              <span className="pharmacy-product-name oneLineTruncate">By {pharmaciesLabelsList[pharmacy_id]}</span>

                              <span className="pharmacy-product-name pharmacy-product-price oneLineTruncate">
                                KWD {price}
                              </span>
                            </div>

                          </div>

                          {/* Edit and delete icons */}
                          <div
                            className="position-absolute pharmacy-product-edit"
                            style={{ top: "10px", right: "10px" }}
                          >
                            <img
                              onClick={() => {
                                setAddProduct(true);
                                setGetCardId(id);
                                getDataById(id);
                              }}
                              alt="Edit"
                              src={EditIcon}
                              style={{ cursor: "pointer" }}
                            />
                          </div>

                          <div
                            className="position-absolute pharmacy-product-delete"
                            style={{ bottom: "10px", right: "10px" }}
                          >
                            <img
                              onClick={() => {
                                setDeleteModal(true);
                                setGetCardId(id);
                              }}
                              alt="Delete"
                              src={DeleteIcon}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </div>
                      </>
                    );
                  }
                )
              : // <div className='d-flex justify-content-center align-items-center' style={{ width: "100%", height: '300px', backgroundColor: 'rgba(46, 46, 46, 0.4)', borderRadius:'5px' }}>
                //   <ButtonLoader />
                // </div>
                null}
          </div>
        </div>

        <div className="pagination-container px-md-3 ml-md-1 mt-md-2 w-100">
          <div className="pagination-detail">
            Showing {(page - 1) * rowsPerPage + 1} - {rows?.data?.to} of{" "}
            {rows?.data?.total}
          </div>
          <CustomPagination
            page={page}
            totalPages={totalPages}
            onChangePage={(newPage) => {
              setPage(newPage);
            }}
          />
        </div>
      </div>

      <Modal
        className="doctor-filter-modal"
        centered
        open={addProduct}
        // onOk={() => setModal2Open(false)}
        onCancel={() => setAddProduct(false)}
        width={925}
        footer={
          <div className="row px-3 mt-4 mb-2">
            <div className="col-12 d-flex justify-content-end mt-3">
              <button
                className="apply-filter submit-pharmacy-add-product"
                onClick={handleSubmit(handleSubmitForm)}
              >
                {" "}
                {AddProductHook?.isLoading ? (
                  <div className="pb-3">
                    <ButtonLoader />
                  </div>
                ) : getId ? (
                  "Update Product"
                ) : (
                  "Add Product"
                )}{" "}
              </button>
            </div>
          </div>
        }
      >
        <div className="row px-3 border-bottom">
          <div className="col-12 ">
            <p className=" pharmacy-modal-header">Add Product</p>
          </div>
        </div>

        <div className="row px-3 ">
          <div className="col-4 mt-2">
            <div className="">
              <div
                className="col-12 d-flex"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div
                  className="col-5 "
                  style={{
                    width: "100px",
                    height: "100px",
                    padding: "0",
                    margin: "2px",
                    marginTop: "5px",
                  }}
                >
                  {fileName[0] ? (
                    <img
                      src={remove}
                      alt="imge"
                      style={{
                        width: "15px",
                        position: "absolute",
                        zIndex: "1000",
                        left: "-5px",
                        top: "-4px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        removeIndex(0);
                      }}
                    />
                  ) : null}

                  {fileName[0] ? (
                    <img
                      src={`${
                        fileName[0]?.image
                          ? process.env.REACT_APP_IMAGE_URL +
                            "/" +
                            fileName[0]?.image
                          : fileName[0]
                      }`}
                      alt="imgwe"
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "8px",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <div className="loader-bar-null-for-add-product" />
                  )}
                </div>
                <div
                  className="col-5"
                  style={{
                    width: "100px",
                    height: "100px",
                    padding: "0",
                    margin: "2px",
                    marginTop: "5px",
                  }}
                >
                  {fileName[1] ? (
                    <img
                      src={remove}
                      alt="img"
                      style={{
                        width: "15px",
                        position: "absolute",
                        zIndex: "1000",
                        right: "-5px",
                        top: "-4px",
                        cursor: "pointer",
                        objectFit: "cover",
                      }}
                      onClick={() => {
                        removeIndex(1);
                      }}
                    />
                  ) : null}
                  {fileName[1] ? (
                    <img
                      src={`${
                        fileName[1]?.image
                          ? process.env.REACT_APP_IMAGE_URL +
                            "/" +
                            fileName[1]?.image
                          : fileName[1]
                      }`}
                      alt="img"
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "8px",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <div className="loader-bar-null-for-add-product" />
                  )}
                </div>
              </div>
              <div
                className="col-12 d-flex"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div
                  className="col-5 "
                  style={{
                    width: "100px",
                    height: "100px",
                    padding: "0",
                    margin: "2px",
                    marginTop: "2px",
                  }}
                >
                  {fileName[2] ? (
                    <img
                      src={remove}
                      alt="img"
                      style={{
                        width: "15px",
                        position: "absolute",
                        zIndex: "1000",
                        left: "-5px",
                        top: "-4px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        removeIndex(2);
                      }}
                    />
                  ) : null}
                  {fileName[2] ? (
                    <img
                      src={`${
                        fileName[2]?.image
                          ? process.env.REACT_APP_IMAGE_URL +
                            "/" +
                            fileName[2]?.image
                          : fileName[2]
                      }`}
                      alt="img"
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "8px",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <div className="loader-bar-null-for-add-product" />
                  )}
                </div>
                <div
                  className="col-5 "
                  style={{
                    width: "100px",
                    height: "100px",
                    padding: "0",
                    margin: "2px",
                    marginTop: "2px",
                  }}
                >
                  {fileName[3] ? (
                    <img
                      src={remove}
                      alt="img"
                      style={{
                        width: "15px",
                        position: "absolute",
                        zIndex: "1000",
                        right: "-5px",
                        top: "-4px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        removeIndex(3);
                      }}
                    />
                  ) : null}
                  {fileName[3] ? (
                    <img
                      src={`${
                        fileName[3]?.image
                          ? process.env.REACT_APP_IMAGE_URL +
                            "/" +
                            fileName[3]?.image
                          : fileName[3]
                      }`}
                      alt="img"
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "8px",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <div className="loader-bar-null-for-add-product" />
                  )}
                </div>
              </div>

              <ErrorMessage error={validationErrors?.fileErrorMessage(errors)} />
            </div>
            <button
              disabled={fileName?.length === 4}
              className="pharmacy-add-upload-img mt-3"
              onClick={() => {
                fileName?.length !== 4 && handleDoctorImageClick();
              }}
            >
              Upload Image
            </button>
          </div>

          <div className="col-8">
            <div className="row px-3 mt-4">
                <CustomDropDown
                  label={'Category'}
                  labelClass={'doc-add-filter-text'}
                  arLabel={t('Common.Category')}
                  dropDownDivClass={'col-lg-6 mt-lg-0 pr-1 mt-4 doc-setting-input'}
                  defaultValue="Select"
                  style={{
                    width: "100%",
                  }}
                  name="category_id"
                  value={
                    addProductData?.category_id == "1"
                      ? "Skin Care"
                      : addProductData?.category_id == "2"
                      ? "Cough & Cold"
                      : addProductData?.category_id == "3"
                      ? "Pain Relief​​"
                      : addProductData?.category_id == "4"
                      ? "Heart Health"
                      : addProductData?.category_id == "5"
                      ? "Diabetes Care"
                      : addProductData?.category_id == "6"
                      ? "Cancer Care"
                      : addProductData?.category_id == "7"
                      ? "Weight Management"
                      : ""
                  }
                  handleChangeSelect={(value) =>
                    handleChangeSelectCategory(value, "category_id")
                  }
                  option={[
                    {
                      label: "Skin Care",
                      value: "1",
                    },
                    {
                      label: "Cough & Cold",
                      value: "2",
                    },
                    {
                      label: "Pain Relief",
                      value: "3",
                    },
                    {
                      label: "Heart Health",
                      value: "4",
                    },
                    {
                      label: "Diabetes Care",
                      value: "5",
                    },
                    {
                      label: "Cancer Care",
                      value: "6",
                    },
                    {
                      label: "Weight Management",
                      value: "7",
                    },
                  ]}
                  isController={true}
                  control={control}
                  error={errors?.category_id?.message}
                />

              <TextInput 
                label={'Title'} 
                arLabel={t('Common.Title')} 
                labelClass={'doc-add-filter-text'}
                inputDivClass='col-lg-6 doc-setting-input pr-3 pl-1' 
                type='text' 
                name='title' 
                value={addProductData?.title} 
                control={control} 
                error={errors?.title?.message}
                handleOnChange={(e) => {
                  setAddProductData({
                    ...addProductData,
                    title: e.target.value,
                  });
                }} 
              />

              <div className="col-12">
                <div className="row mt-3">
                  <div className="col-lg-6 doc-setting-input pr-1">

                    <InputLabel
                      label={'Price'}
                      arLabel={t('Common.Price')}
                      labelClass={'doc-add-filter-text'}
                    />

                    <div className="d-flex doc-setting-input-border-pharmacy justify-content-center align-items-center">
                      <span
                        className="border-right px-2"
                        style={{ fontSize: "12px" }}
                      >
                        KWD
                      </span>
                      <Controller 
                        name="price"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            className="doc-setting-input-inner"
                            type="text"
                            placeholder="Price"
                            name="price"
                            value={field?.value ?? ''}
                            onChange={(e) => {
                              setAddProductData({
                                ...addProductData,
                                price: e.target.value,
                              });
                              field?.onChange(e?.target?.value);
                            }}
                          />
                        )}
                      />
                    </div>
                    <ErrorMessage error={errors?.price?.message} />
                  </div>

                    <CustomDropDownMulti
                      label={'Label'}
                      labelClass={'doc-add-filter-text'}
                      arLabel={t('Common.Label')}
                      dropDownDivClass={'col-lg-6 mt-lg-0 mt-4 doc-setting-input pl-1'}
                      handleChangeSelect={(value, name) => {
                        setProductLabels(value);
                        HandleSelectLabel(value, name);
                      }}
                      name="label"
                      mode="multiple"
                      value={ProductLabels}
                      option={[
                        {
                          label: "Featured​​",
                          value: "Featured",
                        },
                        {
                          label: "New",
                          value: "New",
                        },
                        {
                          label: "Popular",
                          value: "Popular",
                        },
                      ]}
                      isController={true}
                      control={control}
                      error={errors?.label?.message}
                    />
                </div>

                <div className="row">
                  <div className="col-lg-6 doc-setting-input pr-1">

                    <InputLabel
                      label={'Sales Price'}
                      arLabel={t('Common.SalesPrice')}
                      labelClass={'doc-add-filter-text'}
                    />

                    <div className="d-flex doc-setting-input-border-pharmacy justify-content-center align-items-center">
                      <span
                        className="border-right px-2"
                        style={{ fontSize: "12px" }}
                      >
                        KWD
                      </span>

                      <Controller 
                        name="sales_price"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            className="doc-setting-input-inner"
                            type="text"
                            placeholder="Price"
                            name="sales_price"
                            value={addProductData?.sales_price}
                            onChange={(e) => {
                              setAddProductData({
                                ...addProductData,
                                sales_price: e.target.value,
                              });
                              field.onChange(e?.target?.value);
                            }}
                          />
                        )}
                      />
                    </div>
                    <ErrorMessage error={errors?.sales_price?.message}/>
                  </div>

                  <TextInput 
                    label={'Quantity'} 
                    arLabel={t('Common.Quantity')} 
                    labelClass={'doc-add-filter-text'}
                    inputDivClass='col-lg-6 doc-setting-input pr-3 pl-1' 
                    type='text' 
                    name='quantity' 
                    value={addProductData?.quantity} 
                    handleOnChange={(e) => {
                      setAddProductData({
                        ...addProductData,
                        quantity: e?.target?.value,
                      });
                    }} 
                    control={control} 
                    error={errors?.quantity?.message}
                  />

                </div>

                <div className="row mt-3">

                    <CustomDropDown
                      label={'Pharmacy'}
                      labelClass={'doc-add-filter-text'}
                      arLabel={t('Pharmacy.Shop.Pharmacy')}
                      dropDownDivClass={'col-lg-6 mt-lg-0 mt-4 doc-setting-input pr-1'}
                      defaultValue="Select"
                      style={{
                        width: "100%",
                      }}
                      name="pharmacy_id"
                      value={addProductData?.pharmacy_id || ""}
                      handleChangeSelect={(value, name) => {
                        setAddProductData({
                          ...addProductData,
                          pharmacy_id: value,
                        });
                      }}
                      option={pharmaciesList}
                      isController={true}
                      control={control} 
                      error={errors?.pharmacy_id?.message}
                    />

                </div>

                <div className="row mt-4 px-3">
                  <TextInput
                    label={'Description'} 
                    inputDivClass='col-6 pr-1' 
                    type='text' 
                    name='about' 
                    textArea={true}
                    rows={4}
                    value={addProductData?.about} 
                    handleOnChange={(e) => {setAddProductData(addProductData => ({...addProductData, about: e?.target?.value}))}}
                    isController={true}
                    control={control} 
                    error={errors?.about?.message}
                  />

                  <ArabicTextArea
                    divClass="col-lg-6 doc-setting-input pr-0 pl-1"
                    label={t("Common.Description")}
                    formStateData={addProductData}
                    setFormStateData={setAddProductData}
                    name="about_ar"
                    rows={4}
                  />
                </div>

              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        className="doctor-filter-modal"
        centered
        open={editProduct}
        // onOk={() => setModal2Open(false)}
        onCancel={() => setEditProduct(false)}
        width={925}
        footer={
          <div className="row px-3 mt-4 mb-2">
            <div className="col-12 d-flex justify-content-end mt-3">
              <button className="apply-filter submit-pharmacy-add-product">
                Add Product
              </button>
            </div>
          </div>
        }
      >
        <div className="row px-3 border-bottom">
          <div className="col-12 ">
            <p className=" pharmacy-modal-header rrrr">Edit Product</p>
          </div>
        </div>

        <div className="row px-3">
          <div className="col-4 mt-2">
            <div>
              <div
                className="col-12 d-flex"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div
                  className="col-5 "
                  style={{
                    width: "100px",
                    height: "100px",
                    padding: "0",
                    margin: "2px",
                    marginTop: "5px",
                  }}
                >
                  {fileName[0] ? (
                    <img
                      src={remove}
                      alt="img"
                      style={{
                        width: "15px",
                        position: "absolute",
                        zIndex: "1000",
                        left: "-5px",
                        top: "-4px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        removeIndex(0);
                      }}
                    />
                  ) : null}

                  {fileName[0] ? (
                    <img
                      src={fileName[0] ?? ""}
                      alt="img"
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "8px",
                      }}
                    />
                  ) : (
                    <div className="loader-bar-null-for-add-product" />
                  )}
                </div>
                <div
                  className="col-5"
                  style={{
                    width: "100px",
                    height: "100px",
                    padding: "0",
                    margin: "2px",
                    marginTop: "5px",
                  }}
                >
                  {fileName[1] ? (
                    <img
                      src={remove}
                      alt="img"
                      style={{
                        width: "15px",
                        position: "absolute",
                        zIndex: "1000",
                        right: "-5px",
                        top: "-4px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        removeIndex(1);
                      }}
                    />
                  ) : null}
                  {fileName[1] ? (
                    <img
                      src={fileName[1] ?? ""}
                      alt="img"
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "8px",
                      }}
                    />
                  ) : (
                    <div className="loader-bar-null-for-add-product" />
                  )}
                </div>
              </div>
              <div
                className="col-12 d-flex"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div
                  className="col-5"
                  style={{
                    width: "100px",
                    height: "100px",
                    padding: "0",
                    margin: "2px",
                    marginTop: "5px",
                  }}
                >
                  {fileName[2] ? (
                    <img
                      src={remove}
                      alt="img"
                      style={{
                        width: "15px",
                        position: "absolute",
                        zIndex: "1000",
                        left: "-5px",
                        top: "-4px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        removeIndex(2);
                      }}
                    />
                  ) : null}
                  {fileName[2] ? (
                    <img
                      src={fileName[2] ?? ""}
                      alt="img"
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "8px",
                      }}
                    />
                  ) : (
                    <div className="loader-bar-null-for-add-product" />
                  )}
                </div>
                <div
                  className="col-5"
                  style={{
                    width: "100px",
                    height: "100px",
                    padding: "0",
                    margin: "2px",
                    marginTop: "5px",
                  }}
                >
                  {fileName[3] ? (
                    <img
                      src={remove}
                      alt="img"
                      style={{
                        width: "15px",
                        position: "absolute",
                        zIndex: "1000",
                        right: "-5px",
                        top: "-4px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        removeIndex(3);
                      }}
                    />
                  ) : null}
                  {fileName[3] ? (
                    <img
                      src={fileName[3] ?? ""}
                      alt="img"
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "8px",
                      }}
                    />
                  ) : (
                    <div className="loader-bar-null-for-add-product" />
                  )}
                </div>
              </div>

              {errorData === 1 ? (
                <span className="error-message">
                  Please select a valid image file (JPEG or PNG)
                </span>
              ) : (
                ""
              )}
            </div>
            <button
              className="pharmacy-add-upload-img mt-3"
              onClick={handleDoctorImageClick}
            >
              Upload Image
            </button>
          </div>

          <div className="col-8">
            <div className="row px-3 mt-4">
              <div className="col-lg-6 mt-lg-0  mt-4  doc-setting-input">
                <p className=" doc-add-filter-text">Category</p>

                <Select
                  defaultValue="Select"
                  style={{
                    width: "100%",
                  }}
                  onChange={() => {}}
                  options={[
                    {
                      label: "Cardiology0​​",
                      value: "Cardiology0",
                    },
                    {
                      label: "Neurology",
                      value: "Neurology",
                    },
                    {
                      label: "Cardiology​​",
                      value: "Cardiology",
                    },
                    {
                      label: "Neurology1",
                      value: "Neurology1",
                    },
                  ]}
                />
              </div>
              <div className="col-6 doc-setting-input">
                <p className=" doc-add-filter-text">Add Title</p>
                <input type="text" />
              </div>

              <div className="col-12">
                <div className="row mt-3">
                  <div className="col-lg-6 doc-setting-input">
                    <p className=" doc-add-filter-text">Price</p>

                    <div className="d-flex doc-setting-input-border-pharmacy justify-content-center align-items-center">
                      <span className="border-right px-3">$</span>{" "}
                      <input
                        className="doc-setting-input-inner"
                        type="text"
                        placeholder="Price"
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 mt-lg-0  mt-4  doc-setting-input">
                    <p className=" doc-add-filter-text">Label</p>

                    <Select
                      defaultValue="Select"
                      style={{
                        width: "100%",
                      }}
                      onChange={() => {}}
                      options={[
                        {
                          label: "Featured​​",
                          value: "Featured",
                        },
                        {
                          label: "New",
                          value: "New",
                        },
                        {
                          label: "Popular",
                          value: "Popular",
                        },
                        {
                          label: "Recent",
                          value: "Recent",
                        },
                      ]}
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-lg-6 doc-setting-input">
                    <p className=" doc-add-filter-text">Sales Price</p>

                    <div className="d-flex doc-setting-input-border-pharmacy justify-content-center align-items-center">
                      <span className="border-right px-3">$</span>{" "}
                      <input
                        className="doc-setting-input-inner"
                        type="text"
                        placeholder="Price"
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 doc-setting-input">
                    <p className=" doc-add-filter-text">Quantity</p>
                    <input type="text" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        className="doctor-filter-modal"
        centered
        open={deleteModal}
        // onOk={() => setModal2Open(false)}
        onCancel={() => setDeleteModal(false)}
        width={514}
        footer={null}
        closable={false}
      >
        <div className="row pb-1">
          <div className="col-12 d-flex flex-column align-items-center justify-content-center pharmacy-delete">
            <p className="mb-0 pt-lg-5 pt-3 pb-4 mt-lg-3">
              Are you sure you want to delete?
            </p>
            <button
              className="mt-lg-4 mt-1 mb-lg-5 mb-2"
              disabled={deleteDataLoading}
              onClick={handleDelete}
            >
              {" "}
              {deleteDataLoading ? (
                <div className="pb-3">
                  <ButtonLoader />
                </div>
              ) : (
                "Delete"
              )}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PharmacyShop;
