import DashboardCom from "../components/DashboardComponents/DashboardCom";
import LaboratoryList from "./Laboratory/LaboratoryList";
import Pharmacy from "./Pharmacy/pharmacy";
import Appointments from "./Appointment/Appointments";
import { useLocation } from "react-router-dom";

//  const role =JSON.parse(localStorage.getItem("userRoles"))
const userRolesJson = localStorage.getItem("userRoles");
const role = userRolesJson ? JSON.parse(userRolesJson) : {};
const allowedhost = Object?.keys(role).includes("hospitaladmin");
const allowedlab = Object?.keys(role).includes("technologist");
const allowedphar = Object?.keys(role).includes("pharmacist");
const alloweddoc = Object?.keys(role).includes("doctor");
const allowedstore = Object?.keys(role).includes("storeadmin");
const allowedradio = Object?.keys(role).includes("radiologic");
const allowedProvider = Object?.keys(role).includes("serviceprovider");

const ValidateRoute = (currentPath) => {
  let HospitalAdmin = [
    // "/laboratory",
    "/xray/list",
    "/bloodtest",
    "/bloodtest/orderlist",
    "/xray",
    "/xray/orderlist",
    "/bloodtest/orderlist/bloodtestcartdetail",
    "/xray/orderlist/xraycartdetail",
    // "/laboratory/add",
    "/hospitals",
    "/hospitals/add",
    "/hospitals/edit",
    "/hospitals/hospitalrooms",
    "/hospitals/bookedhospitalrooms",
    // "/pharmacy",
    // "/pharmacy/shop",
    // "/pharmacyshopdetail",
    // "/pharmacy/shop/detail",
    // "/pharmacy/add",
    // "/pharmacy/detail",
    "/appointment",
    "/appointment/add",
    "/doctors",
    "/doctors/add",
    "/doctors/detail",
    "/patients",
    "/patients/add",
    "/patients/edit",
    "/patientprofile",
    "/blood-donation",
    "/home-service-provider",
    "/banner-promo",
    "/banner-promo/add",
    "/manageroles",
    "/rolepermission",
    "/allroles",
    "/doctors/availability",
    "/pharmacy/update/",
    "/change-password",
    "/hospitals/hospitalroom/add",
    "/hospitals/hospitalroom/edit",
  ];

  let LaboratoryAdmin = [
    "/dashboard",
    "/laboratory",
    // "/laboratory/add",
    "/laboratory/update",
    "/bloodtest",
    "/bloodtest/orderlist",
    "/bloodtest/detail",
    // "/xray/list",
    // "/xray",
    // "/xray/orderlist",
    // "/bloodtest/orderlist/bloodtestcartdetail",
    // "/xray/orderlist/xraycartdetail",
    // "/xray/add",
    "/change-password",
  ];

  let PharmacyAdmin = [
    "/dashboard",
    "/pharmacy",
    // "/pharmacy/add",
    "/pharmacy/update",
    "/pharmacy/shop",
    "/pharmacy/shop/detail",
    "/pharmacy/detail",
    // "/pharmacyshopdetail",
    // "/medical/equipment",
    // "/medical/equipment/shop",
    // "/medical/equipmentshopdetail",
    // "/medical/equipment/shop/detail",
    // "/medical/equipment/add",
    // "/medical/equipment/detail",
    "/change-password",
  ];

  let Doctor = [
    "/dashboard",
    "/doctors",
    "/doctors/detail",
    // "/doctors/add",
    "/appointment",
    "/appointment/add",
    // "/doctors/detail/3",
    // "/needy-patients",
    // "/treatment-sponsor",
    // "/patients",
    // "/patients/add",
    "/change-password",
  ];

  let MedicalEquipmentAdmin = [
    "/medical/equipment",
    // "/medical/equipment/add",
    "/medical/equipment/shop",
    "/medical/equipment/shop/detail",

    "/medical/equipment/detail",
    "/medical/equipment/update",
    "/change-password",
  ];

  let XrayAdmin = [
    "/xray/list",
    "/xray/update",
    // "/xray/add",
    "/xray",
    "/xray/orderlist",
    "/xray/detail",
    // "/xray/orderlist/xraycartdetail",
    "/change-password",
  ];

  let ServiceProviderAdmin = [
    "/dashboard",
    "/service-provider/edit",
    "/service-provider-list",
    "/service/add",
    "/service/edit",
    "/service-list",
    "/service-request-list",
    "/change-password",
  ];

  const userRole = localStorage.getItem("userRole") ?? "";

  let allowedRoutes = [];

  if (allowedhost) {
    allowedRoutes = [...allowedRoutes, ...HospitalAdmin];
  } else if (allowedlab) {
    allowedRoutes = [...allowedRoutes, ...LaboratoryAdmin];
  } else if (alloweddoc) {
    allowedRoutes = [...allowedRoutes, ...Doctor];
  } else if (allowedphar) {
    allowedRoutes = [...allowedRoutes, ...PharmacyAdmin];
  } else if (allowedstore) {
    allowedRoutes = [...allowedRoutes, ...MedicalEquipmentAdmin];
  } else if (allowedradio) {
    allowedRoutes = [...allowedRoutes, ...XrayAdmin];
  } else if (allowedProvider) {
    allowedRoutes = [...allowedRoutes, ...ServiceProviderAdmin];
  } else {
    allowedRoutes = [];
  }

  const segments = currentPath.split("/");
  const lastSegment = segments[segments.length - 1];
  let textBeforeNumber = currentPath;

  if (/^\d+$/.test(lastSegment)) {
    textBeforeNumber = segments.slice(0, -1).join("/");
  }

  const validate =
    userRole === "superAdmin" ? true : allowedRoutes.includes(textBeforeNumber);

  return validate ? currentPath : "";
};

const ValidUI = (validation) => {
  return role;
};

const DefultRoute = () => {
  let route = ValidUI();
  switch (route) {
    case "HospitalAdmin":
      return <DashboardCom />;
      break;
    case "superAdmin":
      return <DashboardCom />;
      break;
    case "LaboratoryAdmin":
      return <DashboardCom />;
      break;
    case "PharmacyAdmin":
      return <DashboardCom />;
      break;
    case "Doctor":
      return <DashboardCom />;
      break;
    case "MedicalEquipmentAdmin":
      return <DashboardCom />;
      break;
    case "XrayAdmin":
      return <DashboardCom />;
      break;

    default:
      return <DashboardCom />;
      break;
  }
};
export { ValidateRoute, ValidUI, DefultRoute };
