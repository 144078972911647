import React, { useEffect, useState } from 'react'
import RoomsTableListHeader from '../../molecules/HospitalRoomManagement/RoomsTableListHeader';
import DynamicRoomsDataTable from '../../molecules/HospitalRoomManagement/DynamicRoomsDataTable';
import useDeleteData from '../../customHook/useDelete';
import moment from 'moment';
import { toast } from 'react-toastify';
import PaginationHelper from '../../molecules/tablepagination/paginationhelper';

const NewsDataTable = () => {

    const tableColumns = [
        { name: '#', align: 'left', className: 'number' },
        { name: 'News', align: 'left', textTruncLength: 30 },
        { name: 'Expiry Date', align: 'left' },
        { name: 'Expiry Time', align: 'left' },
        // { name: 'Status', align: 'left' },
        { name: 'Action', align: 'left' }
    ];

    const [newsList, setNewsList] = useState([]);
    const getApiMethod = useDeleteData();
    const { paginationData, setPaginationData, handleChangePage } = PaginationHelper();

    useEffect(() => {
        getApiMethod?.deleteData(`list_news?page=${paginationData?.page}&per_page=10`, (response) => {
            setNewsList(response?.data?.data?.map((news) => ({
                id: news?.id,
                news: news?.news,
                expiry_date: moment(news?.expiry_date).format('YYYY-MM-DD'),
                expiry_time: moment(news?.expiry_date).format('HH:mm:ss'),
                // status: news?.status === 1 ? 'Live' : 'Expired'
            })));

            setPaginationData({...paginationData, totalRows: response?.data?.total, totalPages: response?.data?.total / 10, to: response?.data?.to});
        });
    },[paginationData?.page])

    const handleDeleteList = (listDeleteEndPoint, listID) => {
        
        getApiMethod?.deleteData(`${listDeleteEndPoint}/${listID}`, (response) => {
            toast.success('Successfully deleted news.');
            setNewsList(newsList?.filter(news => {
                if(news?.id !== listID){
                    return true;
                }
            }))
        })

    }

    const breadCrumbItems = [
        {
          title: 'News'
        },
        {
          title: 'All News',
          href: ''
        }
    ];

  return (
    <div className='row pl-4 pr-4 pt-3 roomslistBody'>

        <RoomsTableListHeader
            pageTitle='All News'
            formButtonLabel='Add News'
            addFormPathName='/news/add'
            breadCrumbItems={breadCrumbItems}
        />

      <div className='col-12 p-0' style={{overflowX: "hidden" }}>
        <DynamicRoomsDataTable tableColumns={tableColumns} tableRows={newsList} isRowsDataLoading={getApiMethod?.isLoading} paginationData={paginationData} handleChangePage={handleChangePage} handleDeleteList={handleDeleteList} deleteApiEndPoint={'delete_news'} isEdit={true} />
      </div>
      
    </div>
  )
}

export default NewsDataTable;