import React, { useState } from 'react'

const PaginationHelper = () => {
  
    const [paginationData, setPaginationData] = useState({
        page: 1,
        rowsPerPage: 10,
        from: 0,
        to: 0,
        totalRows: null,
        totalPages: null
    });
    
    const handleChangePage = (newPage) => {
        setPaginationData({...paginationData, page: newPage});
    };

    return {
        paginationData,
        setPaginationData,
        handleChangePage
    }

}

export default PaginationHelper;