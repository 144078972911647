import React, { useMemo } from "react";
import { Pie } from "@ant-design/plots";

const PieChart = ({ userRoles, PieChartData }) => {
  const preprocessedData = useMemo(
    () =>
      PieChartData.map((dataItem) => ({
        ...dataItem,
        value: dataItem.value > 0 ? dataItem.value : null,
      })),
    [PieChartData]
  );

  const config = useMemo(
    () => ({
      appendPadding: 10,
      data: preprocessedData,
      angleField: "value",
      colorField: "type",
      radius: 1,
      innerRadius: 0.7,
      label: {
        type: "inner",
        offset: "-50%",
        content: "{value}",
        style: {
          textAlign: "center",
          fontSize: 14,
        },
      },
      interactions: [
        {
          type: "element-selected",
        },
        {
          type: "element-active",
        },
      ],
      legend: false,
      statistic: {
        title: false,
        content: {
          style: {
            whiteSpace: "pre-wrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
          content: "Users",
        },
      },

      color: ({ type }) => {
        if (type === "Hospitals") {
          return "#5588FF";
        }
        if (type === "Laboratories") {
          return "#FFCE00";
        }
        if (type === "Pharmacies") {
          return "#11B3CF";
        }
        if (type === "Medical Equipments") {
          return "#6555C5";
        }
        if (type === "X-rays") {
          return "#A501F4";
        }
        if (type === "Service Providers") {
          return "#BAC94A";
        }
        if (type === "Doctors") {
          return "#35A28F";
        }
      },
    }),
    [preprocessedData]
  );

  return (
    <>
      <Pie {...config} />

      <div className="col-12 px-3 pb-4">
        <div className="row">
          {userRoles?.map((item, index) => {
            return (
              <div className=" flex-nowrap d-flex align-items-center">
                <span
                  className="dot "
                  style={{
                    backgroundColor:
                      item === "Hospitals"
                        ? "#5588FF"
                        : item === "Doctors"
                        ? "#35A28F"
                        : item === "Pharmacies"
                        ? "#11B3CF"
                        : item === "Medical Equipments"
                        ? "#6555C5"
                        : item === "X-ray"
                        ? "#A501F4"
                        : item === "Service Providers"
                        ? "#BAC94A"
                        : item === "Laboratories"
                        ? "#FFCE00"
                        : "black",
                  }}
                ></span>{" "}
                <span className="pl-2 pr-4">{item}</span>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default PieChart;
