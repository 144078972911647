import React, { useRef, useState, useEffect } from "react";
import { Modal } from "antd";
import usePost from "../../customHook/usePost";
import UploadCertificate from "../../assets/images/common/UploadCertificate.svg";
import crossGrey from "../../assets/images/common/crossGrey.svg";

const CertificateUploadModal = ({
  Id,
  addUrl,
  deleteUrl,
  setSelectedImagesCall,
  selectedImagesCall,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [removeImgId, setRemoveImgId] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const { data, isLoading, error, postData } = usePost();

  useEffect(() => {
    if (Id && selectedImages.some((image) => image.name)) {
      const formData = new FormData();
      selectedImages.forEach((image) => formData.append("images[]", image));

      postData(`${addUrl}/${Id}`, formData, (res) => {
        setSelectedImagesCall(!selectedImagesCall);
      });
    }
  }, [selectedImages]);

  useEffect(() => {
    if (removeImgId) {
      const formData = new FormData();
      formData.append("image_ids[]", removeImgId);

      postData(`${deleteUrl}/${Id}`, formData, (res) => {
      });
    }
  }, [removeImgId]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleImageChange = async (event) => {
    try {
      const files = event.target.files;
      const newImages = Array.from(files);

      setSelectedImages([...selectedImages, ...newImages]);
    } catch (error) {
    }
  };

  const removeImage = (imageName) => {
    try {
      const updatedImages = selectedImages.filter(
        (image) => image.name !== imageName
      );

      setSelectedImages(updatedImages);
      setRemoveImgId(imageName); 
    } catch (error) {
      console.error("Error removing image:", error);
    }
  };

  const removeAllImages = () => {
    try {
      setSelectedImages([]);
      setRemoveImgId(null); 
    } catch (error) {
    }
  };

  return (
    <>
      <div className="">
        <Modal
          visible={isModalOpen}
          onCancel={handleCancel}
          footer={
            <>
              <div className="d-flex justify-content-end pr-7">
                <button
                  disabled={isLoading}
                  className="d-flex mr-3 align-items-center border cursor-pointer hospital-slider-image ml-2 "
                  onClick={removeAllImages}
                >
                  Remove Files
                </button>
                <button
                  className="hospital-slider-image-inner px-5 mr-4"
                  onClick={handleCancel}
                >
                  Submit
                </button>
              </div>
            </>
          }
          width={"75%"}
          maskClosable={true}
        >
          <div className="img-modal-header border-bottom pb-3">
            <div className="d-flex justify-content-between align-items-center mx-4 px-2">
              <span className="img-modal-header-text1">Upload Certificates</span>
              <img src={crossGrey} alt="" />
            </div>
          </div>
          <div
            className="d-flex mt-4 flex-column flex-lg-row justify-content-between align-items-center mb-4 pb-3"
            style={{ minHeight: "300px" }}
          >
            <div className="d-flex justify-content-center w-100">
              <div className="hospital-slider-image-inner-show-container">
                {selectedImages.length > 0 ? (
                  selectedImages.map((image, index) => (
                    <div
                      style={{ opacity: isLoading ? "0.4" : "1" }}
                      className="hospital-slider-image-inner-show border position-relative"
                      key={index}
                    >
                      <img
                        src={
                          image.name
                            ? URL.createObjectURL(image)
                            : image.imageUrl
                        }
                        alt={`Image ${index}`}
                        className="w-100 h-100"
                      />
                      <button
                        disabled={isLoading}
                        className="delete-button-image position-absolute"
                        onClick={() => removeImage(image.name)}
                      >
                        &#10005;
                      </button>
                    </div>
                  ))
                ) : (
                  <p>No Certificates selected.</p>
                )}
              </div>
            </div>
            <div className="d-flex flex-column align-items-center w-100">
              <div className="d-flex justify-content-center w-100">
                <button
                  className="hospital-upload-image"
                  disabled={isLoading || selectedImages.length > 3}
                  onClick={handleImageChange}
                >
                  <img
                    style={{
                      opacity: isLoading || selectedImages.length > 3 ? "0.5" : "",
                    }}
                    src={UploadCertificate}
                    alt="Upload Image"
                  />
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default CertificateUploadModal;
