export const handlePhoneCode = (name, value, setValue) => {

    if(value?.startsWith('+965')){
      setValue && setValue(name, value);
      return value;
    }
    else{
      setValue && setValue(name, '+965');
      return '+965';
    }

}