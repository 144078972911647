import { useState, useRef, useEffect } from "react";
import { Switch } from "antd";
import camera from "../../../assets/images/common/camera.svg";
import CustomSelect from "../../common/CustomSelect";
import "../../../assets/css/treatmentsponsor/treatmentsponsor.scss";
import CustomDropDown from "../../../atoms/CustomDropDown/Index";
// import UploadFile from "../../../molecules/UploadFile/UploadFile";

import { Controller, useForm } from "react-hook-form";
import Phone from "../../../atoms/phone";
import useFetch from "../../../customHook/useFetch";
import usePost from "../../../customHook/usePost";
import useDeleteData from "../../../customHook/useDelete";
import { CustomToast } from "../../../atoms/toastMessage";
import { useNavigate } from "react-router-dom";
import ButtonLoader from "../../../atoms/buttonLoader";
import CertificateInput from "../../../molecules/CertificateInput/CertificateInput";
import NumericInput from "../../../molecules/NumericInput/NumericInput";
import ArabicInput from "../../../molecules/ArabicInput";
import { useTranslation } from "react-i18next";
import ArabicTextArea from "../../../molecules/ArabicTextArea/ArabicTextArea";
import SpinnerLoaderModal from "../../common/spinnerloadermodal";
import { handlePhoneCode } from "../../common/functionalityhelper";
import schema from "../../../formvalidation/schema";
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorMessage from "../../../formvalidation/errormessage";
import { toast } from "react-toastify";
import TextInput from "../../../molecules/TextInput/TextInput";
import formschemas from "../../../formvalidation/formschemas";

const AddNeedyPatientForm = ({ Id }) => {
  const [previewImage, setPreviewImage] = useState(null);
  const [needyPatient, setNeedyPatient] = useState({
    documents: [],
    remove_document_ids: [],
  });
  const [hospitals, setHospitals] = useState([]);
  const fileInputRef = useRef(null);
  const { data, isLoading, error, postData } = usePost();
  const customData = useDeleteData();
  const KWDID_Api = useDeleteData();
  const navigate = useNavigate();
  const [SelectedTreatmentCertificate, setSelectedTreatmentCertificate] =
    useState([]);
  const { t } = useTranslation();

  const {
    formState: { errors },
    control,
    trigger,
    watch,
    reset,
    setValue,
    handleSubmit,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(formschemas?.needyPatientSchema),
  });

  const { data: hospitalData } = useFetch(
    process.env.REACT_APP_GET_HOSPITAL_DATA
  );

  useEffect(() => {
    if (hospitalData?.success) {
      setHospitals(
        hospitalData?.data?.map(({ id, name }) => ({
          value: id,
          label: name,
        }))
      );
    }
  }, [hospitalData]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    setValue('profile_pic', file, {shouldValidate: true});
    setNeedyPatient((pre) => ({ ...pre, profile_pic: file }));

    reader.onload = () => {
    };
    reader.readAsDataURL(file);
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
    trigger('profile_pic');
  };

  const gender = [
    {
      label: "Male",
      value: 1,
    },
    {
      label: "Female",
      value: 2,
    },
  ];

  const handleNeedyPatientSubmit = () => {

    if(needyPatient?.kwd_id !== isPatientFetched){
      toast?.error("KWD ID must match the current patient's KWD ID.")
      return;
    }

    const formData = new FormData();
    for (const key in needyPatient) {
      if (key === "specialties" && Array.isArray(needyPatient[key])) {
        needyPatient[key].forEach((value) => {
          formData.append(`${key}[]`, value);
        });
      } else if (key === "documents" && Array.isArray(needyPatient[key])) {
        needyPatient[key].forEach((file, index) => {
          formData.append(`documents[]`, file);
        });
      } else if (
        key === "remove_document_ids" &&
        Array.isArray(needyPatient[key])
      ) {
        if (Id) {
          needyPatient[key].forEach((value) => {
            formData.append(`${key}[]`, value);
          });
        }
      } else if (key !== "medical_history") {
        formData.append(key, needyPatient[key]);
      }
    }

    formData?.append('civil_id', needyPatient?.kwd_id);

    postData(
      Id
        ? `${process.env.REACT_APP_UPDATE_NEEDY_PATIENT}/${Id}`
        : `${process.env.REACT_APP_ADD_NEEDY_PATIENT}`,
      formData,
      (res) => {
        // setAddRole({...addRole, 'join_id':res?.data?.id})
        if (res?.success === true) {
          CustomToast({
            type: "success",
            message: `Needy patient ${!Id ? "added" : "edited"} successfully`,
          });
          setValue("");
          setNeedyPatient({});
          reset();
          navigate("/needy-patient/list");
        }
      }
    );
  };

  useEffect(() => {
    if (Id) {
      customData.deleteData(
        `${process.env.REACT_APP_GET_NEEDY_PATIENT_BY_ID}/${Id}`,
        (val) => {
          setNeedyPatient({
            ...needyPatient,
            ...val?.data,
            profile_pic: '/uploads/users/images/SampleImg.png',
            documents: val?.data?.medical_histories || [],
          });
          Object.entries(val?.data).forEach(([fieldName, fieldValue]) => {
            setValue(fieldName, fieldValue);
          });
          setValue('profile_pic', '/uploads/users/images/SampleImg.png');
          setIsPatientFetched(val?.data?.kwd_id);
        }
      );
    }
  }, [Id]);

  const [isPatientFetched, setIsPatientFetched] = useState(null);

  const handleButtonClick = () => {
    // Fetch data with the updated URL
    KWDID_Api.deleteData(
      `${process.env.REACT_APP_GET_PATIENT_DETAIL_BY_KWD_ID}/${needyPatient?.kwd_id}`,
      (response) => {

        if(response?.success){
  
          setNeedyPatient((pre) => ({
            ...pre,
            profile_pic: response?.data?.user?.profile_pic,
            kwd_id: response?.data?.kwd_id,
            first_name: response?.data?.user?.first_name,
            first_name_ar: response?.data?.user?.first_name_ar ?? '',
            last_name: response?.data?.user?.last_name,
            last_name_ar: response?.data?.user?.last_name_ar ?? '',
            contact: response?.data?.user?.contact,
            gender: response?.data?.user?.gender,
            age: response?.data?.user?.age,
            hospital_id: response?.data?.hospital_id,
          }));
          setValue('kwd_id', response?.data?.kwd_id);
          setValue('profile_pic', response?.data?.user?.profile_pic);
          setValue("first_name", response?.data?.user?.first_name);
          setValue("last_name", response?.data?.user?.last_name);
          setValue("contact", response?.data?.user?.contact);
          setValue("gender", response?.data?.user?.gender);
          setValue("hospital_id", response?.data?.hospital_id);
          setValue("age", response?.data?.user?.age);

          setIsPatientFetched(response?.data?.kwd_id);
          toast?.success('Patient record found.');

        }
        else toast?.error(response?.response);

      }
    );
  };

  return (
    <div className="add-needy-patient-form-wrapper">

      {Id && <SpinnerLoaderModal isDataLoading={customData?.isLoading} />}

      <div className="add-profile">
        <div className="image-uploader">

          <div className="left">
            <div className="image-preview" style={{cursor: 'no-drop'}}>
              <img 
                src={
                  needyPatient?.profile_pic ? `${process.env.REACT_APP_IMAGE_URL}${needyPatient?.profile_pic}` : camera
                }
                alt=""
                className={needyPatient?.profile_pic && 'add-doc-camera-upload-1st'}
              />
            </div>
            <div>Profile Picture / {t('Common.ProfilePicture')}</div>
          </div>

          <div className="right">
            <div className="upload-text" 
            >
              Upload Image
            </div>
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
          </div>
        </div>
        <ErrorMessage error={errors?.profile_pic?.message} />
      </div>
      <form className="">
        <div className="add-new-patient-form">
          <div class="form-row">
            <div class="form-group col-lg-9">
              
              <div className="d-flex justify-content-between">
                <label for="last-name-input">
                  KWD ID<span className="text-danger">*</span>
                </label>
                <p className="mb-2">{`${t("Common.KWDID")}`}</p>
              </div>

              <Controller
                name="kwd_id"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <>
                    <input
                      type="text"
                      name="kwd_id"
                      {...field}
                      value={field.value || ""}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setNeedyPatient((pre) => ({
                          ...pre,
                          kwd_id: e.target.value,
                        }));
                      }}
                    />

                    <ErrorMessage error={errors?.kwd_id?.message} />
                  </>
                )}
              />
            </div>

            <div className="form-group col-lg-3 mt-2">
              <div
                onClick={() => {
                  needyPatient?.kwd_id && !errors?.kwd_id && needyPatient?.kwd_id !== isPatientFetched  && handleButtonClick();
                }}
                className="common-btn mt-4 d-flex justify-content-center align-items-center cursor-pointer"
                style={{ height: "36.6px", cursor: (!needyPatient?.kwd_id || errors?.kwd_id || needyPatient?.kwd_id === isPatientFetched) && 'no-drop' }}
              >
                Submit
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-lg-3">
              <label for="first-name-input">
                First Name<span className="text-danger">*</span>
              </label>
              <Controller
                name="first_name"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <>
                    <input
                      type="text"
                      name="first_name"
                      {...field}
                      value={field.value || ""}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setNeedyPatient((pre) => ({
                          ...pre,
                          first_name: e.target.value,
                        }));
                      }}
                      disabled
                    />

                    <ErrorMessage error={errors?.first_name?.message} />
                  </>
                )}
              />
            </div>

            <ArabicInput
              divClass="form-group col-3"
              label={t("NeedyPatient.FirstName")}
              formStateData={needyPatient}
              setFormStateData={setNeedyPatient}
              name="first_name_ar"
              isdisabled={true}
            />

            <div class="form-group col-lg-3">
              <label for="last-name-input">
                Last Name<span className="text-danger">*</span>
              </label>
              {/* <input type="text" id="last-name-input" name="last-name" /> */}
              <Controller
                name="last_name"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <>
                    <input
                      type="text"
                      name="last_name"
                      {...field}
                      value={field.value || ""}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setNeedyPatient((pre) => ({
                          ...pre,
                          last_name: e.target.value,
                        }));
                      }}
                      disabled
                    />

                    <ErrorMessage error={errors?.last_name?.message} />
                  </>
                )}
              />
            </div>

            <ArabicInput
              divClass="form-group col-3"
              label={t("NeedyPatient.LastName")}
              formStateData={needyPatient}
              setFormStateData={setNeedyPatient}
              name="last_name_ar"
              isdisabled={true}
            />
          </div>

          <div class="form-row">
            <div class="form-group col-lg-6">
              
              <div className="d-flex justify-content-between">
                <label for="cost-input">
                  Approx. Treatment Cost<span className="text-danger">*</span>
                </label>
                <p className="mb-2">{`${t("NeedyPatient.ApproxTreatmentCost")}`}</p>
              </div>

              <Controller
                name="approx_treatment_cost"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <>
                    <input
                      type="text"
                      style={{ height: "33px" }}
                      name="approx_treatment_cost"
                      {...field}
                      value={field.value || ""}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        // setNameData(e.target.value);
                        setNeedyPatient((pre) => ({
                          ...pre,
                          approx_treatment_cost: e.target.value,
                        }));
                        // handleChangeHospital(e);
                      }}
                    />

                    <ErrorMessage error={errors?.approx_treatment_cost?.message} />
                  </>
                )}
              />
            </div>
            <div class="form-group col-lg-6">
              <Controller
                name="contact"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <>
                    <Phone
                      name="contact"
                      arLabel={t('Common.PhoneNo')}
                      field={field}
                      value={field.value}
                      handleChange={(e) => {
                        field.onChange(e);
                        setNeedyPatient((pre) => ({
                          ...pre,
                          contact: handlePhoneCode(e?.target?.name, e?.target?.value, setValue),
                        }));
                      }}
                      disabled={true}
                    />
                    <ErrorMessage error={errors?.contact?.message} />
                  </>
                )}
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-lg-6">

              <div className="d-flex justify-content-between">
                <label for="phone-number-input">
                  Age<span className="text-danger">*</span>
                </label>
                <p className="mb-2">{`${t("Common.Age")}`}</p>
              </div>

              <Controller
                name="age"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <>
                    <input
                      type="text"
                      name="age"
                      {...field}
                      value={field.value || ""}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setNeedyPatient((pre) => ({
                          ...pre,
                          age: e.target.value,
                        }));
                      }}
                      disabled
                    />

                    <ErrorMessage error={errors?.age?.message} />
                  </>
                )}
              />
            </div>
            <div class="form-group col-lg-6">
              
              <div className="d-flex justify-content-between">
                <label>
                  Gender<span className="text-danger">*</span>
                </label>
                <p className="mb-2">{`${t("Common.Gender")}`}</p>
              </div>

              <Controller
                name="gender"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <>
                    <CustomDropDown
                      handleChangeSelect={(value, name) => {
                        field.onChange(value);
                        setNeedyPatient((pre) => ({ ...pre, gender: value }));
                      }}
                      option={gender}
                      field={field}
                      value={field.value || ""}
                      onBlur={field.onBlur}
                      disabled={true}
                    />

                    <ErrorMessage error={errors?.gender?.message} />
                  </>
                )}
              />
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-lg-6">
              
              <div className="d-flex justify-content-between">
                <label for="patient-id-input">
                  Hospital<span className="text-danger">*</span>
                </label>
                <p className="mb-2">{`${t("Common.Hospital")}`}</p>
              </div>

              <Controller
                name="hospital_id"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <>
                    <CustomDropDown
                      handleChangeSelect={(value, name) => {
                        field.onChange(value);
                        setNeedyPatient((pre) => ({
                          ...pre,
                          hospital_id: value,
                        }));
                      }}
                      option={hospitals}
                      field={field}
                      value={field.value}
                      onBlur={field.onBlur}
                      disabled={true}
                    />

                    <ErrorMessage error={errors?.hospital_id?.message} />
                  </>
                )}
              />
            </div>
            <div class="form-group col-lg-6">
              
              <div className="d-flex justify-content-between">
                <label for="disease-input">
                  Disease<span className="text-danger">*</span>{" "}
                </label>
                <p className="mb-2">{`${t("NeedyPatient.Disease")}`}</p>
              </div>

              <Controller
                name="disease"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <>
                    <input
                      type="text"
                      name="disease"
                      {...field}
                      value={field.value || ""}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        // setNameData(e.target.value);
                        setNeedyPatient((pre) => ({
                          ...pre,
                          disease: e.target.value,
                        }));
                        // handleChangeHospital(e);
                      }}
                    />

                    <ErrorMessage error={errors?.disease?.message} />
                  </>
                )}
              />
            </div>
          </div>

          <div className="form-row">

            <TextInput
              label={"Monthly Income"}
              arLabel={t('NeedyPatient.MonthlyIncome')}
              inputDivClass='col-6 form-group' 
              type='text' 
              name='monthly_income' 
              value={needyPatient?.monthly_income} 
              handleOnChange={(e) => setNeedyPatient(needyPatient => ({...needyPatient, monthly_income: e?.target?.value}))} 
              control={control} 
              error={errors?.monthly_income?.message}
            />

            <div class="form-group col-lg-6">

              <div className="d-flex justify-content-between">
                <label for="patient-id-input">
                  Medical History<span className="text-danger"></span>
                </label>
                <p className="mb-2">{`${t("NeedyPatient.MedicalHistory")}`}</p>
              </div>

              <CertificateInput
                selectedFiles={SelectedTreatmentCertificate}
                setSelectedFiles={setSelectedTreatmentCertificate}
                formDataState={needyPatient}
                setFormDataState={setNeedyPatient}
              />
            </div>

          </div>

          <div class="form-row">
            <div class="form-group col-lg-6">
              <label for="Location">Desciption</label>

              <Controller
                name="description"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <>
                    <textarea
                      type="text"
                      name="description"
                      rows={7}
                      cols={30}
                      {...field}
                      value={field.value || ""}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setNeedyPatient((pre) => ({
                          ...pre,
                          description: e.target.value,
                        }));
                      }}
                    />

                    <ErrorMessage error={errors?.description?.message} />
                  </>
                )}
              />
            </div>

            <ArabicTextArea
              divClass="form-group col-6"
              label={t("NeedyPatient.Description")}
              formStateData={needyPatient}
              setFormStateData={setNeedyPatient}
              name="description_ar"
            />
          </div>
        </div>

        <div className="privacy-selection">
          <div className="private-container"></div>
        </div>
        <div className="add-new-patient-form">
          <button
            type="button"
            disabled={isLoading}
            className="add-patient"
            style={{ cursor: !isPatientFetched && 'no-drop' }}
            onClick={isPatientFetched ? handleSubmit(handleNeedyPatientSubmit) : undefined}
          >
            {!isLoading ? (
              <span>{Id ? "Edit Needy Patient" : "Add Needy Patient"}</span>
            ) : (
              <div className="pb-2">
                <ButtonLoader />
              </div>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddNeedyPatientForm;
