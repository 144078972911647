import React from "react";

// svg

import { useState } from "react";
import useFetch from "../../customHook/useFetch";

import "../../assets/css/appointments/modal.scss";

import { Controller } from "react-hook-form";
import { Modal, DatePicker } from "antd";

import CustomDropDown from "../../atoms/CustomDropDown/Index";
import Phone from "../../atoms/phone";
import Availability from "../Doctor/Availability";
import ArabicInput from "../../molecules/ArabicInput";
import { useTranslation } from "react-i18next";
import BreadCrum from "../../atoms/breadcrum/BreadCrum";
import InputLabel from "../../molecules/TextInput/InputLabel";
import ErrorMessage from "../../formvalidation/errormessage";
import CustomButton from "../../molecules/CustomButton/CustomButton";
import useAppointmentForm from "../../helpers/appointmentform.hook";

const AddAppointments = () => {

  const breadCrumbItems = [
    {
      title: 'Appointments'
    },
    {
      title: 'Add Appointment',
      href: '/appointment/add'
    }
  ];

  const { appointmentStates, appointmentFuncs, useFormProps, apiHooks } = useAppointmentForm();
  const { appointmentData, appointmentState, otherStates } = appointmentStates;
  const { setAppointmentState, setOtherStates, handleOnChange, fetchPatientByKwdID, onHandleSubmit } = appointmentFuncs;
  const { control, errors, setValue, handleSubmit } = useFormProps;
  const { fetchKwdIDApiHook, fetchDoctorTimingsApiHook, addAppointmentApiHook } = apiHooks;
  const { t } = useTranslation();

  return (
    <div className="row pl-3 pr-2 pt-4 appointment-tab">
      <div className="col-12">
        <p className="mb-0 appointment-heading">Appointments</p>
      </div>
      <div className="col-12  my-4">
        <BreadCrum
          breadCrumbItems={breadCrumbItems}
        />
      </div>

      <div className="modal-content-wrapper w-100">

        <div class="form-wrapper px-3 mb-5 pb-5">
          <form>
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-9 pr-0">
                    <div class="form-group full-width">

                      <InputLabel
                        label={'KWD ID'}
                        arLabel={t('Common.KWDID')}
                        labelClass={''}
                        required={true}
                      />

                      <Controller
                        name="kwdId"
                        control={control}
                        rules={{
                          required: true,
                          pattern: /^\d{12}$/,
                        }}
                        render={({ field }) => (
                          <>
                            <input
                              type="text"
                              name="kwdId"
                              {...field}
                              value={field.value || ""}
                              onChange={(e) => {
                                field.onChange(e.target.value);
                                handleOnChange(e);
                              }}
                            />
                            <ErrorMessage error={errors?.kwdId?.message} />
                          </>
                        )}
                      />
                    </div>
                  </div>

                    <CustomButton 
                      label='Submit'
                      buttonDivClass={'col-3 mt-3'} 
                      buttonClass={`common-btn mt-4`} 
                      buttonHeight={'36.6px'}
                      isLoading={fetchKwdIDApiHook?.isLoading} 
                      disabled={appointmentState?.kwdId && !errors?.kwdId && appointmentState?.kwdId !== otherStates?.fetchedPatientKwdID ? false : true}
                      handleOnClick={() => {
                          fetchPatientByKwdID();
                      }}
                    />
                </div>
              </div>

              <div className="col-lg-3">
                <div class="form-group">
                  <p>Patient Name</p>
                  <Controller
                    name="patient_name"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <>
                        <input
                          style={{ backgroundColor: "white" }}
                          type="text"
                          name="patient_name"
                          {...field}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                          disabled
                        />

                        <ErrorMessage error={errors?.patient_name?.message} />
                      </>
                    )}
                  />
                </div>
              </div>

              <ArabicInput
                divClass="form-group col-3"
                label={t("Promo.Title")}
                labelClass="mb-3"
                InputClass="pr-2"
                formStateData={appointmentData}
                name="patient_name_ar"
                isdisabled={true}
              />

              <div class="col-lg-6">
                <div class="form-group" style={{ marginTop: "11px" }}>
                  <Controller
                    name="phone"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <>
                        <Phone
                          disabled={true}
                          name="phone"
                          field={field}
                          value={
                            field.value
                          }
                          handleChange={(e) => {
                            field.onChange(e);
                          }}
                          arLabel={t('Common.PhoneNo')}
                        />
                        <ErrorMessage error={errors?.phone?.message} />
                      </>
                    )}
                  />
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group">
                  
                  <InputLabel
                    label={'Age'}
                    arLabel={t('Common.Age')}
                  />

                  <Controller
                    name="age"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <>
                        <input
                          style={{ backgroundColor: "white" }}
                          type="text"
                          name="age"
                          {...field}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                          disabled
                        />
                      </>
                    )}
                  />

                  <ErrorMessage error={errors?.age?.message} />
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group">

                  <InputLabel
                    label={'Email'}
                    arLabel={t('Common.Email')}
                  />

                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: true,
                      pattern:
                        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
                    }}
                    render={({ field }) => (
                      <input
                        style={{ backgroundColor: "white" }}
                        className=""
                        type="text"
                        name="email"
                        {...field}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                        disabled
                      />
                    )}
                  />

                  <ErrorMessage error={errors?.email?.message} />
                </div>
              </div>

              <div className="col-lg-6">
                <div class="form-group full-width">

                  <InputLabel
                    label={'Hospital'}
                    arLabel={t('Common.Hospital')}
                    required={true}
                  />

                  <Controller
                    name="hospital_id"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <>
                        <CustomDropDown
                          name={'hospital_id'}
                          handleChangeSelect={(value, name) => {
                            field.onChange(value);
                            handleOnChange({value, name});
                          }}
                          option={appointmentData?.hospitals}
                          field={field}
                          value={field.value}
                          onBlur={field.onBlur}
                          allowClear={false}
                        />

                        <ErrorMessage error={errors?.hospital_id?.message} />
                      </>
                    )}
                  />
                </div>
              </div>

              <div className="col-lg-6">
                <div class="form-group">

                  <InputLabel
                    label={'Specialization'}
                    arLabel={t('Common.Specialization')}
                    required={true}
                  />

                  <Controller
                    name="specialization_id"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <>
                        <CustomDropDown
                          name="specialization_id"
                          handleChangeSelect={(value, name) => {
                            field.onChange(value);
                            handleOnChange({name, value});
                          }}
                          option={appointmentData?.specialities}
                          field={field}
                          value={field.value}
                          onBlur={field.onBlur}
                          disabled={appointmentState?.hospital_id ? false : true}
                          allowClear={false}
                        />

                        <ErrorMessage error={errors?.specialization_id?.message} />
                      </>
                    )}
                  />
                </div>
              </div>

              <div className="col-lg-6">
                <div class="form-group">
                  
                  <InputLabel
                    label={'Doctor'}
                    arLabel={t('Common.Doctor')}
                    required={true}
                  />

                  <Controller
                    name="doctor_id"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <>
                        <CustomDropDown
                          name="doctor_id"
                          handleChangeSelect={(value, name) => {
                            field.onChange(value);
                            handleOnChange({name, value});
                          }}
                          option={appointmentData?.doctors}
                          field={field}
                          value={field.value}
                          onBlur={field.onBlur}
                          disabled={appointmentState?.specialization_id ? false : true}
                          allowClear={false}
                        />
                      </>
                    )}
                  />
                  <ErrorMessage error={errors?.doctor_id?.message} errorTagClass={'mr-2'} />

                  <label style={{ fontSize: "12px", marginBottom: "-15px" }}>
                    Please select the{" "}
                    <span
                      style={{ textDecoration: "underline", cursor: appointmentState?.doctor_id && !fetchDoctorTimingsApiHook?.isLoading ? 'pointer' : 'no-drop' }}
                      className="font-weight-bold text-dark cursor-pointer "
                      onClick={() => {
                        if(appointmentState?.doctor_id && !fetchDoctorTimingsApiHook?.isLoading) setOtherStates({...otherStates, isTimingsModalOpen: true});
                      }}
                    >
                      {" "}
                      doctor's availability
                    </span>{" "}
                    before booking an appointment.{" "}
                  </label>
                </div>
              </div>

              <div className="col-6">

                <InputLabel
                  label={'Doctor Availability'}
                  arLabel={t('Common.DoctorAvailability')}
                  required={true}
                />

                <CustomDropDown
                  name={'doctor_availability'}
                  handleChangeSelect={(value, name) => {
                    handleOnChange({name, value});
                    setValue('doctor_availability', value, { shouldValidate: true });
                  }}
                  option={appointmentData?.doctor_availabilities}
                  value={appointmentState?.doctor_availability}
                  disabled={!appointmentState?.doctor_id && true}
                  allowClear={false}
                  isController={true}
                  control={control}
                  error={errors?.doctor_availability?.message}
                />
              </div>

              <Modal
                className="doctor-filter-modal"
                centered
                open={otherStates?.isTimingsModalOpen}
                // onOk={() => setOtherStates({...otherStates, isTimingsModalOpen: false})}
                onCancel={() => setOtherStates({...otherStates, isTimingsModalOpen: false})}
                width={735}
                footer={<div className="row px-3 mt-4 mb-2"></div>}
              >
                <div className="row mx-3 mt-0 pt-3 mb-5 pb-5">
                  <Availability
                    appointmentDate={appointmentState}
                    setAppointmentDate={setAppointmentState}
                    slotData={appointmentData.doctor_slots}
                    setValue={setValue}
                  />
                </div>
              </Modal>

              <div class="col-lg-6">
                <div class="form-group">
                  
                  <InputLabel
                    label={'Fees'}
                    arLabel={t('Common.Fees')}
                  />

                  <Controller
                    name="fees"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <>
                        <input
                          style={{ backgroundColor: "white" }}
                          type="text"
                          name="fees"
                          {...field}
                          value={field.value || ""}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                          disabled
                        />

                      </>
                    )}
                  />
                  <ErrorMessage error={errors?.fees?.message} />
                </div>
              </div>

              <div className="col-lg-6">
                <div class="form-group">

                  <InputLabel
                    label={'Pick slot'}
                    arLabel={t('Common.Pickslot')}
                  />

                  <div
                    className="border date-picker-appointment"
                    style={{ borderRadius: "5px" }}
                  >
                    <DatePicker.RangePicker
                      placeholder={[
                        `${appointmentState?.start_slot || "Start Slot"}`,
                        `${appointmentState?.end_slot || "End Slot"}`,
                      ]}
                      disabled
                      // onChange={handleDatesChange}
                      format="DD/MM/YYYY"
                      style={{
                        border: "none",
                        width: "100%",
                        height: "36.5px",
                      }}
                    />
                  </div>

                  <ErrorMessage error={errors?.time?.message} />
                </div>
              </div>

              <div className="col-lg-6">
                <div class="form-group">
                  
                  <InputLabel
                    label={'Date'}
                    arLabel={t('Common.Date')}
                  />

                  <div
                    className="border date-picker-appointment"
                    style={{ borderRadius: "5px" }}
                  >
                    <DatePicker
                      disabled
                      placeholder={
                        appointmentState.selected_date || "Select Date"
                      }
                      dateRender={(current) => {
                        
                      }}
                      format="YYYY-MM-DD"
                      style={{
                        border: "none",
                        width: "100%",
                        height: "36.5px",
                      }}
                    />
                  </div>
                  <ErrorMessage error={errors?.date?.message} />
                </div>
              </div>

              <div class="col-12 mt-4">

                <CustomButton 
                  label='Add Appointment' 
                  type={'submit'}
                  buttonDivClass={'w-25'} 
                  buttonClass={'common-btn'} 
                  isLoading={addAppointmentApiHook?.isLoading}
                  disabled={appointmentState?.patient_id ? false : true}
                  handleOnClick={handleSubmit(onHandleSubmit)}
                />

              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddAppointments;
