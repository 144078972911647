import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "../../assets/css/common/datatable.scss";
import "../../components/Hospital/BookedHospitalRooms/bookedhospitalrooms.scss";

import DeleteIcon from "../../assets/images/pharmacy/DeleteIcon.svg";
import EditIcon from "../../assets/images/pharmacy/EditIcon.svg";
import ServiceListDocumentIcon from "../../assets/images/homeservice/ServiceListDocumentIcon.svg";
import GreenArrowDownIcon from "../../assets/images/homeservice/GreenArrowDownIcon.svg";
import { Modal } from "antd";
import CustomPagination from "../../components/common/CustomPagination";
import { useNavigate } from "react-router-dom";
import ListSkeleton from "../ListSkeleton/ListSkeleton";
import FiltersHelper from "./helper";
import ButtonLoader from "../../atoms/buttonLoader";

const DynamicRoomsDataTable = ({
  tableColumns,
  tableRows,
  paginationData,
  statusApiEndPoint,
  deleteApiEndPoint,
  handleChangePage,
  handleDeleteList,
  handleChangeListStatus,
  setIsViewDetailModalOpen,
  handleRequestDetail,
  isEdit,
  isDel,
  isCompleted,
  updateListPath,
  isRowsDataLoading,
  handleSortByAge,
  handleSortIcons,
  isStatusLoading,
  statusOptions
}) => {
  const [otherStates, setOtherStates] = useState({
    listID: null,
    statusValue: '',
    isStatusModalOpen: false,
    isDeleteModalOpen: false
  });
  const navigate = useNavigate();
  const { page, rowsPerPage, totalRows, to, totalPages } = paginationData;

  const { filterFuncs: { handleTruncateText } } = FiltersHelper();

  useEffect(() => {
    if(!isStatusLoading) setOtherStates({...otherStates, isStatusModalOpen: false});
  },[isStatusLoading])

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: "#FFFFFF" }}
        className="custom-scroll roomsListTable"
      >
        <Table aria-label="simple table">
          <TableHead
            sx={{
              "& th": {
                color: "#193F52",
                whiteSpace: "nowrap",
                wordWrap: "break-word",
              },
            }}
          >
            <TableRow>
              {tableColumns?.map((column) => {
                return (
                  <TableCell
                    className={`${column?.className ? column?.className : ""} ${column?.sortBy && 'tableCellHover'}`}
                    align={column.align}
                    onClick={() => { column?.sortBy && handleSortByAge && handleSortByAge(column?.ascKey, column?.descKey) }}
                  >
                    {column?.sortBy && handleSortIcons && <i
                      class={`fa-solid ${handleSortIcons(
                        column?.ascKey,
                        column?.descKey
                      )} mr-2`}
                    ></i>}
                    {column.name}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody
            className="w-100"
            sx={{
              "& td": {
                color: "#767676",
                whiteSpace: "nowrap",
                wordWrap: "break-word",
              },
            }}
          >
            {isRowsDataLoading === undefined || !isRowsDataLoading ?

              tableRows?.length > 0 ? tableRows?.map((row, index) => {
                return (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left" className="number">
                      {(page - 1) * rowsPerPage + 1 + index}
                    </TableCell>

                    {Object.entries(row).map(([key, value], colIndex) => {

                      if (key === "status") {
                        return (
                          <TableCell align="left">
                            <div
                              className={`py-1 ${
                                value === 0
                                  ? "pendingStatus"
                                  : value === 1
                                  ? "acceptedStatus"
                                  : value === 3
                                  ? "completedStatus"
                                  : "declinedStatus"
                              }`}
                              style={{
                                borderRadius: "5px",
                                cursor: `${
                                  value === 0 ||
                                  (value === 1 && isCompleted)
                                    ? "pointer"
                                    : "no-drop"
                                }`,
                                width: "100px",
                                textAlign: "center",
                              }}
                              onClick={() => {
                                if (
                                  value === 0 ||
                                  (value === 1 && isCompleted)
                                ) {
                                  setOtherStates({
                                    ...otherStates, 
                                    listID: row['id'],
                                    statusValue: value,
                                    isStatusModalOpen: true
                                  })
                                }
                              }}
                            >
                              {statusOptions[value]}
                            </div>
                          </TableCell>
                        );
                      } else if (key === "document") {
                        return (
                          <TableCell align="center">
                            <img
                              className="cursor-pointer"
                              src={ServiceListDocumentIcon}
                              alt=""
                              onClick={() => {
                                window.open(
                                  `https://api.maweidi.com.kw/${value}`
                                );
                              }}
                            />
                          </TableCell>
                        );
                      } else if (key === "details") {
                        return (
                          <TableCell align="left">
                            <button
                              className="customButton"
                              onClick={() => {
                                setIsViewDetailModalOpen(index);
                              }}
                              style={{
                                padding: "5px 25px 5px 25px",
                              }}
                            >
                              View
                            </button>
                          </TableCell>
                        );
                      } else if (key !== "id") {
                        return <TableCell align={tableColumns[colIndex]?.rowColDir ? tableColumns[colIndex]?.rowColDir : 'left'}>{handleTruncateText(value, tableColumns[colIndex]?.textTruncLength)}</TableCell>;
                      }
                    })}

                    {(!isEdit || !isDel) && (
                      <TableCell align={`${!isEdit ? "left" : "left"}`}>
                        {!isEdit && (
                          <img
                            className="cursor-pointer"
                            src={EditIcon}
                            alt=""
                            onClick={() => {
                              if (updateListPath) {
                                navigate(`${updateListPath}${row?.id}`);
                              }
                            }}
                          />
                        )}
                        {!isDel && (
                          <img
                            className="cursor-pointer"
                            src={DeleteIcon}
                            alt=""
                            onClick={() => {
                              setOtherStates({
                                ...otherStates, 
                                listID: row['id'],
                                isDeleteModalOpen: true
                              })
                            }}
                          />
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                );
              }) 
              
              :

              <TableRow>
                <TableCell align="center" colSpan={tableColumns?.length}>
                  No data found.
                </TableCell>
              </TableRow>
              
              :

              <TableRow>
                <TableCell colSpan={tableColumns?.length}>
                  <ListSkeleton totalRow={5} totalCol={tableColumns?.length} />
                </TableCell>
              </TableRow>

            }
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination Section */}

      <div
        className="pagination-container px-md-3 ml-md-1 mt-md-2 col-12"
        style={{ marginBottom: "130px" }}
      >
        <div className="pagination-detail">
          Showing {(page - 1) * rowsPerPage + 1} - {to} of {totalRows}
        </div>
        <CustomPagination
          page={page}
          totalPages={totalPages}
          onChangePage={handleChangePage}
        />
      </div>

      <Modal
        centered
        open={otherStates?.isStatusModalOpen}
        className="statusModal"
        onCancel={() => {
          !isStatusLoading && setOtherStates({
            ...otherStates, 
            listID: null,
            isStatusModalOpen: false
          })
        }}
        closeIcon={null}
        footer={null}
        bodyStyle={{
          height: !handleRequestDetail && "200px",
          display: "flex",
          flexDirection: 'column',
          alignItems: "center",
          justifyContent: "center"
        }}
      >
          {handleRequestDetail && handleRequestDetail(otherStates?.listID)}
        <div className="statusModalBody" style={{width: '100%'}}>
          <p className={handleRequestDetail && 'mb-4'}>Are you sure you want to change the status ?</p>

          {otherStates?.statusValue === 0 ? (
            <>
              <button
                className="acceptedStatus"
                onClick={() => {
                  setOtherStates({...otherStates, status: 1});
                  handleChangeListStatus(statusApiEndPoint, 1, otherStates?.listID);
                }}
                disabled={isStatusLoading}
              >
                {isStatusLoading && otherStates?.status === 1 ? <ButtonLoader /> : 'Approve'}
              </button>
              <button
                className="declinedStatus"
                onClick={() => {
                  setOtherStates({...otherStates, status: 2});
                  handleChangeListStatus(statusApiEndPoint, 2, otherStates?.listID);
                }}
                disabled={isStatusLoading}
              >
                {isStatusLoading && otherStates?.status === 2 ? <ButtonLoader /> : 'Decline'}
              </button>
            </>
          ) : (
            <button
              className="completedStatus"
              onClick={() => {
                setOtherStates({...otherStates, status: 3});
                handleChangeListStatus(statusApiEndPoint, 3, otherStates?.listID);
              }}
              disabled={isStatusLoading}
            >
              {isStatusLoading && otherStates?.status === 3 ? <ButtonLoader /> : 'Complete'}
            </button>
          )}
        </div>
      </Modal>

      <Modal
        centered
        open={otherStates?.isDeleteModalOpen}
        className="statusModal"
        onCancel={() => {
          setOtherStates({
            ...otherStates, 
            listID: null,
            isDeleteModalOpen: false
          })
        }}
        closeIcon={null}
        footer={null}
        bodyStyle={{
          height: "200px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="statusModalBody">
          <p className="">Are you sure you want to delete ?</p>
          <button
            className="deleteButton"
            onClick={() => {
              if (handleDeleteList) {
                handleDeleteList(deleteApiEndPoint, otherStates?.listID);
              }
              setOtherStates({
                ...otherStates, 
                listID: null,
                isDeleteModalOpen: false
              })
            }}
          >
            Delete
          </button>
        </div>
      </Modal>
    </>
  );
};

export default DynamicRoomsDataTable;
