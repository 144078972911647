import React, { useEffect } from "react";
import Searchbar from "../../components/common/Searchbar";
import BreadCrum from "../../atoms/breadcrum/BreadCrum";
import { Link } from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";
import "../../assets/css/blooddonation.scss";
import { useState } from "react";
import DownIcon from "../../assets/images/dashboard/DownIcon.svg";
import { DatePicker, Dropdown, Space } from "antd";
import moment from "moment";
import CustomDropDown from "../../atoms/CustomDropDown/Index";

import DocumentRemoveIconRed from "../../assets/images/doctor/DocumentRemoveIconRed.svg";

const ListHeader = ({
  mainHeading,
  isAppointmentHeader,
  isBloodDonationHeader,
  placeholder,
  setParamTitle,
  items,
  isSortBy,
  setIsSortBy,
  dateRange,
  setDateRange,
  isBloodGroup,
  setIsBloodGroup,
  btnText,
  linkBreadCrum,
  blinkBreadCrumText,
  linkbtn,
  csvData,
  disabled,
  exportFileName,
  blinkBreadCrumText1,
  filterOption,
  setFilterOption,
  filterOptionData,
  btnShow,
  breadCrumbItems
}) => {
  const role = JSON.parse(localStorage.getItem("userRoles"));
  const isSuperAdmin = Object.keys(role).length === 0;

  const [isActive, setIsActive] = useState(false);

  const { RangePicker } = DatePicker;
  const [isRangePickerOpen, setIsRangePickerOpen] = useState(false);
  const [isSortedItemSelected, setIsSortedItemSelected] = useState(null);
  const [check, setCheck] = useState({});
  const [dateRangeValue, setDateRangeValue] = useState([]);

  const onClick = ({ key }) => {
    if (isSortBy === items[key].sortBy) {
      setIsSortBy("");
      setIsSortedItemSelected(null);
    } else {
      setIsSortBy(items[key].sortBy);
      setIsSortedItemSelected(key);
    }
  };

  useEffect(() => {
    const check = items?.some((item) => item?.sortBy === isSortBy);

    if (!check) {
      setIsSortedItemSelected(null);
    }
  }, [isSortBy]);

  const handleSelectDateRange = (e) => {
    setDateRangeValue(e);
    setDateRange({
      start_date: moment(e[0]?.$d).format("YYYY-MM-DD"),
      end_date: moment(e[1]?.$d).format("YYYY-MM-DD"),
    });
  };

  const [bloodTypes, setBloodTypes] = useState([
    {
      value: "A%2B",
      label: "A+",
    },
    {
      value: "A%2D",
      label: "A-",
    },
    {
      value: "B%2B",
      label: "B+",
    },
    {
      value: "B%2D",
      label: "B-",
    },
    {
      value: "AB%2B",
      label: "AB+",
    },
    {
      value: "AB%2D",
      label: "AB-",
    },
    {
      value: "O%2B",
      label: "O+",
    },
    {
      value: "O%2D",
      label: "O-",
    },
  ]);

  const handleRemoveDateFilter = () => {
    setDateRange({
      start_date: "",
      end_date: "",
    });
    setIsRangePickerOpen(false);
    setDateRangeValue([]);
  };

  return (
    <div className="row mb-1">
      <div
        className="col-12 mb-2 blooddonation-breadcrumb"
        style={{ fontSize: "14px", color: "#202020" }}
      >
        {mainHeading}
      </div>
      <div className={`${isAppointmentHeader ? "col-lg-4" : "col-lg-5"}`}>
        <BreadCrum
          firstLink={linkBreadCrum}
          firstText={blinkBreadCrumText}
          secondText={blinkBreadCrumText1}
          breadCrumbItems={breadCrumbItems}
        />
      </div>
      <div
        className={`${
          isAppointmentHeader ? "col-lg-8" : "col-lg-7"
        } d-flex justify-content-end align-items-center list-header`}
      >
        <div className="search-input-field d-flex align-items-center">
          <i
            class="fas fa-search pl-2 pt-1"
            style={{ color: "#6D7482", fontSize: "14.5px" }}
          ></i>
          <input
            type="text"
            placeholder={placeholder}
            className="pl-2"
            onChange={(e) => {
              setParamTitle(e.target.value);
            }}
          />
        </div>

        {filterOptionData && (
          <>
            <div className="ml-2 position-relative">
              <button
                className="dateButton"
                style={{ fontSize: dateRangeValue?.length !== 0 && "12px" }}
                onClick={() => setIsRangePickerOpen(!isRangePickerOpen)}
              >
                {dateRangeValue?.length !== 0
                  ? moment(dateRangeValue[0]?.$d).format("YYYY-MM-DD") + "..."
                  : "Select Date"}
              </button>
              {dateRangeValue?.length !== 0 && (
                <img
                  src={DocumentRemoveIconRed}
                  className="position-absolute cursor-pointer"
                  style={{ top: "-15%", right: "-8%", width: "18%" }}
                  onClick={handleRemoveDateFilter}
                ></img>
              )}
            </div>

            <RangePicker
              open={isRangePickerOpen}
              onOpenChange={() => setIsRangePickerOpen(!isRangePickerOpen)}
              style={{
                zIndex: -100,
                height: "0",
                width: "0",
                padding: "0",
              }}
              onChange={handleSelectDateRange}
              value={dateRangeValue}
            />
          </>
        )}

        {isBloodDonationHeader && (
          <div className="ml-2" style={{ width: "188px", height: "36px" }}>
            <CustomDropDown
              handleChangeSelect={(value) => {
                bloodTypes?.map((blood) => {
                  if (blood?.value == value) {
                    setIsBloodGroup(value);
                  } else {
                    if (!value) {
                      setIsBloodGroup("");
                    }
                  }
                });
              }}
              option={bloodTypes}
              value={isBloodGroup ? isBloodGroup : "Select by Blood Group"}
              allowClear={isBloodGroup !== "" ? true : false}
            />
          </div>
        )}

        {items && (
          <Dropdown
            menu={{
              items,
              onClick,
              selectedKeys: [isSortedItemSelected],
            }}
          >
            <div className="ml-2">
              <button className="dateButton">Sort By</button>
            </div>
          </Dropdown>
        )}

        <CSVLink filename={exportFileName} data={csvData}>
          <button disabled={disabled} className="list-header-btn1 ml-2">
            <i
              class="fa-solid fa-arrow-down pr-2 pt-1"
              style={{ color: "#6D7482", fontSize: "14.5px" }}
            ></i>
            Export
          </button>
        </CSVLink>

        {btnText ? (
          isSuperAdmin || btnShow === true ? (
            <Link to={linkbtn} className="">
              {" "}
              <button
                style={{ width: "230px" }}
                className="list-header-btn2 ml-2"
              >
                <i class="fa-solid fa-circle-plus pr-2 pt-1"></i>
                {btnText}
              </button>
            </Link>
          ) : null
        ) : null}
      </div>
    </div>
  );
};

export default ListHeader;
