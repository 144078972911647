
const required = 'This field is required';
const alphabets = 'This field must contain only alphabets';
const numeric = 'This field must contain only numeric values';
const email = 'This field must contain a valid email address';
const contact = 'Please enter valid contact number e.g. +96512345678';
const kwdID = 'This field must contain alphanumeric values';
const profilePicture = 'Please select a valid file format Png/Jpeg/Jpg';
const username = 'This field must contain only alphanumeric characters, underscores and periods';
const website = 'Please enter valid url e.g. www.maweidi.com';
const password = 'This field must contain both letters and digits. Special characters are allowed';

const arrayRange = (length, key) => {
    const range = (key === 'min' ? 'at least' : key === 'max' ? 'at most' : '');
    return `This field must contain ${range} ${length} values`;
}

const stringMaxLength = (max) => {
    return `This field must be at most ${max} characters`;
}

const stringMinLength = (min) => {
    return `This field must be at least ${min} characters`;
}

const filesMinLength = (min) => {
    return `At least ${min} file is required`;
}

const filesMaxLength = (max) => {
    return `You can upload up to ${max} images`;
}

const stringLimitedLength = (length) => {
    return `This field must contain ${length} characters`;
}

const numericRange = (min, max) => {
    return `Number must be between ${min} and ${max}`;
}

export default {
    required,
    alphabets,
    numeric,
    email,
    contact,
    kwdID,
    profilePicture,
    username,
    website,
    password,
    stringMaxLength,
    stringMinLength,
    stringLimitedLength,
    numericRange,
    filesMinLength,
    filesMaxLength,
    arrayRange
};