import React, { useState } from 'react'
import TimeTablePencil from "../../assets/images/doctor/TimeTablePencil.svg";
import { Modal } from 'antd';
import PharmacyTimings from '../../components/Pharmacy/PharmacyTimings';


const TimeTableModal = ({addTimePostReq, setaddTimePostReq, id, label, arLabel}) => {

    const handleModalToggle = () => {
        setIsModalVisible(!isModalVisible);
    };

    const [isModalVisible, setIsModalVisible] = useState(false);

    function getDayName(dayNumber) {
        switch (dayNumber) {
        case 1:
            return "Sunday";
        case 2:
            return "Monday";
        case 3:
            return "Tuesday";
        case 4:
            return "Wednesday";
        case 5:
            return "Thursday";
        case 6:
            return "Friday";
        case 7:
            return "Saturday";
        default:
            return null;
        }
    }

  return (
    <div className="row mt-4">
        <div className="col-lg-6 pr-lg-1 doc-setting-input">
            <p className="mb-2">{label}{arLabel && arLabel}</p>
            <div className="pharmacy-schedule-shadow">
            <div className=" pharmacy-schedule d-flex w-100 justify-content-between flex-column border-left border-right border-top border-bottom">
                <div className="d-flex w-100 justify-content-between px-2">
                <span className="mb-2  input-field-timings" style={{ fontSize: "14px", color: "#535353" }}>
                    Set Schedule
                </span>
                <img
                    onClick={handleModalToggle}
                    src={TimeTablePencil}
                    alt="TimeTablePencil"
                    className="input-field-icon input-field-timings cursor-pointer"
                />
                </div>
            </div>

            <table className="table table-borderless">
                <thead>
                <tr>
                    <th className="text-left pharmacy-timings pl-3 border-left border-right" >Day</th>
                    <th className="text-center pharmacy-timings border-left border-right" >
                    Opening Time
                    </th>
                    <th className="text-center pharmacy-timings  border-left border-right" >
                    Closing Time
                    </th>
                </tr>
                </thead>
                <tbody>
                {addTimePostReq?.schedules.map((time) => {
                    const hasTimeSlots = time?.time_slots;
                    return (
                    <tr key={time.day} className="border pharmacy-time-cell">
                        <td className="" style={{ fontSize: '14px', color: '#535353' }}>{getDayName(time?.day) ? getDayName(time?.day) : "Days"}</td>
                        <td className="text-center " style={{ fontSize: '14px', color: '#535353' }}>
                        {time?.time_slots[0]?.start_time ? time?.time_slots[0]?.start_time : "Off"}
                        </td>
                        <td className="text-center  pl-3" style={{ fontSize: '14px', color: '#535353' }}>
                        {time?.time_slots[0]?.end_time ? time?.time_slots[0]?.end_time : "Off"}
                        </td>
                    </tr>
                    );
                })}
                </tbody>
            </table>
            </div>
        </div>

        <Modal
          title="Time Table"
          visible={isModalVisible}
          onCancel={handleModalToggle}
          footer={
            <div className="row px-3 mt-4 mb-2">
              <div className="col-6"></div>
              <div className="col-6 d-flex justify-content-end mt-3">
                <button className="apply-filter" onClick={handleModalToggle}>
                  Save Schedule
                </button>
              </div>
            </div>
          }
        >
          <hr />
          <div className="row px-3 mt-4">
            <p className=" mb-0 time-edit-text1">Set Standard Hours</p>
          </div>
          <br />
          <PharmacyTimings
            addTimePostReq={addTimePostReq}
            setaddTimePostReq={setaddTimePostReq}
            EquipmentID={id}
          />
        </Modal>
    </div>
  )
}

export default TimeTableModal;