import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { Space, Switch } from "antd";
import TimeChanger from "../../atoms/TimeslotChanger/TimeChanger";
import CustomDropDown from "../../atoms/CustomDropDown/timeSlotDropDown";
import usePost from "../../customHook/usePost";
import TimeTableRemoveBtn from "../../assets/images/doctor/dash-circle-fill-red.svg";
import TimeTableAddBtn from "../../assets/images/doctor/TimeTableAddBtn.svg";
import ScheduleLayer from "../TimeTableModal/ScheduleLayer";
import useDeleteData from "../../customHook/useDelete";
import useFetch from "../../customHook/useFetch";

const DoctorTimeTable = ({
  Id,
  label,
  arLabel,
  itemLabel,
  itemIdLabel,
  isEditSlots,
  addTimePostReq,
  setaddTimePostReq,
  selectDay,
  setSelectDay,
  isTimeSlotError,
  setIsTimeSlotError,
  modal2Open,
  setModal2Open,
  specialistOptions,
  setspecialistOptions,
  selectedArrayItems,
  isNotDoctorTimeTable,
  getTimeTableData,
  postDoctorAvalibility,
}) => {
  const [doctorTimeTable, setdoctorTimeTable] = useState([]);
  const { postData } = usePost();
  const { deleteData, isLoadingTimeTable, errorTimeTable } = useDeleteData();
  const Hospitals = useFetch(
    isNotDoctorTimeTable
      ? "list_of_service_provider"
      : `${process.env.REACT_APP_GET_HOSPITAL_DATA}`
  );

  useEffect(() => {
    if (Hospitals?.data?.data) {
      if (isNotDoctorTimeTable) {
        setspecialistOptions(
          Hospitals?.data?.data?.service_providers?.map((hospital) => {
            return {
              label: hospital?.name,
              value: hospital?.name,
              id: hospital?.id,
            };
          })
        );
      } else {
        setspecialistOptions(
          Hospitals?.data?.data?.map((hospital) => {
            return {
              label: hospital?.name,
              value: hospital?.name,
              id: hospital?.id,
            };
          })
        );
      }
    }
  }, [Hospitals?.data]);

  useEffect(() => {
    if (selectedArrayItems?.length) {
      var temp = [];
      var tempIndex = -1;

      if (isNotDoctorTimeTable) {
        selectedArrayItems?.map((hospitalID) => {
          Hospitals?.data?.data?.service_providers?.map((hospital) => {
            if (hospitalID === hospital?.id) {
              temp[++tempIndex] = {
                label: hospital?.name,
                value: hospital?.name,
                id: hospital?.id,
              };
            }
          });
        });
      } else {
        selectedArrayItems?.map((hospitalID) => {
          Hospitals?.data?.data?.map((hospital) => {
            if (hospitalID === hospital?.id) {
              temp[++tempIndex] = {
                label: hospital?.name,
                value: hospital?.name,
                id: hospital?.id,
              };
            }
          });
        });
      }

      var addTimePostReq_temp = addTimePostReq;
      var hospitals_temp = selectedArrayItems;

      if (hospitals_temp) {
        addTimePostReq_temp?.schedules?.map((slot, index_i) => {
          if (slot?.time_slots?.length === 0) {
          } else if (slot?.time_slots?.length === 1) {
            if (
              hospitals_temp?.indexOf(slot?.time_slots[0]?.[itemIdLabel]) === -1
            ) {
              addTimePostReq_temp.schedules[index_i].time_slots[0][
                itemIdLabel
              ] = "";
            }
          } else if (slot?.time_slots?.length > 1) {
            slot?.time_slots?.map((time, index_j) => {
              if (
                hospitals_temp?.indexOf(
                  slot?.time_slots[index_j]?.[itemIdLabel]
                ) === -1
              ) {
                addTimePostReq_temp.schedules[index_i].time_slots[index_j][
                  itemIdLabel
                ] = "";
              }
            });
          }
        });

        setspecialistOptions(temp);
        setaddTimePostReq(addTimePostReq_temp);
      }
    }
  }, [selectedArrayItems]);

  const handleChangeSelect = (val, name, updatedValue, dayID, setAblID) => {
    let data = specialistOptions.filter((value) => {
      return value.value === val;
    })[0]?.id;
    setaddTimePostReq({
      ...addTimePostReq,
      schedules: addTimePostReq?.schedules?.map((schedule) =>
        schedule?.day === dayID
          ? {
              ...schedule,
              time_slots: schedule?.time_slots?.map((timeSlot) =>
                timeSlot?.uniVal === updatedValue
                  ? {
                      ...timeSlot,
                      [itemIdLabel]: data,
                    }
                  : timeSlot
              ),
            }
          : schedule
      ),
    });
  };

  let filterTimeAvalibility = (dayID) => {
    let filteredSchedules = addTimePostReq?.schedules?.filter(
      (tableFilter) => tableFilter.day == dayID
    );
    return [filteredSchedules[0]?.time_slots, dayID];
  };

  const appendSingleTimeSlot = (dayIndex) => {
    const newUniVal =
      addTimePostReq?.schedules?.reduce((maxUniVal, schedule) => {
        return Math.max(
          maxUniVal,
          Math.max(...schedule?.time_slots.map((slot) => slot?.uniVal))
        );
      }, 0) + 1;

    const newTimeSlot = {
      end_time: "",
      [itemIdLabel]: "",
      start_time: "",
      uniVal: newUniVal,
    };

    setaddTimePostReq((prevAddTimePostReq) => ({
      ...prevAddTimePostReq,
      schedules: prevAddTimePostReq?.schedules?.map((schedule) =>
        schedule?.day === dayIndex
          ? {
              ...schedule,
              time_slots: [...schedule?.time_slots, newTimeSlot],
            }
          : schedule
      ),
    }));
  };

  const deleteSingleTimeSlot = (day, elementID) => {
    setaddTimePostReq({
      ...addTimePostReq,
      schedules: addTimePostReq?.schedules?.map((schedule) =>
        schedule.day === day
          ? {
              ...schedule,
              time_slots: schedule.time_slots.filter(
                (timeSlot) => timeSlot.uniVal !== elementID
              ),
            }
          : schedule
      ),
    });

    if (addTimePostReq?.schedules[day - 1]?.time_slots?.length === 1) {
      const tempDays = Object.keys(selectDay);
      const tempDay = tempDays[day - 1];

      setSelectDay((selectDay) => ({
        ...selectDay,
        [tempDay]: {
          ...selectDay[tempDay],
          toggle: false,
        },
      }));
    }
  };

  const HandleRemoveTimeSlots = (dayToggle, day, dayID) => {
    var tempSchedules = addTimePostReq;

    if (dayToggle === true) {
      tempSchedules.schedules[dayID - 1].time_slots = [];
    }

    setIsTimeSlotError(null);
  };

  function renderLoop(countDays = [], dayName, dayNumber) {
    var actualDay = countDays[0];
    const items = [];

    for (let i = 0; i < actualDay?.length; i++) {
      const isLastElement = i === actualDay?.length - 1;

      actualDay[i].uniVal = i;
      let hospitalName = specialistOptions?.filter((e) => {
        return e?.id === actualDay[i]?.[itemIdLabel];
      })[0]?.label;

      items?.push(
        <>
          <div
            className={`${countDays === 1 ? "" : "pb-3"} `}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div style={{ width: "141px" }}>
                <CustomDropDown
                  option={specialistOptions}
                  handleChangeSelect={handleChangeSelect}
                  setAblID={actualDay[i]?.[itemIdLabel]}
                  value={
                    hospitalName?.length > 13
                      ? hospitalName?.slice(0, 13) + "..."
                      : hospitalName ?? actualDay[i]?.[itemIdLabel]
                  }
                  updatedValue={actualDay[i]?.uniVal}
                  dayId={dayNumber}
                />
              </div>

              {/* .slice(0, -3) */}
              <TimeChanger
                Time={actualDay[i]?.start_time}
                setaddTimePostReq={setaddTimePostReq}
                addTimePostReq={addTimePostReq}
                elementID={actualDay[i].uniVal}
                dayNumber={dayNumber}
                singleSelector={true}
              />

              <TimeChanger
                Time={actualDay[i]?.end_time}
                setaddTimePostReq={setaddTimePostReq}
                addTimePostReq={addTimePostReq}
                elementID={actualDay[i].uniVal}
                dayNumber={dayNumber}
                singleSelector={false}
              />
            </div>
            {isEditSlots === false ? null : !isLastElement ? (
              <span className="pl-lg-3 pl-1">
                <img
                  className="cursor-pointer"
                  onClick={() => {
                    deleteSingleTimeSlot(countDays[1], actualDay[i]?.uniVal);
                    // decreaseDayCount(dayName)
                    // deleteTimeSlot(dayName, countDays[i]?.hospital_id)
                  }}
                  src={TimeTableRemoveBtn}
                  alt=""
                  style={{ width: "20px" }}
                />
              </span>
            ) : (
              <>
                <span className="pl-lg-3 pl-1">
                  <img
                    className="cursor-pointer"
                    onClick={() => {
                      deleteSingleTimeSlot(countDays[1], actualDay[i]?.uniVal);
                      // decreaseDayCount(dayName)
                      // deleteTimeSlot(dayName, countDays[i]?.hospital_id)
                    }}
                    src={TimeTableRemoveBtn}
                    alt=""
                    style={{ width: "20px" }}
                  />
                </span>
                <span className="pl-lg-3 pl-1">
                  <img
                    className="cursor-pointer"
                    onClick={() => {
                      // increaseDayCount("sunday")
                      appendSingleTimeSlot(dayNumber);
                    }}
                    src={TimeTableAddBtn}
                    alt=""
                  />
                </span>
              </>
            )}
          </div>
        </>
      );
    }

    return items;
  }

  useEffect(() => {}, [selectDay]);

  useEffect(() => {}, [addTimePostReq]);

  useEffect(() => {}, [specialistOptions]);

  return (
    <>
      <ScheduleLayer
        label={label}
        arLabel={arLabel}
        itemLabel={itemLabel}
        itemIdLabel={itemIdLabel}
        addTimePostReq={addTimePostReq}
        specialistOptions={specialistOptions}
        setModal2Open={setModal2Open}
        getTimeTableData={getTimeTableData}
      />

      <Modal
        className="doctor-filter-modal"
        centered
        open={modal2Open}
        onOk={() => setModal2Open(false)}
        onCancel={() => {
          if (postDoctorAvalibility()) {
            setModal2Open(false);
          }
        }}
        width={"60%"}
        footer={
          <div className="row px-3 mt-4 mb-2">
            <div
              className="col-6 text-danger text-center d-flex align-items-center"
              style={{ fontWeight: "600" }}
            >
              {isTimeSlotError}
            </div>

            <div className="col-6 d-flex justify-content-end mt-3">
              <button
                className="apply-filter"
                onClick={() => {
                  if (postDoctorAvalibility()) {
                    setModal2Open(false);
                  }
                }}
              >
                Save Schedules
              </button>
            </div>
          </div>
        }
      >
        <div className="row px-2 border-bottom">
          <div className="col-12 ">
            <p className="doc-add-filter">Time Table</p>
          </div>
        </div>

        <div className="row px-3 mt-4">
          <p className=" mb-0 time-edit-text1">Set Standard Hours</p>
        </div>

        <div className="row px-3  mt-lg-4 mb-1">
          <div className="col-lg-4 px-1 d-flex justify-content-end align-items-start pt-1 ">
            <div
              className="  mb-lg-3 d-flex justify-content-between"
              style={{ width: "100%" }}
            >
              <span className="time-edit-days-name">Sunday</span>
              <Space direction="vertical">
                <Switch
                  size={300}
                  checked={selectDay.sunday.toggle}
                  onClick={(e) => {
                    setSelectDay((prevState) => ({
                      ...prevState,
                      sunday: {
                        ...prevState.sunday,
                        toggle: !prevState.sunday.toggle,
                      },
                    }));
                    if (filterTimeAvalibility(1)[0]?.length < 1) {
                      appendSingleTimeSlot(1);
                    }
                    // selectDay?.sunday.toggle ? deleteTimeSlot(1) : deleteTimeSlot()

                    HandleRemoveTimeSlots(
                      selectDay?.sunday?.toggle,
                      "sunday",
                      1
                    );
                  }}
                />
              </Space>
            </div>
          </div>

          <div className="col-lg-8 d-flex  flex-column  align-items-lg-end align-items-md-center align-items-start">
            {
              // selectDay.sunday.toggle && addTimePostReq?.schedules[1]?.time_slots.length === 0
              true
                ? null
                : //       < div className="mb-3" style={{ width: "100%", display: 'flex', justifyContent: "space-around", alignItems: "center" }}>
                  //   <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                  //     <div style={{ width: "141px" }}>
                  //       <CustomDropDown option={specialistOptions} hospitalDopDown={hospitalDopDown} dayId={1} handleChangeSelect={handleChangeSelect} />
                  //     </div>

                  //     <TimeChanger staringTimeDrop={staringTimeDrop} dayId={1} selector={true} empetyTime={empetyTime} />

                  //     <TimeChanger endTimeDrop={endTimeDrop} dayId={1} selector={false} empetyTime={empetyTime} />

                  //   </div>
                  //   <span className="pl-lg-3 pl-1">
                  //     <img
                  //       className="cursor-pointer"
                  //       onClick={() => {
                  //         increaseDayCount("sunday")
                  //         appendSingleTimeSlot(1)
                  //       }}
                  //       src={TimeTableAddBtn}
                  //       alt=""

                  //     />
                  //   </span>

                  // </div>

                  null
            }

            {selectDay?.sunday?.toggle &&
              renderLoop(filterTimeAvalibility(1), "sunday", 1)}
          </div>
        </div>

        <div className="row px-3  mb-1">
          <div className="col-lg-4 px-1 d-flex justify-content-end align-items-start pt-1 ">
            <div
              className="  mb-lg-3 d-flex justify-content-between"
              style={{ width: "100%" }}
            >
              <span className="time-edit-days-name">Monday</span>
              <Space direction="vertical">
                <Switch
                  size={300}
                  checked={selectDay.monday.toggle}
                  onClick={() => {
                    setSelectDay((prevState) => ({
                      ...prevState,
                      monday: {
                        ...prevState.monday,
                        toggle: !prevState.monday.toggle,
                      },
                    }));
                    if (filterTimeAvalibility(2)[0]?.length < 1) {
                      appendSingleTimeSlot(2);
                    }
                    // selectDay?.monday.toggle ? deleteTimeSlot(2) : deleteTimeSlot()
                    HandleRemoveTimeSlots(
                      selectDay?.monday?.toggle,
                      "monday",
                      2
                    );
                  }}
                />
              </Space>
            </div>
          </div>

          <div className="col-lg-8 d-flex  flex-column  align-items-lg-end align-items-md-center">
            {selectDay.monday.toggle
              ? //  &&  addTimePostReq?.schedules[2]?.time_slots.length === 0

                null
              : // <div className="mb-3" style={{ width: "100%", display: 'flex', justifyContent: "space-around", alignItems: "center" }}>
                //   <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                //     <div style={{ width: "141px" }}>
                //       <CustomDropDown option={specialistOptions} hospitalDopDown={hospitalDopDown} dayId={2} handleChangeSelect={handleChangeSelect} />
                //     </div>

                //     <TimeChanger staringTimeDrop={staringTimeDrop} dayId={2} selector={true} />

                //     <TimeChanger endTimeDrop={endTimeDrop} dayId={2} selector={false} />

                //   </div>
                //   <span className="pl-lg-3 pl-1">
                //     <img
                //       className="cursor-pointer"
                //       onClick={() => {
                //         increaseDayCount("monday")
                //         appendSingleTimeSlot(2)
                //       }}
                //       src={TimeTableAddBtn}
                //       alt=""
                //     />
                //   </span>

                // </div>

                null}
            {selectDay.monday.toggle &&
              renderLoop(filterTimeAvalibility(2), "monday", 2)}
          </div>
        </div>

        <div className="row px-3  mb-1">
          <div className="col-lg-4 px-1 d-flex justify-content-end align-items-start pt-1 ">
            <div
              className="  mb-lg-3 d-flex justify-content-between"
              style={{ width: "100%" }}
            >
              <span className="time-edit-days-name">Tuesday</span>

              <Space direction="vertical">
                <Switch
                  size={300}
                  checked={selectDay.tuesday.toggle}
                  onClick={() => {
                    setSelectDay((prevState) => ({
                      ...prevState,
                      tuesday: {
                        ...prevState.tuesday,
                        toggle: !prevState.tuesday.toggle,
                      },
                    }));

                    if (filterTimeAvalibility(3)[0]?.length < 1) {
                      appendSingleTimeSlot(3);
                    }
                    // selectDay?.tuesday.toggle ? deleteTimeSlot(3) : deleteTimeSlot()
                    HandleRemoveTimeSlots(
                      selectDay?.tuesday?.toggle,
                      "tuesday",
                      3
                    );
                  }}
                />
              </Space>
            </div>
          </div>

          <div className="col-lg-8 d-flex  flex-column  align-items-lg-end align-items-md-center">
            {selectDay.tuesday.toggle
              ? // <div className="mb-3" style={{ width: "100%", display: 'flex', justifyContent: "space-around", alignItems: "center" }}>
                //   <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                //     <div style={{ width: "141px" }}>
                //       <CustomDropDown option={specialistOptions} hospitalDopDown={hospitalDopDown} dayId={3} handleChangeSelect={handleChangeSelect} />
                //     </div>

                //     <TimeChanger staringTimeDrop={staringTimeDrop} dayId={3} selector={true} />

                //     <TimeChanger endTimeDrop={endTimeDrop} dayId={3} selector={false} />

                //   </div>
                //   <span className="pl-lg-3 pl-1">
                //     <img
                //       className="cursor-pointer"
                //       onClick={() => {
                //         increaseDayCount("tuesday")
                //         appendSingleTimeSlot(3)
                //       }}
                //       src={TimeTableAddBtn}
                //       alt=""
                //     />
                //   </span>

                // </div>
                null
              : null}
            {selectDay.tuesday.toggle &&
              renderLoop(filterTimeAvalibility(3), "tuesday", 3)}
          </div>
        </div>

        <div className="row px-3  mb-1">
          <div className="col-lg-4 px-1 d-flex justify-content-end align-items-start pt-1 ">
            <div
              className="  mb-lg-3 d-flex justify-content-between"
              style={{ width: "100%" }}
            >
              <span className="time-edit-days-name">Wednesday</span>
              <Space direction="vertical">
                <Switch
                  size={300}
                  checked={selectDay.wednesday.toggle}
                  onClick={() => {
                    setSelectDay((prevState) => ({
                      ...prevState,
                      wednesday: {
                        ...prevState.wednesday,
                        toggle: !prevState.wednesday.toggle,
                      },
                    }));

                    if (filterTimeAvalibility(4)[0]?.length < 1) {
                      appendSingleTimeSlot(4);
                    }
                    // selectDay?.wednesday.toggle ? deleteTimeSlot(4) : deleteTimeSlot()
                    HandleRemoveTimeSlots(
                      selectDay?.wednesday?.toggle,
                      "wednesday",
                      4
                    );
                  }}
                />
              </Space>
            </div>
          </div>

          <div className="col-lg-8 d-flex  flex-column  align-items-lg-end align-items-md-center">
            {selectDay.wednesday.toggle
              ? // <div className="mb-3" style={{ width: "100%", display: 'flex', justifyContent: "space-around", alignItems: "center" }}>
                //   <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                //     <div style={{ width: "141px" }}>
                //       <CustomDropDown option={specialistOptions} hospitalDopDown={hospitalDopDown} dayId={4} handleChangeSelect={handleChangeSelect} />
                //     </div>

                //     <TimeChanger staringTimeDrop={staringTimeDrop} selector={true} dayId={4} />

                //     <TimeChanger endTimeDrop={endTimeDrop} selector={false} dayId={4} />

                //   </div>
                //   <span className="pl-lg-3 pl-1">
                //     <img
                //       className="cursor-pointer"
                //       onClick={() => {
                //         increaseDayCount("wednesday")
                //         appendSingleTimeSlot(4)
                //       }}
                //       src={TimeTableAddBtn}
                //       alt=""
                //     />
                //   </span>

                // </div>
                null
              : null}
            {selectDay.wednesday.toggle &&
              renderLoop(filterTimeAvalibility(4), "wednesday", 4)}
          </div>
        </div>

        <div className="row px-3  mb-1">
          <div className="col-lg-4 px-1 d-flex justify-content-end align-items-start pt-1 ">
            <div
              className="  mb-lg-3 d-flex justify-content-between"
              style={{ width: "100%" }}
            >
              <span className="time-edit-days-name">Thursday</span>
              <Space direction="vertical">
                <Switch
                  size={300}
                  checked={selectDay.thursday.toggle}
                  onClick={() => {
                    setSelectDay((prevState) => ({
                      ...prevState,
                      thursday: {
                        ...prevState.thursday,
                        toggle: !prevState.thursday.toggle,
                      },
                    }));

                    if (filterTimeAvalibility(5)[0]?.length < 1) {
                      appendSingleTimeSlot(5);
                    }
                    // selectDay?.thursday.toggle ? deleteTimeSlot(5) : deleteTimeSlot()
                    HandleRemoveTimeSlots(
                      selectDay?.thursday?.toggle,
                      "thursday",
                      5
                    );
                  }}
                />
              </Space>
            </div>
          </div>

          <div className="col-lg-8 d-flex  flex-column  align-items-lg-end align-items-md-center">
            {selectDay.thursday.toggle
              ? // <div className="mb-3" style={{ width: "100%", display: 'flex', justifyContent: "space-around", alignItems: "center" }}>
                //   <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                //     <div style={{ width: "141px" }}>
                //       <CustomDropDown option={specialistOptions} hospitalDopDown={hospitalDopDown} dayId={5} handleChangeSelect={handleChangeSelect} />
                //     </div>

                //     <TimeChanger staringTimeDrop={staringTimeDrop} selector={true} dayId={5} />

                //     <TimeChanger endTimeDrop={endTimeDrop} selector={false} dayId={5} />

                //   </div>
                //   <span className="pl-lg-3 pl-1">
                //     <img
                //       className="cursor-pointer"
                //       onClick={() => {
                //         increaseDayCount("thursday")
                //         appendSingleTimeSlot(5)
                //       }}
                //       src={TimeTableAddBtn}
                //       alt=""
                //     />
                //   </span>

                // </div>
                null
              : null}

            {selectDay.thursday.toggle &&
              renderLoop(filterTimeAvalibility(5), "thursday", 5)}
          </div>
        </div>

        <div className="row px-3  mb-1">
          <div className="col-lg-4 px-1 d-flex justify-content-end align-items-start pt-1 ">
            <div
              className="  mb-lg-3 d-flex justify-content-between"
              style={{ width: "100%" }}
            >
              <span className="time-edit-days-name">Friday</span>
              <Space direction="vertical">
                <Switch
                  size={300}
                  checked={selectDay.friday.toggle}
                  onClick={() => {
                    setSelectDay((prevState) => ({
                      ...prevState,
                      friday: {
                        ...prevState.friday,
                        toggle: !prevState.friday.toggle,
                      },
                    }));

                    if (filterTimeAvalibility(6)[0]?.length < 1) {
                      appendSingleTimeSlot(6);
                    }
                    // selectDay?.friday.toggle ? deleteTimeSlot(6) : deleteTimeSlot()
                    HandleRemoveTimeSlots(
                      selectDay?.friday?.toggle,
                      "friday",
                      6
                    );
                  }}
                />
              </Space>
            </div>
          </div>
          <div className="col-lg-8 d-flex  flex-column  align-items-lg-end align-items-md-center">
            {selectDay.friday.toggle &&
              renderLoop(filterTimeAvalibility(6), "friday", 6)}
          </div>
        </div>

        <div className="row px-3  mb-1">
          <div className="col-lg-4 px-1 d-flex justify-content-end align-items-start pt-1 ">
            <div
              className="  mb-lg-3 d-flex justify-content-between"
              style={{ width: "100%" }}
            >
              <span className="time-edit-days-name">Saturday</span>
              <Space direction="vertical">
                <Switch
                  size={300}
                  checked={selectDay.saturday.toggle}
                  onClick={() => {
                    setSelectDay((prevState) => ({
                      ...prevState,
                      saturday: {
                        ...prevState.saturday,
                        toggle: !prevState.saturday.toggle,
                      },
                    }));

                    if (filterTimeAvalibility(7)[0]?.length < 1) {
                      appendSingleTimeSlot(7);
                    }
                    // selectDay?.thursday.toggle ? deleteTimeSlot(7) : deleteTimeSlot()
                    HandleRemoveTimeSlots(
                      selectDay?.saturday?.toggle,
                      "saturday",
                      7
                    );
                  }}
                />
              </Space>
            </div>
          </div>

          <div className="col-lg-8 d-flex  flex-column  align-items-lg-end align-items-md-center">
            {selectDay.saturday.toggle
              ? // <div className="mb-3" style={{ width: "100%", display: 'flex', justifyContent: "space-around", alignItems: "center" }}>
                //   <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                //     <div style={{ width: "141px" }}>
                //       <CustomDropDown option={specialistOptions} hospitalDopDown={hospitalDopDown} dayId={7} handleChangeSelect={handleChangeSelect} />
                //     </div>

                //     <TimeChanger staringTimeDrop={staringTimeDrop} selector={true} dayId={7} />

                //     <TimeChanger endTimeDrop={endTimeDrop} selector={false} dayId={7} />

                //   </div>
                //   <span className="pl-lg-3 pl-1">
                //     <img
                //       className="cursor-pointer"
                //       onClick={() => {
                //         increaseDayCount("saturday")
                //         appendSingleTimeSlot(7)
                //       }}
                //       src={TimeTableAddBtn}
                //       alt=""

                //     />
                //   </span>

                // </div>
                null
              : null}
            {selectDay.saturday.toggle &&
              renderLoop(filterTimeAvalibility(7), "saturday", 7)}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DoctorTimeTable;
