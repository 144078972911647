import React from 'react'
import TimeTablePencil from "../../assets/images/doctor/TimeTablePencil.svg";
import moment from 'moment';

const ScheduleLayer = ({label, arLabel, itemLabel, itemIdLabel, addTimePostReq, specialistOptions, setModal2Open, getTimeTableData}) => {

    function getDayName(dayNumber) {
        switch (dayNumber) {
        case 1:
            return "Sunday";
        case 2:
            return "Monday";
        case 3:
            return "Tuesday";
        case 4:
            return "Wednesday";
        case 5:
            return "Thursday";
        case 6:
            return "Friday";
        case 7:
            return "Saturday";
        default:
            return null;
        }
    }

  return (
        <div className={`pr-lg-1 doc-setting-input`}>
            <p className="mb-2">{label ? label : 'Time Table'}{arLabel && arLabel}</p>
            <div className="pharmacy-schedule-shadow">
            <div className=" pharmacy-schedule d-flex w-100 justify-content-between flex-column border-left border-right border-top border-bottom">
                <div className="d-flex w-100 justify-content-between px-2">
                <span className="mb-2  input-field-timings" style={{ fontSize: "14px", color: "#535353" }}>
                    Set Schedule
                </span>
                <img
                    src={TimeTablePencil}
                    alt="TimeTablePencil"
                    className="input-field-icon input-field-timings cursor-pointer"
                    onClick={() => {
                        setModal2Open(true);
                    }}
                />
                </div>
            </div>

            <table className="table table-borderless">
                <thead>
                <tr>
                    <th className="text-left pharmacy-timings pl-3 border-left border-right" >Day</th>
                    <th className="text-center pharmacy-timings  border-left border-right" >{itemLabel}</th>
                    <th className="text-center pharmacy-timings border-left border-right" >
                    Opening Time
                    </th>
                    <th className="text-center pharmacy-timings  border-left border-right" >
                    Closing Time
                    </th>
                </tr>
                </thead>
                <tbody>
                {addTimePostReq?.schedules.map((time) => {
                    return <>
                    {time?.time_slots?.length > 0 ? (
                        time?.time_slots?.map((slot, index) => {
                            return (
                                <tr key={time.day} className="border pharmacy-time-cell">
                                    <td className="" style={{ fontSize: '14px', color: '#535353' }}>{getDayName(time?.day) ? index === 0 && getDayName(time?.day) : "Days"}</td>
                                    <td className="text-center" style={{ fontSize: '14px', color: '#535353' }}>
                                    {
                                        specialistOptions?.filter((e) => {
                                        return (
                                            e?.id === slot?.[itemIdLabel]
                                        );
                                        })[0]?.label
                                    }
                                    </td>
                                    <td className="text-center " style={{ fontSize: '14px', color: '#535353' }}>
                                    {slot?.start_time ? moment(slot?.start_time, "HH:mm:ss").format("HH:mm") : "Off"}
                                    </td>
                                    <td className="text-center  pl-3" style={{ fontSize: '14px', color: '#535353' }}>
                                    {slot?.end_time ? moment(slot?.end_time, "HH:mm:ss").format("HH:mm") : "Off"}
                                    </td>
                                </tr>
                            )
                        })
                    ) : 
                        <tr key={time.day} className="border pharmacy-time-cell">
                            <td className="" style={{ fontSize: '14px', color: '#535353' }}>{getDayName(time?.day) ? getDayName(time?.day) : "Days"}</td>
                            <td className="text-center" style={{ fontSize: '14px', color: '#535353' }}>---</td>
                            <td className="text-center " style={{ fontSize: '14px', color: '#535353' }}>Off</td>
                            <td className="text-center  pl-3" style={{ fontSize: '14px', color: '#535353' }}>Off</td>
                        </tr>
                    }</>
                })}
                </tbody>
            </table>
            </div>
        </div>
  )
}

export default ScheduleLayer