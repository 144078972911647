import React, { useEffect, useState } from "react";
import BreadCrum from "../../../atoms/breadcrum/BreadCrum";
import CustomDropDown from "../../../atoms/CustomDropDown/Index";
import "../../../assets/css/doctor.scss";
import MuiltiplesImages from "../../../atoms/MuiltiplesImages/MuiltiplesImages";
import UploadProfile from "../../../assets/images/hospital/Hospital Room Upload Image.svg";
import RoomUploadPlusIcon from "../../../assets/images/hospital/RoomUploadPlusIcon.svg";
import RoomUploadMinusIcon from "../../../assets/images/hospital/RoomUploadMinusIcon.svg";
import RemoveRoomSlotIcon from "../../../assets/images/doctor/dash-circle-fill-red.svg";
import AddRoomSlotIcon from "../../../assets/images/doctor/TimeTableAddBtn.svg";
import { toast } from "react-toastify";
import TextInput from "../../../molecules/TextInput/TextInput";
import HospitalRoomHelper from "./hospitalroomhelper";
import ButtonLoader from "../../../atoms/buttonLoader";
import CustomButton from "../../../molecules/CustomButton/CustomButton";
import SpinnerLoaderModal from "../../common/spinnerloadermodal";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import formschemas from "../../../formvalidation/formschemas";
import ErrorMessage from "../../../formvalidation/errormessage";
import validationErrors from "../../../utilities/validation.errors";

const NewHospitalRoom = ({ hospitalRoomID }) => {

  const {

    roomFormStates: {
      roomData,
      setRoomData,
      roomState,
      setRoomState,
      isDataLoading,
      isLoading,
      cancelationDurations
    },

    roomFormFuncs: {
      handleOnChange,
      handleFloorOnChange,
      handleAddRoomSlot,
      handleRemoveRoomSlot,
      handleOnSubmit,
    }
    
  } = HospitalRoomHelper(hospitalRoomID);

  const {
    reset,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: 'all',
    values: {...roomData},
    resetOptions: {
      keepErrors: true
    },
    resolver: yupResolver(formschemas?.hospRoomSchema),
  });

  const handleUploadImage = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.multiple = true;

    input.onchange = (event) => {
      const temp = Array.from(event.target.files);
        setValue('images', [...roomData?.images, ...temp], { shouldValidate: true });
        setRoomData((roomData) => ({
          ...roomData,
          images: [...roomData?.images, ...temp],
        }));
    };

    input.click();
    setValue('images', watch('images'), { shouldValidate: true });
  };

  const handleDeleteImage = (index) => {
    if (roomData?.images[index]?.id) {
      setRoomData((roomData) => ({
        ...roomData,
        remove_images_ids: [
          ...roomData?.remove_images_ids,
          roomData?.images[index]?.id,
        ],
      }));
    }

    const temp = [...roomData?.images];
    temp.splice(index, 1);
    setRoomData((roomData) => ({ ...roomData, images: [...temp] }));
    setValue('images', [...temp], { shouldValidate: true });
  };

  const handleChangeFacility = (key) => {
    const tempFacilities = [...roomState?.roomFacilities];
    tempFacilities[key].toggle = !tempFacilities[key].toggle;
    setRoomState({ ...roomState, roomFacilities: [...tempFacilities] });

    const temp = tempFacilities
      ?.filter((facility) => facility?.toggle === true)
      ?.map((facility) => facility?.value);
    setRoomData({ ...roomData, room_facility_id: [...temp] });
    setValue('room_facility_id', [...temp], { shouldValidate: true });
  };

  const breadCrumbItems = [
    {
      title: 'Hospital'
    },
    {
      title: `${hospitalRoomID ? 'Edit' : 'Add'} Hospital Room`,
      href: '/hospitals/hospitalrooms'
    }
  ];

  const { t } = useTranslation();

  return (
    <div className="row pt-3 px-2 roomslistBody">
      <div className="col-8">

        {hospitalRoomID && <SpinnerLoaderModal isDataLoading={isDataLoading} />}

        <div className="row">
          <div className="col-12">
            <span className="listHeading">Add Hospital Room</span>
          </div>

          <div className="col-12 mt-3 mb-3">
            <BreadCrum
              breadCrumbItems={breadCrumbItems}
            />
          </div>

          <div className="col-12 mt-4" style={{ marginBottom: "150px" }}>
            <div className="row ml-0 add-doc-left-col pb-5 px-3">
              <div className="col-12 d-flex mt-4 inputSpacing">
                <div className="position-relative" style={{ height: "80px" }}>
                  <img src={UploadProfile} className=""></img>
                  <img
                    src={RoomUploadPlusIcon}
                    onClick={handleUploadImage}
                    className="position-absolute"
                    style={{ right: "3px", bottom: "1px" }}
                  ></img>
                </div>

                <div
                  className={`pl-2 doc-setting-input ${
                    roomData?.images?.length < 1 && "d-flex align-items-center"
                  }`}
                >
                  <p className="ml-3 mt-3 mb-4">Upload Picture / {`${t('Hospital.Room.UploadPicture')}`}</p>
                  <div
                    className="d-flex cursor-pointer"
                    style={{ columnGap: "15px" }}
                  >
                    {roomData?.images?.map((image, index) => {
                      return (
                        <div
                          className="position-relative d-flex align-items-center"
                          style={{
                            height: "46px",
                            width: "46px",
                            background: "#D9D9D9",
                            borderRadius: "4px",
                          }}
                        >
                          <img
                            className="w-100 h-100 rounded-sm"
                            style={{ objectFit: "contain" }}
                            src={
                              image?.name
                                ? URL.createObjectURL(image)
                                : `${process.env.REACT_APP_IMAGE_URL}/${image?.image}`
                            }
                          ></img>
                          <img
                            src={RoomUploadMinusIcon}
                            style={{
                              position: "absolute",
                              top: "-6px",
                              right: "-8px",
                            }}
                            onClick={() => handleDeleteImage(index)}
                          ></img>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="col-12 p-0 mt-1">
                <ErrorMessage error={validationErrors?.fileErrorMessage(errors)} />
              </div>

              {/* Hospital Name */}

              <div className="col-12 doc-setting-input mt-4 mb-2 inputSpacing">
                
                <div className="d-flex justify-content-between">
                  <p className="mb-2">Hospital</p>
                  <p className="mb-2">{`${t("Common.Hospital")}`}</p>
                </div>

                <CustomDropDown
                  name={"hospital_id"}
                  option={roomState?.hospitals}
                  handleChangeSelect={(value, name) => {
                    handleOnChange({ value, name });
                  }}
                  value={roomData?.hospital_id}
                  disabled={
                    roomState?.hospitals?.length === 0 || hospitalRoomID
                  }
                  isController={true}
                  control={control}
                  error={errors?.hospital_id?.message}
                />
              </div>

              <div className="col-12 mt-4 mb-2">
                {roomData?.room_specifications?.map((room, index) => (
                  <div className="row justify-content-between">
                    <TextInput
                      label={index === 0 && "Floor Title"}
                      arLabel={index === 0 && t("Hospital.Room.FloorTitle")}
                      inputDivClass="col-5 mb-3"
                      type="text"
                      name="floor_title"
                      handleOnChange={(e) => handleFloorOnChange(e, index, setValue)}
                      value={room?.floor_title}
                      isController={true}
                      control={control}
                    />
                    <TextInput
                      label={index === 0 && "Total Rooms"}
                      arLabel={index === 0 && t("Hospital.Room.TotalRooms")}
                      inputDivClass="col-5 mb-3"
                      type="text"
                      name="total_rooms"
                      handleOnChange={(e) => handleFloorOnChange(e, index, setValue)}
                      value={room?.total_rooms}
                      isController={true}
                      control={control}
                    />
                    <div
                      className="col-1 d-flex p-0 pt-1"
                      style={{ gap: "10px" }}
                    >
                      {index === roomData?.room_specifications?.length - 1 && (
                        <img
                          src={AddRoomSlotIcon}
                          width={"20px"}
                          alt=""
                          onClick={() => handleAddRoomSlot(setValue)}
                        ></img>
                      )}
                      <img
                        src={RemoveRoomSlotIcon}
                        width={"20px"}
                        alt=""
                        onClick={() => handleRemoveRoomSlot(index, setValue)}
                      ></img>
                    </div>
                  </div>
                ))}

                <div className={`row ${errors?.room_specifications?.message && 'mb-2'}`}>
                  <ErrorMessage error={errors?.room_specifications?.message} />
                </div>
              </div>

              <CustomDropDown
                label={'Room Type'}
                arLabel={t('Hospital.Room.RoomType')}
                name={"room_type_id"}
                dropDownDivClass='col-6 p-0 pr-2 inputSpacing mb-4'
                option={roomState?.roomTypes}
                handleChangeSelect={(value, name) => {
                  handleOnChange({ value, name });
                }}
                value={roomData?.room_type_id}
                isController={true}
                control={control}
                error={errors?.room_type_id?.message}
              />

              <TextInput
                label={"Per Day Fee"}
                arLabel={t('Hospital.Room.PerDayFee')}
                inputDivClass={"col-6 pl-2 mb-4"}
                type="text"
                name="per_day_fee"
                handleOnChange={handleOnChange}
                value={roomData?.per_day_fee}
                control={control}
                error={errors?.per_day_fee?.message}
              />

              <CustomDropDown
                label={"Booking Cancelation Duration"}
                arLabel={t('Hospital.Room.BookingCancelationDuration')}
                name={"booking_cancelation_duration"}
                dropDownDivClass="col-12 p-0 mb-4"
                option={cancelationDurations}
                value={roomData?.booking_cancelation_duration}
                handleChangeSelect={(value, name) =>
                  handleOnChange({ value, name })
                }
                isController={true}
                control={control}
                error={errors?.booking_cancelation_duration?.message}
              />

              <div className="col-12 doc-setting-input mb-2 inputSpacing">
                <p className="mb-3">Room Facilities / {`${t('Hospital.Room.RoomFacilities')}`}</p>
                <div className="d-flex flex-wrap mb-1" style={{ gap: "18px" }}>
                  {roomState?.roomFacilities?.map((facility, index) => {
                    return (
                      <div
                        className="px-4 facilityButton"
                        style={{
                          background: !facility?.toggle ? "#D9D9D9" : "#125A77",
                          color: !facility?.toggle ? "#000000" : "#ffffff",
                        }}
                        onClick={() => {
                          handleChangeFacility(index);
                        }}
                      >
                        {facility?.label}
                      </div>
                    );
                  })}
                </div>
                <ErrorMessage error={errors?.room_facility_id?.message} />
              </div>

              <TextInput
                label={"Room Description"}
                arLabel={t('Hospital.Room.RoomDescription')}
                inputDivClass='col-12 mt-4 mb-3'
                type="text"
                name="description"
                handleOnChange={handleOnChange}
                value={roomData?.description}
                textArea={true}
                control={control}
                error={errors?.description?.message}
              />

              <CustomButton
                label="Save"
                buttonWidth="17%"
                buttonHeight="40px"
                buttonDivClass={"col-12 p-0 mt-5"}
                handleOnClick={handleSubmit(handleOnSubmit)}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewHospitalRoom;
