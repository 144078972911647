
const bookedRoomItems = [{ label: "Date", sortBy: "date", key: 0 }];

const serviceRequestItems = [{ label: "Date", sortBy: "date", key: 0 }];

const salesPromotionItems = [{ label: "Date", sortBy: "date", key: 0 }];
const salesPromotionRequestItems = [{ label: "Date", sortBy: "date", key: 0 }];


export default {
  serviceRequestItems,
  bookedRoomItems,
  salesPromotionItems,
  salesPromotionRequestItems
}