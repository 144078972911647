import React, { useEffect, useState } from 'react'
import usePost from '../../customHook/usePost';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import useDeleteData from '../../customHook/useDelete';

const DoctorTimeTableHelper = (itemLabel, itemId) => {
    
    const [addTimePostReq, setaddTimePostReq] = useState({
        doctor_id: null,
        schedules: [
            {
                day: 1,
                time_slots: []
            },
            {
                day: 2,
                time_slots: []
            },
            {
                day: 3,
                time_slots: []
            },
            {
                day: 4,
                time_slots: []
            },
            {
                day: 5,
                time_slots: []
            },
            {
                day: 6,
                time_slots: []
            },
            {
                day: 7,
                time_slots: []
            },
        ]
    });
    
    const [selectDay, setSelectDay] = useState({
        sunday: {
            toggle: false,
            count: 1,
        },
        monday: {
            toggle: false,
            count: 1,
        },
        tuesday: {
            toggle: false,
            count: 1,
        },
        wednesday: {
            toggle: false,
            count: 1,
        },
        thursday: {
            toggle: false,
            count: 1,
        },
        friday: {
            toggle: false,
            count: 1,
        },
        saturday: {
            toggle: false,
            count: 1,
        },
    });

    const [isTimeSlotError, setIsTimeSlotError] = useState(null);
    const [modal2Open, setModal2Open] = useState(false);
    const [specialistOptions, setspecialistOptions] = useState([]);

    const { postData } = usePost();
    const { deleteData } = useDeleteData();

    const postDoctorAvalibility = () => {

        let isTimeSlotEmpty = false;
    
        addTimePostReq?.schedules?.map((obj) => {
          if(obj?.time_slots?.length === 1){
            if(obj?.time_slots[0]?.end_time === '' || obj?.time_slots[0]?.hospital_id === '' || obj?.time_slots[0]?.start_time === ''){
              isTimeSlotEmpty = true;
              setIsTimeSlotError('Time slots must not be empty*');
              // toast.error('Selected time slots must not be empty.');
            }
          }
          else{
            obj?.time_slots?.map((slot) => {
              if(slot?.end_time === '' || slot?.hospital_id === '' || slot?.start_time === ''){
                isTimeSlotEmpty = true;
                setIsTimeSlotError('Time slots must not be empty*');
                // toast.error('Selected time slots must not be empty.');
              }
            })
          }
        })
    
        if(isTimeSlotEmpty){
          return;
        }
    
          const newData = addTimePostReq.schedules.map((shed) => {
            if (shed.day === 1 && selectDay.sunday.toggle === false)
              return { day: 1, time_slots: [] };
            if (shed.day === 2 && selectDay.monday.toggle === false)
              return { day: 2, time_slots: [] };
            if (shed.day === 3 && selectDay.tuesday.toggle === false)
              return { day: 3, time_slots: [] };
            if (shed.day === 4 && selectDay.wednesday.toggle === false)
              return { day: 4, time_slots: [] };
            if (shed.day === 5 && selectDay.thursday.toggle === false)
              return { day: 5, time_slots: [] };
            if (shed.day === 6 && selectDay.friday.toggle === false)
              return { day: 6, time_slots: [] };
            if (shed.day === 7 && selectDay.saturday.toggle === false)
              return { day: 7, time_slots: [] };
            else return shed;
          });
    
          setIsTimeSlotError(null);
          setModal2Open(false);
          return newData;
    
    };

    const navigate = useNavigate();

    const HandlePostTimingsApi = (apiEndPoint, itemLabel, itemId, timeSchedules, otherPayload ) => {

      postData(apiEndPoint, {
        [itemLabel]: itemId,
        schedules: timeSchedules,
        ...(otherPayload && otherPayload)
      },
      (response) => {
        console.log('Doctor: After Adding Doctor Timings Post Api Response: ', response);
      }
      )
    }

    const handleGetTimeSlots = (apiEndPoint, itemId) => {
      
        deleteData(
          `${apiEndPoint}/${itemId}`,
          (Data) => {
            
            setaddTimePostReq({
              ...addTimePostReq,
              schedules: Data?.data?.schedules,
            });

            setSelectDay((prevState) => ({
              ...prevState,
              sunday: {
                ...prevState.sunday,
                toggle:
                  Data?.data?.schedules[0]?.time_slots?.length > 0 ? true : false,
              },
              monday: {
                ...prevState.monday,
                toggle:
                  Data?.data?.schedules[1]?.time_slots?.length > 0 ? true : false,
              },
              tuesday: {
                ...prevState.tuesday,
                toggle:
                  Data?.data?.schedules[2]?.time_slots?.length > 0 ? true : false,
              },
              wednesday: {
                ...prevState.wednesday,
                toggle:
                  Data?.data?.schedules[3]?.time_slots?.length > 0 ? true : false,
              },
              thursday: {
                ...prevState.thursday,
                toggle:
                  Data?.data?.schedules[4]?.time_slots.length > 0 ? true : false,
              },
              friday: {
                ...prevState.friday,
                toggle:
                  Data?.data?.schedules[5]?.time_slots?.length > 0 ? true : false,
              },
              saturday: {
                ...prevState.saturday,
                toggle:
                  Data?.data?.schedules[6]?.time_slots?.length > 0 ? true : false,
              },
            }));
          }
        );
    };

    return {
        addTimePostReq,
        setaddTimePostReq,
        selectDay,
        setSelectDay,
        specialistOptions,
        setspecialistOptions,
        isTimeSlotError,
        setIsTimeSlotError,
        modal2Open,
        setModal2Open,
        postDoctorAvalibility,
        HandlePostTimingsApi,
        handleGetTimeSlots
    }
}

export default DoctorTimeTableHelper;