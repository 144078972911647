import React from 'react'
import AddHospital from './AddHospital'
import { useParams } from 'react-router-dom'

const EditHospital = () => {

  const { id } = useParams();

  return (
    <AddHospital Id={id} />
  )
}

export default EditHospital;