import React, { useState } from 'react'

import './bookedhospitalrooms.scss'
import '../../../assets/css/common/datatable.scss'
import RoomsTableListHeader from '../../../molecules/HospitalRoomManagement/RoomsTableListHeader';
import DynamicRoomsDataTable from '../../../molecules/HospitalRoomManagement/DynamicRoomsDataTable';
import PaginationHelper from '../../../molecules/tablepagination/paginationhelper';
import HospitalRoomsListHelper from '../HospitalRooms/helper';
import listSelect from '../../../utilities/data/list.select';
import listDropdown from '../../../utilities/data/list.dropdown';

const BookedHospitalRoomsDataTable = () => {

  const { 
    
    bookedRoomTableColumns,
    bookHospRoomsList,
    isLoading,
    hospStates: { listData },

    // Filter States

    filterStates: { filtersData, otherStates, isSortedItemSelected, hospitals },
    filterFuncs: { handleOnFilterChange, handleSortByClick, handleChangeListStatus, handleSortByAge, handleSortIcons, setOtherStates},
    apiHooks: { listStatusApiHook },
    
    // Pagination States
    paginationData, 
    setPaginationData, 
    handleChangePage 
  
  } = HospitalRoomsListHelper('Booked Hosp Rooms List', '', listDropdown?.bookedRoomItems);

  const breadCrumbItems = [
    {
      title: 'Hospital'
    },
    {
      title: 'Booked Hospital Rooms',
      href: ''
    }
  ];

  const filtersOrder = [
    {
      type: 'search',
      name: 'title',
      value: filtersData?.title,
      divClass: 'col-4 p-0 pr-1',
      placeholder: 'Search booked by',
      onChange: handleOnFilterChange
    },
    {
      type: 'select',
      name: 'hospital_id',
      value: filtersData?.hospital_id,
      divClass: 'col-3 pr-1 p-0',
      placeholder: 'Select Hospital',
      onChange: handleOnFilterChange,
      options: hospitals
    },
    {
      type: 'dropdown',
      value: isSortedItemSelected,
      divClass: 'col-2 p-0 pr-1',
      placeholder: 'Sort By',
      onChange: handleSortByClick,
      options: listDropdown?.bookedRoomItems
    },
    {
      type: 'select',
      name: 'status',
      value: filtersData?.status,
      divClass: 'col-3 p-0',
      placeholder: 'Select Status',
      onChange: handleOnFilterChange,
      options: listSelect?.threeListStatus
    },
  ];

  return (
    <div className='row pl-4 pr-4 pt-3 roomslistBody'>

      <RoomsTableListHeader
        pageTitle='Booked Hospital Rooms'
        breadCrumbItems={breadCrumbItems}
        filtersOrder={filtersOrder}
      />

      <div className='col-12 p-0 mt-2' style={{overflowX: "hidden" }}>
        <DynamicRoomsDataTable 
          tableColumns={bookedRoomTableColumns} 
          tableRows={bookHospRoomsList} 
          isRowsDataLoading={isLoading}
          paginationData={paginationData} 
          setPaginationData={setPaginationData} 
          statusApiEndPoint='change_booking_status'
          handleChangePage={handleChangePage}
          handleChangeListStatus={handleChangeListStatus}
          isEdit={true}
          isDel={true}
          handleSortByAge={handleSortByAge}
          handleSortIcons={handleSortIcons}
          otherStates={otherStates}
          setOtherStates={setOtherStates}
          isStatusLoading={listStatusApiHook?.isLoading}
          statusOptions={listData?.statusOptions}
        />
      </div>
      
    </div>
  )
}

export default BookedHospitalRoomsDataTable;