import React from "react";
import { useState } from "react";
import CustomDropDown from "../../atoms/CustomDropDown/Index";
import DocRoleCrossIcon from "../../assets/images/doctor/DocRoleCrossIcon.svg";
import DeleteBigIcon from "../../assets/images/doctor/DeleteBigIcon.svg";
import usePost from "../../customHook/usePost";
import { CustomToast } from "../../atoms/toastMessage";
import { useLocation, useParams } from "react-router-dom";
import ErrorMessage from "../../formvalidation/errormessage";

const ConsutancyFee = ({
  setShowDoctorFee,
  setFeeData,
  selectdedHospital,
  feeData,
  id,
  formDataState,
  setFormDataState,
  error,
  setValue,
  hospNames
}) => {
  const [feeDataState, setFeeDataState] = useState({});

  const handleOnFeesChange = (value, index) => {

    const temp = [...formDataState?.fees];
    temp[index].fees = value;
    setFormDataState(formDataState => ({...formDataState, fees: [...temp]}));
    setValue('fees', [...temp], { shouldValidate: true });
  };

  const { postData } = usePost();

  const handleAdd = () => {
    const data = { ...feeDataState, doctor_id: id };

    if (feeDataState?.hospital_id) {
      postData(`${process.env.REACT_APP_SET_DOCTOR_FEES}`, data, (response) => {
        if (feeData?.length === 0) {
          setFeeData([...feeData, feeDataState]);
        } else {
          const tempFeeData = [...feeData];
          let bool = false;

          tempFeeData?.find((hospital, index) => {
            if (hospital?.hospital_id === feeDataState?.hospital_id) {
              tempFeeData[index] = {
                ...tempFeeData[index],
                fees: feeDataState?.fees,
              };
              bool = true;
            }
          });

          if (!bool) {
            tempFeeData?.push({
              hospital_id: feeDataState?.hospital_id,
              hosp_name: feeDataState?.hosp_name,
              fees: feeDataState?.fees,
            });
          }

          setFeeData([...tempFeeData]);
        }

        setFeeDataState({});
        CustomToast({
          type: "success",
          message: `Saved Successfuly!`,
        });
      });
    }
  };

  const handleRemove = (index) => {
    const updatedaat = feeData?.filter((f, i) => i !== index);
    setFeeData(updatedaat);
  };

  const filteredHospitalOptions =
    selectdedHospital &&
    selectdedHospital?.filter(
      (hospital) => !feeData.some((fee) => fee.hospitals === hospital.value)
  );

  return (
    <div className="row w-100 mx-0 add-doc-left-col pb-4">
      <div className="col-12 px-4 py-3 my-1">
        <div className="d-flex justify-content-between align-items-center ">
          <p className="mb-0 add-doc-role-text">Set Consultancy Fee</p>
          <img
            onClick={() => {
              setShowDoctorFee(false);
            }}
            src={DocRoleCrossIcon}
            alt=""
          />
        </div>
      </div>

      <div
        className="border-top pt-3"
        id="scrollableDiv"
        style={{
          width: "100%",
          overflow: "auto",
          padding: "0 16px",
        }}
      >
        <div
          className="col-12 mt-2"
          style={{
            background: "rgb(9, 73, 99)",
            borderRadius: "5px",
          }}
        >
          <div className="row">
            <div className="col-6 py-3">
              <h6 className="m-0 add-doc-role-text text-light">Hospital</h6>
            </div>

            <div className="col-6 py-3">
              <h6 className="m-0 add-doc-role-text text-light">Fees</h6>
            </div>

            {formDataState?.fees?.map((hosp, index) => {
              return (
                <>
                  <div className="col-6 py-2 bg-light">
                    <span className="m-0 add-doc-role-type-detail">
                      {hospNames[hosp?.hospital_id]}
                    </span>
                  </div>

                  <div className="col-6 py-2 bg-light">

                    <input
                      type="text"
                      className="pl-1 add-doc-role-type-detail bg-light"
                      style={{borderColor: '#cfcece'}}
                      value={hosp?.fees}
                      onChange={(e) => handleOnFeesChange(e.target.value, index)}
                    />

                  </div>
                </>
              );
            })}

            {!formDataState?.fees?.length && (
              <>
                <div className="col-12 py-2 bg-light text-center">
                  <span className="add-doc-role-type-detail" >No Hospital Selected.</span>
                </div>
              </>
            )}
          </div>
        </div>

        <ErrorMessage error={error} />
      </div>
    </div>
  );
};

export default ConsutancyFee;
