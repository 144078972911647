import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import { Button, Modal, Rate, Select, Slider } from "antd";
import sohaibavatar from "../../assets/images/dashboard/sohaibavatar.png";
import "../../assets/css/common/datatable.scss";
import { Box, Typography } from "@mui/material";
import CustomPagination from "../common/CustomPagination";
import prescriptionSVG from "../../assets/images/common/prescription.svg";
import Edit from "../common/Edit.js";
import Delete from "../common/Delete.js";
import Cross from "../common/Cross";
import Tick from "../common/Tick.js";

// img svg
import NullIcon from "../../assets/images/pharmacy/NullIcon.svg";
import CameraIcon from "../../assets/images/doctor/CameraIcon.svg";

// images png
import pic1 from "../../assets/images/doctor/doc1.png";
import pic2 from "../../assets/images/doctor/doc2.png";
import pic3 from "../../assets/images/doctor/doc3.png";
import pic4 from "../../assets/images/doctor/doc4.png";
import pic5 from "../../assets/images/doctor/doc5.png";
import pic6 from "../../assets/images/doctor/doc6.png";
import pic7 from "../../assets/images/doctor/doc7.png";
import pic8 from "../../assets/images/doctor/doc8.png";
import { Link, Navigate } from "react-router-dom";
import useFetch from "../../customHook/useFetch";
import ButtonLoader from "../../atoms/buttonLoader";
import usePost from "../../customHook/usePost";
import ListSkeleton from "../../molecules/ListSkeleton/ListSkeleton";
import FiltersHelper from "../../molecules/HospitalRoomManagement/helper.js";
import SortTableCell from "../common/SortTableCell.jsx";

const PharmacyOrderList = ({ filtersData, handleSortByAge, handleSortIcons }) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [modal1Open, setModal1Open] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [errorData, setErrorData] = useState(0);
  const [image, setImage] = useState(null);
  const navigate = useNavigate();
  const [orderListState, setOrderListState] = useState();
  const orderlistPost = usePost();
  const [reRender, setReRender] = useState(false);

  useEffect(() => {
    orderlistPost?.postData(
      `${
        process.env.REACT_APP_GET_GET_ORDERS
      }?per_page=${rowsPerPage}&page=${page}&pharmacy=${1}&title=${filtersData?.title}&status=${filtersData?.status}&sort_by=${filtersData?.isSortBy}`,
      {},
      (res) => {
        setOrderListState(res);
      }
    );
  }, [page, reRender]);

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    } else {
      setReRender(!reRender);
    }
  }, [filtersData]);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleDoctorImageClick = () => {
    // Create a file input element and trigger a click event
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    // input.accept = 'image/png,image/jpeg';  // its just show png and jpeg file rather then other
    input.onchange = (event) => {
      const file = event.target.files[0];
      if (!file) {
        setErrorData(0);
        return;
      }
      const fileType = file.type;
      if (fileType !== "image/png" && fileType !== "image/jpeg") {
        // alert('Please select a PNG or JPEG file');
        setErrorData(1);
        return;
      } else {
        setErrorData(0);
      }
      // Set the selected image as the state of the component
      setImage(URL.createObjectURL(file));
    };
    input.click();
  };

  // const getHospital = useFetch(
  //   `${process.env.REACT_APP_GET_HOSPITAL_DATA}?per_page=${rowsPerPage}&page=${page}`
  // );

  const rows = orderListState;
  const totalRows = rows?.data?.total;
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  const startIndex = page * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage, totalRows);
  const visibleRows = rows?.data?.data;

  const { filterFuncs: { handleTruncateText } } = FiltersHelper();
  
  return (
    <>
        <TableContainer
          component={Paper}
          sx={{ backgroundColor: "#FFFFFF" }}
          className="custom-scroll"
        >
          <Table aria-label="simple table">
            <TableHead
              sx={{
                "& th": {
                  color: "#193F52",
                  whiteSpace: "nowrap",
                  wordWrap: "break-word",
                },
              }}
            >
              <TableRow>
                <TableCell className="number" align="left">
                  #
                </TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Email</TableCell>
                <TableCell align="left">Address</TableCell>
                <TableCell align="left">Mobile No.</TableCell>
                <TableCell align="left">Product</TableCell>
                <SortTableCell
                  label='Total'
                  handleSortByAge={handleSortByAge}
                  handleSortIcons={handleSortIcons}
                  ascKey={'total_amount_asc'}
                  descKey={'total_amount_desc'}
                />
                <TableCell align="left">Details</TableCell>
                <TableCell align="left">Status</TableCell>
              </TableRow>
            </TableHead>

            <TableBody
              sx={{
                "& td": {
                  color: "#767676",
                  whiteSpace: "nowrap",
                  wordWrap: "break-word",
                },
              }}
            >
              {!orderlistPost?.isLoading ? (
                visibleRows?.length > 0 ? visibleRows?.map(
                  (
                    {
                      id,
                      name,
                      address,
                      phone_no,
                      status,
                      user,
                      total_amount,
                      order_items,
                    },
                    index
                  ) => (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left" className="number">
                        {(page - 1) * rowsPerPage + index + 1}
                      </TableCell>
                      <TableCell align="left">
                        <CardHeader
                          sx={{ padding: "0px" }}
                          avatar={
                            <Box
                              sx={{
                                filter:
                                  "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1))",
                              }}
                            >
                              <Avatar
                                alt={name}
                                src={`${
                                  process.env.REACT_APP_IMAGE_URL +
                                  "/" +
                                  user?.profile_pic
                                }`}
                              />
                            </Box>
                          }
                          title={handleTruncateText(user?.name, 15)}
                        />
                      </TableCell>
                      <TableCell align="left">{user?.email}</TableCell>
                      <TableCell align="left">
                        {handleTruncateText(address, 30)}
                      </TableCell>
                      <TableCell align="left">{phone_no}</TableCell>
                      <TableCell align="left">
                        {handleTruncateText(order_items[0]?.product?.title, 15)}
                      </TableCell>
                      <TableCell align="center">{total_amount}</TableCell>
                      <TableCell align="center">
                        <button
                          onClick={() => {
                            navigate(`/pharmacy/detail/${id}`);
                          }}
                          className="btn-add-new-doc pharmacy-view-detail"
                        >
                          View detail
                        </button>
                      </TableCell>
                      <TableCell align="left">
                        {status === 0 ? (
                          <button
                            className="pharmacy-order-status"
                            style={{
                              color: '#ffa807',
                              backgroundColor: '#f7eed0',
                            }}
                          >
                            Pending
                          </button>
                        ) : status === 1 ? (
                          <button className="pharmacy-order-status ">
                            Accepted
                          </button>
                        ) : status === 2 ? (
                          <button
                            className="pharmacy-order-status "
                            style={{
                              color: "#EC826E",
                              backgroundColor: "#f1938097",
                            }}
                          >
                            Declined
                          </button>
                        ) : status === 3 ? (
                          <button
                            className="pharmacy-order-status "
                            style={{
                              color: "rgb(103, 188, 216)",
                              backgroundColor: "rgb(184, 226, 239)",
                            }}
                          >
                            Completed
                          </button>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  )
                )

              :

              <TableRow>
                <TableCell align="center" colSpan={9}>
                  No data found.
                </TableCell>
              </TableRow>
              
              ) : (
                <TableRow>
                  <TableCell colSpan={9}>
                    <ListSkeleton totalRow={5} totalCol={9} image={true} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

      <div className="pagination-container px-md-3 ml-md-1 mt-md-2 ">
        <div className="pagination-detail">
          Showing {(page - 1) * rowsPerPage + 1} - {rows?.data?.to} of{" "}
          {rows?.data?.total}
        </div>
        <CustomPagination
          page={page}
          totalPages={totalPages}
          onChangePage={handleChangePage}
        />
      </div>
    </>
  );
};

export default PharmacyOrderList;
