import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Button, Modal, TimePicker } from "antd";
import { Space, Switch } from "antd";
import Slider from "react-slick";

// files

import TimeChanger from "../../atoms/TimeslotChanger/TimeChanger";

import "../../assets/css/Carousel.scss";
import "../../assets/css/doctor.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// img svg
import RightArrow from "../../assets/images/doctor/RightArrow.svg";
import RightArrowSpec from "../../assets/images/doctor/RightArrowSpec.svg";
import TimeTablePencil from "../../assets/images/doctor/TimeTablePencil.svg";
import ClockTimeTable from "../../assets/images/doctor/ClockTimeTable.svg";
import TimeTableMessageIcon from "../../assets/images/doctor/TimeTableMessageIcon.svg";
import TimeTablePhoneIcon from "../../assets/images/doctor/TimeTablePhoneIcon.svg";
import TimeTableAddBtn from "../../assets/images/doctor/TimeTableAddBtn.svg";
import TimeTableRemoveBtn from "../../assets/images/doctor/dash-circle-fill-red.svg";
import DocumentIcon from "../../assets/images/doctor/BrowseDocumentIcon.svg";

import Group1175 from "../../assets/images/doctor/doc1.png";
import Group1176 from "../../assets/images/doctor/doc2.png";
import Group1177 from "../../assets/images/doctor/doc3.png";
import Group1178 from "../../assets/images/doctor/doc4.png";
import PDF_icon from "../../assets/images/doctor/PDF_icon.png";
import CrouselCard from "../DashboardComponents/CrouselCard";
import DoctorSetting from "./DoctorSetting";
import ReviewPagination from "../../organisms/ReviewPagination";
import useFetch from "../../customHook/useFetch";
import useDeleteData from "../../customHook/useDelete";
import CustomDropDown from "../../atoms/CustomDropDown/timeSlotDropDown";
import usePost from "../../customHook/usePost";
import ImagePreview from "../../atoms/ImagePreview/ImagePreview.js";
import ScheduleLayer from "../../molecules/TimeTableModal/ScheduleLayer.jsx";
import ImagePlaceHolder from "../../assets/images/imageplaceholder.png";
import SpinnerLoaderModal from "../common/spinnerloadermodal.jsx";

const ViewDoctor = () => {

  const { id: Id } = useParams();
  const location = useLocation();
  const receivedData = location.state?.data;
  const [docBtn, setDocBtn] = useState(0);
  const [doctorTimeTable, setdoctorTimeTable] = useState([]);
  const [modal2Open, setModal2Open] = useState(false);
  const [specialistOptions, setspecialistOptions] = useState([]);
  const [presentDays, setpresentDays] = useState([]);
  const [temp, settemp] = useState({});
  const [addTimePostReq, setaddTimePostReq] = useState({
    doctor_id: Id,
    schedules: [{}],
  });
  const [addSingleTimeSlot, setaddSingleTimeSlot] = useState({
    hospital_id: "",
    start_time: "",
    end_time: "",
  });
  const [empetyTime, setempetyTime] = useState("");
  const setUpAvailableTime = () => {};
  const { postData } = usePost();
  const { deleteData, isLoadingTimeTable, errorTimeTable } = useDeleteData();
  const [selectDay, setSelectDay] = useState({
    sunday: {
      toggle: true,
      count: 1,
    },
    monday: {
      toggle: true,
      count: 1,
    },
    tuesday: {
      toggle: true,
      count: 1,
    },
    wednesday: {
      toggle: true,
      count: 1,
    },
    thursday: {
      toggle: true,
      count: 1,
    },
    friday: {
      toggle: true,
      count: 1,
    },
    saturday: {
      toggle: true,
      count: 1,
    },
  });
  const [moveNext, setmoveNext] = useState(true);

  const allHospitals = useFetch(`${process.env.REACT_APP_GET_HOSPITAL_DATA}`);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const allPureHospitals = allHospitals?.data?.data?.map((item) => ({
    id: item?.id,
    name: item?.name,
  }));

  const { data, isLoading, error } = useFetch(
    `${process.env.REACT_APP_DOCTOR_DETAIL}/${Id}`
  );

  const hospitalIdToName = new Map(
    allPureHospitals?.map((hospital) => [hospital.id, hospital.name])
  );

  // Match hospital IDs in the data and retrieve their names
  const hospitalNames = data?.data?.fees?.map((item) => ({
    hospitalName: hospitalIdToName.get(item.hospital_id),
    fee: item.fees,
    hospital_id: item.hospital_id,
  }));

  const getTimeTableData = () => {
    deleteData(
      `${process.env.REACT_APP_GET_DOCTOR_TIMETABLE}/${Id}`,
      (Data) => {
        setdoctorTimeTable(Data?.data?.schedules);
        setspecialistOptions(
          data?.data?.hospitals?.map((i) => {
            return { value: i.name, label: i.name, id: i.id };
          })
        );

        setSelectDay((prevState) => ({
          ...prevState,
          sunday: {
            ...prevState.sunday,
            toggle:
              Data?.data?.schedules[0]?.time_slots.length > 0 ? true : false,
          },
          monday: {
            ...prevState.monday,
            toggle:
              Data?.data?.schedules[1]?.time_slots.length > 0 ? true : false,
          },
          tuesday: {
            ...prevState.tuesday,
            toggle:
              Data?.data?.schedules[2]?.time_slots.length > 0 ? true : false,
          },
          wednesday: {
            ...prevState.wednesday,
            toggle:
              Data?.data?.schedules[3]?.time_slots.length > 0 ? true : false,
          },
          thursday: {
            ...prevState.thursday,
            toggle:
              Data?.data?.schedules[4]?.time_slots.length > 0 ? true : false,
          },
          friday: {
            ...prevState.friday,
            toggle:
              Data?.data?.schedules[5]?.time_slots.length > 0 ? true : false,
          },
          saturday: {
            ...prevState.saturday,
            toggle:
              Data?.data?.schedules[6]?.time_slots.length > 0 ? true : false,
          },
        }));
      }
    );
  };

  const increaseDayCount = (day) => {
    setSelectDay((prevState) => ({
      ...prevState,
      [day]: {
        ...prevState[day],
        count: prevState[day].count + 1,
      },
    }));
  };

  const decreaseDayCount = (day) => {
    setSelectDay((prevState) => ({
      ...prevState,
      [day]: {
        ...prevState[day],
        count: prevState[day].count - 1,
      },
    }));
  };

  const handleChangeSelect = (val, name, updatedValue, dayID, setAblID) => {
    let data = specialistOptions.filter((value) => {
      return value.value === val;
    })[0]?.id;
    setaddTimePostReq({
      ...addTimePostReq,
      schedules: addTimePostReq?.schedules?.map((schedule) =>
        schedule?.day === dayID
          ? {
              ...schedule,
              time_slots: schedule?.time_slots?.map((timeSlot) =>
                timeSlot?.uniVal === updatedValue
                  ? {
                      ...timeSlot,
                      hospital_id: data,
                    }
                  : timeSlot
              ),
            }
          : schedule
      ),
    });
  };

  function renderLoop(countDays = [], dayName, dayNumber) {
    var actualDay = countDays[0];
    const items = [];

    // countDays[0]?.length !== 0 ? setSelectDay({ ...selectDay, [selectDay[dayName].toggle]: false }):setSelectDay(selectDay)

    for (let i = 0; i < actualDay?.length; i++) {
      const isLastElement = i === actualDay.length - 1;

      actualDay[i].uniVal = i;
      let hospitalName = specialistOptions?.filter((e) => {
        return e?.id === actualDay[i]?.hospital_id;
      })[0]?.label;

      items?.push(
        <>
          <div
            className={`${countDays === 1 ? "" : "pb-3"} `}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div style={{ width: "141px" }}>
                <CustomDropDown
                  option={specialistOptions}
                  handleChangeSelect={handleChangeSelect}
                  setAblID={actualDay[i]?.hospital_id}
                  value={
                    hospitalName?.length > 13
                      ? hospitalName?.slice(0, 13) + "..."
                      : hospitalName ?? actualDay[i]?.hospital_id
                  }
                  updatedValue={actualDay[i]?.uniVal}
                  dayId={dayNumber}
                />
              </div>

              {/* .slice(0, -3) */}
              <TimeChanger
                Time={actualDay[i]?.start_time}
                setaddTimePostReq={setaddTimePostReq}
                addTimePostReq={addTimePostReq}
                elementID={actualDay[i].uniVal}
                dayNumber={dayNumber}
                singleSelector={true}
              />

              <TimeChanger
                Time={actualDay[i]?.end_time}
                setaddTimePostReq={setaddTimePostReq}
                addTimePostReq={addTimePostReq}
                elementID={actualDay[i].uniVal}
                dayNumber={dayNumber}
                singleSelector={false}
              />
            </div>
            {!isLastElement ? (
              <span className="pl-lg-3 pl-1">
                <img
                  className="cursor-pointer"
                  onClick={() => {
                    deleteSingleTimeSlot(countDays[1], actualDay[i]?.uniVal);
                    // decreaseDayCount(dayName)
                    // deleteTimeSlot(dayName, countDays[i]?.hospital_id)
                  }}
                  src={TimeTableRemoveBtn}
                  alt=""
                  style={{ width: "20px" }}
                />
              </span>
            ) : (
              <>
                <span className="pl-lg-3 pl-1">
                  <img
                    className="cursor-pointer"
                    onClick={() => {
                      deleteSingleTimeSlot(countDays[1], actualDay[i]?.uniVal);
                      // decreaseDayCount(dayName)
                      // deleteTimeSlot(dayName, countDays[i]?.hospital_id)
                    }}
                    src={TimeTableRemoveBtn}
                    alt=""
                    style={{ width: "20px" }}
                  />
                </span>
                <span className="pl-lg-3 pl-1">
                  <img
                    className="cursor-pointer"
                    onClick={() => {
                      // increaseDayCount("sunday")
                      appendSingleTimeSlot(dayNumber);
                    }}
                    src={TimeTableAddBtn}
                    alt=""
                  />
                </span>
              </>
            )}
          </div>
        </>
      );
    }

    return items;
  }
  //Set Doctor Time Table.
  useEffect(() => {
    setaddTimePostReq({
      ...addTimePostReq,
      schedules: [...doctorTimeTable],
    });
  }, [doctorTimeTable]);

  let settings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const datavalues = [
    {
      id: 1,
      name: "Dustin Wilson",
      pic: Group1175,
      fun: "Fonctions",
      description:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Beatae, sed consequuntur! Ducimus, numquam!",
    },

    {
      id: 1,
      name: "Dustin Wilson",
      pic: Group1176,
      fun: "Fonctions",
      description:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Beatae, sed consequuntur! Ducimus, numquam!",
    },

    {
      id: 1,
      name: "Dustin Wilson",
      pic: Group1177,
      fun: "Fonctions",
      description:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Beatae, sed consequuntur! Ducimus, numquam!",
    },

    {
      id: 1,
      name: "Dustin Wilson",
      pic: Group1178,
      fun: "Fonctions",
      description:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Beatae, sed consequuntur! Ducimus, numquam!",
    },
  ];

  useEffect(() => {}, [selectDay]);

  let filterTimeAvalibility = (dayID) => {
    let filteredSchedules = addTimePostReq?.schedules?.filter(
      (tableFilter) => tableFilter.day == dayID
    );
    return [filteredSchedules[0]?.time_slots, dayID];
  };
  const hospitalDopDown = (hosName, id, name, setAblID) => {
    const index = addTimePostReq?.schedules?.findIndex(
      (schedule) => schedule.day === id
    );
    const getIndex = specialistOptions?.filter((value) => {
      return value.value === hosName;
    })[0]?.id;

    // if (index === -1) {
    //   const timeSlot = addTimePostReq?.schedules[index]?.time_slots[0];
    //   if (timeSlot?.start_time === "" && timeSlot?.end_time === "") {
    //     timeSlot.hospital_id = getIndex;

    //   } else {
    //     addTimePostReq?.schedules[index]?.time_slots.push({
    //       start_time: "",
    //       end_time: "",
    //       hospital_id: getIndex,
    //       uniVal: Math.random().toString(36).substr(2, 9)
    //     });
    //   }

    // }

    // setaddSingleTimeSlot(
    //   {
    //     start_time: "",
    //     end_time: "",
    //     hospital_id: getIndex,
    //     uniVal: Math.random().toString(36).substr(2, 9)
    //   }
    // )
  };

  const staringTimeDrop = (time, dayId) => {
    // const index = addTimePostReq.schedules.findIndex((schedule) => schedule.day === dayId);
    // if (index !== -1) {
    //   let lastOne = addTimePostReq.schedules[index].time_slots.length - 1
    //   // If the entry with the day value exists, update its start_time field
    //   addTimePostReq.schedules[index].time_slots[lastOne].start_time = time;
    // }

    setaddSingleTimeSlot(
      addSingleTimeSlot?.hospital_id
        ? {
            ...addSingleTimeSlot,
            start_time: time,
          }
        : {}
    );
  };

  const endTimeDrop = (time, dayId) => {
    // const index = addTimePostReq.schedules.findIndex((schedule) => schedule.day === dayId);
    // if (index !== -1) {
    //   let lastOne = addTimePostReq.schedules[index].time_slots.length - 1
    //   // If the entry with the day value exists, update its start_time field
    //   addTimePostReq.schedules[index].time_slots[lastOne].end_time = time;
    // }

    setaddSingleTimeSlot(
      addSingleTimeSlot?.hospital_id
        ? {
            ...addSingleTimeSlot,
            end_time: time,
          }
        : {}
    );
  };

  const [isTimeSlotError, setIsTimeSlotError] = useState(null);

  const HandleRemoveTimeSlots = (dayToggle, day, dayID) => {
    var tempSchedules = addTimePostReq;

    if (dayToggle === true) {
      tempSchedules.schedules[dayID - 1].time_slots = [];
    }

    setIsTimeSlotError(null);
  };

  const postDoctorAvalibility = () => {
    let isTimeSlotEmpty = false;

    addTimePostReq?.schedules?.map((obj) => {
      if (obj?.time_slots?.length === 1) {
        if (
          obj?.time_slots[0]?.end_time === "" ||
          obj?.time_slots[0]?.hospital_id === "" ||
          obj?.time_slots[0]?.start_time === ""
        ) {
          isTimeSlotEmpty = true;
          setIsTimeSlotError("Time slots must not be empty*");
        }
      } else {
        obj?.time_slots?.map((slot) => {
          if (
            slot?.end_time === "" ||
            slot?.hospital_id === "" ||
            slot?.start_time === ""
          ) {
            isTimeSlotEmpty = true;
            setIsTimeSlotError("Time slots must not be empty*");
          }
        });
      }
    });

    if (isTimeSlotEmpty) {
      return;
    }

    if (isTimeSlotEmpty === false) {
      const newData = addTimePostReq.schedules.map((shed) => {
        if (shed.day === 1 && selectDay.sunday.toggle === false)
          return { day: 1, time_slots: [] };
        if (shed.day === 2 && selectDay.monday.toggle === false)
          return { day: 2, time_slots: [] };
        if (shed.day === 3 && selectDay.tuesday.toggle === false)
          return { day: 3, time_slots: [] };
        if (shed.day === 4 && selectDay.wednesday.toggle === false)
          return { day: 4, time_slots: [] };
        if (shed.day === 5 && selectDay.thursday.toggle === false)
          return { day: 5, time_slots: [] };
        if (shed.day === 6 && selectDay.friday.toggle === false)
          return { day: 6, time_slots: [] };
        if (shed.day === 7 && selectDay.saturday.toggle === false)
          return { day: 7, time_slots: [] };
        else return shed;
      });

      postData(`${process.env.REACT_APP_SET_DOCTOR_AVaAILABILITY}`, {
        ...addTimePostReq,
        schedules: newData,
      });

      setIsTimeSlotError(null);
      setModal2Open(false);
    }
  };

  const deleteTimeSlot = (dayName, hospitalID) => {
    setaddTimePostReq({
      ...addTimePostReq,
      schedules: addTimePostReq?.schedules?.map((schedule) => {
        if (schedule.day === dayName) {
          return {
            ...schedule,
            time_slots: [],
          };
        }
        return {
          ...schedule,
          time_slots: [...(schedule?.time_slots || [])],
        };
      }),
    });
  };
  const deleteSingleTimeSlot = (day, elementID) => {
    setaddTimePostReq({
      ...addTimePostReq,
      schedules: addTimePostReq?.schedules?.map((schedule) =>
        schedule.day === day
          ? {
              ...schedule,
              time_slots: schedule.time_slots.filter(
                (timeSlot) => timeSlot.uniVal !== elementID
              ),
            }
          : schedule
      ),
    });

    if (addTimePostReq?.schedules[day - 1]?.time_slots?.length === 1) {
      const tempDays = Object.keys(selectDay);
      const tempDay = tempDays[day - 1];

      setSelectDay((selectDay) => ({
        ...selectDay,
        [tempDay]: {
          ...selectDay[tempDay],
          toggle: false,
        },
      }));
    }
  };

  const appendSingleTimeSlot = (dayIndex) => {
    const newUniVal =
      addTimePostReq?.schedules?.reduce((maxUniVal, schedule) => {
        return Math.max(
          maxUniVal,
          Math.max(...schedule.time_slots.map((slot) => slot.uniVal))
        );
      }, 0) + 1;

    const newTimeSlot = {
      end_time: "",
      hospital_id: "",
      start_time: "",
      uniVal: newUniVal,
    };

    setaddTimePostReq((prevAddTimePostReq) => ({
      ...prevAddTimePostReq,
      schedules: prevAddTimePostReq?.schedules?.map((schedule) =>
        schedule?.day === dayIndex
          ? {
              ...schedule,
              time_slots: [...schedule?.time_slots, newTimeSlot],
            }
          : schedule
      ),
    }));
  };

  function convertTo12HourFormat(time24) {
    const timeParts = time24?.split(":");
    if (timeParts?.length !== 3 || !/^\d{2}:\d{2}:\d{2}$/.test(time24)) {
      return "....";
    }

    const [hours, minutes] = timeParts.slice(0, 2).map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    let hours12 = hours % 12 || 12;
    const paddedHours = hours12.toString().padStart(2, "0");
    const paddedMinutes = minutes.toString().padStart(2, "0");

    return `${paddedHours}:${paddedMinutes} ${period}`;
  }
  const getDayName = (dayNumber) => {
    let dayName;
    switch (dayNumber) {
      case 1:
        dayName = "Sunday";
        break;
      case 2:
        dayName = "Monday";
        break;
      case 3:
        dayName = "Tuesday";
        break;
      case 4:
        dayName = "Wednesday";
        break;
      case 5:
        dayName = "Thursday";
        break;
      case 6:
        dayName = "Friday";
        break;
      case 7:
        dayName = "Saturday";
        break;
      default:
        dayName = "";
    }
    return dayName;
  };

  const handleImageClick = (imageUrl) => {
    setImagePreviewUrl(imageUrl);
    setIsPreviewOpen(true);
  };
  // Function to close the preview
  const closeImagePreview = () => {
    setIsPreviewOpen(false);
    setImagePreviewUrl(null);
  };

  const [DoctorOverviewData, setDoctorOverviewData] = useState({});
  const OverviewHeadings = {
    email: "Email",
    support_email: "Customer Support Email",
    phone: "Phone No",
    support_phone: "Customer Support No",
    gender: "Gender",
    specialization: "Specialization",
    qualification: "Qualification",
    experirence: "Experience in years",
    certificates: "Certificates",
  };

  useEffect(() => {
    setDoctorOverviewData({
      email: data?.data?.user?.email,
      support_email:
        data?.data?.support_email !== "null" ? data?.data?.support_email : null,
      phone: data?.data?.user?.contact,
      support_phone:
        data?.data?.support_phone_no !== "null"
          ? data?.data?.support_phone_no
          : null,
      gender: data?.data?.user?.gender === 0 ? "Female" : "Male",
      specialization: data?.data?.specialization?.name,
      qualification: data?.data?.qualification,
      experirence: data?.data?.experience_years,
      certificates: data?.data?.documents,
    });
  }, [data]);

  useEffect(() => {}, [DoctorOverviewData]);

  return (
    <>

    {Id && <SpinnerLoaderModal isDataLoading={isLoading} />}

      <div className="row mb-5 pb-lg-5 px-2 pt-4">
        <ImagePreview
          imagePreviewUrl={imagePreviewUrl}
          closeImagePreview={closeImagePreview}
          isPreviewOpen={isPreviewOpen}
          width="30%"
        />
        <div className="col-12  ">
          <p className="mb-0 dashboard-com-top-text">Profile</p>
        </div>

        <div className=" col-12 mt-4 pt-1">
          <p className="mb-0 doctor-header-top-text">
            <Link className="doc-link " to="/">
              DASHBOARD
            </Link>
            <img className="mx-lg-3 ml-2 pr-1 pb-1" src={RightArrow} alt="" />{" "}
            <span>
              <Link className="doc-link " to="doctors">
                <span>DOCTORS</span>{" "}
              </Link>
            </span>{" "}
            <img className="mx-lg-3 ml-2 pr-1 pb-1" src={RightArrow} alt="" />{" "}
            <span style={{ color: "#4FA6D1" }}>ALL DOCTORS</span>{" "}
          </p>
        </div>

        <div className="col-12 my-5 ">
          <div className="view-doctor-profile-border" style={{ width: "100%" }}>
            <div className="row mx-0 view-doctor-profile position-relative">
              <div className="col-12 view-doctor-profile-div1 "></div>
              <div className="col-12  view-doctor-profile-div2 ">
                <p className="mb-0 view-doctor-profile-div2-text1 pt-2">
                  {data?.data?.user?.name}
                </p>
                <p className="mb-0 view-doctor-profile-div2-text2">
                  {data?.data?.specialization?.name}
                </p>
              </div>
              <div className="position-absolute view-doctor-profile-img ">
                {data?.data?.user?.profile_pic ? (
                  <img
                    className="ml-4 w-100 h-100"
                    style={{ objectFit: "cover", borderRadius: "50%" }}
                    src={`${process.env.REACT_APP_IMAGE_URL}/${data?.data?.user?.profile_pic}`}
                    alt=""
                  />
                ) : (
                  <img
                    className="ml-4 w-100 h-100"
                    style={{ objectFit: "cover", borderRadius: "50%" }}
                    src={ImagePlaceHolder} // Replace 'placeholder.jpg' with the path to your placeholder image
                    alt=""
                  />
                )}
              </div>
            </div>

            <div className="row mx-2 my-lg-5 pt-4">
              <div className="col-12  d-flex justify-content-between">
                <button
                  onClick={() => {
                    setDocBtn(0);
                  }}
                  className={`${
                    docBtn === 0
                      ? "view-doctor-btn"
                      : "view-doctor-btn-noactive"
                  }`}
                  style={{ borderRadius: "6px 0px 0px 6px" }}
                >
                  Overview
                </button>
                <button
                  onClick={() => {
                    setDocBtn(1);
                  }}
                  className={`${
                    docBtn === 1
                      ? "view-doctor-btn"
                      : "view-doctor-btn-noactive"
                  }`}
                >
                  Reviews
                </button>
                <button
                  onClick={() => {
                    setDocBtn(2);
                    getTimeTableData();
                  }}
                  className={`${
                    docBtn === 2
                      ? "view-doctor-btn"
                      : "view-doctor-btn-noactive"
                  }`}
                >
                  Time Table
                </button>
                <button
                  onClick={() => {
                    setDocBtn(3);
                  }}
                  className={`${
                    docBtn === 3
                      ? "view-doctor-btn"
                      : "view-doctor-btn-noactive"
                  }`}
                  style={{ borderRadius: "0px 6px 6px 0px" }}
                >
                  Settings
                </button>
              </div>

              {docBtn === 0 && (
                <>
                  <div className="col-12 px-3 my-4 pt-lg-3" style={{wordWrap: 'break-word'}}>
                    <p className="mb-0 doc-overview-detail">
                      {data?.data?.about === "null" ? "" : data?.data?.about}
                    </p>
                  </div>

                  {Object.keys(DoctorOverviewData).map((key) => {
                    if (key !== "certificates") {
                      return (
                        <div className="col-6 mt-4">
                          <p className="mb-2 view-doc-special">
                            {OverviewHeadings[key]}:
                          </p>
                          <div
                            className="d-flex"
                            style={{ alignItems: "baseline" }}
                          >
                            <img
                              className="pr-3 view-doc-sub-special "
                              src={RightArrowSpec}
                            />
                            <span className="view-doc-sub-special">
                              {DoctorOverviewData[key] !== null
                                ? DoctorOverviewData[key]
                                : "---"}
                            </span>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div className="col-6 mt-5">
                          <p className="mb-2 view-doc-special">
                            {OverviewHeadings[key]}:
                          </p>
                          {DoctorOverviewData[key]?.length > 0 ? (
                            <div
                              className="row m-0"
                              style={{
                                height:
                                  DoctorOverviewData[key]?.length > 4
                                    ? "127px"
                                    : "63px",
                                overflowY:
                                  DoctorOverviewData[key]?.length > 4 &&
                                  "scroll",
                                background: "#f5f5f5",
                                borderRadius: "5px",
                                border: "1px solid #f5f5f5",
                              }}
                            >
                              {DoctorOverviewData[key]?.map((document) => {
                                return (
                                  <div
                                    className="col-3 pt-2 d-flex"
                                    style={{
                                      flexDirection: "column",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <a
                                      href={`https://api.maweidi.com.kw/${document?.document}`}
                                      target="_blank"
                                    >
                                      <img
                                        src={DocumentIcon}
                                        className=""
                                      ></img>
                                    </a>
                                    <span
                                      className="view-doc-sub-special"
                                      style={{ fontSize: "10px" }}
                                    >
                                      {document?.original_name
                                        ? document?.original_name?.slice(0, 17)
                                        : "undefined"}
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            <div
                              className="d-flex"
                              style={{ alignItems: "baseline" }}
                            >
                              <img
                                className="pr-3 view-doc-sub-special "
                                src={RightArrowSpec}
                              />
                              <span className="view-doc-sub-special">---</span>
                            </div>
                          )}
                        </div>
                      );
                    }
                  })}
                </>
              )}

              {docBtn === 1 && (
                <>
                  <ReviewPagination Id={Id} />
                  {/* <div className="col-12  my-4 pt-lg-3 px-md-5 ">
                    <p className="mb-0 doc-review-detail mx-5 px-lg-5 text-center">
                      " There is now an abundance of readable dummy texts. These
                      are usually used when a text is required purely to fill a
                      space. These alternatives to the classic Lorem Ipsum texts
                      are often amusing and tell short, funny or nonsensical “
                    </p>
                  </div>

                  <div className="col-12 pb-5">
                    <div className="row">
                      <div className="col-md-4"></div>
                      <div className="col-md-4">
                        <Slider {...settings}>
                          {data &&
                            data.map((el) => {
                              return (
                                <div className="px-2 ">
                                  <CrouselCard el={el} />
                                </div>
                              );
                            })}
                        </Slider>
                      </div>
                      <div className="col-md-4"></div>
                    </div>
                  </div> */}
                </>
              )}

              {docBtn === 2 && (
                <>
                  <div className="col-12  my-4 pt-lg-3 ">
                    <div className="row mx-0">
                      {/* <div className="col-lg-5 pr-0 pb-2 d-flex justify-content-between">
                        <span className="time-table-text pl-3">Time Table</span>{" "}
                        <img
                          className="cursor-pointer"
                          onClick={() => {
                            setModal2Open(true);
                            getTimeTableData();
                          }}
                          src={TimeTablePencil}
                          alt=""
                        />
                      </div> */}

                      <Modal
                        className="doctor-filter-modal"
                        centered
                        open={modal2Open}
                        onOk={() => setModal2Open(false)}
                        onCancel={() => {
                          if (postDoctorAvalibility()) {
                            setModal2Open(false);
                          }

                          const keys = Object.keys(selectDay);

                          keys.forEach((key) => {
                            setSelectDay((prevState) => ({
                              ...prevState,
                              key: {
                                ...prevState.key,
                                toggle: false,
                              },
                            }));
                          });
                        }}
                        width={"60%"}
                        footer={
                          <div className="row px-3 mt-4 mb-2">
                            <div
                              className="col-6 text-danger text-center d-flex align-items-center"
                              style={{ fontWeight: "600" }}
                            >
                              {isTimeSlotError}
                            </div>

                            <div className="col-6 d-flex justify-content-end mt-3">
                              <button
                                className="apply-filter"
                                onClick={() => {
                                  postDoctorAvalibility();
                                }}
                              >
                                Save Schedules
                              </button>
                            </div>
                          </div>
                        }
                      >
                        <div className="row px-2 border-bottom">
                          <div className="col-12 ">
                            <p className="doc-add-filter">Time Table</p>
                          </div>
                        </div>

                        <div className="row px-3 mt-4">
                          <p className=" mb-0 time-edit-text1">
                            Set Standard Hours
                          </p>
                        </div>

                        <div className="row px-3  mt-lg-4 mb-1">
                          <div className="col-lg-4 px-1 d-flex justify-content-end align-items-start pt-1 ">
                            <div
                              className="  mb-lg-3 d-flex justify-content-between"
                              style={{ width: "100%" }}
                            >
                              <span className="time-edit-days-name">
                                Sunday
                              </span>
                              <Space direction="vertical">
                                <Switch
                                  size={300}
                                  checked={selectDay.sunday.toggle}
                                  onClick={(e) => {
                                    setSelectDay((prevState) => ({
                                      ...prevState,
                                      sunday: {
                                        ...prevState.sunday,
                                        toggle: !prevState.sunday.toggle,
                                      },
                                    }));
                                    if (
                                      filterTimeAvalibility(1)[0]?.length < 1
                                    ) {
                                      appendSingleTimeSlot(1);
                                    }
                                    // selectDay?.sunday.toggle ? deleteTimeSlot(1) : deleteTimeSlot()

                                    HandleRemoveTimeSlots(
                                      selectDay?.sunday?.toggle,
                                      "sunday",
                                      1
                                    );
                                  }}
                                />
                              </Space>
                            </div>
                          </div>

                          <div className="col-lg-8 d-flex  flex-column  align-items-lg-end align-items-md-center align-items-start">
                            {
                              // selectDay.sunday.toggle && addTimePostReq?.schedules[1]?.time_slots.length === 0
                              true
                                ? null
                                : //       < div className="mb-3" style={{ width: "100%", display: 'flex', justifyContent: "space-around", alignItems: "center" }}>
                                  //   <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                  //     <div style={{ width: "141px" }}>
                                  //       <CustomDropDown option={specialistOptions} hospitalDopDown={hospitalDopDown} dayId={1} handleChangeSelect={handleChangeSelect} />
                                  //     </div>

                                  //     <TimeChanger staringTimeDrop={staringTimeDrop} dayId={1} selector={true} empetyTime={empetyTime} />

                                  //     <TimeChanger endTimeDrop={endTimeDrop} dayId={1} selector={false} empetyTime={empetyTime} />

                                  //   </div>
                                  //   <span className="pl-lg-3 pl-1">
                                  //     <img
                                  //       className="cursor-pointer"
                                  //       onClick={() => {
                                  //         increaseDayCount("sunday")
                                  //         appendSingleTimeSlot(1)
                                  //       }}
                                  //       src={TimeTableAddBtn}
                                  //       alt=""

                                  //     />
                                  //   </span>

                                  // </div>

                                  null
                            }

                            {selectDay?.sunday?.toggle &&
                              renderLoop(filterTimeAvalibility(1), "sunday", 1)}
                          </div>
                        </div>

                        <div className="row px-3  mb-1">
                          <div className="col-lg-4 px-1 d-flex justify-content-end align-items-start pt-1 ">
                            <div
                              className="  mb-lg-3 d-flex justify-content-between"
                              style={{ width: "100%" }}
                            >
                              <span className="time-edit-days-name">
                                Monday
                              </span>
                              <Space direction="vertical">
                                <Switch
                                  size={300}
                                  checked={selectDay.monday.toggle}
                                  onClick={() => {
                                    setSelectDay((prevState) => ({
                                      ...prevState,
                                      monday: {
                                        ...prevState.monday,
                                        toggle: !prevState.monday.toggle,
                                      },
                                    }));
                                    if (
                                      filterTimeAvalibility(2)[0]?.length < 1
                                    ) {
                                      appendSingleTimeSlot(2);
                                    }
                                    // selectDay?.monday.toggle ? deleteTimeSlot(2) : deleteTimeSlot()
                                    HandleRemoveTimeSlots(
                                      selectDay?.monday?.toggle,
                                      "monday",
                                      2
                                    );
                                  }}
                                />
                              </Space>
                            </div>
                          </div>

                          <div className="col-lg-8 d-flex  flex-column  align-items-lg-end align-items-md-center">
                            {selectDay.monday.toggle
                              ? //  &&  addTimePostReq?.schedules[2]?.time_slots.length === 0

                                null
                              : // <div className="mb-3" style={{ width: "100%", display: 'flex', justifyContent: "space-around", alignItems: "center" }}>
                                //   <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                //     <div style={{ width: "141px" }}>
                                //       <CustomDropDown option={specialistOptions} hospitalDopDown={hospitalDopDown} dayId={2} handleChangeSelect={handleChangeSelect} />
                                //     </div>

                                //     <TimeChanger staringTimeDrop={staringTimeDrop} dayId={2} selector={true} />

                                //     <TimeChanger endTimeDrop={endTimeDrop} dayId={2} selector={false} />

                                //   </div>
                                //   <span className="pl-lg-3 pl-1">
                                //     <img
                                //       className="cursor-pointer"
                                //       onClick={() => {
                                //         increaseDayCount("monday")
                                //         appendSingleTimeSlot(2)
                                //       }}
                                //       src={TimeTableAddBtn}
                                //       alt=""
                                //     />
                                //   </span>

                                // </div>

                                null}
                            {selectDay.monday.toggle &&
                              renderLoop(filterTimeAvalibility(2), "monday", 2)}
                          </div>
                        </div>

                        <div className="row px-3  mb-1">
                          <div className="col-lg-4 px-1 d-flex justify-content-end align-items-start pt-1 ">
                            <div
                              className="  mb-lg-3 d-flex justify-content-between"
                              style={{ width: "100%" }}
                            >
                              <span className="time-edit-days-name">
                                Tuesday
                              </span>

                              <Space direction="vertical">
                                <Switch
                                  size={300}
                                  checked={selectDay.tuesday.toggle}
                                  onClick={() => {
                                    setSelectDay((prevState) => ({
                                      ...prevState,
                                      tuesday: {
                                        ...prevState.tuesday,
                                        toggle: !prevState.tuesday.toggle,
                                      },
                                    }));

                                    if (
                                      filterTimeAvalibility(3)[0]?.length < 1
                                    ) {
                                      appendSingleTimeSlot(3);
                                    }
                                    // selectDay?.tuesday.toggle ? deleteTimeSlot(3) : deleteTimeSlot()
                                    HandleRemoveTimeSlots(
                                      selectDay?.tuesday?.toggle,
                                      "tuesday",
                                      3
                                    );
                                  }}
                                />
                              </Space>
                            </div>
                          </div>

                          <div className="col-lg-8 d-flex  flex-column  align-items-lg-end align-items-md-center">
                            {selectDay.tuesday.toggle
                              ? // <div className="mb-3" style={{ width: "100%", display: 'flex', justifyContent: "space-around", alignItems: "center" }}>
                                //   <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                //     <div style={{ width: "141px" }}>
                                //       <CustomDropDown option={specialistOptions} hospitalDopDown={hospitalDopDown} dayId={3} handleChangeSelect={handleChangeSelect} />
                                //     </div>

                                //     <TimeChanger staringTimeDrop={staringTimeDrop} dayId={3} selector={true} />

                                //     <TimeChanger endTimeDrop={endTimeDrop} dayId={3} selector={false} />

                                //   </div>
                                //   <span className="pl-lg-3 pl-1">
                                //     <img
                                //       className="cursor-pointer"
                                //       onClick={() => {
                                //         increaseDayCount("tuesday")
                                //         appendSingleTimeSlot(3)
                                //       }}
                                //       src={TimeTableAddBtn}
                                //       alt=""
                                //     />
                                //   </span>

                                // </div>
                                null
                              : null}
                            {selectDay.tuesday.toggle &&
                              renderLoop(
                                filterTimeAvalibility(3),
                                "tuesday",
                                3
                              )}
                          </div>
                        </div>

                        <div className="row px-3  mb-1">
                          <div className="col-lg-4 px-1 d-flex justify-content-end align-items-start pt-1 ">
                            <div
                              className="  mb-lg-3 d-flex justify-content-between"
                              style={{ width: "100%" }}
                            >
                              <span className="time-edit-days-name">
                                Wednesday
                              </span>
                              <Space direction="vertical">
                                <Switch
                                  size={300}
                                  checked={selectDay.wednesday.toggle}
                                  onClick={() => {
                                    setSelectDay((prevState) => ({
                                      ...prevState,
                                      wednesday: {
                                        ...prevState.wednesday,
                                        toggle: !prevState.wednesday.toggle,
                                      },
                                    }));

                                    if (
                                      filterTimeAvalibility(4)[0]?.length < 1
                                    ) {
                                      appendSingleTimeSlot(4);
                                    }
                                    // selectDay?.wednesday.toggle ? deleteTimeSlot(4) : deleteTimeSlot()
                                    HandleRemoveTimeSlots(
                                      selectDay?.wednesday?.toggle,
                                      "wednesday",
                                      4
                                    );
                                  }}
                                />
                              </Space>
                            </div>
                          </div>

                          <div className="col-lg-8 d-flex  flex-column  align-items-lg-end align-items-md-center">
                            {selectDay.wednesday.toggle
                              ? // <div className="mb-3" style={{ width: "100%", display: 'flex', justifyContent: "space-around", alignItems: "center" }}>
                                //   <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                //     <div style={{ width: "141px" }}>
                                //       <CustomDropDown option={specialistOptions} hospitalDopDown={hospitalDopDown} dayId={4} handleChangeSelect={handleChangeSelect} />
                                //     </div>

                                //     <TimeChanger staringTimeDrop={staringTimeDrop} selector={true} dayId={4} />

                                //     <TimeChanger endTimeDrop={endTimeDrop} selector={false} dayId={4} />

                                //   </div>
                                //   <span className="pl-lg-3 pl-1">
                                //     <img
                                //       className="cursor-pointer"
                                //       onClick={() => {
                                //         increaseDayCount("wednesday")
                                //         appendSingleTimeSlot(4)
                                //       }}
                                //       src={TimeTableAddBtn}
                                //       alt=""
                                //     />
                                //   </span>

                                // </div>
                                null
                              : null}
                            {selectDay.wednesday.toggle &&
                              renderLoop(
                                filterTimeAvalibility(4),
                                "wednesday",
                                4
                              )}
                          </div>
                        </div>

                        <div className="row px-3  mb-1">
                          <div className="col-lg-4 px-1 d-flex justify-content-end align-items-start pt-1 ">
                            <div
                              className="  mb-lg-3 d-flex justify-content-between"
                              style={{ width: "100%" }}
                            >
                              <span className="time-edit-days-name">
                                Thursday
                              </span>
                              <Space direction="vertical">
                                <Switch
                                  size={300}
                                  checked={selectDay.thursday.toggle}
                                  onClick={() => {
                                    setSelectDay((prevState) => ({
                                      ...prevState,
                                      thursday: {
                                        ...prevState.thursday,
                                        toggle: !prevState.thursday.toggle,
                                      },
                                    }));

                                    if (
                                      filterTimeAvalibility(5)[0]?.length < 1
                                    ) {
                                      appendSingleTimeSlot(5);
                                    }
                                    // selectDay?.thursday.toggle ? deleteTimeSlot(5) : deleteTimeSlot()
                                    HandleRemoveTimeSlots(
                                      selectDay?.thursday?.toggle,
                                      "thursday",
                                      5
                                    );
                                  }}
                                />
                              </Space>
                            </div>
                          </div>

                          <div className="col-lg-8 d-flex  flex-column  align-items-lg-end align-items-md-center">
                            {selectDay.thursday.toggle
                              ? // <div className="mb-3" style={{ width: "100%", display: 'flex', justifyContent: "space-around", alignItems: "center" }}>
                                //   <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                //     <div style={{ width: "141px" }}>
                                //       <CustomDropDown option={specialistOptions} hospitalDopDown={hospitalDopDown} dayId={5} handleChangeSelect={handleChangeSelect} />
                                //     </div>

                                //     <TimeChanger staringTimeDrop={staringTimeDrop} selector={true} dayId={5} />

                                //     <TimeChanger endTimeDrop={endTimeDrop} selector={false} dayId={5} />

                                //   </div>
                                //   <span className="pl-lg-3 pl-1">
                                //     <img
                                //       className="cursor-pointer"
                                //       onClick={() => {
                                //         increaseDayCount("thursday")
                                //         appendSingleTimeSlot(5)
                                //       }}
                                //       src={TimeTableAddBtn}
                                //       alt=""
                                //     />
                                //   </span>

                                // </div>
                                null
                              : null}

                            {selectDay.thursday.toggle &&
                              renderLoop(
                                filterTimeAvalibility(5),
                                "thursday",
                                5
                              )}
                          </div>
                        </div>

                        <div className="row px-3  mb-1">
                          <div className="col-lg-4 px-1 d-flex justify-content-end align-items-start pt-1 ">
                            <div
                              className="  mb-lg-3 d-flex justify-content-between"
                              style={{ width: "100%" }}
                            >
                              <span className="time-edit-days-name">
                                Friday
                              </span>
                              <Space direction="vertical">
                                <Switch
                                  size={300}
                                  checked={selectDay.friday.toggle}
                                  onClick={() => {
                                    setSelectDay((prevState) => ({
                                      ...prevState,
                                      friday: {
                                        ...prevState.friday,
                                        toggle: !prevState.friday.toggle,
                                      },
                                    }));

                                    if (
                                      filterTimeAvalibility(6)[0]?.length < 1
                                    ) {
                                      appendSingleTimeSlot(6);
                                    }
                                    // selectDay?.friday.toggle ? deleteTimeSlot(6) : deleteTimeSlot()
                                    HandleRemoveTimeSlots(
                                      selectDay?.friday?.toggle,
                                      "friday",
                                      6
                                    );
                                  }}
                                />
                              </Space>
                            </div>
                          </div>

                          <div className="col-lg-8 d-flex  flex-column  align-items-lg-end align-items-md-center">
                            {selectDay.friday.toggle &&
                              renderLoop(filterTimeAvalibility(6), "friday", 6)}
                          </div>
                        </div>

                        <div className="row px-3  mb-1">
                          <div className="col-lg-4 px-1 d-flex justify-content-end align-items-start pt-1 ">
                            <div
                              className="  mb-lg-3 d-flex justify-content-between"
                              style={{ width: "100%" }}
                            >
                              <span className="time-edit-days-name">
                                Saturday
                              </span>
                              <Space direction="vertical">
                                <Switch
                                  size={300}
                                  checked={selectDay.saturday.toggle}
                                  onClick={() => {
                                    setSelectDay((prevState) => ({
                                      ...prevState,
                                      saturday: {
                                        ...prevState.saturday,
                                        toggle: !prevState.saturday.toggle,
                                      },
                                    }));

                                    if (
                                      filterTimeAvalibility(7)[0]?.length < 1
                                    ) {
                                      appendSingleTimeSlot(7);
                                    }
                                    // selectDay?.thursday.toggle ? deleteTimeSlot(7) : deleteTimeSlot()
                                    HandleRemoveTimeSlots(
                                      selectDay?.saturday?.toggle,
                                      "saturday",
                                      7
                                    );
                                  }}
                                />
                              </Space>
                            </div>
                          </div>

                          <div className="col-lg-8 d-flex  flex-column  align-items-lg-end align-items-md-center">
                            {selectDay.saturday.toggle
                              ? // <div className="mb-3" style={{ width: "100%", display: 'flex', justifyContent: "space-around", alignItems: "center" }}>
                                //   <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                //     <div style={{ width: "141px" }}>
                                //       <CustomDropDown option={specialistOptions} hospitalDopDown={hospitalDopDown} dayId={7} handleChangeSelect={handleChangeSelect} />
                                //     </div>

                                //     <TimeChanger staringTimeDrop={staringTimeDrop} selector={true} dayId={7} />

                                //     <TimeChanger endTimeDrop={endTimeDrop} selector={false} dayId={7} />

                                //   </div>
                                //   <span className="pl-lg-3 pl-1">
                                //     <img
                                //       className="cursor-pointer"
                                //       onClick={() => {
                                //         increaseDayCount("saturday")
                                //         appendSingleTimeSlot(7)
                                //       }}
                                //       src={TimeTableAddBtn}
                                //       alt=""

                                //     />
                                //   </span>

                                // </div>
                                null
                              : null}
                            {selectDay.saturday.toggle &&
                              renderLoop(
                                filterTimeAvalibility(7),
                                "saturday",
                                7
                              )}
                          </div>
                        </div>
                      </Modal>

                      <div className="col"></div>

                      <div className="col"></div>
                    </div>

                    <div className="row mx-0">
                      <div className="col-lg-5 p-0">
                        <ScheduleLayer
                          itemLabel={"Hospital"}
                          itemIdLabel={"hospital_id"}
                          addTimePostReq={addTimePostReq}
                          specialistOptions={specialistOptions}
                          setModal2Open={setModal2Open}
                          getTimeTableData={getTimeTableData}
                        />
                      </div>

                      <div className="col mt-lg-0 mt-4 d-flex flex-column justify-content-center align-items-center">
                        <img className="" src={TimeTablePhoneIcon} alt="" />
                        <p className="mt-4 pt-2 mb-0 time-table-phone-text">
                          Phone
                        </p>
                        <p className=" px-4 pt-2 mb-0 text-center time-table-phone-text1">
                          Keep Your Doctor's Phone Number Handy for Urgent
                          Medical Situations.
                        </p>
                        <p className="mb-0 pt-4 mt-1 time-table-phone-text2 cursor-pointer">
                          <a
                            className="time-table-phone-text2-link"
                            href={`tel:${data?.data?.user?.contact}`}
                          >
                            {data?.data?.user?.contact}
                          </a>
                        </p>
                      </div>

                      <div className="col mt-lg-0 mt-4 d-flex flex-column justify-content-center align-items-center">
                        <img src={TimeTableMessageIcon} alt="" />
                        <p className="mt-4 pt-2 mb-0 time-table-phone-text">
                          Email
                        </p>
                        <p className=" px-4 pt-2 mb-0 text-center time-table-phone-text1">
                          Keep Your Doctor's Mail Handy for Urgent Medical
                          Situations.
                        </p>

                        <p className="mb-0 pt-4 mt-1 time-table-phone-text2 cursor-pointer">
                          <a
                            className="time-table-phone-text2-link"
                            href={`mailto:${data?.data?.user?.email}`}
                          >
                            {data?.data?.user?.email}
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {docBtn === 3 && (
                <>
                  <DoctorSetting id={Id} rawData={data?.data} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewDoctor;
